import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendUrl } from '../utils/env';

export const types = {
  GET_PENDING_NOTIFICATIONS_REQUEST: 'notifications/pending/get/pending',
  GET_PENDING_NOTIFICATIONS_SUCCESS: 'notifications/pending/get/fulfilled',
  GET_PENDING_NOTIFICATIONS_FAILURE: 'notifications/pending/get/rejected',
  RESET_NOTIFICATIONS_ERRORS: 'errors/notifications/reset'
};

export const resetNotificationsErrors = createAction('errors/notifications/reset');

export const getPendingNotifications = createAsyncThunk(
  'notifications/pending/get',
  async (payload: { userId: string, activePage: number }) => {
    const { userId, activePage } = payload;
    const response = await axios.get(
      `${backendUrl}/Notifications/receiverId/${userId}?pageNumber=${activePage}`
    );
    return {
      notifications: response.data.notifications,
      total: response.data.total,
    };
  }
);
