import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendUrl } from '../utils/env';

export const types = {
  CREATE_EXAMPLE_REQUEST: 'example/post/pending',
  CREATE_EXAMPLE_SUCCESS: 'example/post/fulfilled',
  CREATE_EXAMPLE_FAILURE: 'example/post/rejected',
  GET_EXAMPLES_REQUEST: 'example/get/pending',
  GET_EXAMPLES_SUCCESS: 'example/get/fulfilled',
  GET_EXAMPLES_FAILURE: 'example/get/rejected',
};


export const createFAQ = createAsyncThunk(
  'faq/post',
  async ({ payload, token }: any, thunkAPI) => {
    let authToken = '';
    if (token) {
      authToken = `Bearer ${token}`;
    }
    const headers = {
      Authorization: authToken,
      'Content-Type': 'application/json',
    };
    try {
      const response = await axios.post(`${backendUrl}/Example`, payload, {
        headers,
      });
      thunkAPI.dispatch(getExamples());
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getExamples = createAsyncThunk('example/get', async () => {
  const response = await axios.get(`${backendUrl}/Example`);
  return response.data;
});
