import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ISearchParameters } from '../types/Search';
import { backendUrl } from '../utils/env';

export const types = {
  GET_SEARCH_REQUEST: 'search/get/pending',
  GET_SEARCH_SUCCESS: 'search/get/fulfilled',
  GET_SEARCH_FAILURE: 'search/get/rejected',
  SET_SEARCH_PARAMS: 'search/params',
  RESET_SEARCH_PARAMS: 'search/resetParams',
};

export const search = createAsyncThunk(
  'search/get',
  async (data: ISearchParameters, thunkAPI) => {
    const state =
      data.location.state === 'All States' ? undefined : data.location.state;

    try {
      let response;

      let industryParams = '';
      const industries = data.industryPayload.industries;
      Object.keys(industries).forEach((industry) => {
        industryParams += `&${industry}=${industries[industry].value}`;
      });
      let opportunitiesParams = '';
      const opportunities = data.opportunityPayload.opportunities;
      if (data.opportunityPayload) {
        Object.keys(opportunities).forEach((opportunity) => {
          opportunitiesParams += `&${opportunity}=${
            data.isSupplier
              ? opportunities[opportunity]
              : opportunities[opportunity].value
          }`;
        });
      }
      response = await axios.get(
        `${backendUrl}/Search/suppliers?isCustomer=${!data.isSupplier}&shippingTime=${
          data.shippingTime
        }&sellingCycle=${data.sellingCycle}&state=${state}&city=${
          data.location.city
        }&industrySelected=${
          data.industryPayload.industrySelected
        }&pageNumber=${data.page}&userId=${
          data.userId
        }${industryParams}${opportunitiesParams}`
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const setSearchParams = createAction<ISearchParameters>('search/params');

export const resetSearchParams = createAction<void>('search/resetParams');
