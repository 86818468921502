import React, { useEffect, useState } from "react";
import {
  connectionStatus,
  ConnectionWithProfile,
} from "../../types/Connection";
import ConnectionsGridItem from "../ConnectionItem/ConnectionsGridItem";
import { FadeLoader } from "react-spinners";
import { getDisplayName } from "../../utils/connectionUtils";
import { ListItemTypes } from "../../utils/validations";
import { getIsoCode } from "../../utils/getIsoCode";
import { getFieldOfOpportunities } from "../../utils/searchUtils";
import { SearchResult } from "../SearchResult/SearchResult";
import { IFieldOfOpportunity } from "../../types/SupplierInfo";

interface Props {
  // Props for both Connections and Search Results
  data: any;
  loading: boolean;
  type: string;
  userId: string;
  noDataMessage?: string;
  // Props for Connections
  openModal?: (type: string, name: string, payload: any) => void;
  changeStatus?: (
    connectionId: number,
    newStatus: number,
    name: string
  ) => void;
  tab?: string;
  // Props for Search Results
  allStates?: Array<any>;
  handleRequestConnection?: (
    supplier: {
      email: string;
      sub: string;
      fieldsOfOpportunity: IFieldOfOpportunity;
    },
    name: string
  ) => void;
  requestMeeting?: (connection: ConnectionWithProfile) => void;
  requestMeetingSearch?: (
    receiverName: string,
    receiverEmail: string,
    recipientId: string
  ) => void;
}

const GridView = ({
  data,
  loading,
  type,
  noDataMessage,
  userId,
  openModal,
  changeStatus,
  tab,
  allStates = [],
  handleRequestConnection,
  requestMeeting,
  requestMeetingSearch,
}: Props) => {
  const [minHeight, setMinHeight] = useState("40px");

  useEffect(() => {
    if (type === ListItemTypes.CONNECTION) {
      if (
        data.some(
          (connection) =>
            getDisplayName(connection, connection.senderId !== userId).length >
            24
        )
      ) {
        setMinHeight("64px");
      } else {
        setMinHeight("40px");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const getConnectionType = (connection: ConnectionWithProfile) => {
    let status = "";
    if (connection.status === connectionStatus.PENDING) {
      status = connection.senderId === userId ? "sent" : "received";
    } else if (connection.status === connectionStatus.ACCEPTED) {
      status = "connected";
    }
    return status;
  };

  const getItem = (data, index) => {
    switch (type) {
      case ListItemTypes.CONNECTION:
        return (
          <ConnectionsGridItem
            connection={data}
            key={index}
            type={getConnectionType(data)}
            userId={userId}
            openModal={openModal}
            changeStatus={changeStatus}
            minHeight={minHeight}
            requestMeeting={requestMeeting}
          />
        );

      case ListItemTypes.SEARCH_RESULT:
        const searchProps = {
          name: data.firstName + " " + data.lastName,
          email: data.email,
          id: data.sub,
          state:
            data.state && data.state.length > 0
              ? getIsoCode(data.state, allStates)
              : "",
          city: data.city,
          industries: data.industries,
          fieldOfOpportunity: data.fieldofopportunity
            ? getFieldOfOpportunities(data.fieldofopportunity)
            : [],
          image: data.picture,
          supplierId: data.sub,
          isSupplier: data.isSupplier,
          connectionStatus: data.connectionStatus,
        };

        return (
          <SearchResult
            key={`search-result-${index}`}
            handleRequestConnection={() =>
              handleRequestConnection(
                {
                  email: data.email,
                  sub: data.sub,
                  fieldsOfOpportunity: data.fieldofopportunity,
                },
                searchProps.name
              )
            }
            grid
            hideButton={data.sub === userId}
            requestMeetingSearch={requestMeetingSearch}
            {...searchProps}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="d-flex justify-content-center mx-0 connection-width-hack">
      {data.length > 0 ? (
        <div
          className={`grid-wrapper ${
            type === ListItemTypes.CONNECTION
              ? "connections-grid"
              : "search-grid"
          }`}
        >
          {data.map((itemData, index) => getItem(itemData, index))}
        </div>
      ) : loading ? (
        <div className="pl-3 mt-5 d-flex justify-content-center">
          <FadeLoader color={"#F8DCD4"} />
        </div>
      ) : tab && tab === "pending" ? (
        <div className="mt-5 d-flex justify-content-center text-center">
          <p className="font-primary text-black font-20">
            There are no connections requests to show.
          </p>
        </div>
      ) : tab && tab === "connected" ? (
        <div className="d-flex flex-column justify-content-center">
          <div className="mt-5 d-flex justify-content-center">
            <p className="font-primary text-black font-20">
              People are waiting for you!
            </p>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <a
              href="/guided-search/area"
              className="font-primary text-black font-20"
            >
              Start connecting!
            </a>
          </div>
        </div>
      ) : (
        <div className="mt-5 d-flex justify-content-center text-center">
          <p className="font-primary text-black font-weight-bold font-20">
            {noDataMessage ? noDataMessage : "There is nothing to show."}
          </p>
        </div>
      )}
    </div>
  );
};

export default GridView;
