import { useHistory } from 'react-router-dom';

interface Props {
  role: string;
  icon: string;
  description: string;
  url: string;
}

const RoleSection = ({ role, icon, description, url }: Props) => {
  const history = useHistory();

  return (
    <div className="role-selection px-3">
      <div className="role-selection-icon d-flex justify-content-center mt-4 mt-md-5">
        <div className="d-flex justify-content-center align-items-center pink-circle text-center text-black">
          <i className={icon} />
        </div>
      </div>
      <div className="role-selection-title d-flex justify-content-center">
        <p className="text-center text-black font-weight-bold pt-1">
          {`${role} User`}
        </p>
      </div>
      <span className="role-selection-container d-flex  justify-content-md-center justify-content-start text-left text-md-center">
        <div className="role-selection-description">
          <span className="text-black font-16">{description}</span>
        </div>
      </span>
      <span className="role-selection-container d-flex  justify-content-md-center justify-content-start text-left text-md-center">
        <div className="role-selection-action mt-3 justify-content-center d-flex align-items-center">
          <button
            className="btn btn-lg btn-block btn-black text-uppercase text-pinky align-items-center rounded-5"
            onClick={() => {
              history.push(url);
            }}
          >
            {role}
          </button>
        </div>
      </span>
    </div>
  );
};

export default RoleSection;
