import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { RingLoader } from "react-spinners";
import { getCustomerById } from "../../actions/customer";
import { RootState } from "../../reducers";
import { CustomerCompleteProfile } from "../../types/CustomerInfo";
import emptyStateProfilePic from "../../styles/img/empty-state-profile-pic.svg";
import { trimName } from "../../utils/validations";
import { authContext } from "../../components/Authentication/CognitoContextProvider";
import {
  connectionStatus,
  ConnectionWithProfile,
} from "../../types/Connection";
import {
  getConnectionByIds,
  requestConnection,
  resetConnectionErrors,
} from "../../actions/connection";
import { toast } from "react-toastify";
import { search } from "../../actions/search";
import { redirectToLogin } from "../../utils/redirectToLogin";
import Auth from "@aws-amplify/auth";
import { requestMeeting } from "../../utils/connectionUtils";
import { Link } from "react-router-dom";
import { isAdmin } from "../../auth/userUtils";
import { AdmissionStatus } from "../../types/User";

export const ViewCustomerProfile = () => {
  const { auth } = useContext(authContext);
  const params: { customerId: string } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [trimmedName, setTrimmedName] = useState("");
  const [activateToast, setActivateToast] = useState(false);
  const customerProfile: CustomerCompleteProfile = useSelector(
    (state: RootState) => state.customer.customerInfo
  );

  const { loading } = useSelector((state: RootState) => state.customer);

  const connection: ConnectionWithProfile = useSelector(
    (state: RootState) => state.connection.connection
  );

  const {
    loading: connectionLoading,
    requestFinished: connectionRequestFinished,
    hasErrors: connectionHasErrors,
    errorMessage: connectionErrorMessage,
  } = useSelector((state: RootState) => state.connection);

  const searchParameters = useSelector(
    (state: RootState) => state.search.searchParameters
  );

  const opportunities: {
    name: string;
    description: string;
    attribute: string;
    icon: string;
  }[] = [
    {
      name: "Mentorship",
      description: "(Provide guidance)",
      attribute: "mentorships",
      icon: "icon-mentor",
    },
    {
      name: "Investment",
      description: "(Looking to invest)",
      attribute: "investment",
      icon: "icon-investment",
    },
    {
      name: "Supplier Diversity",
      description: "(Corporations)",
      attribute: "corporations",
      icon: "icon-corporations",
    },
    {
      name: "Procurement",
      description: "(Big box & Boutique)",
      attribute: "",
      icon: "icon-procurement",
    },
    {
      name: "Hiring",
      description: "(Contracts or Positions)",
      attribute: "openToWork",
      icon: "icon-hiring",
    },
    {
      name: "Partnerships",
      description: "(Non-Monetary & Monetary)",
      attribute: "partnerships",
      icon: "icon-partnership",
    },
  ];

  useEffect(() => {
    if (params.customerId) {
      dispatch(getCustomerById(params.customerId));
      if (auth && auth.data) {
        dispatch(
          getConnectionByIds({
            user1Id: auth.data.attributes.sub,
            user2Id: params.customerId,
          })
        );
      } else redirectToLogin(history, history.location.pathname);
    } else {
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (customerProfile) {
      setTrimmedName(
        trimName(customerProfile.firstName, customerProfile.lastName)
      );
    }
  }, [customerProfile]);

  useEffect(() => {
    if (connectionRequestFinished && !activateToast) {
      dispatch(resetConnectionErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionRequestFinished, activateToast]);

  const handleRequestConnection = () => {
    const payload = {
      senderEmail: auth.data.attributes.email,
      receiverEmail: customerProfile.email,
      status: connectionStatus.PENDING,
      senderId: auth.data.attributes.sub,
      receiverId: customerProfile.sub,
      fieldsOfOpportunity: customerProfile.fieldofopportunity,
    };
    setActivateToast(true);
    dispatch(requestConnection(payload));
  };

  const shouldDisplayConnectButton = () => {
    if (
      customerProfile &&
      customerProfile.admissionStatus !== AdmissionStatus.APPROVED
    )
      return false;
    if (params.customerId) {
      if (auth && auth.data && auth.data.attributes.sub === params.customerId) {
        return false;
      } else return true;
    }
    return false;
  };

  const handleRequestMeeting = async (connection: ConnectionWithProfile) => {
    try {
      let token = "";
      if (auth.data) {
        const getSession = await Auth.currentSession();
        token = getSession.getIdToken().getJwtToken();
      } else {
        toast.error("Your session has expired, please login again");
        redirectToLogin(history, "/my-connections");
        return;
      }
      setActivateToast(true);
      const message = await requestMeeting(connection, auth, token);
      toast.success(message);
    } catch (err) {
      toast.error("An error occured while attempting to request the meeting", {
        toastId: "request-meeting-error",
      });
    }
  };

  const showToast = () => {
    if (connectionRequestFinished && activateToast) {
      if (connectionHasErrors) {
        toast.error(connectionErrorMessage);
      } else {
        dispatch(search(searchParameters));
        toast.success(
          `You have requested to connect with ${
            customerProfile ? trimmedName : 'this user'
          }.`,
          { toastId: "request-connection-toast" }
        );
      }
      setActivateToast(false);
      dispatch(resetConnectionErrors());
    }
    return null;
  };

  const getCorrespondingButton = (connectionStatus: number) => {
    if (connectionStatus === 1) {
      return (
        <>
          <button
            className='btn btn-black btn-md text-white rounded-5 mt-2 px-5 font-weight-normal'
            type='button'
            disabled={true}
          >
            <label className='font-primary font-weight-normal d-flex justify-content-center align-items-center my-0'>
              <i className='icon-link text-white mr-2 font-16 align-middle ' />
              Pending
            </label>
          </button>
        </>
      );
    }

    if (connectionStatus === 2) {
      return (
        <>
          {" "}
          <button
            className='btn btn-black btn-md text-white rounded-5 mt-2 px-5 font-weight-normal'
            onClick={() => handleRequestMeeting(connection)}
          >
            Request a Meeting
          </button>
        </>
      );
    }
    return (
      <>
        <button
          className='btn btn-black btn-md text-white rounded-5 mt-2 px-5 font-weight-normal'
          type='button'
          onClick={() => {
            handleRequestConnection();
          }}
          disabled={connectionLoading}
        >
          <i className='icon-link text-white mr-2' />
          Connect with me
        </button>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>BSN - Champion Profile</title>
      </Helmet>
      <main
        className="body-min-h90 container-lg px-0 px-lg-3 pt-3 supplier-profile"
        style={{ marginBottom: '10rem' }}
      >
        {loading ? (
          <div className="align-middle mt-5">
            <div className="d-flex justify-content-center">
              <RingLoader size={120} loading={loading} />
            </div>
            <p className="loader-text text-center text-black">
              Loading Profile
            </p>
          </div>
        ) : (
          <>
            <div className="row d-flex justify-content-between mr-0 mr-md-3 px-4">
              <button
                className="d-flex justify-content-center align-items-center go-back-btn text-pinky ml-3"
                onClick={() => {
                  history.goBack();
                }}
              >
                <i className="icon-chevron-left" />
              </button>
              {(isAdmin(auth.data) ||
                (customerProfile &&
                  auth &&
                  auth.data &&
                  auth.data.attributes.sub === params.customerId)) && (
                <Link
                  className="btn-btn-link text-decoration-none"
                  to={`/champion/edit/${params.customerId}`}
                >
                  <i className="icon-settings text-black font-40" />
                </Link>
              )}
            </div>
            <div className="avatar-opener d-flex justify-content-center align-items-center pt-4 pb-2">
              <div className="supplier-big-avatar">
                <LazyLoadImage
                  referrerPolicy="no-referrer"
                  src={
                    customerProfile && customerProfile.picture
                      ? customerProfile.picture
                      : emptyStateProfilePic
                  }
                  alt=""
                  effect="blur"
                />
              </div>
            </div>
            <div className="text-center mt-3 ">
              <h1 className="h4 text-black font-primary font-weight-normal mb-1">
                {customerProfile ? `${trimmedName}` : 'Profile Not Found'}
              </h1>
            </div>
            {shouldDisplayConnectButton() && (
              <section className="body pb-3 px-4 pt-0 mb-3 text-center">
                {getCorrespondingButton(connection ? connection.status : 0)}
              </section>
            )}
            <br />
            <section className="business-opportunity mt-4 px-3">
              {customerProfile && customerProfile.companyName && (
                <div className="bg-black text-center py-4">
                  <h2
                    className="h4 text-pinky font-primary font-weight-bold"
                    style={{ fontSize: '16pt' }}
                  >
                    {customerProfile.companyName}
                  </h2>
                </div>
              )}
              {customerProfile && customerProfile.fieldofopportunity && (
                <section className="business-opportunity mb-5">
                  <div className="bg-pinky text-center py-4 mb-3">
                    <h1
                      className="h3 text-black font-primary font-weight-bold"
                      style={{ fontSize: '18pt' }}
                    >
                      Area of Opportunity
                    </h1>
                  </div>
                  <div className="row px-3">
                    {opportunities.map((opportunity, index) => {
                      return customerProfile.fieldofopportunity[
                        opportunity.attribute
                      ] ||
                        (opportunity.name === 'Procurement' &&
                          (customerProfile.fieldofopportunity.bigBoxRetailer ||
                            customerProfile.fieldofopportunity
                              .smallBoutiques)) ? (
                        <div
                          className=" col-6 px-0 text-black font-primary d-flex align-items-center py-2"
                          key={`business-opportunities-${opportunity.name}-${index}`}
                        >
                          <div className="col-2 p-0 mr-2 mr-md-0">
                            <i
                              className={`${opportunity.icon} customer-opportunity-font`}
                            />
                          </div>
                          <div className="col-10 text-uppercase">
                            <p className="customer-opportunity-font font-weight-bold my-0">
                              {opportunity.name}
                            </p>
                            <p className="customer-opportunity-font font-weight-normal description my-0">
                              {opportunity.description}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      );
                    })}
                  </div>
                </section>
              )}
            </section>
            {/* <section className="business-opportunity customer-service-product mt-4 px-3">
              <div className="bg-pinky text-center py-4">
                <h2
                  className="h4 text-black font-primary font-weight-bold px-1"
                  style={{ fontSize: '16pt' }}
                >
                  {`I'm interested in a Service/Product with`}
                </h2>
              </div>
              <table className="table bgv table-hover table-borderless font-14 table-va-center">
                <tbody className="text-black">
                  <tr>
                    <td className="d-flex align-items-center align-left font-weight-bold text-uppercase font-16 align-middle">
                      <i className={`icon-calendar text-black font-32 mr-2`} />
                      Shipping Time
                    </td>
                    <td className="align-items-center align-left font-16 text-muted align-middle">
                      3 Business days
                    </td>
                  </tr>
                  <tr>
                    <td className="d-flex align-items-center align-left font-weight-bold text-uppercase font-16 align-middle">
                      <i
                        className={`icon-productupdate text-black font-32 mr-2`}
                      />
                      Product Update
                    </td>
                    <td className="align-items-center align-left font-16 text-muted align-middle">
                      Trimonthly
                    </td>
                  </tr>
                  <tr>
                    <td className="d-flex align-items-center align-left font-weight-bold text-uppercase font-16 align-middle">
                      <i
                        className={`icon-productservice text-black font-32 mr-2`}
                      />
                      Selling Cycle
                    </td>
                    <td className="align-items-center align-left font-16 text-muted align-middle">
                      Daily
                    </td>
                  </tr>
                </tbody>
              </table>
            </section> */}
          </>
        )}
        {showToast()}
      </main>
    </>
  );
};
