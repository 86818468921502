import Modal from 'react-modal';

interface Props {
  modalMessage: string;
  isModalOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
}

export const ConfirmationModal = ({
  modalMessage,
  isModalOpen,
  closeModal,
  onConfirm,
  confirmText,
  cancelText,
}: Props) => {
  const customStyles = {
    content: {
      background: 'transparent',
      border: 'none',
      padding: 'none',
      position: 'absolute',
      display: 'block',
      margin: '0',
      transform: 'translate(-50%, -30%)',
      width: '90%',
      maxWidth: '360px',
      inset: 'unset',
      overflow: 'initial',
    },
    overlay: {
      zIndex: '3',
      backgroundColor: 'rgba(23, 41, 64, 0.75)',
    },
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={500}
      ariaHideApp={false}
    >
      <div className="modal-content" style={{ borderRadius: '14.1562px' }}>
        <div className="modal-header border-0">
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            onClick={closeModal}
            aria-label="Close"
          >
            <span className="text-black" aria-hidden="true">
              <i className="icon-close-x" />
            </span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <span className="d-flex flex-column text-center font-weight-bold text-black font-primary px-3 py-3 font-20">
            {modalMessage}
          </span>
        </div>
        <div className="modal-footer justify-content-center border-0 pb-4">
          <div className="row">
            <div className="col-auto text-center order-1 my-2">
              <button
                className="btn-lg btn modal-cancel-btn text-black font-primary rounded"
                onClick={() => {
                  closeModal();
                }}
              >
                <span>{cancelText || 'Cancel'}</span>
              </button>
            </div>
            <div className="col-auto text-center order-1 my-2">
              <button
                className="btn btn-lg btn-primary btn-black rounded"
                onClick={() => {
                  onConfirm();
                  closeModal();
                }}
              >
                {confirmText || 'Confirm'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
