import React, { Dispatch, SetStateAction, useState } from 'react';
import { toast } from 'react-toastify';
import youtubeUrl from 'youtube-url';
import { fileIsPDF } from '../../utils/validations';

interface Props {
  bio: string;
  setBio: Dispatch<SetStateAction<string>>;
  facebookUrl: string;
  setFacebookUrl: Dispatch<SetStateAction<string>>;
  twitterUrl: string;
  setTwitterUrl: Dispatch<SetStateAction<string>>;
  instagramUrl: string;
  setInstagramUrl: Dispatch<SetStateAction<string>>;
  linkedinUrl: string;
  setLinkedinUrl: Dispatch<SetStateAction<string>>;
  websiteUrl: string;
  setWebsiteUrl: Dispatch<SetStateAction<string>>;
  pitchVideoUrl: string;
  setPitchVideoUrl: Dispatch<SetStateAction<string>>;
  pitchDeckDoc: File;
  setPitchDeckDoc: Dispatch<SetStateAction<File>>;
  businessCanvasDoc: File;
  setBusinessCanvasDoc: Dispatch<SetStateAction<File>>;
  salesSheetDoc: File;
  setSalesSheetDoc: Dispatch<SetStateAction<File>>;
  capabilitiesStatementDoc: File;
  setCapabilitiesStatementDoc: Dispatch<SetStateAction<File>>;
  setActivePage?: Dispatch<SetStateAction<number>>;
  isEdit?: boolean;
  pitchDeckUrl?: string;
  businessCanvasUrl?: string;
  salesSheetUrl?: string;
  capabilitiesStatementUrl?: string;
  handleDocModification?: (docType: string, deleted: boolean) => void;
  isAdmin?: boolean;
}

const SupplierOtherInformation = ({
  bio,
  setBio,
  facebookUrl,
  setFacebookUrl,
  twitterUrl,
  setTwitterUrl,
  instagramUrl,
  setInstagramUrl,
  linkedinUrl,
  setLinkedinUrl,
  websiteUrl,
  setWebsiteUrl,
  pitchVideoUrl,
  setPitchVideoUrl,
  pitchDeckDoc,
  setPitchDeckDoc,
  businessCanvasDoc,
  setBusinessCanvasDoc,
  salesSheetDoc,
  setSalesSheetDoc,
  capabilitiesStatementDoc,
  setCapabilitiesStatementDoc,
  setActivePage,
  isEdit,
  pitchDeckUrl,
  businessCanvasUrl,
  salesSheetUrl,
  capabilitiesStatementUrl,
  handleDocModification,
  isAdmin = false,
}: Props) => {
  const [highlightPitchVideoField, setHighlightPitchVideoField] =
    useState(false);
  const [highlightPitchDeck, setHighlightPitchDeck] = useState(false);

  const highlightRequiredFields = () => {
    setHighlightPitchVideoField(
      pitchVideoUrl.length !== 0 && !youtubeUrl.valid(pitchVideoUrl)
    );
    setHighlightPitchDeck(!pitchDeckDoc);
  };

  const setDocument = (document: File, input: string) => {
    switch (input) {
      case 'pitchDeck':
        setPitchDeckDoc(document);
        setHighlightPitchDeck(false);
        isEdit && handleDocModification(input, false);
        break;
      case 'businessCanvas':
        setBusinessCanvasDoc(document);
        isEdit && handleDocModification(input, false);
        break;
      case 'salesSheet':
        setSalesSheetDoc(document);
        isEdit && handleDocModification(input, false);
        break;
      case 'capabilitiesStatement':
        setCapabilitiesStatementDoc(document);
        isEdit && handleDocModification(input, false);
        break;
      default:
        console.error('Could not identify the document you tried to upload.');
    }
  };

  const handlePDFFileChange = (files: FileList, input: string) => {
    if (files && files[0]) {
      const selectedFile = files[0];
      if (selectedFile.size > 30000000) {
        toast.error('File can not be larger than 30MB');
      } else {
        if (fileIsPDF(selectedFile.name)) {
          setDocument(selectedFile, input);
        } else {
          toast.error('File must be a PDF');
        }
      }
    } else {
      setDocument(null, input);
    }
  };

  const validateFields = () => {
    return (
      !pitchDeckDoc ||
      (pitchVideoUrl.length !== 0 && !youtubeUrl.valid(pitchVideoUrl))
    );
  };

  return (
    <div className="section mx-lg-5 mt-4">
      <div
        className={`d-flex flex-row text-left text-center-md justify-content-left justify-content-md-center align-items-center mt-md-4 ${
          isEdit ? 'mb-0' : 'mb-5'
        }`}
      >
        <div
          className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
          style={{ height: '35px', width: '35px' }}
        >
          <span className="my-0 font-primary text-black font-20">{`${
            isEdit && !isAdmin ? '3' : '2'
          }`}</span>
        </div>
        <span className="text-black py-2 ml-3 text-uppercase font-20">
          Other Information
        </span>
      </div>
      <div className="d-flex justify-content-center">
        <form className="auth-form mt-4 supplier-onboarding-container">
          <div className="row d-flex justify-content-center py-2">
            <div className="form-group col-md-10">
              <label className="form-label text-black font-primary font-weight-bolder">
                Bio{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <textarea
                className="form-control border-left-0 border-top-0 border-right-0 border-black rounded-0"
                id="biography"
                placeholder="Enter a brief description of who you are"
                maxLength={750}
                value={bio}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value && value.length > 750) value = value.substr(0, 750);
                  setBio(value);
                }}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center py-2">
            <div className="form-group col-12 col-md-5">
              <label className="form-label text-black font-primary font-weight-bolder">
                Facebook URL{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                type="text"
                id="facebook-url"
                placeholder="www.facebook.com/username"
                value={facebookUrl}
                onChange={(event) => {
                  setFacebookUrl(event.target.value);
                }}
              />
            </div>
            <div className="form-group col-12 col-md-5">
              <label className="form-label text-black font-primary font-weight-bolder">
                Twitter URL{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                type="text"
                id="twitter-url"
                placeholder="www.twitter.com/username"
                value={twitterUrl}
                onChange={(event) => {
                  setTwitterUrl(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center py-2">
            <div className="form-group col-12 col-md-5">
              <label className="form-label text-black font-primary font-weight-bolder">
                Instagram URL{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                type="text"
                id="instagram-url"
                placeholder="www.instagram.com/username"
                value={instagramUrl}
                onChange={(event) => {
                  setInstagramUrl(event.target.value);
                }}
              />
            </div>
            <div className="form-group col-12 col-md-5">
              <label className="form-label text-black font-primary font-weight-bolder">
                LinkedIn URL{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                type="text"
                id="linkedin-url"
                placeholder="www.linkedin.com/username"
                value={linkedinUrl}
                onChange={(event) => {
                  setLinkedinUrl(event.target.value);
                }}
              />
            </div>
          </div>
          <div className="row d-flex justify-content-center py-2">
            <div className="form-group col-12 col-md-5">
              <label className="form-label text-black font-primary font-weight-bolder">
                Website URL{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                type="text"
                id="website-url"
                placeholder="www.website.com"
                value={websiteUrl}
                onChange={(event) => {
                  setWebsiteUrl(event.target.value);
                }}
              />
            </div>
            <div className="form-group col-12 col-md-5">
              <label className="form-label text-black font-primary font-weight-bolder">
                Pitch Video URL{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                type="text"
                id="pitch-video-url"
                placeholder="www.youtube.com/watch?v=ABCDEFGH"
                value={pitchVideoUrl}
                onChange={(event) => {
                  let value = event.target.value;
                  setHighlightPitchVideoField(
                    value.length !== 0 && !youtubeUrl.valid(value)
                  );
                  setPitchVideoUrl(value);
                }}
              />
              {highlightPitchVideoField && (
                <div className="input-helper mb-2">
                  <small className={`text-danger`}>
                    <strong>Invalid video URL format</strong>
                  </small>
                </div>
              )}
            </div>
          </div>
          <div className="row d-flex justify-content-center py-2">
            <div className="form-group col-12 col-md-5">
              <label
                htmlFor="pitch-deck-document"
                className="form-label text-black font-primary font-weight-bolder"
              >
                Pitch Deck Document<span className="text-danger">*</span>
              </label>
              <div className="row">
                <div className="col-auto">
                  {pitchDeckDoc || pitchDeckUrl ? (
                    <button
                      type="button"
                      className="remove btn btn-link text-black text-decoration-none px-0"
                      onClick={(e) => {
                        e.preventDefault();
                        setPitchDeckDoc(null);
                        setHighlightPitchDeck(true);
                        isEdit && handleDocModification('pitchDeck', true);
                      }}
                    >
                      <i className="icon-close-x font-20 text-black" />
                      <span className="sr-only">
                        Remove Pitch Deck Document
                      </span>
                    </button>
                  ) : (
                    <>
                      <button
                        className="upload-doc-btn text-black px-0"
                        onClick={(e) => {
                          e.preventDefault();
                          const pitchDeckDocInput = document.getElementById(
                            'pitch-deck-document'
                          );
                          if (pitchDeckDocInput) pitchDeckDocInput.click();
                        }}
                      >
                        <i className="icon-upload-cloud font-32 text-black"></i>
                        <span className="sr-only">Pitch Deck Document</span>
                      </button>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        id="pitch-deck-document"
                        name="pitch-deck-document"
                        accept="application/pdf"
                        onChange={(e) => {
                          handlePDFFileChange(e.target.files, 'pitchDeck');
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="col align-items-center d-flex">
                  {pitchDeckUrl ? (
                    <a href={pitchDeckUrl} className="text-black">
                      View Pitch Deck
                    </a>
                  ) : pitchDeckDoc ? (
                    <span className="text-black text-truncate">
                      {pitchDeckDoc.name}
                    </span>
                  ) : (
                    <span className="text-muted">Upload Pitch Deck</span>
                  )}
                </div>
              </div>
              {highlightPitchDeck && (
                <div className="input-helper mb-2">
                  <small className={`text-danger`}>
                    <strong>Pitch Deck is required</strong>
                  </small>
                </div>
              )}
            </div>
            <div className="form-group col-12 col-md-5">
              <label
                htmlFor="business-canvas"
                className="form-label text-black font-primary font-weight-bolder"
              >
                Business Canvas{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <div className="row">
                <div className="col-auto">
                  {businessCanvasDoc || businessCanvasUrl ? (
                    <button
                      type="button"
                      className="remove btn btn-link text-black text-decoration-none px-0"
                      onClick={(e) => {
                        e.preventDefault();
                        setBusinessCanvasDoc(null);
                        isEdit && handleDocModification('businessCanvas', true);
                      }}
                    >
                      <i className="icon-close-x font-20 text-black" />
                      <span className="sr-only">Remove Business Canvas</span>
                    </button>
                  ) : (
                    <>
                      <button
                        className="upload-doc-btn text-black px-0"
                        onClick={(e) => {
                          e.preventDefault();
                          const canvasDocInput =
                            document.getElementById('business-canvas');
                          if (canvasDocInput) canvasDocInput.click();
                        }}
                      >
                        <i className="icon-upload-cloud font-32 text-black"></i>
                        <span className="sr-only">Business Canvas</span>
                      </button>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        id="business-canvas"
                        name="business-canvas"
                        accept="application/pdf"
                        onChange={(e) => {
                          handlePDFFileChange(e.target.files, 'businessCanvas');
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="col align-items-center d-flex">
                  {businessCanvasUrl ? (
                    <a href={businessCanvasUrl} className="text-black">
                      View Business Canvas
                    </a>
                  ) : businessCanvasDoc ? (
                    <span className="text-black text-truncate">
                      {businessCanvasDoc.name}
                    </span>
                  ) : (
                    <span className="text-muted">Upload Business Canvas</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="form-group col-12 col-md-5">
              <label
                htmlFor="sales-sheet"
                className="form-label text-black font-primary font-weight-bolder"
              >
                Line Sheet{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <div className="row">
                <div className="col-auto">
                  {salesSheetDoc || salesSheetUrl ? (
                    <button
                      type="button"
                      className="remove btn btn-link text-black text-decoration-none px-0"
                      onClick={(e) => {
                        e.preventDefault();
                        setSalesSheetDoc(null);
                        isEdit && handleDocModification('salesSheet', true);
                      }}
                    >
                      <i className="icon-close-x font-20 text-black" />
                      <span className="sr-only">Remove Line Sheet</span>
                    </button>
                  ) : (
                    <>
                      <button
                        className="upload-doc-btn text-black px-0"
                        onClick={(e) => {
                          e.preventDefault();
                          const salesSheetDocInput =
                            document.getElementById('sales-sheet');
                          if (salesSheetDocInput) salesSheetDocInput.click();
                        }}
                      >
                        <i className="icon-upload-cloud font-32 text-black"></i>
                        <span className="sr-only">Line Sheet</span>
                      </button>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        id="sales-sheet"
                        name="sales-sheet"
                        accept="application/pdf"
                        onChange={(e) => {
                          handlePDFFileChange(e.target.files, 'salesSheet');
                        }}
                      />
                    </>
                  )}
                </div>

                <div className="col align-items-center d-flex">
                  {salesSheetUrl ? (
                    <a href={salesSheetUrl} className="text-black">
                      View Line Sheet
                    </a>
                  ) : salesSheetDoc ? (
                    <span className="text-black text-truncate">
                      {salesSheetDoc.name}
                    </span>
                  ) : (
                    <span className="text-muted">Upload Line Sheet</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group col-12 col-md-5">
              <label
                htmlFor="capabilities-statement-document"
                className="form-label text-black font-primary font-weight-bolder"
              >
                Capabilities Statement{' '}
                <small className="text-kinda-muted font-weight-normal">
                  (optional)
                </small>
              </label>
              <div className="row">
                <div className="col-auto">
                  {capabilitiesStatementDoc || capabilitiesStatementUrl ? (
                    <button
                      type="button"
                      className="remove btn btn-link text-black text-decoration-none px-0"
                      onClick={(e) => {
                        e.preventDefault();
                        setCapabilitiesStatementDoc(null);
                        isEdit &&
                          handleDocModification('capabilitiesStatement', true);
                      }}
                    >
                      <i className="icon-close-x font-20 text-black" />
                      <span className="sr-only">
                        Remove Capabilities Statement Document
                      </span>
                    </button>
                  ) : (
                    <>
                      <button
                        className="upload-doc-btn text-black px-0"
                        onClick={(e) => {
                          e.preventDefault();
                          const capabilitiesStatementDocInput =
                            document.getElementById(
                              'capabilities-statement-document'
                            );
                          if (capabilitiesStatementDocInput)
                            capabilitiesStatementDocInput.click();
                        }}
                      >
                        <i className="icon-upload-cloud font-32 text-black"></i>
                        <span className="sr-only">
                          Capabilities Statement Document
                        </span>
                      </button>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        id="capabilities-statement-document"
                        name="capabilities-statement-document"
                        accept="application/pdf"
                        onChange={(e) => {
                          handlePDFFileChange(
                            e.target.files,
                            'capabilitiesStatement'
                          );
                        }}
                      />
                    </>
                  )}
                </div>
                <div className="col align-items-center d-flex">
                  {capabilitiesStatementUrl ? (
                    <a href={capabilitiesStatementUrl} className="text-black">
                      View Capabilities Statement
                    </a>
                  ) : capabilitiesStatementDoc ? (
                    <span className="text-black text-truncate">
                      {capabilitiesStatementDoc.name}
                    </span>
                  ) : (
                    <span className="text-muted">
                      Upload Capabilities Statement
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="form-group col-12 text-center">
              <small className="font-primary text-black">{`*Pitch Deck, Business Canvas, Line Sheet & Capabilities Statement only support .pdf files`}</small>
            </div>
          </div>
        </form>
      </div>
      {!isEdit && (
        <div className="d-flex justify-content-center flex-column-reverse flex-md-row">
          <button
            className="btn-lg btn btn-white text-black font-primary text-uppercase rounded-5 mt-4 px-4 mr-1"
            onClick={(e) => {
              e.preventDefault()
              window.scrollTo(0, 0);
              setActivePage(1);
            }}
          >
            Go back
          </button>
          <button
            className="btn btn-black btn-lg text-uppercase text-pinky rounded-5 mt-4 px-5 ml-1"
            disabled={validateFields()}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              if (validateFields()) {
                highlightRequiredFields();
                return;
              }
              window.scrollTo(0, 0);
              setActivePage(3);
            }}
          >
            NEXT
          </button>
        </div>
      )}
    </div>
  );
};

export default SupplierOtherInformation;
