import { Helmet } from 'react-helmet';
import RoleSection from '../../components/RoleSection/RoleSection';
import { UserRolesData } from '../../types/User';

const RoleSelection = () => {
  return (
    <>
      <Helmet>
        <title>BSN - Role Selection</title>
      </Helmet>
      <main className="body-min-h90 container onboarding py-5 px-4 mb-5">
        <div className="section pt-2 pb-2">
          <h1
            className="font-weight-bold font-primary text-black text-left text-md-center"
            style={{ fontSize: '2.05rem' }}
          >
            Choose your role
          </h1>
        </div>
        <RoleSection
          role="Champion"
          icon={UserRolesData.CHAMPION.icon}
          description={UserRolesData.CHAMPION.description}
          url={`${UserRolesData.CHAMPION.url}${window.location.search}`}
        />
        <div className="pt-5 pb-4" />
        <RoleSection
          role="Supplier"
          icon={UserRolesData.SUPPLIER.icon}
          description={UserRolesData.SUPPLIER.description}
          url={`${UserRolesData.SUPPLIER.url}${window.location.search}`}
        />
      </main>
    </>
  );
};

export default RoleSelection;
