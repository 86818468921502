import { IAuth } from "../types/CognitoAuth";
import { ConnectionWithProfile } from "../types/Connection";
import { sendMeetEmail } from "./emailUtils";
import { trimName } from "./validations";

export const getDisplayName = (
  connection: ConnectionWithProfile,
  receiver: boolean
) => {
  if (receiver && connection.sender) {
    return connection.sender["custom:first_name"]
      ? trimName(
          connection.sender["custom:first_name"],
          connection.sender["custom:last_name"]
        )
      : trimName(connection.sender.given_name, "");
  } else if (!receiver && connection.receiver) {
    return connection.receiver["custom:first_name"]
      ? trimName(
          connection.receiver["custom:first_name"],
          connection.receiver["custom:last_name"]
        )
      : trimName(connection.receiver.given_name, "");
  } else return "";
};

export const getPicture = (
  connection: ConnectionWithProfile,
  receiver: boolean
) => {
  if (receiver) {
    const img = connection.sender["custom:picture"]
      ? connection.sender["custom:picture"]
      : connection.sender.picture;
    return img === "N/A" ? undefined : img;
  } else {
    const img = connection.receiver["custom:picture"]
      ? connection.receiver["custom:picture"]
      : connection.receiver.picture;
    return img === "N/A" ? undefined : img;
  }
};

export const requestMeeting = async (
  connection: ConnectionWithProfile,
  user: IAuth,
  token: any
) => {
  const userId = user.data.attributes.sub;
  const { senderEmail, receiverEmail, receiverId, senderId } = connection;
  const userIsReceiver = userId === receiverId;
  const recepientName = getDisplayName(connection, userIsReceiver);
  const requesterName = getDisplayName(connection, !userIsReceiver);
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  await sendMeetEmail(
    userIsReceiver ? receiverEmail : senderEmail,
    requesterName,
    userIsReceiver ? senderEmail : receiverEmail,
    recepientName,
    receiverId,
    senderId,
    headers
  );
  return `An email requesting a meeting with ${recepientName} has been sent!`;
};
