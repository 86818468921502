import { IAuth } from '../types/CognitoAuth';

export const getUserEmail = (auth: IAuth) => {
  if (auth && auth.data) {
    return auth.data.attributes.email;
  }
  return '';
};

export const getUserSub = (auth: IAuth) => {
  if (auth && auth.data) {
    return auth.data.attributes.sub;
  }
  return '';
};
