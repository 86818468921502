import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ICompanyStage } from '../types/SupplierInfo';
import { backendUrl } from '../utils/env';

export const types = {
  ADD_SUPPLIER_INFO_REQUEST: 'supplier/post/pending',
  ADD_SUPPLIER_INFO_SUCCESS: 'supplier/post/fulfilled',
  ADD_SUPPLIER_INFO_FAILURE: 'supplier/post/rejected',
  GET_SUPPLIER_INFO_REQUEST: 'supplier/get/pending',
  GET_SUPPLIER_INFO_SUCCESS: 'supplier/get/fulfilled',
  GET_SUPPLIER_INFO_FAILURE: 'supplier/get/rejected',
  RESET_SUPPLIER_ERRORS: 'errors/supplier/reset',
  UPDATE_SUPPLIER_INFO_REQUEST: 'supplier/put/pending',
  UPDATE_SUPPLIER_INFO_SUCCESS: 'supplier/put/fulfilled',
  UPDATE_SUPPLIER_INFO_FAILURE: 'supplier/put/rejected',
  GET_SUPPLIER_INFO_BY_ID_REQUEST: 'supplier/getById/pending',
  GET_SUPPLIER_INFO_BY_ID_SUCCESS: 'supplier/getById/fulfilled',
  GET_SUPPLIER_INFO_BY_ID_FAILURE: 'supplier/getById/rejected',
  RESET_SUPPLIER_INFO: 'supplier/reset'
};

export const resetSupplierErrors = createAction('errors/supplier/reset');

export const addSupplierInfo = createAsyncThunk(
  'supplier/post',
  async (
    payload: {
      firstName: string;
      lastName: string;
      email: string;
      companyName: string;
      revenueGenerated: string;
      establishmentDate: string;
      stage: ICompanyStage;
      bio: string;
      facebook: string;
      twitter: string;
      instagram: string;
      linkedin: string;
      website: string;
      pitchVideo: string;
      pitchDeck: string;
      businessCanvas: string;
      salesSheet: string;
      capabilitiesStatement: string;
      industries: string[];
      state: string;
      city: string;
      companyInformation: any;
      teamInfo: any;
      fieldOfOpportunity: any;
      isCommunityVisible: boolean;
      isHiringVisible: boolean;
      isInvestmentVisible: boolean;
      isAlumni: boolean;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(`${backendUrl}/Suppliers`, payload);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const resetSupplierInfo = createAction('supplier/reset');

export const getSupplierInfoByEmail = createAsyncThunk(
  'supplier/get',
  async (email: string) => {
    const response = await axios.get(`${backendUrl}/Suppliers/${email}`);
    return response.data;
  }
);

export const getSupplierById = createAsyncThunk(
  'supplier/getById',
  async (id: string) => {
    const response = await axios.get(`${backendUrl}/Suppliers/byId/${id}`);
    return response.data;
  }
);

export const updateSupplierInfo = createAsyncThunk(
  'supplier/put',
  async ({ payload, token, supplierInfoId }: any, thunkAPI) => {
    let authToken = '';
    if (token) {
      authToken = `Bearer ${token}`;
    }
    const headers = {
      Authorization: authToken,
    };
    try {
      const response = await axios.put(
        `${backendUrl}/Suppliers/${supplierInfoId}`,
        payload,
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
