import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { isSupplier } from '../../auth/userUtils';
import { authContext } from '../Authentication/CognitoContextProvider';

interface Props {
  activeTab: number
}

const BottomNavbar = ({
  activeTab
}: Props) => {
    const history = useHistory();
    const { auth } = useContext(authContext);
    
    return (
        <nav className="admin-toolbar row bg-white hz-scroller mx-0">
            <ul className="list-unstyled d-flex justify-content-around justify-content-lg-center">
              <li>
                <span
                  className={`link clickable ${
                    activeTab === 1 ? 'active' : ''
                  }`}
                  onClick={() => history.push('/')}
                >
                  <i className={`icon-home ${activeTab === 1 ? 'text-black' : 'text-gray-600'} font-24 link-icon`}></i>
                  <span className="link-label pl-2">Home</span>
                </span>
              </li>
              {isSupplier(auth.data) && (
                <li>
                  <span
                    className={`link clickable ${
                      activeTab === 2 ? 'active' : ''
                    }`}
                    onClick={() => history.push(`/supplier/${auth.data.attributes.sub}`)}
                  >
                    <i className={`icon-user ${activeTab === 2 ? 'text-black' : 'text-gray'} font-24 link-icon`}></i>
                    <span className="link-label pl-2">Profile</span>
                  </span>
                </li>
              )}
              <li>
                <span
                  className={`link clickable ${
                    activeTab === 3 ? 'active' : ''
                  }`}
                  onClick={() => history.push('/my-connections')}
                >
                  <i className={`icon-link ${activeTab === 3 ? 'text-black' : 'text-gray'} font-24 link-icon`}></i>
                  <span className="link-label pl-2">Connections</span>
                </span>
              </li>
              <li>
                <span
                  className={`link clickable ${
                    activeTab === 4 ? 'active' : ''
                  }`}
                  onClick={() => history.push('/notifications')}
                >
                  <i className={`icon-bell ${activeTab === 4 ? 'text-black' : 'text-gray'} font-24 link-icon`}></i>
                  <span className="link-label pl-2">Notifications</span>
                </span>
              </li>
            </ul>
          </nav>
    )
}

export default BottomNavbar;