import { IFieldOfOpportunity } from "./SupplierInfo";

export interface CustomerInfo {
  id: number;
  creationDate: Date;
  email: string;
  companyName: string;
  linkedin: string;
  website: string;
  firstName: string;
  lastName: string;
  city: string;
  state: string;
  industries: string[];
  isCommunityVisible: boolean;
  isHiringVisible: boolean;
  isInvestmentVisible: boolean;
  fieldofopportunity: IFieldOfOpportunity;
  admissionStatus: number;
}

export interface CustomerCompleteProfile extends CustomerInfo {
  sub: string;
  lastName: string;
  firstName: string;
  picture: string;
  isCustomer: boolean;
}

export const CustomerOpportunities: {
  name: string;
  description: string;
  attribute: string;
  icon: string;
}[] = [
  {
    name: "Mentorship",
    description: "(Provide guidance)",
    attribute: "mentorships",
    icon: "icon-mentor",
  },
  {
    name: "Investment",
    description: "(Looking to invest)",
    attribute: "investment",
    icon: "icon-investment",
  },
  {
    name: "Supplier Diversity",
    description: "(Corporations)",
    attribute: "corporations",
    icon: "icon-corporations",
  },
  {
    name: "Procurement",
    description: "(Big box & Boutique)",
    attribute: "",
    icon: "icon-procurement",
  },
  {
    name: "Hiring",
    description: "(Contracts or Positions)",
    attribute: "openToWork",
    icon: "icon-hiring",
  },
  {
    name: "Partnerships",
    description: "(Non-Monetary & Monetary)",
    attribute: "partnerships",
    icon: "icon-partnership",
  },
];
