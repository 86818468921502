import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Auth } from 'aws-amplify';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { uploadProfilePictureToS3 } from '../../utils/uploadToS3';
import BasicInformation from '../../components/OnboardingViews/BasicInformation';
import AreasOfOpportunity from '../../components/OnboardingViews/AreasOfOpportunity';
import GuidedSearchRedirect from '../../components/OnboardingViews/GuidedSearchRedirect';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { addCustomerInfo, resetCustomerErrors } from '../../actions/customer';
import { authContext } from '../../components/Authentication/CognitoContextProvider';
import { v4 as uuidv4 } from 'uuid';
import { isGoogleUser, makeUserRole } from '../../auth/userUtils';
import ProfessionalInformation from '../../components/OnboardingViews/ProfessionalInformation';

const Onboarding = () => {
  const dispatch = useDispatch();
  const { auth, setAuthData } = useContext(authContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [image, setImage] = useState<File>();
  const [imageSrc, setImageSrc] = useState('');
  const [fieldOfOpportunity, setFieldOfOpportunity] = useState({
    smallBoutiques: false,
    mentorships: false,
    bigBoxRetailer: false,
    corporations: false,
    partnerships: false,
    investment: false,
    internship: false,
    openToWork: false,
  });

  const { requestFinished, hasErrors, errorMessage } = useSelector(
    (state: RootState) => state.customer
  );

  const history = useHistory();
  const params: { step: string } = useParams();

  useEffect(() => {
    window.scrollTo(0,0);
    localStorage.removeItem('google-signup');
    getDataFromQuery()
  }, []);

  useEffect(() => {
    if (auth && auth.data && isGoogleUser(auth.data)) {
      const givenName = auth.data.attributes.given_name.split(' ');
      const pw = uuidv4();
      setFirstName(givenName[0] ? givenName[0] : '');
      setLastName(givenName[1] ? givenName[1] : '');
      setCompanyName('');
      setPassword(pw);
      setConfirmPassword(pw);
      setEmail(auth.data.attributes.email);
      localStorage.setItem('google-signup', 'true');
      history.push('/onboarding/professional-information');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.data]);

  const getDataFromQuery = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsEmail = urlParams.has('email') ? urlParams.get('email') : '';
    setEmail(paramsEmail);
    if (urlParams.has('name')) {
      const splittedName = urlParams.get('name').split(' ');
      if (splittedName.length >= 2) {
        setFirstName(splittedName[0]);
        setLastName(splittedName.slice(1, splittedName.length).join(' '));
      } else {
        setFirstName(splittedName[0]);
      }
    }
  };

  const getErrorMessage = (cognitoErrorCode: string) => {
    switch (cognitoErrorCode) {
      case 'InvalidParameterException':
        return 'Invalid parameters. Please check the information provided.';
      case 'InvalidPasswordException':
        return 'Password does not meet minimum requirements.';
      default:
        return 'Could not register the user.';
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    const fName = firstName.replace(/,|"/g, '').trim();
    const lName = lastName.replace(/,|"/g, '').trim();
    const isGoogle = localStorage.getItem('google-signup');
    try {
      if (!isGoogle) {
        let imageUrl = '';
        if (image) {
          imageUrl = await uploadProfilePictureToS3(
            image,
            `${firstName}-${lastName}`
          );
        }
        await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
            'custom:first_name': fName,
            'custom:last_name': lName,
            'custom:picture': imageUrl,
            'custom:is_customer': 'true',
          },
        });
      } else {
        await makeUserRole(auth.data, 'is_customer', setAuthData);
      }
      const payload = {
        email,
        firstName: fName,
        lastName: lName,
        companyName,
        website: companyWebsite,
        linkedin: linkedinUrl,
        fieldOfOpportunity,
        isGoogle: !!isGoogle,
      };

      dispatch(addCustomerInfo(payload));
      localStorage.removeItem('redirect-url');
    } catch (err) {
      const message = getErrorMessage(err.code);
      toast.error(message);
    }
  };

  const showToast = () => {
    if (requestFinished) {
      if (hasErrors) {
        toast.error(errorMessage, {
          toastId: 'profile-creation-error',
        });
      } else {
        if (localStorage.getItem('google-signup')) {
          toast.success(`Your account has been created`, {
            toastId: 'signup-verification',
          });
          localStorage.removeItem('google-signup');
          localStorage.removeItem('needsOpportunities');
          window.scrollTo(0, 0);
          history.push('guided-search-redirect');
        } else {
          toast.success(
            `A verification link has been sent to your email ${email}`,
            { toastId: 'signup-verification' }
          );
          localStorage.setItem(
            'redirect-url',
            '/onboarding/guided-search-redirect'
          );
          window.scrollTo(0, 0);
          history.push('/');
        }
      }
      dispatch(resetCustomerErrors());
    }
    return null;
  };

  const renderCurrentStep = () => {
    let googleSignup;
    switch (params.step) {
      case 'basic-information':
        return (
          <BasicInformation
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            image={image}
            setImage={setImage}
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
          />
        );
      case 'professional-information':
        googleSignup = localStorage.getItem('google-signup');
        if (
          !googleSignup &&
          (!email || !firstName || !lastName || !password || !confirmPassword)
        ) {
          history.push('/onboarding/basic-information');
          return;
        }
        return (
          <ProfessionalInformation
            linkedinUrl={linkedinUrl}
            setLinkedinUrl={setLinkedinUrl}
            companyName={companyName}
            setCompanyName={setCompanyName}
            companyWebsite={companyWebsite}
            setCompanyWebsite={setCompanyWebsite}
          />
        );
      case 'areas-of-opportunity':
        googleSignup = localStorage.getItem('google-signup');
        if (
          !googleSignup &&
          (!email || !firstName || !lastName || !password || !confirmPassword || !linkedinUrl || !companyName)
        ) {
          history.push('/onboarding/basic-information');
          return;
        }
        return (
          <AreasOfOpportunity
            fieldsOfOpportunity={fieldOfOpportunity}
            setFieldsOfOpportunity={setFieldOfOpportunity}
            handleSignup={handleSignup}
            authData={auth}
          />
        );
      case 'guided-search-redirect':
        return <GuidedSearchRedirect />;
      default:
        history.replace('/onboarding/basic-information');
    }
  };

  return (
    <>
      <Helmet>
        <title>BSN - Onboarding</title>
      </Helmet>
      <main className="body-min-h90 container onboarding py-5 px-4">
        {renderCurrentStep()}
      </main>
      {showToast()}
    </>
  );
};

export default Onboarding;
