import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Connection, connectionStatus } from '../../types/Connection';
import emptyStateProfilePic from '../../styles/img/empty-state-profile-pic.svg';

interface Props {
    type: string,
    image: string,
    date: string,
    name: string,
    changeStatus: (connectionId: number, newStatus: number, name: string) => void;
    connection?: Connection,
}

const Notification = ({
    type,
    image,
    date,
    name,
    connection,
    changeStatus
}: Props) => {

    const renderButtons = () => (
        <div className="d-flex flex-row justify-content-between">
            <button
                className="btn btn-white btn-block btn-md text-black btn-outline-black px-0 w-48 font-13"
                onClick={(event) => {
                    if (event) {
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation()
                    }
                    changeStatus(
                        connection.id,
                        connectionStatus.DENIED,
                        name,
                    );
                }}
            >
                Deny
            </button>
            <button
                className="btn btn-black btn-block btn-md text-white m-0 px-0 w-48 font-13"
                onClick={(event) => {
                    if (event) {
                    event.stopPropagation();
                    event.nativeEvent.stopImmediatePropagation()
                    }
                    changeStatus(
                        connection.id,
                        connectionStatus.ACCEPTED,
                        name
                    );
                }}
            >
                Accept
            </button>
        </div>
    )

    return (
        <div className="row connection-list-item mx-0 py-3 my-2">
          <div className="col-3 col-md-2 pr-1 pr-md-2 pb-4">
            <div className="notification avatar-opener d-flex justify-content-center align-items-center pb-4">
              <div className="medium-avatar">
                <LazyLoadImage
                  referrerPolicy="no-referrer"
                  src={image || emptyStateProfilePic}
                  alt=""
                  effect="blur"
                />
              </div>
            </div>
          </div>
          <div className="col-9 col-md-10 pl-1 pl-md-3">
            <p className="text-black font-primary font-weight-bold font-20 mb-2 text-truncate">
              {name}
            </p>
            <p className="text-dark-gray mb-1">
                {type === 'connection' ? 'Wants to connect with you.' : 'Requested a meeting, an email has been sent.'}
            </p>
            <p className="text-light-gray">
                {date}
            </p>
            {type === 'connection' && renderButtons()}
          </div>
        </div>
    );
};

export default Notification;