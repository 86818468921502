import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import './auth/Amplify';
import CognitoContextProvider from './components/Authentication/CognitoContextProvider';
import { loadStripe } from '@stripe/stripe-js';
import { stripePublishableKey } from './utils/env';
import { Elements } from '@stripe/react-stripe-js';
import { CookiesProvider } from 'react-cookie';

const store = configureStore({
  reducer: rootReducer,
});

const stripePromise = loadStripe(stripePublishableKey);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <CognitoContextProvider>
        <CookiesProvider>
          <Elements stripe={stripePromise}>
            <React.StrictMode>
              <App />
            </React.StrictMode>
          </Elements>
        </CookiesProvider>
      </CognitoContextProvider>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
