import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import emptyStateProfilePic from "../../styles/img/empty-state-profile-pic.svg";
import { AdmissionStatus } from "../../types/User";

interface Props {
  userData: any;
  userRoleData: any;
  handleChangeAdmissionStatus: (
    newStatus: number,
    id: number,
    role: string,
    toastMessage: string
  ) => void;
  openModal: (reqData: any) => void;
}

export const RequestCard = ({
  userData,
  userRoleData,
  handleChangeAdmissionStatus,
  openModal,
}: Props) => {
  const history = useHistory();
  const documents: { name: string; attribute: string }[] = [
    { name: "Pitch Deck", attribute: "pitchDeck" },
    { name: "Business Canvas", attribute: "businessCanvas" },
    { name: "Line Sheet", attribute: "salesSheet" },
    { name: "Capabilities Statement", attribute: "capabilitiesStatement" },
  ];

  const getProductService = (user: any) => {
    const { isProduct, isService } = user.companyinformation;
    if (isProduct & isService) {
      return "Product & Service";
    } else if (isProduct) {
      return "Product";
    } else if (isService) {
      return "Service";
    } else {
      return "N/A";
    }
  };

  const renderIndustries = () => {
    return userData.industries.map((industry) => (
      <div className="col-auto industry-pill font-weight-light px-4 mr-3">
        {industry}
      </div>
    ));
  };

  const renderFieldOfOpportunities = () => {
    let fields = [];
    Object.keys(userData.fieldofopportunity).map(
      (key) => userData.fieldofopportunity[key] && fields.push(key)
    );
    if (fields.length > 4) {
      fields = fields.slice(0, 4);
    }
    return fields.map((op, index) => {
      return (
        <div className="col-auto px-1" key={`ur-${op}-${index}`}>
          <span className="font-weight-bold font-14">#{op}</span>
        </div>
      );
    });
  };

  const validateUrl = (url: string) => {
    return `${
      url.includes("http://") || url.includes("https://") ? url : `//${url}`
    }`;
  };

  const profileRoute = `/${userData.isSupplier ? "supplier" : "champion"}/${
    userData.sub
  }`;

  return (
    <>
      <div className="col-12 col-lg-6 my-3 text-black font-primary">
        <div className="request-card pb-3">
          <div className="row py-4 mx-0">
            <div className="col-3 d-flex justify-content-center px-0">
              <div className="user-request medium-avatar grid-item result-avatar">
                <LazyLoadImage
                  referrerPolicy="no-referrer"
                  src={userData.picture || emptyStateProfilePic}
                  alt=""
                  effect="blur"
                />
              </div>
            </div>
            <div className="col-9 pl-0 mb-3">
              <div className="row pb-2 mx-0 d-flex justify-content-between">
                <div className="col-auto d-flex align-items-center">
                  <span
                    onClick={() => {
                      history.push(profileRoute);
                    }}
                    className="font-weight-bold clickable"
                    style={{ textDecoration: "underline" }}
                  >
                    {`${userData.firstName} ${userData.lastName}`.trim()}
                  </span>{" "}
                  {userRoleData.name === "Supplier" && (
                    <span>
                      {" "}
                      /
                      {userData.city && userData.city.length > 0
                        ? userData.city + ", "
                        : ""}{" "}
                      {userData.state}
                    </span>
                  )}
                </div>
                <div className="col-auto d-flex align-items-center pl-md-0 pl-lg-2">
                  <i className={userRoleData.icon + " font-32"} />
                  <span className="font-weight-bold text-uppercase">
                    {userRoleData.name}
                  </span>
                </div>
              </div>

              <div className="row py-1 mx-0">
                <div className="col-12 col-lg-6">
                  <span>{userData.companyName}</span>
                </div>
                {userRoleData.name === "Supplier" && (
                  <div className="col-12 col-lg-6">
                    <strong>{getProductService(userData)}</strong>
                  </div>
                )}
              </div>
              {userRoleData.name === "Supplier" && (
                <>
                  <div className="row py-1 mx-0">
                    <div className="col-12 col-lg-6">
                      <span className="font-14">REVENUE: </span>
                      <span className="font-weight-bold">
                        {userData.revenueGenerated}
                      </span>
                    </div>
                    <div className="col-12 col-lg-6">
                      <span className="font-14">MARKETS: </span>
                      <span className="font-weight-bold">
                        {userData.companyinformation.markets}
                      </span>
                    </div>
                  </div>
                  <div className="row py-1 mx-0">
                    <div className="col-12">
                      <span className="font-14">COMPANY STAGE: </span>
                      <span className="font-weight-bold">
                        {userData.stage.title}
                      </span>
                    </div>
                  </div>
                  <div className="row mx-0">
                    {documents.map(
                      (doc) =>
                        userData[doc.attribute] && (
                          <div
                            className="col-6 d-flex align-items-center"
                            key={`ur-pdf-${doc.attribute}`}
                          >
                            <i className="icon-pdf font-20 text-black"></i>
                            <a
                              href={userData[doc.attribute]}
                              className="font-weight-bold font-14 pl-1 text-black"
                              style={{ textDecoration: "underline" }}
                            >
                              {doc.name}
                            </a>
                          </div>
                        )
                    )}
                  </div>{" "}
                </>
              )}
              {userRoleData.name === "Supplier" && (
                <div className="row mx-0 pl-3 mt-1">{renderIndustries()}</div>
              )}
              <div className="row mx-0 mb-2 mb-md-2 pl-3">
                {renderFieldOfOpportunities()}
              </div>
              {userRoleData.name === "Champion" && (
                <>
                  {userData.linkedin && (
                    <div className="row py-1 mx-0">
                      <div className="col-12">
                        <a
                          className="btn-link text-black text-decoration-none"
                          href={userData ? validateUrl(userData.linkedin) : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className={`icon-linkedin font-32 align-middle`} />
                          <span className="align-middle">LinkedIn Profile</span>
                          <span className="sr-only">Linkedin</span>
                        </a>
                      </div>
                    </div>
                  )}
                  {userData.website && (
                    <div className="row py-1 mx-0">
                      <div className="col-12">
                        <a
                          className="btn-link text-black text-decoration-none"
                          href={userData ? validateUrl(userData.website) : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className={`icon-globe font-32 align-middle`} />
                          <span className="align-middle">
                            {userData.website}
                          </span>
                          <span className="sr-only">Website</span>
                        </a>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className="row pt-2"
            style={{ position: "absolute", bottom: 0, width: "100%" }}
          >
            <div className="col-6 pr-0 pr-md-1">
              <button
                className="btn btn-black btn-block text-uppercase font-weight-normal"
                style={{
                  borderRadius: "0",
                  borderBottomLeftRadius: "0.325rem",
                  padding: "0.7rem 0.75rem",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  const name =
                    `${userData.firstName} ${userData.lastName}`.trim();
                  handleChangeAdmissionStatus(
                    AdmissionStatus.APPROVED,
                    userData.id,
                    userData.isSupplier ? "supplier" : "champion",
                    `${name}'s profile has been approved`
                  );
                }}
              >
                Accept
              </button>
            </div>
            <div className="col-6 pl-0 pl-md-1">
              <button
                className="btn btn-outline-black btn-block text-uppercase font-weight-normal"
                style={{
                  borderRadius: "0",
                  borderBottomRightRadius: "0.325rem",
                  padding: "0.7rem 0.75rem",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  const name =
                    `${userData.firstName} ${userData.lastName}`.trim();
                  openModal({
                    id: userData.id,
                    name,
                    role: userData.isSupplier ? "supplier" : "champion",
                    newStatus: AdmissionStatus.DENIED,
                  });
                }}
              >
                Deny
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
