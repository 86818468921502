import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Accordion, Card, Button } from 'react-bootstrap';
import {
  customerOpportunitiesInitialState,
  CustomerOpportunitiesInterface,
  IFieldOfOpportunity,
  industriesInitialState,
  IndustriesInterface,
  sellingCycleData,
  shippingTimes,
  supplierOpportunitiesInitialState,
} from '../../types/SupplierInfo';
import { SearchSettings } from '../../types/Search';
import {
  defaultSettings,
  objectHasNonSelectedValues,
} from '../../utils/searchUtils';
import { City } from 'country-state-city';
import { getIsoCode } from '../../utils/getIsoCode';

interface Props {
  isModalOpen: boolean;
  isSupplier: boolean;
  closeModal: () => void;
  onConfirm: (data: any) => void;
  allStates: any;
}

const customStyles = {
  content: {
    background: 'transparent',
    border: 'none',
    padding: 'none',
    position: 'absolute',
    display: 'block',
    margin: '0',
    transform: 'translate(-50%, -32%)',
    width: '90%',
    maxWidth: '700px',
    inset: 'unset',
    overflow: 'initial',
  },
  overlay: {
    zIndex: '3',
    backgroundColor: 'rgba(23, 41, 64, 0.75)',
  },
};

export const SearchSettingsModal = ({
  closeModal,
  isModalOpen,
  isSupplier,
  onConfirm,
  allStates,
}: Props) => {
  const [activeKey, setActiveKey] = useState({
    Opportunity: true,
    Industry: true,
    'Selling Cycle': true,
    'Shipping Time': true,
    State: true,
  });

  const [state, setState] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const [industries, setIndustries] = useState<IndustriesInterface>(
    industriesInitialState
  );
  const [opportunities, setOpportunities] =
    useState<CustomerOpportunitiesInterface>(customerOpportunitiesInitialState);
  const [supplierOpportunities, setSupplierOpportunities] =
    useState<IFieldOfOpportunity>(supplierOpportunitiesInitialState);

  const [shippingTime, setShippingTime] = useState('Any');
  const [sellingCycle, setSellingCycle] = useState('');
  const [allCities, setAllCities] = useState([]);

  const businessOpportunities: Array<{ name: string; attribute: string }> = [
    { name: 'Small Boutiques', attribute: 'smallBoutiques' },
    { name: 'Open To Work', attribute: 'openToWork' },
    { name: 'Big Box Retailer', attribute: 'bigBoxRetailer' },
    { name: 'Corporations', attribute: 'corporations' },
    { name: 'Investment', attribute: 'investment' },
  ];

  const growthOpportunities: Array<{ name: string; attribute: string }> = [
    { name: 'Mentorships', attribute: 'mentorships' },
    { name: 'Partnerships', attribute: 'partnerships' },
    { name: 'Internship', attribute: 'internship' },
  ];

  const toggleActiveKey = (key: string) => {
    setActiveKey((prev) => {
      return { ...prev, [key]: !activeKey[key] };
    });
  };

  const setDefaultData = (storedSettings?: string) => {
    const settings = storedSettings
      ? JSON.parse(storedSettings)
      : defaultSettings;

    if (!isSupplier) {
      setOpportunities((prevState) => {
        return {
          Mentorship: {
            value: settings.areaOfInterest.Mentorship.value,
            icon: prevState.Mentorship.icon,
          },
          Investment: {
            value: settings.areaOfInterest.Investment.value,
            icon: prevState.Investment.icon,
          },
          Procurement: {
            value: settings.areaOfInterest.Procurement.value,
            icon: prevState.Procurement.icon,
          },
          Hiring: {
            value: settings.areaOfInterest.Hiring.value,
            icon: prevState.Hiring.icon,
          },
          Partnerships: {
            value: settings.areaOfInterest.Partnerships.value,
            icon: prevState.Partnerships.icon,
          },
          'Supplier Diversity': {
            value: settings.areaOfInterest['Supplier Diversity'].value,
            icon: prevState['Supplier Diversity'].icon,
          },
        };
      });
    } else {
      setSupplierOpportunities(settings.fieldsOfOpportunity);
    }
    setShippingTime(settings.shippingTime || 'Any');
    setSellingCycle(settings.sellingCycle);
    setIndustries(settings.industries);
    setState(settings.location.state);
    setCity(settings.location.city);
  };

  useEffect(() => {
    const storedSettings = localStorage.getItem('searchSettings');
    if (storedSettings) {
      setDefaultData(storedSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state) {
      setAllCities(City.getCitiesOfState('US', getIsoCode(state, allStates)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const getCheckedValue = (setting, value): boolean => {
    switch (setting) {
      case SearchSettings.Opportunity:
        return value;
      case SearchSettings.Industry:
        return value;
      case SearchSettings.SellingCycle:
        return sellingCycle === value;
      case SearchSettings.ShippingTime:
        return shippingTime === value;
      default:
        break;
    }
  };

  const handleOnChange = (val, isCity?) => {
    if (isCity) {
      setCity(val);
    } else {
      setState(val);
      setCity('');
      setAllCities(City.getCitiesOfState('US', getIsoCode(val, allStates)));
    }
  };

  const handleSettingsChange = (field: string, setting: string) => {
    switch (setting) {
      case SearchSettings.Opportunity:
        setOpportunities((prevState) => {
          return {
            ...prevState,
            [field]: {
              value: !opportunities[field].value,
              icon: opportunities[field].icon,
            },
          };
        });
        break;
      case SearchSettings.Industry:
        setIndustries((prevState) => {
          return {
            ...prevState,
            [field]: { value: !industries[field].value },
          };
        });
        break;
      case SearchSettings.SellingCycle:
        setSellingCycle(field);
        break;
      case SearchSettings.ShippingTime:
        setShippingTime(field);
        break;
      case SearchSettings.State:
        handleOnChange(field);
        break;
      case SearchSettings.City:
        handleOnChange(field, true);
        break;
      default:
        break;
    }
  };

  const handleFieldOfOpportunity = (e) => {
    const setting = e.target.value;
    setSupplierOpportunities((prevState) => ({
      ...prevState,
      [setting]: !supplierOpportunities[setting],
    }));
  };

  const hasEmptyValues = () =>
    sellingCycle.length === 0 ||
    shippingTime.length === 0 ||
    !state ||
    objectHasNonSelectedValues(isSupplier ? supplierOpportunities : opportunities, isSupplier) ||
    objectHasNonSelectedValues(industries);

  const renderCheckboxSettings = (
    propsObj: CustomerOpportunitiesInterface | IndustriesInterface | undefined,
    setting: string,
    propsArray?: string[]
  ) => {
    const fields = propsArray ? propsArray : Object.keys(propsObj);
    const obj =
      setting === SearchSettings.Opportunity ? opportunities : industries;
    return fields.map((f, index) => {
      const isChecked = getCheckedValue(setting, propsArray ? f : obj[f].value);
      return (
        <>
          <div className="col-6 col-md-5 ml-md-5 d-flex align-items-center mb-3 mb-md-0" key={`modal-field-${f}-${index}`}>
            <div
              className="form-check form-check-inline mr-0"
            >
              <label className="field-of-opportunity-container">
              <input
                  readOnly
                  className="form-check-input opportunity-checkbox"
                  id={f}
                  type="checkbox"
                  value={f}
                  checked={isChecked}
                  onClick={(e: any) => {
                    handleSettingsChange(e.target.value, setting);
                  }}
                />
              </label>
            </div>
            {setting === SearchSettings.Opportunity && (
              <i className={`${propsObj[f].icon} opp-icon text-black`}></i>
            )}
            <label
              className={`form-label text-black font-primary font-14 ${
                isChecked ? 'font-weight-bolder' : 'font-weight-normal'
              }`}
              style={{ display: 'block' }}
            >
              {f}
            </label>
          </div>
        </>
      );
    });
  };

  const renderOpportunities = (type: string) => {
    const opportunityList = type === 'Business' ? businessOpportunities : growthOpportunities;
    const allChecked = opportunityList.every(opp => supplierOpportunities[opp.attribute]);
    return <>
    {opportunityList.map((f, index) => (
        <div
          className="col-6 col-md-5 ml-md-5 d-flex align-items-center mb-3 mb-md-0"
          key={`business-opportunity-${f.attribute}-${index}`}
        >
          <div className="form-check form-check-inline">
            <input
              className="form-check-input opportunity-checkbox-modal mr-0"
              type="checkbox"
              value={f.attribute}
              checked={supplierOpportunities[f.attribute]}
              onClick={handleFieldOfOpportunity}
              readOnly
            />
          </div>
          <label className="form-label text-black font-primary">
            {f.name}
          </label>
        </div>
      ))}
      <div
          className="col-6 col-md-5 ml-md-5 d-flex align-items-center mb-3 mb-md-0"
          key="business-opportunity-all"
        >
          <div className="form-check form-check-inline">
            <input
              className="form-check-input opportunity-checkbox-modal mr-0"
              type="checkbox"
              value="business-opportunity-all"
              checked={allChecked}
              onClick={() => {
                const newFields = { ...supplierOpportunities };
                opportunityList.forEach(opp => newFields[opp.attribute] = !allChecked);
                setSupplierOpportunities(newFields);
              }}
              readOnly
            />
          </div>
          <label className="form-label text-black font-primary">
            All
          </label>
        </div>
    </>
  }

  return (
    <Modal
      style={customStyles}
      isOpen={isModalOpen}
      onRequestClose={() => {
        const storedSettings = localStorage.getItem('searchSettings');
        setDefaultData(storedSettings);
        closeModal();
      }}
      closeTimeoutMS={500}
      ariaHideApp={false}
    >
      <div className="modal-content" style={{ borderRadius: '14.1562px' }}>
        <div className="modal-header pb-0 border-0">
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            onClick={() => {
              const storedSettings = localStorage.getItem('searchSettings');
              setDefaultData(storedSettings);
              closeModal();
            }}
            aria-label="Close"
          >
            <span className="text-black" aria-hidden="true">
              <i className="icon-close-x" />
            </span>
          </button>
        </div>
        <div
          className="modal-body py-1 px-1"
          style={{ height: '400px', maxHeight: '450px', overflowY: 'scroll' }}
        >
          <Accordion defaultActiveKey="1">
            <Card style={{ border: 'none', backgroundColor: 'transparent' }}>
              <Card.Header
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <div className="d-flex justify-content-between px-0 px-md-4">
                  <label
                    className="text-primary text-black font-14 font-weight-bolder text-uppercase mb-0"
                    style={{ fontFamily: 'Lato' }}
                  >
                    {SearchSettings.Opportunity}
                  </label>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="1"
                  onClick={() => {
                    toggleActiveKey(SearchSettings.Opportunity);
                  }}
                  className="btn btn-link w-100  text-decoration-none text-black px-0 px-md-4 text-primary settings-accordion pt-0"
                  aria-expanded={activeKey[SearchSettings.Opportunity]}
                >
                  <div className="d-flex justify-content-between border-bottom-black">
                    <label className="d-flex align-items-center text-black mb-0 text-align-left font-14">
                      Select at least one opportunity.
                    </label>
                    <span className="d-flex align-items-center font-display text-dark mb-0 pt-1 Scoreboard btn btn-chip text-right px-0">
                      <i
                        className={`icon-chevron-${
                          activeKey[SearchSettings.Opportunity] ? 'up' : 'down'
                        } font-24 text-black`}
                      />
                    </span>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body className="pt-0">
                  <div className="row d-flex justify-content-between">
                    {!isSupplier ? renderCheckboxSettings(
                      opportunities,
                      SearchSettings.Opportunity
                    ) : (
                      <>
                        {<div className="business-opportunities-section mx-2">
                          <div className="row d-flex">
                            <label className="form-label text-black font-primary font-weight-bolder mb-0 ml-5">
                              Business Opportunity
                            </label>
                          </div>
                          <div className="row d-flex mt-2">
                            {renderOpportunities("Business")}
                          </div>
                        </div>}
                        {<div className="business-opportunities-section mx-2 mt-2">
                          <div className="row d-flex">
                            <label className="form-label text-black font-primary font-weight-bolder mb-0 ml-5">
                              Growth Opportunity
                            </label>
                          </div>
                          <div className="row d-flex mt-2">
                            {renderOpportunities("Growth")}
                          </div>
                        </div>}
                      </>
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion defaultActiveKey="2">
            <Card style={{ border: 'none', backgroundColor: 'transparent' }}>
              <Card.Header
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <div className="d-flex justify-content-between px-0 px-md-4">
                  <label
                    className="text-primary text-black font-14 font-weight-bolder text-uppercase mb-0"
                    style={{ fontFamily: 'Lato' }}
                  >
                    {SearchSettings.Industry}
                  </label>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="2"
                  onClick={() => {
                    toggleActiveKey(SearchSettings.Industry);
                  }}
                  className="btn btn-link w-100  text-decoration-none text-black px-0 px-md-4 text-primary settings-accordion pt-0"
                  aria-expanded={activeKey[SearchSettings.Industry]}
                >
                  <div className="d-flex justify-content-between border-bottom-black">
                    <label className="d-flex align-items-center text-black mb-0 text-align-left font-14">
                      Select the industries that may apply.
                    </label>

                    <span className="d-flex align-items-center font-display text-dark mb-0 pt-1 Scoreboard btn btn-chip text-right px-0">
                      <i
                        className={`icon-chevron-${
                          activeKey[SearchSettings.Industry] ? 'up' : 'down'
                        } font-24 text-black`}
                      />
                    </span>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body className="pt-0">
                  <div className="row d-flex justify-content-between">
                    {renderCheckboxSettings(
                      industries,
                      SearchSettings.Industry
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion defaultActiveKey="3">
            <Card style={{ border: 'none', backgroundColor: 'transparent' }}>
              <Card.Header
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <div className="d-flex justify-content-between px-0 px-md-4">
                  <label
                    className="text-primary text-black font-14 font-weight-bolder text-uppercase mb-0"
                    style={{ fontFamily: 'Lato' }}
                  >
                    {SearchSettings.SellingCycle}
                  </label>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="3"
                  onClick={() => {
                    toggleActiveKey(SearchSettings.SellingCycle);
                  }}
                  className="btn btn-link w-100  text-decoration-none text-black px-0 px-md-4 text-primary settings-accordion pt-0"
                  aria-expanded={activeKey[SearchSettings.SellingCycle]}
                >
                  <div className="d-flex justify-content-between border-bottom-black">
                    <label className="d-flex align-items-center text-black mb-0 text-align-left font-14">
                      What is your onboarding cycle?
                    </label>

                    <span className="d-flex align-items-center font-display text-dark mb-0 pt-1 Scoreboard btn btn-chip text-right px-0">
                      <i
                        className={`icon-chevron-${
                          activeKey[SearchSettings.SellingCycle] ? 'up' : 'down'
                        } font-24 text-black`}
                      />
                    </span>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body className="pt-0">
                  <div className="row d-flex justify-content-between">
                    {renderCheckboxSettings(
                      undefined,
                      SearchSettings.SellingCycle,
                      sellingCycleData
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion defaultActiveKey="4">
            <Card style={{ border: 'none', backgroundColor: 'transparent' }}>
              <Card.Header
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <div className="d-flex justify-content-between px-0 px-md-4">
                  <label
                    className="text-primary text-black font-14 font-weight-bolder text-uppercase mb-0"
                    style={{ fontFamily: 'Lato' }}
                  >
                    {SearchSettings.ShippingTime}
                  </label>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="4"
                  onClick={() => {
                    toggleActiveKey(SearchSettings.ShippingTime);
                  }}
                  className="btn btn-link w-100  text-decoration-none text-black px-0 px-md-4 text-primary settings-accordion pt-0"
                  aria-expanded={activeKey[SearchSettings.ShippingTime]}
                >
                  <div className="d-flex justify-content-between border-bottom-black">
                    <label className="d-flex align-items-center text-black mb-0 text-align-left font-14">
                      How soon would you need to receive the product or service
                      after invoice?
                    </label>

                    <span className="d-flex align-items-center font-display text-dark mb-0 pt-1 Scoreboard btn btn-chip text-right px-0">
                      <i
                        className={`icon-chevron-${
                          activeKey[SearchSettings.ShippingTime] ? 'up' : 'down'
                        } font-24 text-black`}
                      />
                    </span>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body className="pt-0">
                  <div className="row d-flex justify-content-between">
                    {renderCheckboxSettings(
                      undefined,
                      SearchSettings.ShippingTime,
                      shippingTimes
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <Accordion defaultActiveKey="5">
            <Card style={{ border: 'none', backgroundColor: 'transparent' }}>
              <Card.Header
                style={{
                  border: 'none',
                  backgroundColor: 'transparent',
                }}
              >
                <div className="d-flex justify-content-between px-0 px-md-4">
                  <label
                    className="text-primary text-black font-14 font-weight-bolder text-uppercase mb-0"
                    style={{ fontFamily: 'Lato' }}
                  >
                    Location
                  </label>
                </div>
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="5"
                  onClick={() => {
                    toggleActiveKey(SearchSettings.State);
                  }}
                  className="btn btn-link w-100  text-decoration-none text-black px-0 px-md-4 text-primary settings-accordion pt-0"
                  aria-expanded={activeKey[SearchSettings.State]}
                >
                  <div className="d-flex justify-content-between border-bottom-black">
                    <label className="d-flex align-items-center text-black mb-0 text-align-left font-14">
                      Select Location.
                    </label>

                    <span className="d-flex align-items-center font-display text-dark mb-0 pt-1 Scoreboard btn btn-chip text-right px-0">
                      <i
                        className={`icon-chevron-${
                          activeKey[SearchSettings.State] ? 'up' : 'down'
                        } font-24 text-black`}
                      />
                    </span>
                  </div>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body className="pt-0">
                  <div className="row px-0 px-md-4">
                    <div className="col-12 col-md-6 mb-2">
                      <select
                        className="form-control custom-select border-left-0 border-top-0 border-right-0 border-black rounded-0 clickable text-black font-14"
                        style={{ fontSize: '14px' }}
                        name="States"
                        id="state"
                        onChange={(e) => {
                          handleSettingsChange(
                            e.target.value,
                            SearchSettings.State
                          );
                        }}
                      >
                        <option defaultValue={''} hidden={true}>
                          {state
                            ? state
                            : 'States'}
                        </option>
                        <option value={'All States'}>All States</option>
                        {allStates.map((state, index) => (
                          <option key={`modal-state-${state.isoCode}-${index}`} value={state.name}>
                            {state.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-12 col-md-6">
                      <select
                        className="form-control custom-select border-left-0 border-top-0 border-right-0 border-black rounded-0 clickable text-black font-14"
                        style={{ fontSize: '14px' }}
                        name="Cities"
                        id="city"
                        disabled={
                          (allCities.length === 0 && !city) ||
                          state === 'All States'
                        }
                        onChange={(e) => {
                          handleSettingsChange(
                            e.target.value,
                            SearchSettings.City
                          );
                        }}
                        value={city}
                      >
                        <option
                          key={'no-city-selected'}
                          value={''}
                          hidden={true}
                        >
                          {city ? city : 'Please select a city'}
                        </option>
                        {allCities.length > 0 &&
                          allCities.map((city, index) => (
                            <option key={`modal-city-${city.isoCode}-${index}`} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
        {hasEmptyValues() && (
          <div className="row justify-content-center border-0 px-2">
            <p className="text-danger font-weight-bold my-0 px-2">
              *Select at least one of each to apply changes.
            </p>
          </div>
        )}
        <div className="modal-footer justify-content-center border-0 pb-4 pt-0">
          <div className="row">
            <div className="col-auto text-center order-1 my-2">
              <button
                className="btn-lg btn modal-cancel-btn text-black font-primary text-uppercase settings-modal-btn"
                onClick={() => {
                  const storedSettings = localStorage.getItem('searchSettings');
                  setDefaultData(storedSettings);
                  closeModal();
                }}
              >
                Close
              </button>
            </div>
            <div className="col-auto text-center order-1 my-2">
              <button
                className="btn btn-lg btn-primary btn-black text-pinky text-uppercase settings-modal-btn"
                disabled={hasEmptyValues()}
                onClick={() => {
                  onConfirm({
                    opportunities: isSupplier ? supplierOpportunities : opportunities,
                    industries,
                    sellingCycle,
                    shippingTime,
                    location: { state, city }
                  });
                  closeModal();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
