import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Modal from 'react-modal';
import CompanyStageCard from '../CompanyStageCard/CompanyStageCard';
import { companyStages, ICompanyStage } from '../../types/SupplierInfo';
import { isMobileOnly } from 'react-device-detect';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  selectedStage: ICompanyStage;
  handleStageSelection: Dispatch<SetStateAction<ICompanyStage>>;
}

export const CompanyStageModal = ({
  isOpen,
  closeModal,
  selectedStage,
  handleStageSelection,
}: Props) => {
  useEffect(() => {
    if (!isOpen) {
      setLocalSelectedStage(selectedStage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const mobileStyles = {
    content: {
      background: 'transparent',
      border: 'none',
      padding: 'none',
      position: 'absolute',
      display: 'block',
      margin: '0',
      transform: 'translate(-50%, -30%)',
      width: '90%',
      maxWidth: '390px',
      inset: 'unset',
      overflow: 'initial',
    },
    overlay: {
      zIndex: '3',
      backgroundColor: 'rgba(23, 41, 64, 0.75)',
    },
  };

  const desktopStyles = {
    content: {
      background: 'transparent',
      border: 'none',
      padding: 'none',
      position: 'absolute',
      display: 'block',
      margin: '0',
      transform: 'translate(-50%, -30%)',
      maxHeight: '90%',
      width: '90%',
      inset: 'unset',
      overflow: 'auto',
    },
    overlay: {
      zIndex: '3',
      backgroundColor: 'rgba(23, 41, 64, 0.75)',
    },
  };

  const [localSelectedStage, setLocalSelectedStage] = useState(selectedStage);

  if (isMobileOnly) {
    return (
      <Modal
        className="scrollbar-hidden"
        style={mobileStyles}
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        <div className="modal-content" style={{ borderRadius: '14.1562px' }}>
          <div className="modal-header  border-0">
            <div className="row mx-0 d-flex justify-content-end w-100">
              <div className="col-12 px-0">
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  onClick={closeModal}
                  aria-label="Close"
                >
                  <span className="font-primary text-black" aria-hidden="true">
                    <i className="icon-close-x" />
                  </span>
                </button>
              </div>
              <div className="col-12 px-0">
                <div className="modal-title px-3 mt-2 text-center">
                  <span className="modal-title font-primary text-black font-weight-bold font-20">
                    In what Stage is your Company?
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal-body pt-2 pl-3 pr-3 overflow-auto pb-4"
            style={{ height: '400px' }}
          >
            {companyStages.map((stage, index) => (
              <CompanyStageCard
                key={`company-stage-${index}`}
                stage={stage}
                selectedStage={localSelectedStage}
                changeStage={setLocalSelectedStage}
              />
            ))}
          </div>
          <div
            className="d-flex justify-content-center position-sticky bg-white mx-1"
            style={{
              bottom: '-135px',
              borderRadius: '15px'
            }}
          >
            <button
              className="btn btn-black btn-lg text-uppercase text-pinky rounded mt-3 mb-2 pb-2 px-5"
              type="button"
              disabled={localSelectedStage.id === 0}
              onClick={(e) => {
                e.preventDefault();
                handleStageSelection(localSelectedStage);
                closeModal();
              }}
            >
              Select Stage
            </button>
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal
        className="scrollbar-hidden"
        style={desktopStyles}
        isOpen={isOpen}
        onRequestClose={closeModal}
        closeTimeoutMS={500}
        ariaHideApp={false}
      >
        <div className="stage-modal"></div>
        <div className="modal-content" style={{ borderRadius: '14.1562px' }}>
          <div className="modal-header  border-0">
            <div className="row mx-0 d-flex justify-content-end w-100">
              <div className="col-12 px-0">
                <button
                  className="close"
                  type="button"
                  data-dismiss="modal"
                  onClick={closeModal}
                  aria-label="Close"
                >
                  <span className="font-primary text-black" aria-hidden="true">
                    <i className="icon-close-x" />
                  </span>
                </button>
              </div>
              <div className="col-12 px-0">
                <div className="modal-title px-3 mt-2 text-center">
                  <span className="modal-title font-primary text-black font-weight-bold font-20">
                    In what Stage is your Company?
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body pt-0 pr-5 pl-5">
            <div className="stage-modal">
              {companyStages.map((stage, index) => (
                <CompanyStageCard
                  key={`company-stage-${index}`}
                  stage={stage}
                  selectedStage={localSelectedStage}
                  changeStage={setLocalSelectedStage}
                />
              ))}
            </div>
            <div
              className="d-flex justify-content-center position-sticky bg-white"
              style={{
                bottom: '0px',
                marginLeft: '-1rem',
                marginRight: '-1rem',
              }}
            >
              <button
                className="btn btn-black btn-lg text-uppercase text-pinky rounded-5 mt-3 mb-2 pb-2 px-5"
                type="button"
                disabled={localSelectedStage.id === 0}
                onClick={(e) => {
                  e.preventDefault();
                  handleStageSelection(localSelectedStage);
                  closeModal();
                }}
              >
                Select Stage
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};
