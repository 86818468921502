import Amplify from 'aws-amplify';
import { cognitoClientId, cognitoPoolId, cognitoRegion, cognitoDomain, redirectUri, cognitoIdentityPoolId } from '../utils/env';

Amplify.configure({
    identityPoolId: cognitoIdentityPoolId,
    userPoolId: cognitoPoolId,
    userPoolWebClientId: cognitoClientId,
    region: cognitoRegion,
    oauth: {
        domain: cognitoDomain,
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: redirectUri,
        redirectSignOut: redirectUri,
        responseType: 'token'
    }
});