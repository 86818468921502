import { types } from '../actions/supplier';
import { SupplierInfo, SupplierCompleteProfile } from '../types/SupplierInfo';

export interface SupplierState {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  supplierInfo: SupplierInfo | SupplierCompleteProfile;
  requestFinished: boolean;
}

const initialState: SupplierState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  supplierInfo: undefined,
  requestFinished: false,
};

export default function SupplierReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SUPPLIER_INFO_REQUEST:
    case types.UPDATE_SUPPLIER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
      };
    case types.ADD_SUPPLIER_INFO_SUCCESS:
    case types.UPDATE_SUPPLIER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: true,
      };
    case types.ADD_SUPPLIER_INFO_FAILURE:
    case types.UPDATE_SUPPLIER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
        requestFinished: true,
      };

    case types.GET_SUPPLIER_INFO_REQUEST:
    case types.GET_SUPPLIER_INFO_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        supplierInfo: undefined,
      };
    case types.GET_SUPPLIER_INFO_SUCCESS:
    case types.GET_SUPPLIER_INFO_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        supplierInfo: action.payload,
      };
    case types.GET_SUPPLIER_INFO_FAILURE:
    case types.GET_SUPPLIER_INFO_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
      };
    case types.RESET_SUPPLIER_ERRORS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
      };
    case types.RESET_SUPPLIER_INFO:
      return {
        ...state,
        supplierInfo: undefined
      }
    default:
      return state;
  }
}
