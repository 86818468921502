import Auth from '@aws-amplify/auth';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';

const VerifyAccount = () => {

    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    useEffect(() => {
        const verifyAccount = async () => {
            if (query && query.get('email') && query.get('code')) await handleConfirm(query.get('email'), query.get('code'))
            else {
                toast.error('Invalid code or email. Try to log in again to have a new confirmation email sent.');
                history.push('/');
            }
        }
        verifyAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConfirm = async (email: string, code: string) => {
        try {
            await Auth.confirmSignUp(email, code);
            toast.success(
                'Account verified successfully!',
                { toastId: 'verification-success' }    
            );
            const redirectUrl = localStorage.getItem('redirect-url');
            if (redirectUrl) {
              localStorage.removeItem('redirect-url');
              history.push(redirectUrl);
            } else {
              history.push('/login');
            }
        } catch (err) {
            if (err.code === 'NotAuthorizedException') {
                toast.success('This account has already been verified.', { toastId: 'already-verified' });
                history.push('/login');
            } else if (err.code === 'LimitExceededException'){
                toast.error(`You've exceeded the limit of confirmation attempts, try again later`, { toastId: 'confirm-limit-exceeded' });
                history.push('/login');
            } else {
                let errorMessage = err.code === 'CodeMismatchException' || err.code === 'ExpiredCodeException'
                    ? err.message
                    : 'An error occured while verifying your account. An email with a new verification link will be sent.';
                toast.error(errorMessage, { toastId: 'verification-error' });
                await resendCode(email)
            }
        }
    }

    const resendCode = async (email: string) => {
        try{
            await Auth.resendSignUp(email);
            toast.success(`A new verification link has been sent to your email ${email}!`, { toastId: 'code-resend' })
        } catch (err) {
            toast.error('An error occured while attempting to resend the verification link, try to login in later or contact support', { toastId: 'code-resend-error' })
        }
    }

    return (
        <main className="container auth-container py-5 px-4">
            <p>Verifying account...</p>
        </main>
    )
};

export default VerifyAccount;