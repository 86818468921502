import { Helmet } from 'react-helmet';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const state: any = location.state;

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (state && state.email) setEmail(state.email);
    else if (query && query.get('email')) setEmail(query.get('email'));
  }, [state, location.search]);

  const handleReset = async (e) => {
    e.preventDefault();
    if (!validator.isEmail(email)) {
      setValidEmail(false);
      return;
    }
    setValidEmail(true);
    try {
      const authEmail = email;
      await Auth.forgotPassword(authEmail);
      toast.success(
        'An email with a code will be sent if a user with this email exists',
        { toastId: 'reset-password-toast' }
      );
      window.scrollTo(0, 0);
      history.push('/');
    } catch (err) {
      let message = ''
      if (err.code === 'InvalidParameterException') {
        message = `You must verify your account before you can reset your password. A new email with a verification link has been sent to your email ${email}`
        await Auth.resendSignUp(email);
      } else {
        message = err.message;
      } 
      toast.error(message, { toastId: 'reset-password-error' });
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Helmet>
        <title>BSN - Change Password</title>
      </Helmet>
      <main className="body-min-h90 container auth-container py-5 px-4">
        <Breadcrumbs
          goBack={goBack}
          customClasses="navigator mt-3 mb-4 px-md-0"
        />
        <h1 className="h4 text-black text-center font-primary font-weight-bold">
          Reset Password
        </h1>
        <form className="auth-form mt-4">
          <div
            className={`input-group input-group-lg ${
              !validEmail ? 'mb-2' : 'mb-4'
            }`}
          >
            <input
              className="form-control  border-left-0 border-top-0 border-right-0 rounded-0"
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setValidEmail(true);
              }}
            />
          </div>
          {!validEmail && (
            <div className="input-helper mb-2">
              <small className="text-danger">
                <strong>Invalid Email</strong>
              </small>
            </div>
          )}
          <div>
            <button
              disabled={!email}
              className="btn btn-black text-pinky btn-block btn-lg rounded-0 text-uppercase"
              onClick={handleReset}
            >
              Request Password Reset
            </button>
          </div>
        </form>
      </main>
    </>
  );
};

export default ResetPassword;
