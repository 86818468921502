import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { sortFunctions, UserTableRow } from '../types/User';
import { backendUrl } from '../utils/env';

export const types = {
  INVITE_USER_REQUEST: 'invite-user/post/pending',
  INVITE_USER_SUCCESS: 'invite-user/post/fulfilled',
  INVITE_USER_FAILURE: 'invite-user/post/rejected',
  GET_ALL_USERS_FOR_TABLE_REQUEST: 'users/forTable/pending',
  GET_ALL_USERS_FOR_TABLE_SUCCESS: 'users/forTable/fulfilled',
  GET_ALL_USERS_FOR_TABLE_FAILURE: 'users/forTable/rejected',
  GET_USER_BY_ID_REQUEST: 'user/byId/pending',
  GET_USER_BY_ID_SUCCESS: 'user/byId/fulfilled',
  GET_USER_BY_ID_FAILURE: 'user/byId/rejected',
  SORT_USERS_FOR_TABLE_REQUEST: 'users/sortForTable/pending',
  SORT_USERS_FOR_TABLE_SUCCESS: 'users/sortForTable/fulfilled',
  SORT_USERS_FOR_TABLE_FAILURE: 'users/sortForTable/rejected',
  GET_USERS_PENDING_APPROVAL_REQUEST: 'users/pendingApproval/pending',
  GET_USERS_PENDING_APPROVAL_SUCCESS: 'users/pendingApproval/fulfilled',
  GET_USERS_PENDING_APPROVAL_FAILURE: 'users/pendingApproval/rejected',
  UPDATE_ADMISSION_STATUS_REQUEST: 'users/updateAdmissionStatus/pending',
  UPDATE_ADMISSION_STATUS_SUCCESS: 'users/updateAdmissionStatus/fulfilled',
  UPDATE_ADMISSION_STATUS_FAILURE: 'users/updateAdmissionStatus/rejected',
  RESET_USER_ERRORS: 'errors/user/reset',
};

export const getAllUsersForTable = createAsyncThunk(
  'users/forTable',
  async () => {
    const response = await axios.get(`${backendUrl}/Users/forTable`);
    return response.data;
  }
);

export const getUserById = createAsyncThunk(
  'user/byId',
  async (userId: string) => {
    const response = await axios.get(`${backendUrl}/Users/user/byId/${userId}`);
    return response.data;
  }
);

export const sortUsersForTable = createAsyncThunk(
  'users/sortForTable',
  async ({
    users,
    column,
    order,
  }: {
    users: UserTableRow[];
    column: string;
    order: string;
  }) => {
    let type: string =
      column === 'dateAdded'
        ? 'date'
        : column === 'isAlumni'
        ? 'string'
        : typeof users[0][column];
    const sortedUsers = users.sort(sortFunctions[type](column, order));
    const admins = sortedUsers.filter((user) => user.mainRole === 'Admin');
    const suppliers = sortedUsers.filter(
      (user) => user.mainRole === 'Supplier'
    );
    const customers = sortedUsers.filter(
      (user) => user.mainRole === 'Customer'
    );
    const superAdmins = sortedUsers.filter(
      (user) => user.mainRole === 'Super Admin'
    );
    return {
      users: sortedUsers,
      admins,
      suppliers,
      customers,
      superAdmins,
    };
  }
);

export const inviteUser = createAsyncThunk(
  'invite-user/post',
  async ({ payload, token }: any, thunkAPI) => {
    // let authToken = '';
    // if (token) {
    //   authToken = `Bearer ${token}`;
    // }
    // const headers = {
    //   Authorization: authToken,
    //   'Content-Type': 'application/json',
    // };
    try {
      const response = await axios.post(
        `${backendUrl}/Users/invite-user`,
        payload
        // {
        //   headers,
        // }
      );
      if (response.data.code && response.data.code === '500') {
        toast.error(response.data.message);
      } else {
        toast.success('Email has been sent to new user.', {
          toastId: 'invitation-email-success',
        });
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getUsersPendingApproval = createAsyncThunk(
  'users/pendingApproval',
  async ({ page }: { page: number }) => {
    const response = await axios.get(
      `${backendUrl}/Users/pendingApproval?page=${page}`
    );
    return response.data;
  }
);

export const updateAdmissionStatus = createAsyncThunk(
  'users/updateAdmissionStatus',
  async (
    { id, newStatus, role }: { id: number; newStatus: number; role: string },
    thunkAPI
  ) => {
    const response = await axios.put(
      `${backendUrl}/Users/admissionStatus/${id}`,
      { newStatus, role }
    );
    thunkAPI.dispatch(getUsersPendingApproval({ page: 1 }));
    return response.data;
  }
);

export const resetUserErrors = createAction('errors/user/reset');
