import { Auth } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authContext } from '../../components/Authentication/CognitoContextProvider';
import validator from 'validator';

const ConfirmReset = () => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [code, setCode] = useState('');
  const [validEmail, setValidEmail] = useState(true);
  const [email, setEmail] = useState('');
  const location = useLocation();
  const history = useHistory();
  const { setAuthData } = useContext(authContext);
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    const state: any = location.state;
    if (state && state.email) setEmail(state.email);
    else if (query && query.get('email')) setEmail(query.get('email'));

    if (query && query.get('code')) setCode(query.get('code'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleConfirm = async (e) => {
    e.preventDefault();
    if (
      !validator.isEmail(email) ||
      (location.state && location.state['authEmail'] !== email) ||
      (query && query.get('email') && query.get('email') !== email)
    ) {
      setValidEmail(false);
      return;
    }
    if (!code) return;
    try {
      const checkPreviousPw = await Auth.signIn(email, password);
      if (checkPreviousPw) {
        toast.error('Please use a new password');
        await Auth.signOut();
      }
    } catch (err) {
      if (err.message === 'Incorrect username or password.') {
        try {
          await Auth.forgotPasswordSubmit(email, code, password);
          const user = await Auth.signIn(email, password);
          setAuthData(user);
          toast.success('Password reset successful!', {
            toastId: 'password-reset-success',
          });
          window.scrollTo(0, 0);
          history.push('/');
        } catch (err) {
          if (err.name === 'ExpiredCodeException') {
            toast.error(
              'Invalid verification code provided, please try again.'
            );
            console.log(err);
          } else {
            toast.error(err.message);
            console.log(err);
          }
        }
      } else {
        toast.error(err.message);
        console.log(err);
      }
    }
  };

  return (
    <main className="body-min-h90 container auth-container py-5 px-4">
      <h1 className="h4 text-black text-center font-primary font-weight-bold">
        Confirm Password Reset
      </h1>
      <form className="auth-form mt-4">
        <div className="input-group input-group-lg mb-4">
          <input
            className={`form-control  border-left-0 border-top-0 border-right-0 rounded-0 ${
              !validEmail ? 'is-invalid' : ''
            }`}
            placeholder="Email"
            type="email"
            id="email"
            value={email}
            disabled={true}
            onChange={(event) => {
              setEmail(event.target.value);
              setValidEmail(true);
            }}
          />
          {!validEmail && (
            <div className="input-helper mt-2">
              <small className="text-danger">
                <strong>Invalid Email</strong>
              </small>
            </div>
          )}
        </div>
        <div className="mb-4  input-group-lg">
          <input
            id="new-password"
            placeholder="Code"
            disabled={true}
            className="form-control  border-left-0 border-top-0 border-right-0 rounded-0"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <div className="mb-4 input-group-lg">
          <input
            id="new-password"
            placeholder="Password"
            className={`form-control  border-left-0 border-top-0 border-right-0 rounded-0 ${
              password.length !== 0 && password.length < 6 ? 'is-invalid' : ''
            }`}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {password.length !== 0 && password.length < 6 && (
            <div className="input-helper mt-2">
              <small className={`text-danger`}>
                <strong>Password must have at least 6 characters</strong>
              </small>
            </div>
          )}
        </div>
        <div className="mb-4 input-group-lg ">
          <input
            placeholder="Confirm Password"
            id="new-password-confirm"
            className={`form-control  border-left-0 border-top-0 border-right-0 rounded-0 ${
              passwordConfirmation && password !== passwordConfirmation
                ? 'is-invalid'
                : ''
            }`}
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          {passwordConfirmation && password !== passwordConfirmation && (
            <div className="input-helper mt-2">
              <small className={`text-danger`}>
                <strong>Passwords don't match</strong>
              </small>
            </div>
          )}
        </div>
        <div>
          <button
            disabled={
              password.length < 6 ||
              passwordConfirmation.length < 6 ||
              password !== passwordConfirmation ||
              !email
            }
            className="btn btn-black text-pinky btn-block btn-lg rounded-0 text-uppercase"
            onClick={handleConfirm}
          >
            Confirm Password Reset
          </button>
        </div>
      </form>
    </main>
  );
};

export default ConfirmReset;
//
