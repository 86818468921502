import React from 'react';
const ReactUltimatePagination = require('react-ultimate-pagination');


const Page = (props) => {
    return (
      <li
        onClick={props.onClick}
        className={`page-item ${props.isActive ? 'active' : ''}`}
      >
          <button className="page-link">{props.value}</button>
      </li>
    );
}

const Ellipsis = (props) => {
    return (
        <li
            className="page-item disabled"
        >
            <button className="page-link">...</button>
        </li>
    );
}

const PreviousPageLink = (props) => {
    return (
        <li
            className="page-item"
        >
            <button className="page-link" onClick={props.onClick}>{'<'}</button>
        </li>
    );
}

const NextPageLink = (props) => {
    return (
        <li
            className="page-item"
        >
            <button className="page-link" onClick={props.onClick}>{'>'}</button>
        </li>
    );
}

const Wrapper = (props) => {
    return <ul className="col-12 d-md-flex d-lg-flex pagination pagination-md px-0">{props.children}</ul>
}

const itemTypeToComponent = {
    'PAGE': Page,
    'ELLIPSIS': Ellipsis,
    'PREVIOUS_PAGE_LINK': PreviousPageLink,
    'NEXT_PAGE_LINK': NextPageLink,
};

const UltimatePagination = ReactUltimatePagination.createUltimatePagination({
    itemTypeToComponent: itemTypeToComponent,
    WrapperComponent: Wrapper
});

export default UltimatePagination;