import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import validator from 'validator';
import { fileIsImage } from '../../utils/validations';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import Google from '../../styles/img/g-icon.svg';

interface Props {
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  password?: string;
  setPassword?: Dispatch<SetStateAction<string>>;
  confirmPassword?: string;
  setConfirmPassword?: Dispatch<SetStateAction<string>>;
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  image: File;
  setImage: Dispatch<SetStateAction<File>>;
  imageSrc: string;
  setImageSrc: Dispatch<SetStateAction<string>>;
  isEdit?: boolean;
  setImageWasModified?: Dispatch<SetStateAction<boolean>>;
}

const BasicInformation = ({
  email,
  setEmail,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  image,
  setImage,
  imageSrc,
  setImageSrc,
  isEdit,
  setImageWasModified,
}: Props) => {
  const [validEmail, setValidEmail] = useState(true);
  const [highlightEmailField, setHighlightEmailField] = useState(false);
  const [highlightFirstNameField, setHighlightFirstNameField] = useState(false);
  const [highlightLastNameField, setHighlightLastNameField] = useState(false);
  const [highlightPasswordField, setHighlightPasswordField] = useState(false);
  const [highlightConfirmPasswordField, setHighlightConfirmPasswordField] =
    useState(false);
  const [invalidCharactersFirstName, setInvalidCharactersFirstName] =
    useState(false);
  const [invalidCharactersLastName, setInvalidCharactersLastName] =
    useState(false);
  const inputRef = useRef(null);

  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const founderEmail = query.get('email');

  useEffect(() => {
    if (founderEmail) setEmail(founderEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [founderEmail]);

  const highlightRequiredFields = () => {
    if (anyFieldEmpty) {
      setHighlightEmailField(email.length === 0);
      setHighlightPasswordField(password.length === 0);
      setHighlightConfirmPasswordField(confirmPassword.length === 0);
      setHighlightFirstNameField(firstName.trim().length === 0);
      setHighlightLastNameField(lastName.trim().length === 0);
      return true;
    }
    return false;
  };

  const anyFieldEmpty =
    email.length === 0 ||
    (!isEdit && (password.length === 0 || confirmPassword.length === 0)) ||
    firstName.trim().length === 0 ||
    lastName.trim().length === 0;

  return (
    <>
      {isEdit ? (
        <div className="d-flex justify-content-left justify-content-md-center">
          <h2 className="text-black text-center font-primary font-weight-bolder">
            Profile Settings
          </h2>
        </div>
      ) : (
        <div className="section pt-3 pb-2">
          <h1 className="font-weight-bold font-primary text-black text-left text-md-center">
            Setting up your Champion Account
          </h1>
        </div>
      )}
      <div className="d-flex flex-row text-center justify-content-left justify-content-md-center align-items-center mt-3 mt-md-5">
        <div
          className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
          style={{ height: '35px', width: '35px' }}
        >
          <span className="my-0 font-primary text-black font-20">1</span>
        </div>
        <span className="text-black py-2 ml-3 text-uppercase font-20">
          Basic Information
        </span>
      </div>
      <form className="auth-form mt-4">
        <div className="row mx-0 px-0">
          <div className="col-12 col-md-6 px-0 pr-md-3">
            <div
              className={`form-group ${
                highlightFirstNameField || invalidCharactersFirstName
                  ? 'mb-2'
                  : 'mb-4'
              }`}
            >
              <label className="form-label text-black font-primary font-weight-bolder">
                First Name <span className="text-danger">*</span>
              </label>
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 ${
                  highlightFirstNameField || invalidCharactersFirstName
                    ? 'is-invalid'
                    : ''
                }`}
                type="text"
                id="name"
                placeholder="Enter First Name"
                value={firstName}
                onChange={(event) => {
                  const val = event.target.value;
                  setHighlightFirstNameField(val.trim().length === 0);
                  setInvalidCharactersFirstName(
                    val && (val.includes('"') || val.includes(','))
                  );
                  setFirstName(val);
                }}
              />
            </div>
            {highlightFirstNameField && (
              <div className="input-helper mb-2">
                <small className={`text-danger`}>
                  <strong>First Name is required</strong>
                </small>
              </div>
            )}
            {invalidCharactersFirstName && (
              <div className="input-helper mb-2">
                <small className={`text-danger`}>
                  <strong>
                    First Name can not have commas or double quotes
                  </strong>
                </small>
              </div>
            )}
          </div>
          <div className="col-12 col-md-6 px-0 pl-md-3">
            <div
              className={`form-group ${
                highlightLastNameField || invalidCharactersLastName
                  ? 'mb-2'
                  : 'mb-4'
              }`}
            >
              <label className="form-label text-black font-primary font-weight-bolder">
                Last Name <span className="text-danger">*</span>
              </label>
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 ${
                  highlightLastNameField || invalidCharactersLastName
                    ? 'is-invalid'
                    : ''
                }`}
                type="text"
                id="lastname"
                placeholder="Enter Last Name"
                value={lastName}
                onChange={(event) => {
                  const val = event.target.value;
                  setHighlightLastNameField(val.trim().length === 0);
                  setInvalidCharactersLastName(
                    val && (val.includes('"') || val.includes(','))
                  );
                  setLastName(val);
                }}
              />
            </div>
            {highlightLastNameField && (
              <div className="input-helper mb-2">
                <small className={`text-danger`}>
                  <strong>Last Name is required</strong>
                </small>
              </div>
            )}
            {invalidCharactersLastName && (
              <div className="input-helper mb-2">
                <small className={`text-danger`}>
                  <strong>
                    Last Name can not have commas or double quotes
                  </strong>
                </small>
              </div>
            )}
          </div>
        </div>
        <div
          className={`form-group ${
            !validEmail || highlightEmailField ? 'mb-2' : 'mb-4'
          }`}
        >
          <label className="form-label text-black font-primary font-weight-bolder">
            Email <span className="text-danger">*</span>
          </label>
          <input
            required
            className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 ${
              !validEmail || highlightEmailField ? 'is-invalid' : ''
            }`}
            type="email"
            id="email"
            placeholder="Enter Email"
            value={email}
            disabled={!!founderEmail || isEdit}
            onChange={(event) => {
              setHighlightEmailField(event.target.value.length === 0);
              setValidEmail(validator.isEmail(event.target.value));
              setEmail(event.target.value);
            }}
          />
        </div>
        {(!validEmail || highlightEmailField) && (
          <div className="input-helper mb-2">
            <small className="text-danger">
              <strong>
                {email.length === 0 ? 'Email is required' : 'Invalid Email'}
              </strong>
            </small>
          </div>
        )}
        <div className="row" style={{ display: imageSrc ? 'none' : 'flex' }}>
          <div className="col-auto">
            <button
              className="upload-img-btn text-black my-3"
              onClick={(e) => {
                e.preventDefault();
                const imgInput = document.getElementById('featured-image');
                if (imgInput) imgInput.click();
              }}
            >
              <i className="icon-upload-cloud font-48 text-black"></i>
              <span className="sr-only">Profile Picture</span>
            </button>
            <input
              id="featured-image"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              ref={inputRef}
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  const imageFile = e.target.files[0];
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    setImageSrc(
                      event.target.result ? event.target.result.toString() : ''
                    );
                  };
                  if (fileIsImage(imageFile.name)) {
                    reader.readAsDataURL(imageFile);
                    setImage(imageFile);
                    isEdit && setImageWasModified(true);
                  } else {
                    toast.error(
                      'File must be a valid image (JPG, GIF, PNG, JPEG)'
                    );
                  }
                } else {
                  inputRef.current.value = '';
                  setImageSrc('');
                  setImage(null);
                  isEdit && setImageWasModified(true);
                }
              }}
            />
          </div>
          <div className="col align-items-center d-flex">
            <span className="text-muted">Upload Profile Picture</span>
          </div>
        </div>
        {imageSrc && (
          <div className="row my-3">
            <div className="col-auto">
              <div className="upload-img-preview my-3">
                <button
                  className="remove btn btn-link text-white text-decoration-none d-flex justify-content-center align-items-center"
                  onClick={() => {
                    inputRef.current.value = '';
                    setImage(null);
                    setImageSrc('');
                    isEdit && setImageWasModified(true);
                  }}
                >
                  <i className="icon-close-x font-16 text-white" />
                  <span className="sr-only">Remove Image</span>
                </button>
                <button
                  className="replace btn text-white"
                  onClick={(e) => {
                    e.preventDefault();
                    const imgInput = document.getElementById('featured-image');
                    if (imgInput) imgInput.click();
                  }}
                >
                  <i className="icon-refresh font-28 text-white" />
                  <span className="sr-only">Replace Image</span>
                </button>
                <LazyLoadImage src={imageSrc} alt="" />
              </div>
            </div>
          </div>
        )}
        {!isEdit && (
          <>
            <div
              className={`form-group mt-3 
    ${
      (password.length !== 0 && password.length < 6) || highlightPasswordField
        ? 'mb-2'
        : 'mb-4'
    }`}
            >
              <label className="form-label text-black font-primary font-weight-bolder">
                Password <span className="text-danger">*</span>
              </label>
              <input
                required
                className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 ${
                  (password.length !== 0 && password.length < 6) ||
                  highlightPasswordField
                    ? 'is-invalid'
                    : ''
                }`}
                type="password"
                id="password-1"
                placeholder="Enter Password"
                value={password}
                onChange={(event) => {
                  setHighlightPasswordField(event.target.value.length === 0);
                  setPassword(event.target.value);
                }}
              />
            </div>
            {password.length !== 0 && password.length < 6 && (
              <div className="input-helper mb-2">
                <small className={`text-danger`}>
                  <strong>Password must have at least 6 characters</strong>
                </small>
              </div>
            )}
            {highlightPasswordField && (
              <div className="input-helper mb-2">
                <small className={`text-danger`}>
                  <strong>Password is required</strong>
                </small>
              </div>
            )}
            <div
              className={`form-group
      ${
        (confirmPassword && password !== confirmPassword) ||
        highlightConfirmPasswordField
          ? 'mb-2'
          : 'mb-4'
      }`}
            >
              <label className="form-label text-black font-primary font-weight-bolder">
                Confirm Password <span className="text-danger">*</span>
              </label>
              <input
                required
                className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 ${
                  (confirmPassword && password !== confirmPassword) ||
                  highlightConfirmPasswordField
                    ? 'is-invalid'
                    : ''
                }`}
                type="password"
                id="password-2"
                placeholder="Enter Password Again"
                value={confirmPassword}
                onChange={(event) => {
                  setHighlightConfirmPasswordField(
                    event.target.value.length === 0
                  );
                  setConfirmPassword(event.target.value);
                }}
              />
            </div>
            {confirmPassword.length !== 0 && password !== confirmPassword && (
              <div className="input-helper mb-2">
                <small className={`text-danger`}>
                  <strong>Passwords don't match</strong>
                </small>
              </div>
            )}
            {highlightConfirmPasswordField && (
              <div className="input-helper mb-2">
                <small className={`text-danger`}>
                  <strong>Password confirmation is required</strong>
                </small>
              </div>
            )}
            <div className="d-flex flex-column-reverse flex-md-row justify-content-center">
              <button
                className="btn modal-cancel-btn text-black btn-lg text-uppercase mt-4 rounded-5 px-5 mx-1 mx-md-3"
                onClick={(e) => {
                  e.preventDefault();
                  history.replace('/role-selection');
                }}
              >
                Go back
              </button>
              <button
                className="btn btn-black btn-lg text-uppercase text-pinky rounded-5 mt-4 px-5"
                disabled={
                  anyFieldEmpty ||
                  invalidCharactersFirstName ||
                  invalidCharactersLastName ||
                  !validEmail ||
                  password !== confirmPassword
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (!highlightRequiredFields())
                    history.push('/onboarding/professional-information');
                }}
              >
                Next
              </button>
            </div>
          </>
        )}
      </form>
      {!isEdit && (
        <>
          <div className="divider d-flex my-4">
            <div className="black-line mr-3"></div>
            <span className="d-inline-block px-2 text-black">or</span>
            <div className="black-line ml-3"></div>
          </div>
          <section className="alternate-auth">
            <div className="google-auth my-4">
              <div className="btn-outline text-reset btn-block text-decoration-none">
                <span className="d-flex align-items-center justify-content-center">
                  <div
                    className="google-signin-wrapper d-flex align-items-center pointer-cursor"
                    onClick={async () => {
                      localStorage.setItem(
                        'redirect-url',
                        'onboarding/professional-information'
                      );
                      localStorage.setItem('google-signup', 'true');
                      return await Auth.federatedSignIn({
                        provider: CognitoHostedUIIdentityProvider.Google,
                      });
                    }}
                  >
                    <img
                      className="google-icon mr-4 pl-2"
                      src={Google}
                      alt="google-icon"
                    />
                    <span className="google-sso-text text-black pr-2">
                      SIGN UP WITH GOOGLE{' '}
                    </span>
                  </div>
                </span>
              </div>
            </div>
            <div className="login-links text-center text-black mt-3">
              <span>Already have an account? </span>
              <Link className="text-black" to="/login">
                <strong>Log in</strong>
              </Link>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default BasicInformation;
