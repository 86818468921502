import React, { createContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { IAuth } from '../../types/CognitoAuth';
import { useHistory } from 'react-router';
import { checkSessionStillActive } from '../../auth/userUtils';

export const authContext = createContext({
  auth: null as IAuth,
  setAuthData: null,
});

const CognitoContextProvider = ({ children }) => {
  const [auth, setAuth] = useState({ loading: false, data: null } as IAuth);
  const history = useHistory();

  useEffect(() => {
    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyUser = async () => {
    setAuth({ loading: true, data: null });
    const user = await Auth.currentAuthenticatedUser();
    setAuthData(user);
    await checkSessionStillActive(auth, setAuthData, history);
  };

  const setAuthData = (data) => {
    setAuth({ loading: false, data: data });
  };

  return (
    <authContext.Provider value={{ auth, setAuthData }}>
      {children}
    </authContext.Provider>
  );
};

export default CognitoContextProvider;
