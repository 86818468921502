import { industriesInitialState } from '../types/SupplierInfo';

export const industrySelected = (industries) => {
  return (
    Object.keys(industries).find((i) => industries[i] && industries[i] !== 'false') !==
    undefined
  );
};

export const getFieldOfOpportunities = (fieldsOfOpportunities) => {
  const fields = [];
  Object.keys(fieldsOfOpportunities).forEach((op) => {
    if (fieldsOfOpportunities[op]) {
      fields.push(op);
    }
  });
  return fields;
};

export const defaultSettings = {
  page: 1,
  userId: '',
  isSupplier: false,
  areaOfInterest: {
    Investment: false,
    Hiring: false,
    Procurement: false,
    Partnerships: false,
    Mentorship: false,
    'Supplier Diversity': false,
  },
  fieldsOfOpportunity: {
    smallBoutiques: false,
    mentorships: false,
    bigBoxRetailer: false,
    corporations: false,
    partnerships: false,
    investment: false,
    internship: false,
    openToWork: false,
  },
  shippingTime: 'Any',
  sellingCycle: 'Any',
  industries: industriesInitialState,
  location: {
    state: '',
    city: '',
  },
};

export const objectHasNonSelectedValues = (obj: any, isSupplier?: boolean) => {
  const response = [];
  Object.keys(obj).forEach((p) => {
    if (isSupplier ? obj[p] : obj[p].value) {
      response.push(1);
    }
  });
  return response.length === 0;
};
