import { Auth } from 'aws-amplify';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import validator from 'validator';
import {
  getCustomerById,
  resetCustomerErrors,
  updateCustomerInfo,
} from '../../actions/customer';
import { isAdmin, isGoogleUser, updateProfile } from '../../auth/userUtils';
import { authContext } from '../../components/Authentication/CognitoContextProvider';
import BasicInformation from '../../components/OnboardingViews/BasicInformation';
import { RootState } from '../../reducers';
import { uploadProfilePictureToS3 } from '../../utils/uploadToS3';
import { RingLoader } from 'react-spinners';
import { CustomerOpportunities } from '../../types/CustomerInfo';
import ProfessionalInformation from '../../components/OnboardingViews/ProfessionalInformation';
import { LinkedinRegularExpression } from '../../utils/validations';

const EditCustomerProfile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [image, setImage] = useState<File>();
  const [imageSrc, setImageSrc] = useState('');
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [hasCalledUpdate, setHasCalledUpdate] = useState(false);
  const [fieldsOfOpportunity, setFieldsOfOpportunity] = useState({
    smallBoutiques: false,
    mentorships: false,
    bigBoxRetailer: false,
    corporations: false,
    partnerships: false,
    investment: false,
    internship: false,
    openToWork: false,
  });
  const [imageWasModified, setImageWasModified] = useState(false);

  const { requestFinished, hasErrors, errorMessage, customerInfo } =
    useSelector((state: RootState) => state.customer);

  const history = useHistory();
  const { state: locationState } = useLocation<{comesFromAdmin: boolean, comesFromUnderAdmission: boolean}>();
  const dispatch = useDispatch();

  const { auth, setAuthData } = useContext(authContext);
  const params: { customerId: string } = useParams();

  useEffect(() => {
    if (auth && auth.data) {
      if (
        params.customerId !== auth.data.attributes.sub &&
        !isAdmin(auth.data)
      ) {
        history.replace('/');
      } else {
        dispatch(getCustomerById(params.customerId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.data]);

  useEffect(() => {
    if (customerInfo) {
      setFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo]);

  const setFields = () => {
    const profilePicture = customerInfo.picture
      ? customerInfo.picture !== 'N/A'
        ? customerInfo.picture
        : ''
      : customerInfo.picture;

    setFirstName(customerInfo.firstName || '');
    setLastName(customerInfo.lastName || '');
    setEmail(customerInfo.email || '');
    setCompanyName(customerInfo.companyName);
    setLinkedinUrl(customerInfo.linkedin);
    setCompanyWebsite(customerInfo.website);
    setImageSrc(profilePicture || '');
    customerInfo.fieldofopportunity &&
      setFieldsOfOpportunity(customerInfo.fieldofopportunity);
  };

  const showToast = () => {
    if (requestFinished && hasCalledUpdate) {
      if (hasErrors) {
        toast.error(errorMessage);
      } else {
        window.scrollTo(0, 0);
        locationState?.comesFromAdmin
          ? history.replace(`/profile/champion/${params.customerId}`)
          : locationState?.comesFromUnderAdmission
          ? history.replace('/account-under-admission')
          : history.goBack();
        setLoading(false);
        setDisabled(false);
        toast.success('Profile updated successfully', {
          toastId: 'customer-profile-updated-success',
        });
        dispatch(getCustomerById(params.customerId));
      }
      dispatch(resetCustomerErrors());
      setHasCalledUpdate(false);
    }
    return null;
  };

  const redirectToLogin = () => {
    localStorage.setItem(
      'redirect-url',
      `/champion/edit/${auth.data.attributes.sub}`
    );
    history.push('/login');
  };

  const anyFieldEmpty =
    firstName.trim().length === 0 ||
    lastName.trim().length === 0 ||
    (email.length === 0 && customerInfo.email.length === 0) ||
    !Object.values(fieldsOfOpportunity).some((field) => field) ||
    linkedinUrl.trim().length === 0 ||
    companyName.trim().length === 0;

  const validateFields =
    (companyName && (companyName.includes('"') || companyName.includes(','))) ||
    (linkedinUrl && !LinkedinRegularExpression.test(linkedinUrl)) ||
    (companyWebsite &&
      !validator.isURL(companyWebsite));

  const handleSubmit = async () => {
    if (anyFieldEmpty) return;

    let token = '';
    if (auth.data) {
      const getSession = await Auth.currentSession();
      token = getSession.getIdToken().getJwtToken();
    } else redirectToLogin();

    setLoading(true);
    setDisabled(true);

    const cleanFirstName = firstName.replace(/,|"/g, '').trim();
    const cleanLastName = lastName.replace(/,|"/g, '').trim();

    const customerInfoPayload = {
      firstName: cleanFirstName,
      lastName: cleanLastName,
      companyName,
      linkedin: linkedinUrl,
      website: companyWebsite,
      fieldOfOpportunity: fieldsOfOpportunity,
      customerSub: customerInfo.sub,
    };

    try {
      const cognitoPayload = {
        'custom:first_name': cleanFirstName,
        'custom:last_name': cleanLastName,
      };

      const imageFolder = `${firstName}-${lastName}`;
      let imageUrl = '';
      if (imageWasModified && image) {
        imageUrl = await uploadProfilePictureToS3(image, imageFolder);
        cognitoPayload['custom:picture'] = imageUrl;
      } else if (imageWasModified && !image) {
        cognitoPayload['custom:picture'] = 'N/A';
      }

      const updatedCognitoAttributes = await updateProfile(
        cognitoPayload,
        customerInfo.username,
        setAuthData
      );

      if (!updatedCognitoAttributes) {
        throw new Error();
      }

      if (customerInfo.id) {
        dispatch(
          updateCustomerInfo({
            payload: customerInfoPayload,
            token,
            customerInfoId: customerInfo.id,
          })
        );
      } else {
        toast.error('Profile does not exist', {
          toastId: 'profile-existence-error',
        });
      }
    } catch (error) {
      toast.error('Something went wrong when updating the profile');
      console.error(error);
    } finally {
      setLoading(false);
      setDisabled(false);
      setHasCalledUpdate(true);
    }
  };

  const showPasswordField = () => {
    return !isAdmin(auth.data) && !isGoogleUser(auth.data);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (isAdmin(auth.data)) return;

    try {
      await Auth.forgotPassword(email);
      toast.success(`An email with a code will be sent to ${email}`, {
        toastId: 'reset-password-toast',
      });
    } catch (err) {
      let message = err.message;
      toast.error(message, { toastId: 'reset-password-error' });
    }
  };

  return (
    <>
      <Helmet>
        <title>BSN - Onboarding</title>
      </Helmet>
      <main className="body-min-h90 container onboarding py-5 px-4">
        {loading ? (
          <div className="align-middle ">
            <div className="d-flex justify-content-center">
              <RingLoader size={120} loading={loading} />
            </div>
            <p className="loader-text text-center text-black">
              Your profile is being updated, this might take a few seconds
            </p>
          </div>
        ) : (
          <>
            <BasicInformation
              email={email}
              setEmail={setEmail}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              image={image}
              setImage={setImage}
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}
              isEdit={true}
              setImageWasModified={setImageWasModified}
            />
            <ProfessionalInformation
              linkedinUrl={linkedinUrl}
              setLinkedinUrl={setLinkedinUrl}
              companyName={companyName}
              setCompanyName={setCompanyName}
              companyWebsite={companyWebsite}
              setCompanyWebsite={setCompanyWebsite}
              isEdit
            />
            <div className="d-flex flex-row justify-content-left justify-content-md-center align-items-center mt-3 mt-md-5">
              <div
                className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
                style={{ height: '35px', width: '35px' }}
              >
                <span className="my-0 font-primary text-black font-20">3</span>
              </div>
              <span className="text-black py-2 ml-3 text-uppercase font-20">
                Area of Opportunity
              </span>
            </div>
            <label className="form-label text-black font-primary font-weight-bolder text-uppercase font-20 mt-3">
              Opportunity
            </label>
            <div className="row d-flex justify-content-start mt-4 px-1">
              {CustomerOpportunities.map((opportunity, index) => (
                <div
                  className="col-12 col-md-3 mb-1 mb-md-2 ml-2 ml-md-0 px-md-2"
                  key={`field-of-opportunity-${opportunity.attribute}-${index}`}
                >
                  <div className="form-check form-check-inline mr-1">
                    <input
                      className="form-check-input opportunity-checkbox mr-0"
                      type="checkbox"
                      value={opportunity.attribute}
                      checked={
                        opportunity.attribute !== ''
                          ? fieldsOfOpportunity[opportunity.attribute]
                          : fieldsOfOpportunity.bigBoxRetailer &&
                            fieldsOfOpportunity.smallBoutiques
                      }
                      onClick={() => {
                        if (opportunity.name === 'Procurement') {
                          setFieldsOfOpportunity((prev) => {
                            return {
                              ...prev,
                              bigBoxRetailer: !prev.bigBoxRetailer,
                              smallBoutiques: !prev.smallBoutiques,
                            };
                          });
                        } else if (opportunity.name === 'Partnerships') {
                          setFieldsOfOpportunity((prev) => {
                            return {
                              ...prev,
                              internship: !prev.internship,
                              partnerships: !prev.partnerships,
                            };
                          });
                        } else {
                          const prevVal =
                            fieldsOfOpportunity[opportunity.attribute];
                          setFieldsOfOpportunity({
                            ...fieldsOfOpportunity,
                            [opportunity.attribute]: !prevVal,
                          });
                        }
                      }}
                      readOnly
                    />
                  </div>
                  <label className="form-label text-black font-primary font-weight-bolder">
                    <i className={`${opportunity.icon} font-28`} />
                    {opportunity.name}
                  </label>
                </div>
              ))}
            </div>
            {showPasswordField() && (
              <>
                <div
                  className={`d-flex flex-row text-left text-center-md justify-content-left justify-content-md-center align-items-center mt-md-4 mb-3`}
                >
                  <div
                    className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
                    style={{ height: '35px', width: '35px' }}
                  >
                    <span className="my-0 font-primary text-black font-20">
                      4
                    </span>
                  </div>
                  <span className="text-black py-2 ml-3 text-uppercase font-20">
                    Password
                  </span>
                </div>
                <div className="section password-reset d-flex justify-content-center">
                  <div className="col-12 col-md-7 text-black px-0 px-md-3">
                    <p className="font-weight-bold mb-1">Password</p>
                    <p>
                      Press the link to request a password reset email link.
                    </p>
                    <div className="d-flex justify-content-center">
                      <p
                        className="profile-reset-password-href mb-0 pointer-cursor"
                        onClick={handlePasswordReset}
                      >
                        Request Password Reset
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="d-flex mx-0 mt-5 justify-content-center w-100 bor">
              <button
                className="btn btn-white  text-uppercase text-black rounded-5 mt-0 mt-md-4 px-4 mx-1 mx-md-3  border-black"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-black text-uppercase text-pinky rounded-5 mt-0 mt-md-4 px-5 mx-2 mx-md-3"
                disabled={anyFieldEmpty || validateFields || disabled}
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo(0, 0);
                  handleSubmit();
                }}
              >
                Save
              </button>
            </div>
          </>
        )}
      </main>
      {showToast()}
    </>
  );
};

export default EditCustomerProfile;
