import axios from 'axios';
import { backendUrl } from './env';

export const uploadSupplierPDFToS3 = async (
  document: File,
  name: string,
  folder: string
) => {
  const formData = new FormData();
  formData.append('pdfKey', document.name);
  formData.append('pdf', document);
  formData.append('name', name);
  formData.append('folder', folder);
  const response = await axios.post(
    `${backendUrl}/Suppliers/uploadPDF`,
    formData,
    {
      headers: {
        'Content-Type': 'application/form-data',
      },
    }
  );
  return response.data;
};

export const uploadProfilePictureToS3 = async (
  imageDoc: File,
  name: string
) => {
  const formData = new FormData();
  formData.append('imageKey', imageDoc.name);
  formData.append('image', imageDoc);
  formData.append('name', name);
  const response = await axios.post(
    `${backendUrl}/Users/picture`,
    formData,
    {
      headers: {
        'Content-Type': 'application/form-data',
      },
    }
  );
  return response.data;
};
