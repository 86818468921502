import { types } from '../actions/examples';

export interface ExampleState {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  examples: any[];
  requestFinished: boolean;
}

const initialState: ExampleState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  examples: [],
  requestFinished: false,
};

export default function FAQReducer(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_EXAMPLE_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
      };
    case types.CREATE_EXAMPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: true,
      };
    case types.CREATE_EXAMPLE_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
        requestFinished: true,
      };

    case types.GET_EXAMPLES_REQUEST:
      return {
        ...state,
        loading: true,
        examples: [],
      };
    case types.GET_EXAMPLES_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        examples: action.payload,
      };
    case types.GET_EXAMPLES_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}
