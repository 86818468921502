import React, { useEffect, useState } from 'react';
import {
  connectionStatus,
  ConnectionWithProfile,
} from '../../types/Connection';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import emptyStateProfilePic from '../../styles/img/empty-state-profile-pic.svg';
import { getDisplayName, getPicture } from '../../utils/connectionUtils';
import { useHistory } from 'react-router';

interface Props {
  connection: ConnectionWithProfile;
  type: string;
  userId: string;
  openModal: (type:string, name: string, payload: any) => void;
  changeStatus: (connectionId: number, newStatus: number, name: string) => void;
  requestMeeting: (connection: ConnectionWithProfile) => void;
}

const ConnectionsListItem = ({
  connection,
  type,
  userId,
  openModal,
  changeStatus,
  requestMeeting
}: Props) => {
  const [isUserReceiver, setIsUserReceiver] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setIsUserReceiver(connection.senderId !== userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderButtons = () => {
    switch (type) {
      case 'sent':
        return (
          <div className="d-flex flex-row justify-content-between">
            <button
              className="btn btn-black btn-block btn-md text-white m-0 px-0 font-13"
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                changeStatus(
                  connection.id,
                  connectionStatus.CANCELED,
                  getDisplayName(connection, isUserReceiver)
                );
              }}
            >
              Cancel
            </button>
          </div>
        );
      case 'received':
        return (
          <div className="d-flex flex-row justify-content-between">
            <button
              className="btn btn-white btn-block btn-md text-black btn-outline-black px-0 w-48 font-13"
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                changeStatus(
                  connection.id,
                  connectionStatus.DENIED,
                  getDisplayName(connection, isUserReceiver)
                );
              }}
            >
              Deny
            </button>
            <button
              className="btn btn-black btn-block btn-md text-white m-0 px-0 w-48 font-13"
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                changeStatus(
                  connection.id,
                  connectionStatus.ACCEPTED,
                  getDisplayName(connection, isUserReceiver)
                );
              }}
            >
              Accept
            </button>
          </div>
        );
      case 'connected':
        return (
          <div className="d-flex flex-row justify-content-between">
            <button
              className="btn btn-white btn-block btn-md text-black btn-outline-black px-0 w-48 font-13"
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                const payload = {
                  connectionId: connection.id,
                  userId,
                  newStatus: connectionStatus.DISCONNECTED,
                  name: getDisplayName(connection, isUserReceiver),
                };
                openModal('disconnect', getDisplayName(connection, isUserReceiver), payload);
              }}
            >
              Disconnect
            </button>
            <button
              className="btn btn-black btn-block btn-md text-white m-0 px-0 w-48 font-13"
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                requestMeeting(connection)}
              }
            >
              Request a Meeting
            </button>
          </div>
        );
    }
  };

  const redirectToProfile = (event) => {
    const profile = isUserReceiver ? connection.sender : connection.receiver;
    const isSupplier = profile['custom:is_supplier'] && profile['custom:is_supplier'] === 'true';
    history.push(`/${isSupplier ? 'supplier' : 'champion'}/${profile.sub}`);
  }

  return (
    <div className="row connection-list-item mx-0 py-3 my-2 pointer-cursor" onClick={redirectToProfile}>
      <div className="col-3 col-md-2 pr-0 pr-md-3">
        <div className="connection avatar-opener d-flex justify-content-center align-items-center">
          <div className="medium-avatar">
            <LazyLoadImage
              referrerPolicy="no-referrer"
              src={
                getPicture(connection, isUserReceiver) || emptyStateProfilePic
              }
              alt=""
              effect="blur"
            />
          </div>
        </div>
      </div>
      <div className="col-9 col-md-10 pl-0 pl-md-3">
        <p className="text-black font-primary font-20 mb-2 text-truncate">
          {getDisplayName(connection, isUserReceiver)}
        </p>
        {renderButtons()}
      </div>
    </div>
  );
};

export default ConnectionsListItem;
