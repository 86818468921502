import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import validator from "validator";
// import { Auth } from 'aws-amplify';
import { toast } from "react-toastify";
import { authContext } from "../../components/Authentication/CognitoContextProvider";
import { useDispatch } from "react-redux";
import { inviteUser } from "../../actions/user";

export const AddUser = () => {
  const history = useHistory();
  const { auth } = useContext(authContext);
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAlumni, setIsAlumni] = useState(true);
  const [highlightEmailField, setHighlightEmailField] = useState(false);
  const [highlightNameField, setHighlightNameField] = useState(false);
  const [role, setRole] = useState("Supplier");
  const [invalidCharactersName, setInvalidCharactersName] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const handleAddUser = async (e) => {
    e.preventDefault();
    // let token = '';
    const user = auth.data;
    if (user) {
      // const getSession = await Auth.currentSession();
      // token = getSession.getIdToken().getJwtToken();
    } else {
      redirectToLogin();
      return;
    }

    if (email.length === 0 || !validator.isEmail(email)) {
      setValidEmail(false);
      return;
    }
    setValidEmail(true);

    const userName = name.replace(/,|"/g, "").trim();
    if (!userName) return;

    setDisabled(true);

    try {
      const payload = { name: userName, email, isAlumni, role };
      dispatch(inviteUser({ payload }));
      setDisabled(false);
    } catch (err) {
      setDisabled(false);
      toast.error("Something went wrong when adding user");
    }
  };

  const highlightRequiredFields = () => {
    setHighlightEmailField(email.length === 0);
    setHighlightNameField(name.length === 0);
  };

  const redirectToLogin = () => {
    localStorage.setItem("redirect-url", `/add-user`);
    history.push("/login");
  };

  const anyFieldEmpty = email.length === 0 || name.trim().length === 0;

  return (
    <>
      <Helmet>
        <title>BSN - Add User</title>
      </Helmet>
      <main className="body-min-h90 container auth-container py-5 px-4">
        <h1 className="h2 text-black font-primary font-weight-bolder">
          Add a user
        </h1>
        <form className="auth-form mt-4" onSubmit={handleAddUser}>
          <div className="form-group">
            <label className="form-label text-black font-primary font-weight-bolder">
              Name
            </label>
            <div className="input-group">
              <input
                className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 ${
                  highlightNameField || invalidCharactersName
                    ? "is-invalid"
                    : "border-black"
                }`}
                type="text"
                id="name"
                onChange={(event) => {
                  let value = event.target.value;
                  setHighlightNameField(value.trim().length === 0);
                  setInvalidCharactersName(
                    value && (value.includes('"') || value.includes(","))
                  );
                  if (value && value.length > 256) value = value.substr(0, 256);
                  setName(value);
                }}
              />
            </div>
          </div>
          {highlightNameField && (
            <div className="input-helper mb-2">
              <small className={`text-danger`}>
                <strong>Name is required</strong>
              </small>
            </div>
          )}
          {invalidCharactersName && (
            <div className="input-helper mb-2">
              <small className={`text-danger`}>
                <strong>Name can not have commas or double quotes</strong>
              </small>
            </div>
          )}
          <div className="form-group">
            <label className="form-label text-black font-primary font-weight-bolder">
              Email Address
            </label>
            <input
              required
              className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 border-black ${
                !validEmail || highlightEmailField ? "is-invalid" : ""
              }`}
              type="email"
              id="email"
              value={email}
              onChange={(event) => {
                setHighlightEmailField(event.target.value.length === 0);
                setValidEmail(validator.isEmail(event.target.value));
                setEmail(event.target.value);
              }}
            />
          </div>
          {(!validEmail || highlightEmailField) && (
            <div className="input-helper mb-2">
              <small className="text-danger">
                <strong>
                  {email.length === 0 ? "Email is required" : "Invalid Email"}
                </strong>
              </small>
            </div>
          )}
          <div className="form-group">
            <label className="form-label text-black font-primary font-weight-bolder">
              Select a Role
            </label>
            <div className="input-group mb-3">
              <select
                className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0"
                id="inputGroupSelect01"
                onChange={(e) => {
                  setRole(e.target.value);
                }}
              >
                <option value={"Supplier"}>Supplier</option>
                <option>Admin</option>
              </select>
            </div>
          </div>

          <label className="form-label text-black font-primary font-weight-bolder">
            BGV Alumni?
          </label>
          <div className="form-group">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioAlumni"
                id="flexRadioAlumni"
                checked={isAlumni}
                onChange={() => setIsAlumni(true)}
              />
              <label
                className="form-check-label text-black font-primary font-weight-bolder"
                htmlFor="flexRadioAlumni"
              >
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioAlumni"
                id="flexRadioAlumni"
                checked={!isAlumni}
                onChange={() => setIsAlumni(false)}
              />
              <label
                className="form-check-label text-black font-primary font-weight-bolder"
                htmlFor="flexRadioAlumni"
              >
                No
              </label>
            </div>
            <div className="form-check form-check-inline mt-4">
              <label
                className="form-check-label text-black font-primary font-weight-bold"
                style={{ fontSize: "0.9rem" }}
              >
                This user will receive an email notification to create an
                account.
              </label>
            </div>
          </div>
          <div className="form-group d-flex justify-content-center justify-content-around">
            <button
              onClick={() => history.goBack()}
              type="button"
              className="btn btn-outline  btn-lg   text-uppercase text-black rounded-0 mt-4 mr-2"
            >
              Cancel
            </button>
            <button
              className="btn btn-black  btn-lg   text-uppercase text-pinky rounded mt-4"
              disabled={disabled || anyFieldEmpty || invalidCharactersName}
              type="submit"
              onClick={() => highlightRequiredFields()}
            >
              Add User
            </button>
          </div>
        </form>
      </main>
    </>
  );
};
