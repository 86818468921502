interface Props {
  renderIndustries: () => JSX.Element[];
  renderShippingTime: () => JSX.Element[];
  renderOpportunities: (string) => JSX.Element;
  states: any[];
  allCities: any[];
  stateSelected: string;
  citySelected: string;
  sellingCycle: string;
  handleSellingCycleChange: (cycle: string) => void;
  handleOnChange: (event: any, isCity?: any) => void;
  handleSubmit: (params: any) => void;
  shouldBeDisabled: () => boolean;
  isSupplier: boolean;
}

export const GuidedSearchForm = ({
  renderIndustries,
  renderShippingTime,
  renderOpportunities,
  states,
  stateSelected,
  allCities,
  citySelected,
  sellingCycle,
  handleSellingCycleChange,
  handleOnChange,
  handleSubmit,
  shouldBeDisabled,
  isSupplier,
}: Props) => {
  return (
    <div className="body-min-h90 py-2 py-sm-5 d-flex justify-content-center">
      <form
        className="auth-form mt-4 mx-lg-5 guided-search-container"
        style={{ maxWidth: "60rem" }}
      >
        <div className="row mx-0 pt-3 pb-4 d-flex justify-content-center">
          <h1 className="font-weight-bold font-primary text-black text-center">
            {isSupplier ? (
              "I'd like to make a connection with..."
            ) : (
              <>
                Let's craft your perfect
                <br />
                supplier
              </>
            )}
          </h1>
        </div>
        <div className="row section-description text-black text-center px-3 py-3 d-flex justify-content-center mx-0 mx-md-2">
          <p className="mb-0">
            {" "}
            <strong className="font-italic">
              Select Industries that meet your needs and requirements.{" "}
            </strong>{" "}
            You may select all those that apply.
          </p>
        </div>

        <div
          className="row mx-0 py-5 justify-content-center justify-content-md-start"
          id="industry-row"
        >
          {renderIndustries()}
        </div>

        <div className="row mx-0 d-flex justify-content-center">
          <div className="form-group col-md-12">
            <label className="form-label text-black font-primary font-weight-bolder">
              Selling Cycle <span className="text-danger">*</span>
            </label>
            <p className="text-black mb-0">What is your onboarding cycle?</p>
            <select
              className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0 full-width mr-2 "
              name="Selling Cycle"
              id="selling-cycle-select"
              onChange={(e) => handleSellingCycleChange(e.target.value)}
              value={sellingCycle}
            >
              <option defaultValue={""} hidden={true}>
                Please select a selling cycle
              </option>

              <option value="Quarterly">Quarterly</option>
              <option value="Annual">Annual</option>
              <option value="Seasonal">Seasonal</option>
              <option value="Any">Any</option>
            </select>
          </div>
        </div>
        <div className="row mx-0 d-flex justify-content-center">
          <div className="form-group col-md-12">
            <label className="form-label text-black font-primary font-weight-bolder">
              Shipping Time <span className="text-danger">*</span>
            </label>
            <p className="text-black">
              How soon would you need to receive the product or service after
              Invoice?
            </p>
            <div className="guided-search-shipping row">
              {renderShippingTime()}
            </div>
          </div>
        </div>
        <div className="row mx-0 justify-content-center">
          <div className="form-group col-md-12">
            <label className="form-label text-black font-primary font-weight-bolder">
              Location
            </label>
            <p className="text-black">Where should they be located?</p>
            <div className="mb-3">
              <label className="form-label text-black font-primary font-weight-bolder mb-0">
                State <span className="text-danger">*</span>
              </label>

              <select
                className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0 full-width mr-2"
                name="States"
                id="state-select"
                onChange={handleOnChange}
                value={stateSelected}
              >
                <option value={""} hidden={true} className="">
                  {stateSelected ? stateSelected : "Please select state"}
                </option>

                <option value={"All States"}>All States</option>
                {states.map((state) => (
                  <option key={`state-${state.isoCode}`} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-3"></div>
            <label className="form-label text-black font-primary font-weight-bolder">
              City{" "}
              <small className="text-kinda-muted font-weight-normal">
                (optional)
              </small>
            </label>
            <select
              className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0 full-width mr-2"
              name="Cities"
              id="city"
              disabled={allCities.length === 0 && !citySelected}
              onChange={(e) => handleOnChange(e, true)}
              value={citySelected}
            >
              <option key={"no-city-selected"} value={""} hidden={true}>
                {citySelected ? citySelected : "Please select a city"}
              </option>

              {allCities.length > 0 &&
                allCities.map((city, index) => (
                  <option key={`gs-${index}-city-${city.isoCode}`} value={city.name}>
                    {city.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        {isSupplier && (
          <>
            <div className="business-opportunities-section mx-3 mx-lg-5 mt-5 pl-3 pl-sm-0">
              <div className="row d-flex justify-content-left">
                <label className="h4 form-label text-black font-primary font-weight-bolder mb-0">
                  Business Opportunity
                  <span className="text-danger">* </span>
                </label>
                <span className="col-12 col-md-auto px-0 px-md-2 pt-0 pt-md-1 text-kinda-muted font-primary font-16 font-weight-normal">
                  Which opportunities are a best fit for you?
                </span>
              </div>
              <div className="row d-flex justify-content-center mt-4">
                {renderOpportunities("Business")}
              </div>
            </div>
            <div className="business-opportunities-section mx-3 mx-lg-5 mt-5 pl-3 pl-sm-0">
              <div className="row d-flex justify-content-left">
                <label className="h4 form-label text-black font-primary font-weight-bolder mb-0">
                  Growth Opportunity
                  <span className="text-danger">* </span>
                </label>
                <span className="col-12 col-md-auto px-0 px-md-2 pt-0 pt-md-1 text-kinda-muted font-primary font-16 font-weight-normal">
                  Which opportunities are a best fit for you?
                </span>
              </div>
              <div className="row d-flex justify-content-center mt-4">
                {renderOpportunities("Growth")}
              </div>
            </div>
          </>
        )}
        <div className="row mx-2 d-flex justify-content-center align-items-center py-5">
          <div className="col-auto">
            <button
              className="btn btn-black btn-lg text-uppercase text-pinky rounded-1 px-4"
              type="button"
              disabled={shouldBeDisabled()}
              onClick={handleSubmit}
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
