import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet";
import { State, City } from "country-state-city";
import {
  industriesInitialState,
  IndustriesInterface,
  shippingTimes,
  SupplierCompleteProfile,
} from "../../types/SupplierInfo";
import { useDispatch, useSelector } from "react-redux";
import { search, setSearchParams } from "../../actions/search";
import {
  defaultSettings,
  industrySelected,
  objectHasNonSelectedValues,
} from "../../utils/searchUtils";
import { getUserSub } from "../../utils/getUserData";
import { authContext } from "../../components/Authentication/CognitoContextProvider";
import { getIsoCode } from "../../utils/getIsoCode";
import { isCustomer, isSupplier } from "../../auth/userUtils";
import { getCustomerById } from "../../actions/customer";
import { RootState } from "../../reducers";
import { CustomerCompleteProfile } from "../../types/CustomerInfo";
import { GuidedSearchForm } from "../../components/GuidedSearchForm/GuidedSearchForm";
import { getSupplierById, resetSupplierInfo } from "../../actions/supplier";

const GuidedSearch = () => {
  const { auth } = useContext(authContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [investment] = useState(false);
  const [hiring] = useState(false);
  const [supplier] = useState(false);
  const [states, setStates] = useState([]);
  // const [industries] = React.useState<IndustriesInterface>(
  //   industriesInitialState
  // );
  const [customerIndustries, setCustomerIndustries] =
    React.useState<IndustriesInterface>(industriesInitialState);
  const [shippingTime, setShippingTime] = useState("Any");
  const [sellingCycle, setSellingCycle] = useState("");
  const [stateSelected, setStateSelected] = useState(undefined);
  const [citySelected, setCitySelected] = useState(undefined);
  const [allCities, setAllCities] = useState([]);
  const [fieldOfOpportunity, setFieldOfOpportunity] = useState({
    smallBoutiques: false,
    mentorships: false,
    bigBoxRetailer: false,
    corporations: false,
    partnerships: false,
    investment: false,
    internship: false,
    openToWork: false,
  });

  const businessOpportunities: Array<{ name: string; attribute: string }> = [
    { name: "Small Boutiques", attribute: "smallBoutiques" },
    { name: "Open To Work", attribute: "openToWork" },
    { name: "Big Box Retailer", attribute: "bigBoxRetailer" },
    { name: "Corporations", attribute: "corporations" },
    { name: "Investment", attribute: "investment" },
  ];

  const growthOpportunities: Array<{ name: string; attribute: string }> = [
    { name: "Mentorships", attribute: "mentorships" },
    { name: "Partnerships", attribute: "partnerships" },
    { name: "Internship", attribute: "internship" },
  ];

  const customerInfo: CustomerCompleteProfile = useSelector(
    (state: RootState) => state.customer.customerInfo
  );

  const supplierInfo: SupplierCompleteProfile = useSelector(
    (state: RootState) => state.supplier.supplierInfo
  );

  useEffect(() => {
    const getAllStates = State.getStatesOfCountry("US").filter(
      (s) => City.getCitiesOfState("US", s.isoCode).length > 0
    );
    setStates(getAllStates);
  }, []);

  useEffect(() => {
    dispatch(resetSupplierInfo());
    if (isCustomer(auth.data))
      dispatch(getCustomerById(auth.data.attributes.sub));
    else if (isSupplier) {
      dispatch(getSupplierById(auth.data.attributes.sub));
    }
  }, [auth.data, dispatch]);

  useEffect(() => {
    if (supplierInfo) {
      setFieldOfOpportunity(supplierInfo.fieldofopportunity);
      setStateSelected(supplierInfo.state);
      setCitySelected(supplierInfo.city || "");
      setSellingCycle(
        supplierInfo && supplierInfo.companyinformation
          ? supplierInfo.companyinformation.sellingCycle
          : ""
      );
      setShippingTime(
        supplierInfo &&
          supplierInfo.companyinformation &&
          supplierInfo.companyinformation.shippingTime
          ? supplierInfo.companyinformation.shippingTime
          : "Any"
      );
      if (supplierInfo.industries && supplierInfo.industries.length > 0) {
        const newIndustries = customerIndustries;
        supplierInfo.industries.forEach((key) => {
          if (newIndustries[key]) newIndustries[key].value = true;
        });
        setCustomerIndustries(newIndustries);
      }
    } else {
      setCustomerIndustries({
        EdTech: { value: false },
        "App Development": { value: false },
        "Professional Development": { value: false },
        Wellness: { value: false },
        Arts: { value: false },
        Makers: { value: false },
        Beauty: { value: false },
        Design: { value: false },
        FinTech: { value: false },
        "Fashion / Apparel": { value: false },
        "Esports / Egaming": { value: false },
        Hospitality: { value: false },
        Restaurant: { value: false },
        "Share Economy": { value: false },
        InsurTech: { value: false },
        "Construction Tech": { value: false },
        "MarTech / AdTech": { value: false },
        Intersectional: { value: false },
        Skincare: { value: false },
        "Hair Care": { value: false },
        Healthcare: { value: false },
        "Business Development": { value: false },
        "Web Development": { value: false },
        "Product Development": { value: false },
        Other: { value: false },
      });
      setCitySelected(undefined);
      setStateSelected(undefined);
      setSellingCycle("");
      setShippingTime("");
      setFieldOfOpportunity({
        smallBoutiques: false,
        mentorships: false,
        bigBoxRetailer: false,
        corporations: false,
        partnerships: false,
        investment: false,
        internship: false,
        openToWork: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierInfo]);

  useLayoutEffect(() => {
    return () => {
      dispatch(resetSupplierInfo());
      setCustomerIndustries({
        EdTech: { value: false },
        "App Development": { value: false },
        "Professional Development": { value: false },
        Wellness: { value: false },
        Arts: { value: false },
        Makers: { value: false },
        Beauty: { value: false },
        Design: { value: false },
        FinTech: { value: false },
        "Fashion / Apparel": { value: false },
        "Esports / Egaming": { value: false },
        Hospitality: { value: false },
        Restaurant: { value: false },
        "Share Economy": { value: false },
        InsurTech: { value: false },
        "Construction Tech": { value: false },
        "MarTech / AdTech": { value: false },
        Intersectional: { value: false },
        Skincare: { value: false },
        "Hair Care": { value: false },
        Healthcare: { value: false },
        "Business Development": { value: false },
        "Web Development": { value: false },
        "Product Development": { value: false },
        Other: { value: false },
      });
      setCitySelected(undefined);
      setStateSelected(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stateSelected) {
      setAllCities(
        City.getCitiesOfState("US", getIsoCode(stateSelected, states))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelected]);

  const toggleCustomerIndustry = (field: string) => {
    setCustomerIndustries((prevState) => {
      return {
        ...prevState,
        [field]: { value: !customerIndustries[field].value },
      };
    });
  };

  const shouldBeDisabled = () =>
    (isSupplier(auth.data)
      ? !industrySelected(customerIndustries) || !opportunitySelected()
      : objectHasNonSelectedValues(customerIndustries)) ||
    (sellingCycle !== undefined && sellingCycle.length === 0) ||
    (shippingTime !== undefined && shippingTime.length === 0) ||
    !stateSelected;

  const opportunitySelected = () =>
    Object.keys(fieldOfOpportunity).some((key) => fieldOfOpportunity[key]);

  const handleOnChange = (event, isCity?) => {
    const val = event.target.value;
    if (isCity) {
      setCitySelected(val);
    } else {
      setStateSelected(val);
      setCitySelected("");
      setAllCities(City.getCitiesOfState("US", getIsoCode(val, states)));
    }
  };

  const handleSellingCycleChange = (cycle: string) => {
    setSellingCycle(cycle);
  };

  const handleSubmit = () => {
    const storedSettings = localStorage.getItem("searchSettings");
    let settings = storedSettings
      ? JSON.parse(storedSettings)
      : defaultSettings;
    const idMatches = auth.data && settings.userId === auth.data.attributes.sub;
    if (!idMatches) settings = defaultSettings;
    settings.userId = auth.data?.attributes.sub || "";
    settings.isSupplier = isSupplier(auth.data);
    settings.shippingTime = shippingTime;
    settings.sellingCycle = sellingCycle;
    settings.industries = customerIndustries;
    settings.location.city = citySelected;
    settings.location.state = stateSelected;
    if (
      isCustomer(auth.data) &&
      customerInfo &&
      customerInfo.fieldofopportunity
    ) {
      const customerOpportunities = {
        Investment: {
          value: customerInfo.fieldofopportunity.investment,
        },
        Hiring: {
          value: customerInfo.fieldofopportunity.openToWork,
        },
        Procurement: {
          value:
            customerInfo.fieldofopportunity.bigBoxRetailer ||
            customerInfo.fieldofopportunity.smallBoutiques,
        },
        "Supplier Diversity": {
          value: customerInfo.fieldofopportunity.corporations,
        },
        Partnerships: {
          value:
            customerInfo.fieldofopportunity.partnerships ||
            customerInfo.fieldofopportunity.internship,
        },
        Mentorship: {
          value: customerInfo.fieldofopportunity.mentorships,
        },
      };
      settings.areaOfInterest = customerOpportunities;
      settings.fieldsOfOpportunity = customerOpportunities;
    } else if (isSupplier(auth.data)) {
      settings.areaOfInterest = defaultSettings.areaOfInterest;
      settings.fieldsOfOpportunity = fieldOfOpportunity;
    }
    const searchParams = {
      page: 1,
      userId: getUserSub(auth),
      isSupplier: isSupplier(auth.data),
      areaOfInterest: getAreaOfInterest(),
      shippingTime,
      sellingCycle,
      industryPayload: {
        industries: customerIndustries,
        industrySelected: industrySelected(customerIndustries),
      },
      opportunityPayload: {
        opportunities: settings.fieldsOfOpportunity,
        opportunitySelected: objectHasNonSelectedValues(
          settings.fieldsOfOpportunity
        ),
      },
      location: getSearchParamsLocation(),
    };
    dispatch(setSearchParams(searchParams));
    dispatch(search(searchParams));

    localStorage.setItem("searchSettings", JSON.stringify(settings));
    window.scrollTo(0, 0);
    history.push("/search?page=1&hS=true");
  };

  const getProps = () => ({
    renderIndustries: renderCustomerIndustryPills,
    renderShippingTime: renderShippingTime,
    states,
    allCities,
    stateSelected,
    citySelected,
    sellingCycle,
    setSellingCycle,
    handleSellingCycleChange,
    handleOnChange,
    handleSubmit,
    shouldBeDisabled,
  });

  const getAreaOfInterest = () => ({ investment, supplier, hiring });

  const getSearchParamsLocation = () => {
    return {
      state: stateSelected,
      city: citySelected,
    };
  };

  const renderShippingTime = () =>
    shippingTimes.map((time, i) => {
      return (
        <span
          className={`${time === "All" ? "col-12 pr-0" : "col-6 pr-0"} ${
            i % 2 !== 0 ? "pl-0" : ""
          }`}
          key={`shipping-times-${time}`}
        >
          <input
            className=""
            checked={shippingTime === time}
            type="radio"
            onChange={() => {
              setShippingTime(time);
            }}
          />
          <label
            className="text-black ml-1 ml-md-2"
            onClick={() => {
              setShippingTime(time);
            }}
          >
            {time}
          </label>
        </span>
      );
    });

  const renderCustomerIndustryPills = () =>
    Object.keys(customerIndustries).map((ind) => {
      return (
        <div className="col-auto col-md-3 py-2" key={`industry-pills-${ind}`}>
          <button
            className={`btn rounded-pill btn-block btn-block btn-outline-black pink-text-hover font-weight-light ${
              customerIndustries[ind].value
                ? "text-pinky btn-black"
                : "btn-white text-black"
            }`}
            type="button"
            onClick={() => {
              toggleCustomerIndustry(ind);
            }}
          >
            {ind}
          </button>
        </div>
      );
    });

  const handleFieldOfOpportunity = (e) => {
    const setting = e.target.value;
    setFieldOfOpportunity((prevState) => ({
      ...prevState,
      [setting]: !fieldOfOpportunity[setting],
    }));
  };

  const renderOpportunities = (type: string) => {
    const opportunityList =
      type === "Business" ? businessOpportunities : growthOpportunities;
    const allChecked = opportunityList.every(
      (opp) => fieldOfOpportunity[opp.attribute]
    );
    return (
      <>
        {opportunityList.map((f, index) => (
          <div
            className="col-6 mb-1 mb-md-2 px-0 px-md-2"
            key={`business-opportunity-${f.attribute}-${index}`}
          >
            <div className="form-check form-check-inline">
              <input
                className="form-check-input opportunity-checkbox mr-0"
                type="checkbox"
                value={f.attribute}
                checked={fieldOfOpportunity[f.attribute]}
                onClick={handleFieldOfOpportunity}
                readOnly
              />
            </div>
            <label className="form-label text-black font-primary font-weight-bolder">
              {f.name}
            </label>
          </div>
        ))}
        <div
          className="col-6 mb-1 mb-md-2 px-0 px-md-2"
          key="business-opportunity-all"
        >
          <div className="form-check form-check-inline">
            <input
              className="form-check-input opportunity-checkbox mr-0"
              type="checkbox"
              value="business-opportunity-all"
              checked={allChecked}
              onClick={() => {
                const newFields = { ...fieldOfOpportunity };
                opportunityList.forEach(
                  (opp) => (newFields[opp.attribute] = !allChecked)
                );
                setFieldOfOpportunity(newFields);
              }}
              readOnly
            />
          </div>
          <label className="form-label text-black font-primary font-weight-bolder">
            All
          </label>
        </div>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>BSN - Guided Search</title>
      </Helmet>
      <main className="body-min-h90 py-0 py-sm-5 ">
        <GuidedSearchForm
          {...getProps()}
          renderOpportunities={renderOpportunities}
          isSupplier={isSupplier(auth.data)}
        />
      </main>
    </>
  );
};

export default GuidedSearch;
