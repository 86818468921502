import React from 'react';
import moment from 'moment';

interface Props {
  date: any;
}

export const Footer = ({ date }: Props) => {
  return (
    <>
      <footer className="sdd-header px-4 pt-3 pb-5">
        <div className="row m-auto pt-3 pb-5 container justify-content-around">
          <div className="col-12 d-flex flex-column py-2">
            <span className="text-pinky pl-1">
              <strong>All Rights Reserved © {moment(date).year()} </strong>
              Black Girl Ventures Foundation.
            </span>
            <a
              className="text-pinky pl-1"
              href="mailto:contact@blackgirlventures.org"
            >
              <strong>contact@blackgirlventures.org</strong>
            </a>
            <a
              className="text-pinky pt-2 pl-1"
              href={`${process.env.PUBLIC_URL}/privacy-bgv.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              <strong>Privacy Policy</strong>
            </a>
            <div className="row mx-0 py-2">
              <a
                className="text-pinky"
                href="https://www.instagram.com/blackgirlventures"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-instagram font-48" />
              </a>
              <a
                className="text-pinky"
                href="https://www.facebook.com/blackgirlventures"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-facebook font-48" />
              </a>
              <a
                className="text-pinky"
                href="https://www.linkedin.com/company/blackgirlventures"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon-linkedin font-48" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
