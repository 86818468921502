import {
  CustomerOpportunitiesInterface,
  IFieldOfOpportunity,
  IndustriesInterface,
  SupplierInfo,
} from './SupplierInfo';

export interface ISearchParameters {
  page: number;
  userId: string;
  isSupplier: boolean;
  areaOfInterest?: {
    investment: boolean;
    hiring: boolean;
    supplier: boolean;
  };
  shippingTime?: string;
  sellingCycle?: string;
  opportunityPayload?: {
    opportunities: CustomerOpportunitiesInterface | IFieldOfOpportunity;
    opportunitySelected: boolean;
  };
  industryPayload: {
    industries: IndustriesInterface;
    industrySelected: boolean;
  };
  location: {
    state: string;
    city: string;
  };
}

export interface ISearchResult extends SupplierInfo {
  sub: string;
  picture: string;
}

export enum SearchSettings {
  Opportunity = 'Opportunity',
  Industry = 'Industry',
  SellingCycle = 'Selling Cycle',
  ShippingTime = 'Shipping Time',
  State = 'State',
  City = 'City',
}
