import { combineReducers } from 'redux';
import ExampleReducer from './example';
import SupplierReducer from './supplier';
import UserReducer from './user';
import SearchReducer from './search';
import ConnectionReducer from './connection';
import CustomerReducer from './customer';
import NotificationReducer from './notifications';

const rootReducer = combineReducers({
  example: ExampleReducer,
  supplier: SupplierReducer,
  user: UserReducer,
  search: SearchReducer,
  connection: ConnectionReducer,
  customer: CustomerReducer,
  notification: NotificationReducer
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
