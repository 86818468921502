import moment from 'moment';
import { IAttributes } from '../utils/cognito-utils';
import { IFieldOfOpportunity } from './SupplierInfo';

export enum connectionStatus {
  PENDING = 1,
  ACCEPTED = 2,
  DENIED = 3,
  CANCELED = 4,
  DISCONNECTED = 5,
  USER_PROFILE_DELETED = 6,
  ADMIN_DELETED = 7,
}

export interface Connection {
  id: number;
  senderEmail: string;
  receiverEmail: string;
  status: number;
  creationDate: Date;
  modificationDate: Date;
  senderId: string;
  receiverId: string;
  meetRequested: string;
  matchingFieldsOfOpportunity: IFieldOfOpportunity;
}

export interface ConnectionWithProfile extends Connection {
  receiver: IAttributes;
  sender: IAttributes;
}

export const getConnectionMessage = (status: number, name: string) => {
  switch (status) {
    case connectionStatus.ACCEPTED:
      return `You are now connected with ${name}.`;
    case connectionStatus.DENIED:
      return `Connection with ${name} has been denied.`;
    case connectionStatus.CANCELED:
      return `Connection request with ${name} has been canceled.`;
    case connectionStatus.DISCONNECTED:
      return `You have disconnected from ${name}`;
    case connectionStatus.ADMIN_DELETED:
      return `The user has been disconnected from ${name}`;
    default:
      return '';
  }
};

export const sortFunctions = {
  string: (column, order) => (a, b) => {
    const firstValue = a[column];
    const secondValue = b[column];
    if (order === 'ASC') {
      return firstValue.toLowerCase() < secondValue.toLowerCase() ? -1 : 1;
    } else {
      return firstValue.toLowerCase() > secondValue.toLowerCase() ? -1 : 1;
    }
  },
  date: (column, order) => (a, b) => {
    return order === 'ASC'
      ? moment(a[column]).diff(moment(b[column]))
      : moment(b[column]).diff(moment(a[column]));
  },
  boolean: (column, order) => (a, b) => {
    if (order === 'ASC') {
      return a[column] ? 1 : -1;
    } else {
      return a[column] ? -1 : 1;
    }
  },
  connectionName: (column, order, userEmail) => (a, b) => {
    const firstValue = a.receiverEmail === userEmail ? a.sender : a.receiver;
    const secondValue = b.receiverEmail === userEmail ? b.sender : b.receiver;
    const firstValueName = firstValue['custom:first_name']
      ? `${firstValue['custom:first_name']} ${firstValue['custom:last_name']}`.trim()
      : firstValue.given_name;
    const secondValueName = secondValue['custom:first_name']
      ? `${secondValue['custom:first_name']} ${secondValue['custom:last_name']}`.trim()
      : secondValue.given_name;
    if (order === 'ASC') {
      return firstValueName.toLowerCase() < secondValueName.toLowerCase()
        ? -1
        : 1;
    } else {
      return firstValueName.toLowerCase() > secondValueName.toLowerCase()
        ? -1
        : 1;
    }
  },
  connectionEmail: (column, order, userEmail) => (a, b) => {
    const firstValue = a.receiverEmail === userEmail ? a.sender : a.receiver;
    const secondValue = b.receiverEmail === userEmail ? b.sender : b.receiver;
    if (order === 'ASC') {
      return firstValue.email.toLowerCase() < secondValue.email.toLowerCase()
        ? -1
        : 1;
    } else {
      return firstValue.email.toLowerCase() > secondValue.email.toLowerCase()
        ? -1
        : 1;
    }
  },
};
