import React, { useEffect, useState } from 'react';
import {
  connectionStatus,
  ConnectionWithProfile,
} from '../../types/Connection';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import emptyStateProfilePic from '../../styles/img/empty-state-profile-pic.svg';
import { getDisplayName, getPicture } from '../../utils/connectionUtils';
import { useHistory } from 'react-router';

interface Props {
  connection: ConnectionWithProfile;
  type: string;
  userId: string;
  openModal: (type: string, name: string, payload: any) => void;
  changeStatus: (connectionId: number, newStatus: number, name: string) => void;
  requestMeeting: (connection: ConnectionWithProfile) => void;
  minHeight: string;
}

const ConnectionsGridItem = ({
  connection,
  type,
  userId,
  openModal,
  changeStatus,
  requestMeeting,
  minHeight,
}: Props) => {
  const [isUserReceiver, setIsUserReceiver] = useState(false);
  const history = useHistory();
  useEffect(() => {
    setIsUserReceiver(connection.senderId !== userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderButtons = () => {
    switch (type) {
      case 'sent':
        return (
          <div className="d-flex flex-column justify-content-between justify-content-center mt-2 px-2 px-md-0">
            <button
              className="btn btn-black btn-md text-white px-0 accept-deny-connection font-13 "
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                const payload = {
                  connectionId: connection.id,
                  userId,
                  newStatus: connectionStatus.CANCELED,
                  name: getDisplayName(connection, isUserReceiver),
                };
                openModal(
                  'cancelConnection',
                  getDisplayName(connection, isUserReceiver),
                  payload
                );
              }}
            >
              Cancel
            </button>
          </div>
        );
      case 'received':
        return (
          <div className="d-flex flex-column justify-content-between justify-content-center px-0 mt-2">
            <button
              className="btn btn-black btn-md text-white px-0 accept-deny-connection font-13"
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                changeStatus(
                  connection.id,
                  connectionStatus.ACCEPTED,
                  getDisplayName(connection, isUserReceiver)
                );
              }}
            >
              Accept
            </button>
            <button
              className="btn btn-white btn-md text-black btn-outline-black px-0 w-60 mt-2 accept-deny-connection font-13"
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                changeStatus(
                  connection.id,
                  connectionStatus.DENIED,
                  getDisplayName(connection, isUserReceiver)
                );
              }}
            >
              Deny
            </button>
          </div>
        );
      case 'connected':
        return (
          <div className=" d-flex flex-column justify-content-between justify-content-center px-0 mt-2">
            <button
              className="btn btn-black btn-md px-0 accept-deny-connection font-13"
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                requestMeeting(connection)
              }}
            >
              Request a Meeting
            </button>
            <button
              className="btn btn-white btn-md text-black btn-outline-black px-0 w-60 mt-2 accept-deny-connection font-13 "
              onClick={(event) => {
                if (event) {
                  event.stopPropagation();
                  event.nativeEvent.stopImmediatePropagation()
                }
                const payload = {
                  connectionId: connection.id,
                  userId,
                  newStatus: connectionStatus.DISCONNECTED,
                  name: getDisplayName(connection, isUserReceiver),
                };
                openModal(
                  'disconnect',
                  getDisplayName(connection, isUserReceiver),
                  payload
                );
              }}
            >
              Disconnect
            </button>
          </div>
        );
    }
  };

  const redirectToProfile = (event) => {
    const profile = isUserReceiver ? connection.sender : connection.receiver;
    const isSupplier = profile['custom:is_supplier'] && profile['custom:is_supplier'] === 'true';
    history.push(`/${isSupplier ? 'supplier' : 'champion'}/${profile.sub}`);
  }

  return (
    <div className="px-0 d-flex justify-content-center pointer-cursor" onClick={redirectToProfile}>
      <div className="connection-grid-item py-3">
        <div className="connection avatar-opener d-flex justify-content-center align-items-center">
          <div className="medium-avatar grid-item">
            <LazyLoadImage
              referrerPolicy="no-referrer"
              src={
                getPicture(connection, isUserReceiver) || emptyStateProfilePic
              }
              alt=""
              effect="blur"
            />
          </div>
        </div>
        <div className="d-flex justify-content-center" style={{ minHeight }}>
          <p className="text-black text-center font-primary font-weight-bold font-16 mb-2 mt-2 px-1">
            {getDisplayName(connection, isUserReceiver)}
          </p>
        </div>
        {renderButtons()}
      </div>
    </div>
  );
};

export default ConnectionsGridItem;
