export const fileIsImage = (fileName) => {
  const extensionLists = ['jpg', 'gif', 'png', 'jpeg'];
  const fileExtensionType = fileName.split('.').pop().toLowerCase();
  return extensionLists.includes(fileExtensionType);
};

export const fileIsPDF = (fileName) => {
  const fileExtensionType = fileName.split('.').pop().toLowerCase();
  return fileExtensionType === 'pdf';
};

export const removeExtraSpacesFromString = (s: string) => {
  return s.replace(/\s+/g, ' ').trim();
};

export const trimName = (firstName: string, lastName: string) => {
  return `${firstName} ${lastName}`.trim();
};

export enum ListItemTypes {
  CONNECTION = 'connection',
  SEARCH_RESULT = 'search_result',
  NOTIFICATION = 'notification',
}

export const LinkedinRegularExpression =
  /((https?:\/\/)?((www|\w\w)\.)?[Ll]inked[Ii]n\.com\/)((([\w]{2,3})?)([^/]+\/(([\w|\d-&#%?=])+\/?){1,}))$/;
