import React, { useState } from "react";
import { AnnouncementInterface } from "../../containers/Home/HowItWorks";
import pinkBg from "../../styles/img/pink-bg.png";

interface Props {
  announcements: AnnouncementInterface[];
}

export const Carrousel = ({ announcements }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div
      id="carouselIndicators"
      className="carousel slide bg-pinky"
      data-ride="carousel"
    >
      <ol className="carousel-indicators">
        {announcements.map((announcement, index) => {
          return (
            <>
              <li
                data-target="#carouselIndicators"
                data-slide-to={index}
                className={index === activeIndex ? "active" : ""}
                onClick={() => {
                  setActiveIndex(index);
                }}
              ></li>
            </>
          );
        })}
      </ol>
      <div className="carousel-inner">
        {announcements.map((announcement, index) => {
          return (
            <>
              <div
                className={`carousel-item ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <img
                  className="carrousel-image"
                  src={announcement.image ? announcement.image : pinkBg}
                  alt="carrousel slide"
                />
                <div
                  className={`carousel-caption pt-2 ${
                    announcement.image ? "text-white" : "text-black"
                  }`}
                >
                  <h5 className="font-primary font-weight-bold">
                    {announcement.caption}
                  </h5>
                  <p className="d-none d-md-block">
                    {announcement.description}
                  </p>
                </div>
              </div>
            </>
          );
        })}
        <span
          className="carousel-control-prev"
          role="button"
          data-slide="prev"
          onClick={() => {
            setActiveIndex((prev) => {
              if (prev === 0) {
                return announcements.length - 1;
              }
              return prev - 1;
            });
          }}
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </span>
        <span
          className="carousel-control-next"
          role="button"
          data-slide="next"
          onClick={() => {
            setActiveIndex((prev) => {
              if (prev === announcements.length - 1) {
                return 0;
              }
              return prev + 1;
            });
          }}
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </span>
      </div>
    </div>
  );
};
