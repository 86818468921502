import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { authContext } from "../Authentication/CognitoContextProvider";
import { Auth } from "aws-amplify";
import { isSupplier, isAdmin, isCustomer } from "../../auth/userUtils";
import { useDispatch } from "react-redux";
import { resetSearchParams } from "../../actions/search";
import { getUserSub } from "../../utils/getUserData";

const Navbar = () => {
  const { auth, setAuthData } = useContext(authContext);
  const history = useHistory();
  const dispatch = useDispatch();
  const [locationKeys, setLocationKeys] = useState([]);
  const [selectedView, setSelectedView] = useState("");

  const handleNavbarClick = async (link: string) => {
    if (link === "/logout") {
      localStorage.removeItem("lastPage");
      localStorage.removeItem("searchSettings");
      dispatch(resetSearchParams());
      await Auth.signOut();
      setAuthData(null);
      history.push("/", { selectedView: "/" });
      setSelectedView("/");
    } else {
      history.push(link, { selectedView: link });
      setSelectedView(link);
    }
    if (link !== "/") handleBurgerClick();
  };

  const handleBurgerClick = () => {
    const items = document.getElementById("nav-ul");
    items.classList.toggle("responsive-nav-items");
  };

  useEffect(() => {
    if (selectedView === "") setSelectedView(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
        setSelectedView(history.location.pathname);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          setSelectedView(history.location.pathname);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          setSelectedView(history.location.pathname);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKeys]);

  const renderNavButtons = () => {
    const storedSettings = localStorage.getItem("searchSettings");
    const settings = storedSettings ? JSON.parse(storedSettings) : null;
    return !auth || !auth.data ? (
      <>
        <ul
          className="col-auto list-unstyled d-flex align-items-center responsive-nav-items px-0"
          id="nav-ul"
        >
          <li>
            <span
              className={`admin-link clickable ${
                selectedView === "/" ? "active" : ""
              }`}
              onClick={() => {}}
            >
              <span className="font-weight-bold">START A FREE TRIAL!</span>
            </span>
          </li>
          <li>
            <span
              className={`admin-link clickable ${
                selectedView.includes("login") ? "active" : ""
              }`}
              onClick={() => handleNavbarClick("/login")}
            >
              <span>LOG IN</span>
            </span>
          </li>
          <li>
            <span
              className={`admin-link clickable ${
                selectedView.includes("onboarding") ? "active" : ""
              }`}
              onClick={() => handleNavbarClick("/role-selection")}
            >
              <span>SIGN UP</span>
            </span>
          </li>
        </ul>
      </>
    ) : (
      <>
        <ul
          className="list-unstyled d-flex align-items-center responsive-nav-items"
          id="nav-ul"
        >
          <li>
            <span
              className={`admin-link clickable  ${
                selectedView.includes("guided-search") ? "active" : ""
              }`}
              onClick={() => handleNavbarClick(`/guided-search/area`)}
            >
              <span>Guided Search</span>
              <i className="icon-chevron-right font-24 text-pinky d-lg-none"></i>
            </span>
          </li>

          {settings && settings.userId === getUserSub(auth) && (
            <li>
              <span
                className={`admin-link clickable  ${
                  !selectedView.includes("guided") &&
                  selectedView.includes("search")
                    ? "active"
                    : ""
                }`}
                onClick={() =>
                  handleNavbarClick(
                    `${
                      localStorage.getItem("lastPage")
                        ? `/search?page=${localStorage.getItem(
                            "lastPage"
                          )}&hS=true`
                        : "/search?page=1"
                    }`
                  )
                }
              >
                <span>Search</span>
                <i className="icon-chevron-right font-24 text-pinky d-lg-none"></i>
              </span>
            </li>
          )}

          {(isCustomer(auth.data) || isSupplier(auth.data)) && (
            <li>
              <span
                className={`admin-link clickable  ${
                  selectedView.includes("view-profile") ? "active" : ""
                }`}
                onClick={() => {
                  const urlPrefix = isCustomer(auth.data)
                    ? "profile/champion"
                    : "profile/supplier";
                  handleNavbarClick(
                    `/${urlPrefix}/${auth.data.attributes.sub}`
                  );
                }}
              >
                <span>View Profile</span>
                <i className="icon-chevron-right font-24 text-pinky d-lg-none"></i>
              </span>
            </li>
          )}

          {!isAdmin(auth.data) && (
            <>
              <li>
                <span
                  className={`admin-link clickable  ${
                    selectedView.includes("my-connections") ? "active" : ""
                  }`}
                  onClick={() => handleNavbarClick(`/my-connections`)}
                >
                  <span>My Connections</span>
                  <i className="icon-chevron-right font-24 text-pinky d-lg-none" />
                </span>
              </li>
              <li>
                <span
                  className={`admin-link clickable  ${
                    selectedView.includes("notifications") ? "active" : ""
                  }`}
                  onClick={() => handleNavbarClick(`/notifications`)}
                >
                  <span>Notifications</span>
                  <i className="icon-chevron-right font-24 text-pinky d-lg-none" />
                </span>
              </li>
            </>
          )}
          {isAdmin(auth.data) && (
            <>
              <li>
                <span
                  className={`admin-link clickable  ${
                    selectedView.includes("users-management") ? "active" : ""
                  }`}
                  onClick={() => handleNavbarClick("/admin/users-management")}
                >
                  <span>Users Management</span>
                  <i className="icon-chevron-right font-24 text-pinky d-lg-none"></i>
                </span>
              </li>
            </>
          )}
          <li>
            <span
              className={`admin-link clickable`}
              onClick={() => handleNavbarClick("/logout")}
            >
              <span>Logout</span>
              <i className="icon-chevron-right font-24 text-pinky d-lg-none"></i>
            </span>
          </li>
        </ul>
      </>
    );
  };

  return (
    <header>
      <nav className="sdd-header row mx-0 align-items-center">
        <div className="logo px-0 pt-md-1 py-0 col-12 col-lg-4">
          <div className="row mx-0 justify-content-between">
            <div className="col-auto px-0">
              <Link
                className="d-flex align-items-center text-center text-decoration-none"
                to="/"
              >
                <i className="navbar-logo text-pinky pl-3 d-inline-block" />
                <i
                  className="icon-bsn-diamond text-pinky d-inline-block d-md-none py-3"
                  style={{ fontSize: "3rem" }}
                />
                <span className="d-inline-block d-md-none font-primary text-pinky font-weight-bold font-24">
                  BSN
                </span>
              </Link>
            </div>
            <div className="hamburger d-flex d-lg-none align-items-end align-items-center">
              <div className="col-auto px-2 px-md-3">
                <button
                  className="btn btn-black text-pinky bg-black border-0"
                  onClick={() => handleBurgerClick()}
                >
                  <i className="icon-menu font-24"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="main-nav px-0 px-lg-3 col-12 col-md d-flex align-items-center justify-content-end">
          {renderNavButtons()}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
