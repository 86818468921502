import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import validator from 'validator';
import { Auth } from 'aws-amplify';
import {
  addSupplierInfo,
  getSupplierInfoByEmail,
  resetSupplierErrors,
  updateSupplierInfo,
} from '../../actions/supplier';
import { RootState } from '../../reducers';
import axios from 'axios';
import { backendUrl } from '../../utils/env';
import { v4 as uuidv4 } from 'uuid';
import {
  uploadProfilePictureToS3,
  uploadSupplierPDFToS3,
} from '../../utils/uploadToS3';
import { ICompanyStage, SupplierInfo } from '../../types/SupplierInfo';
import { City, State } from 'country-state-city';
import { getIsoCode } from '../../utils/getIsoCode';
import SupplierBasicInformation from '../../components/CreateSupplierViews/SupplierBasicInformation';
import SupplierOtherInformation from '../../components/CreateSupplierViews/SupplierOtherInformation';
import SupplierSearchSettings from '../../components/CreateSupplierViews/SupplierSearchSettings';
import SupplierConfirmationMessage from '../../components/CreateSupplierViews/SupplierConfirmationMessage';
import bgvLogo from '../../styles/img/bgv-logo.png';
import moment from 'moment';

const CreateSupplierProfile = () => {
  const dispatch = useDispatch();

  const [activePage, setActivePage] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [revenueGenerated, setRevenueGenerated] = useState('');
  const [establishmentDate, setEstablishmentDate] = useState('');
  const [currentStage, setCurrentStage] = useState<ICompanyStage>({
    id: 0,
    title: '',
    shortDescription: '',
    longDescription: '',
  });
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [image, setImage] = useState<File>();
  const [imageSrc, setImageSrc] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [bio, setBio] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [pitchVideoUrl, setPitchVideoUrl] = useState('');
  const [pitchDeckDoc, setPitchDeckDoc] = useState<File>();
  const [businessCanvasDoc, setBusinessCanvasDoc] = useState<File>();
  const [salesSheetDoc, setSalesSheetDoc] = useState<File>();
  const [capabilitiesStatementDoc, setCapabilitiesStatementDoc] =
    useState<File>();
  const [industries, setIndustries] = useState([]);
  const [communityVisibility, setCommunityVisibility] = useState(true);
  const [hiringVisibility, setHiringVisibility] = useState(true);
  const [investmentVisibility, setInvestmentVisibility] = useState(true);
  const [markets, setMarkets] = useState('');
  const [funding, setFunding] = useState('');
  const [orderQuantity, setOrderQuantity] = useState('');
  const [shippingTime, setShippingTime] = useState('');
  const [sellingCycle, setSellingCycle] = useState('');
  const [productUpdate, setProductUpdate] = useState('');
  const [teamMembers, setTeamMembers] = useState([
    { id: uuidv4(), name: '', role: '', image: { file: null, src: '' } },
  ]);
  const [loading, setLoading] = useState(false);
  const [isProduct, setIsProduct] = useState(false);
  const [isService, setIsService] = useState(false);
  const [fieldOfOpportunity, setFieldOfOpportunity] = useState({
    smallBoutiques: false,
    mentorships: false,
    bigBoxRetailer: false,
    corporations: false,
    partnerships: false,
    investment: false,
    internship: false,
    openToWork: false,
  });
  const [alreadyExists, setAlreadyExists] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [allCities, setAllCities] = useState([]);
  const [allStates, setAllStates] = useState(undefined);
  const { requestFinished, hasErrors, errorMessage, supplierInfo } =
    useSelector((state: RootState) => state.supplier);

  useEffect(() => {
    window.scrollTo(0,0);
    const getAllStates = State.getStatesOfCountry('US').filter(
      (s) => City.getCitiesOfState('US', s.isoCode).length > 0
    );
    setAllStates(getAllStates);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsEmail = urlParams.has('email') ? urlParams.get('email') : '';
    setEmail(paramsEmail);
    if (paramsEmail) {
      dispatch(getSupplierInfoByEmail(paramsEmail));
    }
    if (urlParams.has('name')) {
      const splittedName = urlParams.get('name').split(' ');
      if (splittedName.length >= 2) {
        setFirstName(splittedName[0]);
        setLastName(splittedName.slice(1, splittedName.length).join(' '));
      } else {
        setFirstName(splittedName[0]);
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (email) setValidEmail(validator.isEmail(email));
  }, [email]);

  useEffect(() => {
    if (supplierInfo) {
      let info: SupplierInfo = supplierInfo;
      setAlreadyExists(true);
      setBio(info.bio);
      setFacebookUrl(info.facebook);
      setLinkedinUrl(info.linkedin);
      setInstagramUrl(info.instagram);
      setWebsiteUrl(info.website);
      setTwitterUrl(info.twitter);
      setPitchVideoUrl(info.pitchVideo);
    } else {
      setAlreadyExists(false);
      setBio('');
      setFacebookUrl('');
      setLinkedinUrl('');
      setInstagramUrl('');
      setWebsiteUrl('');
      setTwitterUrl('');
      setPitchVideoUrl('');
    }
  }, [supplierInfo]);

  const getErrorMessage = (cognitoErrorCode: string) => {
    switch (cognitoErrorCode) {
      case 'InvalidParameterException':
        return 'Invalid parameters. Please check the information provided.';
      case 'InvalidPasswordException':
        return 'Password does not meet minimum requirements.';
      default:
        return 'Could not register the user.';
    }
  };

  const handleSelectChange = (event, isCity?) => {
    const val = event.target.value;
    if (isCity) {
      setCity(val);
    } else {
      setState(val);
      setCity('');
      setAllCities(City.getCitiesOfState('US', getIsoCode(val, allStates)));
    }
  };

  const showToast = () => {
    if (requestFinished) {
      if (hasErrors) {
        toast.error(errorMessage, {
          toastId: 'profile-creation-error',
        });
      } else {
        toast.success(
          `A verification link has been sent to your email ${email}`,
          { toastId: 'signup-verification' }
        );
        localStorage.setItem('redirect-url', '/supplier/edit/new-supplier');
      }
    }
    return null;
  };

  const handleIndustryChange = (industry: string) => {
    if (industries.includes(industry)) {
      setIndustries(industries.filter((ind) => ind !== industry));
    } else if (industries.length < 2) {
      setIndustries([...industries, industry]);
    }
  };

  const getTeamMembers = async () => {
    try {
      let response = await Promise.all(
        teamMembers.map(async (member) => {
          if (member.name.trim().length > 0) {
            const formattedMember = {
              name: member.name.trim(),
              role: member.role,
              image: '',
            };
            if (member.image.file) {
              const imgFormData = new FormData();
              imgFormData.append('imageKey', member.image.file.name);
              imgFormData.append('image', member.image.file);
              imgFormData.append('name', `${companyName}-${member.name}`);
              const imageRequest = await axios.post(
                `${backendUrl}/Suppliers/team-member/picture`,
                imgFormData,
                {
                  headers: {
                    'Content-Type': 'application/form-data',
                  },
                }
              );
              formattedMember.image = imageRequest.data;
            }
            return formattedMember;
          }
        })
      );
      return response;
    } catch (err) {
      console.error(err.message);
    }
  };

  const hasValidTeamMembers = () => {
    let valid = false;

    teamMembers.forEach((member) => {
      if (member.name.trim().length > 0) {
        valid = true;
      }
    });

    return valid;
  };

  const validateFieldsBeforeSubmit = () => {
    if (email.length === 0 || !validator.isEmail(email)) {
      setValidEmail(false);
      return true;
    }
    return (
      firstName.trim().length === 0 ||
      lastName.trim().length === 0 ||
      companyName.trim().length === 0 ||
      revenueGenerated.trim().length === 0 ||
      (establishmentDate.length > 0 &&
        (!validator.isDate(establishmentDate, {
          format: 'mm/dd/yyyy',
          strictMode: true,
          delimiters: ['/'],
        }) ||
          moment().diff(moment(establishmentDate, 'MM/DD/YYYY')) < 0)) ||
      establishmentDate.trim().length === 0 ||
      currentStage.id === 0 ||
      imageSrc.trim().length === 0 ||
      password.length === 0 ||
      confirmPassword.length === 0 ||
      password.length < 6 ||
      password !== confirmPassword ||
      !pitchDeckDoc ||
      (!isProduct && !isService) ||
      industries.length === 0 ||
      state.trim().length === 0 ||
      markets.trim().length === 0 ||
      funding.trim().length === 0 ||
      orderQuantity.trim().length === 0 ||
      productUpdate.trim().length === 0 ||
      sellingCycle.trim().length === 0 ||
      !fieldOfOpportunitySelected()
    );
  };

  const getIsAlumnisFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isAlumni = urlParams.get('isAlumni');
    return isAlumni === 'true';
  }

  const handleSubmit = async () => {
    dispatch(resetSupplierErrors());

    const invalidFields = validateFieldsBeforeSubmit();

    if (invalidFields) {
      return;
    }

    setValidEmail(true);
    setDisabled(true);

    try {
      setValidEmail(true);
      setDisabled(true);
      setLoading(true);
      window.scrollTo(0, 0);

      let teamData = hasValidTeamMembers() ? await getTeamMembers() : [];
      teamData = teamData.filter((element) => element !== undefined);

      if (state.trim().length === 0) {
        return;
      }

      const fName = firstName.replace(/,|"/g, '').trim();
      const lName = lastName.replace(/,|"/g, '').trim();

      const docNamePrefix = `${firstName}-${lastName}`;
      let imageUrl = '';

      // Upload profile picture to S3
      if (image) {
        imageUrl = await uploadProfilePictureToS3(image, docNamePrefix);
      }
      
      const isAlumni = getIsAlumnisFromURL()

      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          'custom:first_name': fName,
          'custom:last_name': lName,
          'custom:picture': imageUrl,
          'custom:is_supplier': 'true',
          'custom:is_alumni': isAlumni.toString(),
        },
      });

      // Upload pitch deck document to S3
      let pitchDeckUrl = '';
      if (pitchDeckDoc) {
        pitchDeckUrl = await uploadSupplierPDFToS3(
          pitchDeckDoc,
          docNamePrefix,
          'pitch-decks'
        );
      }

      // Upload business canvas to S3
      let businessCanvasUrl = '';
      if (businessCanvasDoc) {
        businessCanvasUrl = await uploadSupplierPDFToS3(
          businessCanvasDoc,
          docNamePrefix,
          'business-canvas'
        );
      }

      // Upload sales sheet to S3
      let salesSheetUrl = '';
      if (salesSheetDoc) {
        salesSheetUrl = await uploadSupplierPDFToS3(
          salesSheetDoc,
          docNamePrefix,
          'sales-sheets'
        );
      }

      // Upload capabilites statement document to S3
      let capabilitiesStatemetUrl = '';
      if (capabilitiesStatementDoc) {
        capabilitiesStatemetUrl = await uploadSupplierPDFToS3(
          capabilitiesStatementDoc,
          docNamePrefix,
          'capabilities-statements'
        );
      }

      const payload = {
        firstName,
        lastName,
        email,
        companyName,
        revenueGenerated,
        establishmentDate,
        stage: currentStage,
        bio,
        facebook: facebookUrl,
        twitter: twitterUrl,
        instagram: instagramUrl,
        linkedin: linkedinUrl,
        website: websiteUrl,
        pitchVideo: pitchVideoUrl,
        pitchDeck: pitchDeckUrl,
        businessCanvas: businessCanvasUrl,
        salesSheet: salesSheetUrl,
        capabilitiesStatement: capabilitiesStatemetUrl,
        industries,
        state,
        city: city || '',
        companyInformation: {
          isProduct,
          isService,
          markets,
          funding,
          orderQuantity,
          shippingTime,
          productUpdate,
          sellingCycle,
        },
        teamInfo: teamData,
        fieldOfOpportunity,
        isCommunityVisible: communityVisibility,
        isHiringVisible: hiringVisibility,
        isInvestmentVisible: investmentVisibility,
        isAlumni,
      };
      dispatch(
        alreadyExists
          ? updateSupplierInfo({
              payload: { supplierInfoId: supplierInfo.id, ...payload },
              token: undefined,
              supplierInfoId: supplierInfo.id,
            })
          : addSupplierInfo(payload)
      );

      setDisabled(false);
      setLoading(false);
      setActivePage(4);
    } catch (err) {
      setDisabled(false);
      setLoading(false);
      const message = getErrorMessage(err.code);
      toast.error(message);
    }
  };

  const fieldOfOpportunitySelected = () => {
    let response = false;
    for (const field in fieldOfOpportunity) {
      if (fieldOfOpportunity[field]) response = true;
    }
    return response;
  };

  const handleFieldOfOpportunity = (e) => {
    const setting = e.target.value;
    setFieldOfOpportunity((prevState) => ({
      ...prevState,
      [setting]: !fieldOfOpportunity[setting],
    }));
  };

  return (
    <>
      <Helmet>
        <title>BSN - Create Supplier Profile</title>
      </Helmet>
      <main className="body-min-h90 py-5 px-4">
        {loading ? (
          <div className="align-middle">
            <div className="d-flex justify-content-center">
              <img
                src={bgvLogo}
                alt={'bgv-logo'}
                width={200}
                height={200}
                style={{ maxHeight: 200 }}
              />
            </div>
            <p className="loader-text text-center text-black pt-3">
              Your profile is being created, this might take a few seconds
            </p>
          </div>
        ) : (
          <>
            {activePage !== 4 && (
              <div className="section pt-3 pb-2">
                <h1 className="font-weight-bold font-primary text-black text-left text-md-center">
                  Setting up your Supplier Account
                </h1>
              </div>
            )}
            {activePage === 1 && (
              <SupplierBasicInformation
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                email={email}
                setEmail={setEmail}
                companyName={companyName}
                setCompanyName={setCompanyName}
                revenueGenerated={revenueGenerated}
                setRevenueGenerated={setRevenueGenerated}
                establishmentDate={establishmentDate}
                setEstablishmentDate={setEstablishmentDate}
                currentStage={currentStage}
                setCurrentStage={setCurrentStage}
                image={image}
                setImage={setImage}
                imageSrc={imageSrc}
                setImageSrc={setImageSrc}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                validEmail={validEmail}
                setActivePage={setActivePage}
                hideBackButton={getIsAlumnisFromURL()}
              />
            )}
            {activePage === 2 && (
              <SupplierOtherInformation
                bio={bio}
                setBio={setBio}
                facebookUrl={facebookUrl}
                setFacebookUrl={setFacebookUrl}
                twitterUrl={twitterUrl}
                setTwitterUrl={setTwitterUrl}
                instagramUrl={instagramUrl}
                setInstagramUrl={setInstagramUrl}
                linkedinUrl={linkedinUrl}
                setLinkedinUrl={setLinkedinUrl}
                websiteUrl={websiteUrl}
                setWebsiteUrl={setWebsiteUrl}
                pitchVideoUrl={pitchVideoUrl}
                setPitchVideoUrl={setPitchVideoUrl}
                pitchDeckDoc={pitchDeckDoc}
                setPitchDeckDoc={setPitchDeckDoc}
                businessCanvasDoc={businessCanvasDoc}
                setBusinessCanvasDoc={setBusinessCanvasDoc}
                salesSheetDoc={salesSheetDoc}
                setSalesSheetDoc={setSalesSheetDoc}
                capabilitiesStatementDoc={capabilitiesStatementDoc}
                setCapabilitiesStatementDoc={setCapabilitiesStatementDoc}
                setActivePage={setActivePage}
              />
            )}
            {activePage === 3 && (
              <SupplierSearchSettings
                isProduct={isProduct}
                setIsProduct={setIsProduct}
                isService={isService}
                setIsService={setIsService}
                industries={industries}
                handleIndustryChange={handleIndustryChange}
                handleSelectChange={handleSelectChange}
                allCities={allCities}
                city={city}
                allStates={allStates}
                state={state}
                markets={markets}
                setMarkets={setMarkets}
                funding={funding}
                setFunding={setFunding}
                orderQuantity={orderQuantity}
                setOrderQuantity={setOrderQuantity}
                shippingTime={shippingTime}
                setShippingTime={setShippingTime}
                productUpdate={productUpdate}
                setProductUpdate={setProductUpdate}
                sellingCycle={sellingCycle}
                setSellingCycle={setSellingCycle}
                teamMembers={teamMembers}
                setTeamMembers={setTeamMembers}
                fieldOfOpportunity={fieldOfOpportunity}
                handleFieldOfOpportunity={handleFieldOfOpportunity}
                communityVisibility={communityVisibility}
                setCommunityVisibility={setCommunityVisibility}
                hiringVisibility={hiringVisibility}
                setHiringVisibility={setHiringVisibility}
                investmentVisibility={investmentVisibility}
                setInvestmentVisibility={setInvestmentVisibility}
                disabled={disabled}
                handleSubmit={handleSubmit}
                setActivePage={setActivePage}
              />
            )}
            {activePage === 4 && (
              <SupplierConfirmationMessage setActivePage={setActivePage} />
            )}
          </>
        )}
      </main>
      {showToast()}
    </>
  );
};

export default CreateSupplierProfile;
