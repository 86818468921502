import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllUsersForTable } from "../../actions/user";
import { RootState } from "../../reducers";

export const UserConnectionSearch = ({ setUser }) => {
  const [query, setQuery] = useState("");
  const [usersBatch, setUsersBatch] = useState(undefined);
  const [results, setResults] = useState(undefined);
  const params: { id: string } = useParams();
  const dispatch = useDispatch();
  const { customers, suppliers, loading } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    if (customers.length === 0 && suppliers.length === 0 && !loading) {
      dispatch(getAllUsersForTable());
    }
  });

  useEffect(() => {
    if (query.length === 0) {
      setUser(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (customers.length > 0 || suppliers.length > 0) {
      const batch = [...customers, ...suppliers];
      setUsersBatch(batch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, suppliers]);

  const typeAhead = (query: string) => {
    if (query.length > 0 && usersBatch) {
      let searchResults = usersBatch
        .filter((user) => {
          return (
            (user.name.toLowerCase().includes(query.toLowerCase()) ||
              user.email.toLowerCase().includes(query.toLowerCase())) &&
            user.id !== params.id
          );
        })
        .sort((a, b) => {
          if (
            a.name.toLowerCase().indexOf(query.toLowerCase()) >
            b.name.toLowerCase().indexOf(query.toLowerCase())
          ) {
            return 1;
          } else if (
            a.name.toLowerCase().indexOf(query.toLowerCase()) <
            b.name.toLowerCase().indexOf(query.toLowerCase())
          ) {
            return -1;
          } else {
            if (a.name > b.name) return 1;
            else return -1;
          }
        })
        .slice(0, 4);
      setResults(searchResults);
    } else {
      setResults(undefined);
    }
  };

  const handleSelectUser = (user) => {
    setUser(user);
    setResults(undefined);
    setQuery(user.name + "   " + user.email);
  };

  return (
    <>
      <div className="row user-connect-search align-items-center">
        <label
          className="font-weight-bold text-black mb-0 col-12"
          style={{ fontSize: "1.1rem", textAlign: "start", padding: "0px" }}
        >
          Name/Email Address
        </label>

        <input
          type="text"
          name="user-connect-search"
          className="user-connect-search col-10 col-lg-11 px-0 font-16"
          placeholder="Search user"
          disabled={customers.length === 0 && suppliers.length === 0}
          autoComplete="off"
          value={query}
          style={{ border: "none" }}
          onChange={(e) => {
            setQuery(e.target.value);
            typeAhead(e.target.value);
          }}
        />
        {loading ? (
          <div className="col-1">
            <span
              className="spinner-border spinner-border-sm text-black"
              role="status"
              aria-hidden="true"
              style={{ borderWidth: "0.1em" }}
            ></span>
          </div>
        ) : (
          <i className="icon-search col-1" style={{ color: "grey" }} />
        )}
      </div>
      {results && results.length > 0 && (
        <div
          className="row connect-result-container"
          style={{ textAlign: "start" }}
        >
          {results.map((result, i) => {
            return (
              <>
                <div
                  className={`col-12 text-black font-16 py-1 clickable col-hover`}
                  onClick={() => handleSelectUser(result)}
                >
                  <div className="">
                    <span
                      className={`pt-1 ${i > 0 ? "result-top-border" : ""}`}
                    ></span>
                    {result.name}{" "}
                    <span className="text-muted">({result.mainRole === 'Customer' ? 'Champion' : result.mainRole})</span>{" "}
                    <br />
                    {result.email}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};
