import React from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { UserRolesData } from "../../types/User";
import MainBanner from "../../styles/img/mainbanner.png";
import GuidedSearchImg from "../../styles/img/hp-phoneimg.png";
import CarouselImage from "../../styles/img/unsplash_car.png";
import { Carrousel } from "../../components/Carrousel/Carrousel";

export interface AnnouncementInterface {
  image: string;
  caption: string;
  description: string;
}

const announcements: AnnouncementInterface[] = [
  {
    image: CarouselImage,
    caption: "Black Founders Pitch #3 by Black Men Ventures",
    description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor
do amet sint. Velit officia consequat duis enim velit
mollit. Exercitation veniam consequat sunt nostrud amet.Amet
minim mollit non deserunt ullamco est sit aliqua dolor do
amet sint. Velit officia consequat duis enim velit mollit.
Exercitation veniam consequat sunt nostrud amet.`,
  },
  {
    image: MainBanner,
    caption: "Black Founders Pitch #2 by Black Men Ventures",
    description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor
do amet sint. Velit officia consequat duis enim velit
mollit. Exercitation veniam consequat sunt nostrud amet.Amet
minim mollit non deserunt ullamco est sit aliqua dolor do
amet sint. Velit officia consequat duis enim velit mollit.
Exercitation veniam consequat sunt nostrud amet.`,
  },
];

export const HowItWorks = () => {
  return (
    <>
      <Helmet>
        <title>BSN - BGV Supplier Network</title>
      </Helmet>
      <main className="how-it-works">
        <section className="main-banner ">
          <i
            className="icon-bsn-diamond text-black bg-pinky mx-3 my-3"
            style={{ borderRadius: "100%", fontSize: "6rem" }}
          />
          <h1 className="font-primary text-white font-weight-bold mb-0 text-center ">
            Black Girl Ventures
          </h1>
          <h1 className="font-primary text-white text-center">
            Supplier Network
          </h1>
        </section>
        <section className="quick-guide py-5 px-3">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="font-primary text-black font-weight-bolder">
                Quick guide into the Network
              </h3>
            </div>
            <div className="col-12 text-center">
              <p className="font-primary text-black font-20">
                Get the most of BSN by getting to know the different people you
                can connect with.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-4 mb-md-0">
              <div className="role-selection px-3">
                <div className="role-selection-icon d-flex justify-content-center mt-4 mt-md-5">
                  <div className="d-flex justify-content-center align-items-center pink-circle text-center text-black">
                    <i className={UserRolesData.CHAMPION.icon} />
                  </div>
                </div>
                <div className="role-selection-title d-flex justify-content-center">
                  <h4 className="font-primary text-center text-black font-weight-bold pt-1">
                    Champions
                  </h4>
                </div>
                <span className="role-selection-container d-flex justify-content-md-center justify-content-start text-center text-black font-16 px-md-5 mx-md-5 font-20">
                  {UserRolesData.CHAMPION.description}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="role-selection px-3">
                <div className="role-selection-icon d-flex justify-content-center mt-4 mt-md-5">
                  <div className="d-flex justify-content-center align-items-center pink-circle text-center text-black">
                    <i className={UserRolesData.SUPPLIER.icon} />
                  </div>
                </div>
                <div className="role-selection-title d-flex justify-content-center">
                  <h4 className="font-primary text-center text-black font-weight-bold pt-1">
                    Suppliers
                  </h4>
                </div>
                <span className="role-selection-container d-flex  justify-content-md-center justify-content-start text-center text-black font-16 px-md-5 mx-md-5 font-20">
                  {UserRolesData.SUPPLIER.description}
                </span>
              </div>
            </div>
          </div>
        </section>
        <section className="guided-search-info py-5 px-3">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h3 className="font-primary text-black font-weight-bolder d-flex justify-content-center align-items-center">
                <i className="icon-hiring font-40" />
                <span>Guided Search</span>
              </h3>
            </div>
            <div className="col-12 col-md-6 text-center">
              <p className="font-primary text-black font-20">
                To match with your best fit, we have our guided search to
                provide you with a personalized scope of the people you can
                connect with.
              </p>
            </div>
            <div className="col-12 d-flex justify-content-center py-3">
              <LazyLoadImage
                className="img-fluid fix-size-40"
                src={GuidedSearchImg}
                alt="herobanner"
              />
            </div>
            <div className="col-12 text-center">
              <p className="font-primary text-black font-20">
                Search for Industries, Selling Cycles, Shipping Times and
                Locations to find the perfect match!
              </p>
            </div>
          </div>
        </section>
        <section className="announcements">
          <h3 className="font-primary text-black font-weight-bolder d-flex justify-content-center align-items-center pb-2">
            <i className="icon-fire font-40" />
            <span>Announcements</span>
          </h3>

          <Carrousel announcements={announcements} />
        </section>
      </main>
    </>
  );
};
