import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "react-bootstrap";
import Modal from "react-modal";
import { RootState } from "../../reducers";
import UltimatePagination from "../../components/Pagination/Pagination";
import { CSVLink } from "react-csv";
import { connectionsColumns } from "../../utils/csv-columns";
import { useHistory, useParams } from "react-router";
import { Helmet } from "react-helmet";
import {
  adminAddConnection,
  changeConnectionStatus,
  getAllActiveConnectionsForUser,
  resetConnectionErrors,
  sortConnectionsForTable,
} from "../../actions/connection";
import { ConnectionWithProfile } from "../../types/Connection";
import ConnectionsTable from "../../components/ConnectionsTable/ConnectionsTable";
import { getUserById } from "../../actions/user";
import emptyStateProfilePic from "../../styles/img/empty-state-profile-pic.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getDisplayName } from "../../utils/connectionUtils";
import { toast } from "react-toastify";
import moment from "moment";
import { getCapitalizedWord } from "../../utils/text-format";
import { AdminConnectModal } from "../../components/Modals/AdminConnect";
import { authContext } from "../../components/Authentication/CognitoContextProvider";
import { Auth } from "aws-amplify";
import { redirectToLogin } from "../../utils/redirectToLogin";

const ManageConnections = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { id: string } = useParams();
  const { auth } = useContext(authContext);
  const [activeTab, setActiveTab] = useState("Active Connections");
  const [activePage, setActivePage] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedConnection, setSelectedConnection] =
    useState<ConnectionWithProfile>(undefined);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortProperty, setSortProperty] = useState("modificationDate");

  const [connectModalOpen, setConnectModalOpen] = useState(false);

  const {
    connections,
    requestFinished,
    hasErrors,
    errorMessage,
    toastMessage,
  } = useSelector((state: RootState) => state.connection);

  const loading: boolean = useSelector(
    (state: RootState) => state.connection.loading
  );

  const selectedUser: any = useSelector(
    (state: RootState) => state.user.selectedUser
  );

  useEffect(() => {
    if (deleteModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [deleteModalOpen]);

  useEffect(() => {
    dispatch(getUserById(params.id));
    dispatch(getAllActiveConnectionsForUser(params.id));
    setDataForTableAndCsv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleCurrentPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData.length]);

  useEffect(() => {
    setDataForTableAndCsv();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections]);

  useEffect(() => {
    formatDataForCsv(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const openModal = (connection: ConnectionWithProfile, modal: string) => {
    if (connection) setSelectedConnection(connection);
    if (modal === "Delete") {
      setDeleteModalOpen(true);
    }
  };

  const changePage = (index: number) => {
    setActivePage(index);
  };

  const refreshTable = () => {
    dispatch(getAllActiveConnectionsForUser(params.id));
  };

  const formatDataForCsv = (data: ConnectionWithProfile[]) => {
    const formattedData: any = data.map((item) => {
      const userIsReceiver = item.receiverId === params.id;
      const userToShow = userIsReceiver ? item.sender : item.receiver;
      const name = userToShow["custom:first_name"]
        ? `${userToShow["custom:first_name"]} ${userToShow["custom:last_name"]}`.trim()
        : userToShow.given_name;
      return {
        connectionName: name,
        connectionEmail: userIsReceiver ? item.senderEmail : item.receiverEmail,
        meetRequested: `${item.meetRequested ? "" : "No "}Request Sent`,
        modificationDate: moment(item.modificationDate).format("M/D/YYYY"),
        opportunities: getOpportunitiesForCsv(item),
      };
    });
    setCsvData(formattedData);
  };

  const getOpportunitiesForCsv = (connection: ConnectionWithProfile) => {
    const opportunities = [];
    const fieldsOfOpportunity = connection.matchingFieldsOfOpportunity;
    Object.keys(fieldsOfOpportunity).forEach((field) => {
      if (fieldsOfOpportunity[field]) {
        opportunities.push(getCapitalizedWord(field));
      }
    });

    if (opportunities.length === 0) {
      return "None";
    }
    return opportunities.join("-");
  };

  const handleSortRequest = (column: string) => {
    if (column === sortProperty) {
      const newSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
      setSortOrder(newSortOrder);
      dispatch(
        sortConnectionsForTable({
          connections,
          column,
          order: newSortOrder,
          userEmail: selectedUser.email,
        })
      );
    } else {
      setSortOrder("ASC");
      setSortProperty(column);
      dispatch(
        sortConnectionsForTable({
          connections,
          column,
          order: "ASC",
          userEmail: selectedUser.email,
        })
      );
    }
  };

  const setDataForTableAndCsv = () => {
    setTableData(connections);
    formatDataForCsv(tableData);
    setCsvHeaders(connectionsColumns);
  };

  const renderPagination = () => {
    const total = tableData.length;
    return (
      total > 10 && (
        <nav className="table-pagination my-3 px-4 px-lg-0">
          <UltimatePagination
            hideFirstAndLastPageLinks={true}
            siblingPagesRange={1}
            boundaryPagesRange={1}
            totalPages={Math.ceil(total / 10)}
            currentPage={handleCurrentPage()}
            onChange={changePage}
          />
        </nav>
      )
    );
  };

  const handleCurrentPage = () => {
    if (tableData.length - 1 >= activePage * 10 - 10) {
      return activePage;
    } else {
      const newPage = activePage - 1;
      setActivePage(newPage === 0 ? 1 : newPage);
      return newPage === 0 ? 1 : newPage;
    }
  };

  const renderTableTabs = () => {
    return (
      <Tabs
        id="manage-users-tabs"
        className="users-tabs"
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab);
          setActivePage(1);
          setDataForTableAndCsv();
        }}
      >
        <Tab tabClassName="text-black" eventKey={activeTab} title={activeTab}>
          <ConnectionsTable
            refreshTable={refreshTable}
            data={tableData.slice((activePage - 1) * 10, activePage * 10)}
            openModal={openModal}
            sortRequest={handleSortRequest}
            activeColumn={sortProperty}
            activeOrder={sortOrder}
            userId={params.id}
          />
        </Tab>
      </Tabs>
    );
  };

  const customStyles = {
    content: {
      background: "transparent",
      border: "none",
      padding: "none",
      position: "absolute",
      display: "block",
      margin: "0",
      transform: "translate(-50%, -30%)",
      width: "90%",
      maxWidth: "360px",
      inset: "unset",
      overflow: "initial",
    },
    overlay: {
      zIndex: "3",
      backgroundColor: "rgba(23, 41, 64, 0.75)",
    },
  };

  const handleDeleteConnection = async () => {
    const userIsReceiver = selectedConnection.receiverId === params.id;
    const name = getDisplayName(selectedConnection, userIsReceiver);
    dispatch(
      changeConnectionStatus({
        connectionId: selectedConnection.id,
        newStatus: 7,
        userId: params.id,
        name,
        filter: "",
        activePage: 1,
      })
    );
    closeDeleteModal();
  };

  const handleAddConnection = async (user) => {
    let token;
    if (auth && auth.data) {
      const getSession = await Auth.currentSession();
      token = getSession.getIdToken().getJwtToken();
    } else {
      toast.error("Your session has expired, please login again");
      redirectToLogin(history, history.location.pathname);
      return;
    }
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    dispatch(
      adminAddConnection({
        headers,
        senderId: selectedUser.sub,
        senderEmail: selectedUser.email,
        receiverId: user.id,
        receiverEmail: user.email,
        status: 2,
      })
    );
  };

  const showToast = () => {
    if (requestFinished) {
      if (hasErrors) {
        toast.error(errorMessage, {
          toastId: "manage-connection-actions-error",
        });
      } else {
        toast.success(toastMessage, {
          toastId: "manage-connection-actions-success",
        });
      }
      dispatch(resetConnectionErrors());
    }
    return null;
  };

  const closeDeleteModal = () => setDeleteModalOpen(false);

  const renderDeleteModal = () => {
    const userIsReceiver =
      selectedConnection && selectedConnection.receiverId === params.id;
    const name = selectedConnection
      ? getDisplayName(selectedConnection, userIsReceiver)
      : "";
    return (
      selectedConnection && (
        <Modal
          style={{
            ...customStyles,
            content: { ...customStyles.content, maxWidth: "360px" },
          }}
          isOpen={deleteModalOpen}
          onRequestClose={closeDeleteModal}
          closeTimeoutMS={500}
          ariaHideApp={false}
        >
          <div className="modal-content" style={{ borderRadius: "14.1562px" }}>
            <div className="modal-header py-4 border-0">
              <div className="modal-title px-3 text-center mt-4">
                <p className="modal-title font-primary text-black mt-2">
                  Are you sure you want to <strong>terminate</strong> the
                  connection?
                </p>
                <p className="modal-title font-primary text-black mt-2">
                  Disconnecting from: {name}
                </p>
              </div>
              <button
                className="close"
                type="button"
                data-dismiss="modal"
                onClick={closeDeleteModal}
                aria-label="Close"
              >
                <span aria-hidden="true"></span>
                <i className="icon-close-x" />
              </button>
            </div>
            <div className="modal-footer justify-content-center border-0 pb-4">
              <div className="row">
                <div className="col-6">
                  <button
                    className="btn btn-link text-uppercase text-black font-weight-bold rounded-0"
                    onClick={closeDeleteModal}
                  >
                    <span>Cancel</span>
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-black text-uppercase rounded text-pinky"
                    onClick={handleDeleteConnection}
                    disabled={loading}
                  >
                    Disconnect
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )
    );
  };

  const renderConnectModal = () => {
    const props = {
      modalMessage: "Add Connection",
      isModalOpen: connectModalOpen,
      closeModal: () => setConnectModalOpen(false),
      onConfirm: handleAddConnection,
      confirmText: "Connect",
    };

    return <AdminConnectModal {...props} />;
  };

  return (
    <>
      <Helmet>
        <title>BSN - Manage Connections</title>
      </Helmet>
      <main className="body-min-h90 admin-container py-5 px-0 px-lg-5">
        <div className="row mx-0 px-3 px-lg-0 pr-lg-1 pb-3">
          <button
            className="d-flex justify-content-center align-items-center go-back-btn text-pinky "
            onClick={() => {
              history.goBack();
            }}
          >
            <i className="icon-chevron-left" />
          </button>
        </div>
        <div className="row mx-0 px-3 px-lg-0 pr-lg-1 pb-3 d-flex justify-content-between justify-content-md-end">
          <div className="col-9 col-md row">
            <div className="manage-connections-avatar d-none d-md-block">
              <LazyLoadImage
                referrerPolicy="no-referrer"
                src={
                  selectedUser
                    ? (selectedUser['custom:picture'] && selectedUser['custom:picture'] !== 'N/A'
                          ? selectedUser['custom:picture']
                          : selectedUser.picture || emptyStateProfilePic)
                    : emptyStateProfilePic
                }
                alt=""
                effect="blur"
              />
            </div>
            <div className="col-12 col-md-4">
              {selectedUser && (
                <h2 className="text-black font-primary font-weight-bold">
                  {selectedUser["custom:first_name"]
                    ? `${selectedUser["custom:first_name"]} ${selectedUser["custom:last_name"]}`.trim()
                    : selectedUser["given_name"]}
                </h2>
              )}
              <div className="row justify-content-space-between">
                <div className="col-auto text-black d-flex align-items-center">
                  <i className="icon-user font-32" />
                  {selectedUser && selectedUser["custom:is_supplier"]
                    ? "Supplier"
                    : "Champion"} {/*Customers are now shown as 'champions' to the user*/}
                </div>
                <div className="col-auto text-black d-flex align-items-center">
                  <i className="icon-link font-32" />
                  {connections.length} Connection
                  {connections.length === 1 ? "" : "s"}
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto col-md-auto px-0 text-center mr-lg-4">
            <button
              className="btn btn-black font-weight-lighter text-uppercase"
              onClick={() => {
                setConnectModalOpen(true);
              }}
            >
              <i className="icon-plus"></i>
              <span className="font-14 pl-2 d-none d-md-inline">
                Add connection{" "}
              </span>
            </button>
          </div>
          <div className="col-auto col-md-auto px-0 text-center manage-users-actions">
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`Connections.csv`}
              id="connections-csv-link"
            />
            <button
              className="rounded btn btn-outline-black text-uppercase"
              onClick={() => {
                const csvLink = document.getElementById("connections-csv-link");
                csvLink.click();
              }}
              disabled={loading}
            >
              <i className="icon-export font-20" />
              <span className="pl-2 font-14 d-none d-md-inline">
                Export CSV
              </span>
            </button>
          </div>
        </div>
        {renderDeleteModal()}
        {renderConnectModal()}
        {renderTableTabs()}
        {renderPagination()}
        {showToast()}
      </main>
    </>
  );
};

export default ManageConnections;
