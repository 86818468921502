import { types } from '../actions/search';
import { ISearchParameters, ISearchResult } from '../types/Search';

export interface SearchState {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  examples: any[];
  requestFinished: boolean;
  searchParameters: ISearchParameters;
  searchResults: ISearchResult[];
  total: number;
  message: string;
}

const initialState: SearchState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  examples: [],
  requestFinished: false,
  searchResults: [],
  searchParameters: {
    page: 1,
    userId: '',
    isSupplier: false,
    areaOfInterest: { hiring: false, investment: false, supplier: false },
    industryPayload: {
      industries: {
        EdTech: { value: false },
        'App Development': { value: false },
        'Professional Development': { value: false },
        Wellness: { value: false },
        Arts: { value: false },
        Makers: { value: false },
        Beauty: { value: false },
        Design: { value: false },
        FinTech: { value: false },
        'Fashion / Apparel': { value: false },
        'Esports / Egaming': { value: false },
        Hospitality: { value: false },
        Restaurant: { value: false },
        'Share Economy': { value: false },
        InsurTech: { value: false },
        'Construction Tech': { value: false },
        'MarTech / AdTech': { value: false },
        Intersectional: { value: false },
        Skincare: { value: false },
        'Hair Care': { value: false },
        Healthcare: { value: false },
        'Business Development': { value: false },
        'Web Development': { value: false },
        'Product Development': { value: false },
        Other: { value: false },
      },
      industrySelected: false,
    },
    location: {
      state: undefined,
      city: undefined,
    },
  },
  total: 0,
  message: '',
};

export default function SearchReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_SEARCH_PARAMS:
      return {
        ...state,
        searchParameters: action.payload,
      };
    case types.GET_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        searchResults: [],
        total: 0,
        message: '',
      };
    case types.GET_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        searchResults: action.payload.searchResults,
        total: action.payload.total,
        message: action.payload.message,
      };
    case types.GET_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
      };
    case types.RESET_SEARCH_PARAMS:
      return {
        ...state,
        searchResults: [],
        total: 0,
        searchParameters: {
          page: 1,
          userId: '',
          areaOfInterest: { hiring: false, investment: false, supplier: false },
          industryPayload: {
            industries: {
              edtech: false,
              appDevelopment: false,
              professionalDevelopment: false,
              wellness: false,
              arts: false,
              makers: false,
              beauty: false,
              design: false,
              finTech: false,
              fashionApparel: false,
              esportsEgaming: false,
              hospitality: false,
              restaurant: false,
              shareEconomy: false,
              insurTech: false,
              constructionTech: false,
              marAdTech: false,
              intersectional: false,
              skinCare: false,
              hairCare: false,
              healthCare: false,
              businessDevelopment: false,
              webDevelopment: false,
              productDevelopment: false,
              other: false,
            },
            industrySelected: false,
          },
          location: {
            state: undefined,
            city: undefined,
          },
        },
      };
    default:
      return state;
  }
}
