import React, { useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { AddUser } from "../../containers/AddUser/AddUser";
import UserApproval from "../../containers/UserApproval/UserApproval";
import ManageUsers from "../../containers/ManageUsers/ManageUsers";

enum Views {
  ManageUsers = "",
  AddUser = "add-user",
  UserRequests = "users-requests",
}

export const UsersManagement = () => {
  const [activeView, setActiveView] = useState<Views | string>(
    Views.ManageUsers
  );
  const history = useHistory();
  const [locationKeys, setLocationKeys] = useState([]);

  useEffect(() => {
    const currentView = getView(history.location.pathname);
    if (activeView !== currentView) {
      setActiveView(currentView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
        setActiveView(getView(history.location.pathname));
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
          setActiveView(getView(history.location.pathname));
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          setActiveView(getView(history.location.pathname));
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationKeys]);

  const getView = (pathname: string) => {
    const pathnameArray = pathname.split("/");
    if (pathnameArray.length === 4) {
      return pathnameArray[3];
    }
    return "";
  };

  return (
    <>
      <div className="user-management d-flex justify-content-center">
        <div className="row mx-1">
          <div
            className={`col-4 col-md-auto font-weight-bold d-flex align-items-center justify-content-center font-16 px-2 py-1 px-md-4 py-md-3 clickable ${
              activeView === Views.ManageUsers ? "active" : ""
            }`}
            onClick={() => {
              history.push(`/admin/users-management`);
              setActiveView(Views.ManageUsers);
            }}
          >
            {" "}
            <i className="icon-user font-24" /> Manage Users
          </div>
          <div
            className={`col-4 col-md-auto font-weight-bold d-flex align-items-center justify-content-center font-16 px-2 py-1 px-md-4 py-md-3 clickable ${
              activeView === Views.AddUser ? "active" : ""
            }`}
            onClick={() => {
              history.push(`/admin/users-management/${Views.AddUser}`);
              setActiveView(Views.AddUser);
            }}
          >
            <i className="icon-add-user font-24" />
            Add User
          </div>
          <div
            className={`col-4 col-md-auto font-weight-bold d-flex align-items-center justify-content-center font-16 px-2 py-1 px-md-4 py-md-3 clickable ${
              activeView === Views.UserRequests ? "active" : ""
            }`}
            onClick={() => {
              history.push(`/admin/users-management/${Views.UserRequests}`);
              setActiveView(Views.UserRequests);
            }}
          >
            <i className="icon-bell font-24" />
            Users Requests
          </div>
        </div>
      </div>
      <Route exact path={`/admin/users-management`} component={ManageUsers} />
      <Route
        exact
        path={`/admin/users-management/${Views.AddUser}`}
        component={AddUser}
      />
      <Route
        exact
        path={`/admin/users-management/${Views.UserRequests}`}
        component={UserApproval}
      />
    </>
  );
};
