import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import validator from 'validator';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast } from 'react-toastify';
import {
  ICompanyStage,
  revenueGeneratedOptions,
} from '../../types/SupplierInfo';
import { fileIsImage } from '../../utils/validations';
import ReactInputDateMask from 'react-input-date-mask';
import { CompanyStageModal } from '../Modals/CompanyStageModal';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

interface Props {
  firstName: string;
  setFirstName: Dispatch<SetStateAction<string>>;
  lastName: string;
  setLastName: Dispatch<SetStateAction<string>>;
  email: string;
  setEmail: Dispatch<SetStateAction<string>>;
  companyName: string;
  setCompanyName: Dispatch<SetStateAction<string>>;
  revenueGenerated: string;
  setRevenueGenerated: Dispatch<SetStateAction<string>>;
  establishmentDate: string;
  setEstablishmentDate: Dispatch<SetStateAction<string>>;
  currentStage: ICompanyStage;
  setCurrentStage: Dispatch<SetStateAction<ICompanyStage>>;
  image: File;
  setImage: Dispatch<SetStateAction<File>>;
  imageSrc: string;
  setImageSrc: Dispatch<SetStateAction<string>>;
  password?: string;
  setPassword?: Dispatch<SetStateAction<string>>;
  confirmPassword?: string;
  setConfirmPassword?: Dispatch<SetStateAction<string>>;
  validEmail: boolean;
  setActivePage?: Dispatch<SetStateAction<number>>;
  isEdit?: boolean;
  setImageWasModified?: Dispatch<SetStateAction<boolean>>;
  infoHasLoaded?: boolean;
  isAdmin?: boolean;
  hideBackButton?: boolean;
}

const SupplierBasicInformation = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  companyName,
  setCompanyName,
  revenueGenerated,
  setRevenueGenerated,
  establishmentDate,
  setEstablishmentDate,
  currentStage,
  setCurrentStage,
  image,
  setImage,
  imageSrc,
  setImageSrc,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  validEmail,
  setActivePage,
  isEdit,
  setImageWasModified,
  infoHasLoaded,
  isAdmin = false,
  hideBackButton=false,
}: Props) => {
  const history = useHistory()
  const [highlightEmailField, setHighlightEmailField] = useState(false);
  const [highlightFirstNameField, setHighlightFirstNameField] = useState(false);
  const [highlightLastNameField, setHighlightLastNameField] = useState(false);
  const [highlightCompanyField, setHighlightCompanyField] = useState(false);
  const [highlightRevenueGeneratedField, setHighlightRevenueGeneratedField] =
    useState(false);
  const [highlightEstablishmentDateField, setHighlightEstablishmentDateField] =
    useState(false);
  const [highlightCompanyStageField, setHighlightCompanyStageField] =
    useState(false);

  const [invalidCharactersFirstName, setInvalidCharactersFirstName] =
    useState(false);
  const [invalidCharactersLastName, setInvalidCharactersLastName] =
    useState(false);
  const [highlightPasswordField, setHighlightPasswordField] = useState(false);
  const [highlightPictureField, setHighlightPictureField] = useState(false);

  const [highlightConfirmPasswordField, setHighlightConfirmPasswordField] =
    useState(false);
  const [invalidEstablishmentDate, setInvalidEstablishmentDate] =
    useState(false);
  const [isDateOnFuture, setIsDateOnFuture] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const inputRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
    
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isModalOpen]);

  const anyFieldEmpty =
    email.length === 0 ||
    (!isEdit && (password.length === 0 || confirmPassword.length === 0)) ||
    firstName.trim().length === 0 ||
    lastName.trim().length === 0 ||
    companyName.trim().length === 0 ||
    revenueGenerated.trim().length === 0 ||
    establishmentDate.trim().length === 0 ||
    currentStage.id === 0 ||
    imageSrc.trim().length === 0;

  const highlightRequiredFields = () => {
    setHighlightEmailField(email.length === 0);
    setHighlightPasswordField(password.length === 0);
    setHighlightConfirmPasswordField(confirmPassword.length === 0);
    setHighlightFirstNameField(firstName.trim().length === 0);
    setHighlightLastNameField(lastName.trim().length === 0);
    setHighlightCompanyField(companyName.trim().length === 0);
    setHighlightRevenueGeneratedField(revenueGenerated.trim().length === 0);
    setHighlightEstablishmentDateField(establishmentDate.trim().length === 0);
    setHighlightCompanyStageField(currentStage.id === 0);
    setHighlightPictureField(imageSrc.trim().length === 0);
  };

  const handleImageInputClick = (e) => {
    e.preventDefault();
    const imgInput = document.getElementById('featured-image');
    if (imgInput) imgInput.click();
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (isAdmin) return;
    
    try {
      await Auth.forgotPassword(email);
      toast.success(
        `An email with a code will be sent to ${email}`,
        { toastId: 'reset-password-toast' }
      );
    } catch (err) {
      let message = err.message;
      toast.error(message, { toastId: 'reset-password-error' });
    }
  };

  return (
    <>
      <div className={`section mx-lg-5 mt-2 mt-md-4`}>
        <div
          className={`d-flex flex-row text-left text-center-md justify-content-left justify-content-md-center align-items-center mt-md-4 ${
            isEdit ? 'mb-0' : 'mb-5'
          }`}
        >
          <div
            className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
            style={{ height: '35px', width: '35px' }}
          >
            <span className="my-0 font-primary text-black font-20">1</span>
          </div>
          <span className="text-black py-2 ml-3 text-uppercase font-20">
            Basic Information
          </span>
        </div>
        <div className="d-flex justify-content-center">
          <form className="auth-form mt-4 supplier-onboarding-container">
            <div className="row d-flex justify-content-center">
              <div className="form-group col-12 col-md-5">
                <label className="form-label text-black font-primary font-weight-bolder">
                  First Name <span className="text-danger">*</span>
                </label>
                <input
                  className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0 ${
                    highlightFirstNameField || invalidCharactersFirstName
                      ? 'is-invalid'
                      : ''
                  }`}
                  type="text"
                  id="first-name"
                  placeholder="John"
                  maxLength={256}
                  value={firstName}
                  onChange={(event) => {
                    let value = event.target.value;
                    setHighlightFirstNameField(value.trim().length === 0);
                    setInvalidCharactersFirstName(
                      value && (value.includes('"') || value.includes(','))
                    );
                    if (value && value.length > 256)
                      value = value.substr(0, 256);
                    setFirstName(event.target.value);
                  }}
                />
                {highlightFirstNameField && (
                  <div className="input-helper mb-2">
                    <small className={`text-danger`}>
                      <strong>First Name is required</strong>
                    </small>
                  </div>
                )}
                {invalidCharactersFirstName && (
                  <div className="input-helper mb-2">
                    <small className={`text-danger`}>
                      <strong>
                        First Name can not have commas or double quotes
                      </strong>
                    </small>
                  </div>
                )}
              </div>
              <div className="form-group col-12 col-md-5">
                <label className="form-label text-black font-primary font-weight-bolder">
                  Last Name <span className="text-danger">*</span>
                </label>
                <input
                  className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0 ${
                    highlightLastNameField || invalidCharactersLastName
                      ? 'is-invalid'
                      : ''
                  }`}
                  type="text"
                  id="last-name"
                  placeholder="Doe"
                  maxLength={256}
                  value={lastName}
                  onChange={(event) => {
                    let value = event.target.value;
                    setHighlightLastNameField(value.trim().length === 0);
                    setInvalidCharactersLastName(
                      value && (value.includes('"') || value.includes(','))
                    );
                    if (value && value.length > 256)
                      value = value.substr(0, 256);
                    setLastName(event.target.value);
                  }}
                />
                {highlightLastNameField && (
                  <div className="input-helper mb-2">
                    <small className={`text-danger`}>
                      <strong>Last Name is required</strong>
                    </small>
                  </div>
                )}
                {invalidCharactersLastName && (
                  <div className="input-helper mb-2">
                    <small className={`text-danger`}>
                      <strong>
                        Last Name can not have commas or double quotes
                      </strong>
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="row flex justify-content-center">
              <div className="form-group col-md-10">
                <label className="form-label text-black font-primary font-weight-bolder">
                  Email Address <span className="text-danger">*</span>
                </label>
                <input
                  className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0 ${
                    !validEmail || highlightEmailField ? 'is-invalid' : ''
                  }`}
                  type="email"
                  id="email"
                  placeholder="johndoe@email.com"
                  value={email}
                  disabled={urlParams.has('email') || isEdit}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
                {(!validEmail || highlightEmailField) && (
                  <div className="input-helper mb-2">
                    <small className="text-danger">
                      <strong>
                        {email.length === 0
                          ? 'Email is required'
                          : 'Invalid Email'}
                      </strong>
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="row flex justify-content-center">
              <div className="form-group col-md-10">
                <label className="form-label text-black font-primary font-weight-bolder">
                  Company Name <span className="text-danger">*</span>
                </label>
                <input
                  className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                  type="text"
                  id="company"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(event) => {
                    let value = event.target.value;
                    setHighlightCompanyField(value.trim().length === 0);
                    setCompanyName(value);
                  }}
                />
                {highlightCompanyField && (
                  <div className="input-helper mb-2">
                    <small className="text-danger">
                      <strong>Company Name is required</strong>
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="form-group col-md-10">
                <label className="form-label text-black font-primary font-weight-bolder">
                  Revenue Generated <span className="text-danger">*</span>
                </label>
                <select
                  id="revenue-generated-select"
                  className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0"
                  onChange={(e) => setRevenueGenerated(e.target.value)}
                  value={revenueGenerated}
                >
                  <option value={''} hidden>
                    {revenueGenerated.length > 0
                      ? revenueGenerated
                      : 'Select Revenue'}
                  </option>
                  {revenueGeneratedOptions.map((revenue, index) => (
                    <option key={`revenue-generated-${index}`} value={revenue}>
                      {revenue}
                    </option>
                  ))}
                </select>
                {highlightRevenueGeneratedField && (
                  <div className="input-helper mb-2">
                    <small className="text-danger">
                      <strong>Revenue Generated is required</strong>
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="row flex justify-content-center">
              <div className="form-group col-md-10">
                <label className="form-label text-black font-primary font-weight-bolder">
                  When did your company start?{' '}
                  <span className="text-danger">*</span>
                </label>
                <ReactInputDateMask
                  disabled={isEdit && !infoHasLoaded}
                  mask="mm/dd/yyyy"
                  showMaskOnHover={false}
                  className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                  value={establishmentDate}
                  onChange={(value) => {
                    setEstablishmentDate(value === 'mm/dd/yyyy' ? '' : value);
                    setHighlightEstablishmentDateField(value === 'mm/dd/yyyy');

                    const validDate = validator.isDate(value, {
                      format: 'mm/dd/yyyy',
                      strictMode: true,
                      delimiters: ['/'],
                    });

                    setInvalidEstablishmentDate(!validDate);

                    if (validDate) {
                      setIsDateOnFuture(
                        moment().diff(moment(value, 'MM/DD/YYYY')) < 0
                      );
                    } else {
                      setIsDateOnFuture(false);
                    }
                  }}
                />
                {highlightEstablishmentDateField && (
                  <div className="input-helper mb-2">
                    <small className="text-danger">
                      <strong>Company's start date is required</strong>
                    </small>
                  </div>
                )}
                {establishmentDate.length > 0 && invalidEstablishmentDate && (
                  <div className="input-helper mb-2">
                    <small className={`text-danger`}>
                      <strong>Invalid date format</strong>
                    </small>
                  </div>
                )}
                {establishmentDate.length > 0 && isDateOnFuture && (
                  <div className="input-helper mb-2">
                    <small className={`text-danger`}>
                      <strong>
                        Company start date cannot be set in the future
                      </strong>
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="row flex justify-content-center">
              <div className="form-group col-md-10">
                <label className="form-label text-black font-primary font-weight-bolder">
                  What stage is your company in?{' '}
                  <span className="text-danger">*</span>
                </label>
                <div
                  className="input-group pointer-cursor"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <input
                    className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                    type="text"
                    id="currentStage"
                    placeholder="Click here to select the stage your company is in"
                    readOnly
                    value={currentStage.title}
                  />
                  <span className="input-group-append">
                    <div className="input-group-text bg-transparent border-left-0 border-top-0 border-right-0 border-black rounded-0">
                      <i className="icon-gridview text-black font-24" />
                    </div>
                  </span>
                </div>
                {highlightCompanyStageField && (
                  <div className="input-helper mb-2">
                    <small className="text-danger">
                      <strong>Company's stage is required</strong>
                    </small>
                  </div>
                )}
              </div>
            </div>
            <div className="row d-flex justify-content-start px-0 px-md-2 mx-0 mx-md-5 pl-0 pl-md-4">
              <div
                className="row pl-md-2"
                style={{ display: imageSrc ? 'none' : 'flex' }}
              >
                <div className="col-auto">
                  <button
                    className="upload-img-btn text-black my-3"
                    onClick={handleImageInputClick}
                  >
                    <i className="icon-upload-cloud font-48 text-black"></i>
                    <span className="sr-only">Profile Picture</span>
                  </button>
                  <input
                    id="featured-image"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={inputRef}
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        const imageFile = e.target.files[0];
                        const reader = new FileReader();
                        reader.onload = (event) => {
                          setImageSrc(
                            event.target.result
                              ? event.target.result.toString()
                              : ''
                          );
                        };
                        if (fileIsImage(imageFile.name)) {
                          reader.readAsDataURL(imageFile);
                          setImage(imageFile);
                          isEdit && setImageWasModified(true);
                        } else {
                          toast.error(
                            'File must be a valid image (JPG, GIF, PNG, JPEG)'
                          );
                        }
                      } else {
                        inputRef.current.value = '';
                        setImageSrc('');
                        setImage(null);
                        setHighlightPictureField(true);
                        isEdit && setImageWasModified(true);
                      }
                    }}
                  />
                  {highlightPictureField && (
                    <div className="input-helper mb-2">
                      <small className={`text-danger`}>
                        <strong>Profile Picture is required</strong>
                      </small>
                    </div>
                  )}
                </div>
                <div className="col align-items-center d-flex">
                  <span className="text-muted">
                    Upload Profile Picture<span className="text-danger">*</span>
                  </span>
                </div>
              </div>

              {imageSrc && (
                <div className="row my-3">
                  <div className="col-auto">
                    <div className="upload-img-preview my-3">
                      <button
                        className="remove btn btn-link text-white text-decoration-none"
                        onClick={() => {
                          inputRef.current.value = '';
                          setImage(null);
                          setHighlightPictureField(true);
                          setImageSrc('');
                          isEdit && setImageWasModified(true);
                        }}
                      >
                        <i className="icon-close-x font-16 text-white" />
                        <span className="sr-only">Remove Image</span>
                      </button>
                      <button
                        className="replace btn text-white"
                        onClick={handleImageInputClick}
                      >
                        <i className="icon-refresh font-28 text-white" />
                        <span className="sr-only">Replace Image</span>
                      </button>
                      <LazyLoadImage src={imageSrc} alt="" />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isEdit && !isAdmin && (
              <>
                <div
                  className={`d-flex flex-row text-left text-center-md justify-content-left justify-content-md-center align-items-center mt-md-4 mb-3`}
                >
                  <div
                    className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
                    style={{ height: '35px', width: '35px' }}
                  >
                    <span className="my-0 font-primary text-black font-20">
                      2
                    </span>
                  </div>
                  <span className="text-black py-2 ml-3 text-uppercase font-20">
                    Password
                  </span>
                </div>
                <div className="section password-reset d-flex justify-content-center mb-4">
                  <div className="col-12 col-md-6 text-black px-0 px-md-3">
                    <p className="font-weight-bold mb-1">Password</p>
                    <p>
                      Press the link to request a password reset email link.
                    </p>
                    <div className="d-flex justify-content-center">
                      <p
                        className="profile-reset-password-href mb-0 pointer-cursor"
                        onClick={handlePasswordReset}
                      >
                        Request Password Reset
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {!isEdit && (
              <div className="row d-flex justify-content-center">
                <div className="form-group col-md-10">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Create a New Password
                  </label>
                  <br />
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0 ${
                      (password.length !== 0 && password.length < 6) ||
                      highlightPasswordField
                        ? 'is-invalid'
                        : ''
                    }`}
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(event) => {
                      setHighlightPasswordField(
                        event.target.value.length === 0
                      );
                      setPassword(event.target.value);
                    }}
                  />
                  {password.length !== 0 && password.length < 6 && (
                    <div className="input-helper mb-2">
                      <small className={`text-danger`}>
                        <strong>
                          Password must have at least 6 characters
                        </strong>
                      </small>
                    </div>
                  )}
                  {highlightPasswordField && (
                    <div className="input-helper mb-2">
                      <small className={`text-danger`}>
                        <strong>Password is required</strong>
                      </small>
                    </div>
                  )}
                </div>
              </div>
            )}
            {!isEdit && (
              <div className="row d-flex justify-content-center">
                <div className="form-group col-md-10">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <input
                    required
                    className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0 ${
                      (confirmPassword && password !== confirmPassword) ||
                      highlightConfirmPasswordField
                        ? 'is-invalid'
                        : ''
                    }`}
                    type="password"
                    id="confirm-password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(event) => {
                      setHighlightConfirmPasswordField(
                        event.target.value.length === 0
                      );
                      setConfirmPassword(event.target.value);
                    }}
                  />
                  {confirmPassword.length !== 0 &&
                    password !== confirmPassword && (
                      <div className="input-helper mb-2">
                        <small className={`text-danger`}>
                          <strong>Passwords don't match</strong>
                        </small>
                      </div>
                    )}
                  {highlightConfirmPasswordField && (
                    <div className="input-helper mb-2">
                      <small className={`text-danger`}>
                        <strong>Password confirmation is required</strong>
                      </small>
                    </div>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
        {!isEdit && (
          <div className="d-flex justify-content-center flex-column-reverse flex-md-row">
            {!hideBackButton && (
              <button
                className="btn-lg btn btn-white text-black font-primary text-uppercase rounded-5 mt-4 px-4 mr-1"
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo(0, 0);
                  history.replace('/role-selection');
                }}
              >
                Go back
              </button>
            )}
            <button
              className="btn btn-black btn-lg text-uppercase text-pinky rounded-5 mt-4 px-5"
              disabled={
                anyFieldEmpty ||
                !validEmail ||
                password !== confirmPassword ||
                invalidCharactersFirstName ||
                invalidCharactersLastName ||
                (establishmentDate.length > 0 &&
                  (invalidEstablishmentDate || isDateOnFuture))
              }
              type="button"
              onClick={(e) => {
                e.preventDefault();
                if (anyFieldEmpty) {
                  highlightRequiredFields();
                  return;
                }
                window.scrollTo(0, 0);
                setActivePage(2);
              }}
            >
              NEXT
            </button>
          </div>
        )}
      </div>
      <CompanyStageModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
        selectedStage={currentStage}
        handleStageSelection={setCurrentStage}
      />
    </>
  );
};

export default SupplierBasicInformation;
