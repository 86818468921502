import { types } from '../actions/connection';
import { Connection, ConnectionWithProfile } from '../types/Connection';

export interface ConnectionState {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  requestFinished: boolean;
  connections: Connection[] | ConnectionWithProfile[];
  connection: ConnectionWithProfile;
  total: number;
  toastMessage: string;
}

const initialState: ConnectionState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  connections: [],
  connection: undefined,
  total: 0,
  requestFinished: false,
  toastMessage: '',
};

export default function ConnectionReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_CONNECTION_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
      };
    case types.ADD_CONNECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: true,
      };
    case types.ADMIN_ADD_CONNECTION_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        toastMessage: ''
      };
    case types.ADMIN_ADD_CONNECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: true,
        toastMessage: action.payload.toastMessage
      };
    case types.CHANGE_CONNECTION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        toastMessage: '',
      };
    case types.CHANGE_CONNECTION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: true,
        toastMessage: action.payload.toastMessage,
      };
    case types.ADMIN_ADD_CONNECTION_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: 'An active connection between the users already exists',
        requestFinished: true,
      };
    case types.ADD_CONNECTION_FAILURE:
    case types.CHANGE_CONNECTION_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
        requestFinished: true,
      };
    case types.RESET_CONNECTION_ERRORS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        toastMessage: '',
      };
    case types.GET_CONNECTION_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        connections: [],
      };
    case types.GET_CONNECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        requestFinished: true,
        errorMessage: '',
        connections: action.payload.connections,
        total: action.payload.total,
      };
    case types.GET_CONNECTION_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        requestFinished: true,
        errorMessage: action.payload,
      };
    case types.GET_CONNECTION_BY_IDS_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        connection: null,
      };
    case types.GET_CONNECTION_BY_IDS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        connection: action.payload,
      };
    case types.GET_CONNECTION_BY_IDS_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        requestFinished: true,
        errorMessage: action.payload,
      };
    case types.GET_ALL_ACTIVE_CONNECTIONS_FOR_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        connections: [],
      };
    case types.GET_ALL_ACTIVE_CONNECTIONS_FOR_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        connections: action.payload.connections,
        total: action.payload.total,
      };
    case types.GET_ALL_ACTIVE_CONNECTIONS_FOR_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
        requestFinished: true
      };
    case types.SORT_CONNECTIONS_FOR_TABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        connections: []
      };
    }
    case types.SORT_CONNECTIONS_FOR_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        connections: action.payload.connections,
      };
    case types.SORT_CONNECTIONS_FOR_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: 'An error occured while sorting the data',
      };
    default:
      return state;
  }
}
