import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useHistory } from 'react-router-dom';
import emptyStateProfilePic from '../../styles/img/empty-state-profile-pic.svg';

interface Props {
  handleRequestConnection: () => void;
  name: string;
  email: string;
  id: string;
  state: string;
  city: string;
  industries: string[];
  fieldOfOpportunity: string[];
  grid: boolean;
  image: string;
  supplierId: string;
  isSupplier: boolean;
  connectionStatus: number;
  hideButton: boolean;
  requestMeetingSearch?: (
    receiverName: string,
    receiverEmail: string,
    recipientId: string
  ) => void;
}

export const SearchResult = ({
  handleRequestConnection,
  name,
  email,
  id,
  state,
  city,
  industries,
  fieldOfOpportunity,
  grid,
  image,
  supplierId,
  isSupplier,
  connectionStatus,
  hideButton,
  requestMeetingSearch,
}: Props) => {
  const history = useHistory();
  const [buttonClicked, setButtonClicked] = useState(false);

  const renderFieldOfOpportunities = () => {
    let fields = [...fieldOfOpportunity];
    if (fields.length > 4) {
      fields = fields.slice(0, 4);
    }
    return fields.map((op, index) => {
      return (
        <div className="col-auto mx-0 px-0" key={`opportunity-${op}-${index}`}>
          <label className="text-black font-primary font-weight-bold mr-1 font-14">
            #{op}
          </label>
        </div>
      );
    });
  };

  const getCorrespondingButton = () => {
    if (hideButton) {
      return null;
    }

    if (connectionStatus === 1) {
      return (
        <>
          <button
            className={`btn btn-black btn-md text-white rounded-5 mt-2 font-weight-normal ${
              grid ? 'mx-2 accept-deny-connection' : ''
            } `}
            style={{ fontSize: '0.875rem' }}
            type="button"
            disabled={true}
          >
            <label className="font-primary font-weight-normal d-flex justify-content-center align-items-center my-0">
              <i className="icon-link text-white mr-2 font-16 align-middle " />
              Pending
            </label>
          </button>
        </>
      );
    }

    if (connectionStatus === 2) {
      return (
        <>
          {' '}
          <button
            className="btn btn-black btn-md px-0 accept-deny-connection font-13"
            onClick={() => requestMeetingSearch(name, email, id)}
          >
            Request a Meeting
          </button>
        </>
      );
    }
    return (
      <>
        <button
          className={`btn btn-black btn-md text-white rounded-5 mt-2 font-weight-normal ${
            grid ? 'mx-2 accept-deny-connection' : ''
          } `}
          style={{ fontSize: '0.875rem' }}
          type="button"
          disabled={buttonClicked}
          onClick={() => {
            setButtonClicked(true);
            handleRequestConnection();
          }}
        >
          <label className="font-primary d-flex justify-content-center align-items-center my-0 clickable">
            <i className="icon-link text-white mr-2 font-16 align-middle " />
            Connect with me
          </label>
        </button>
      </>
    );
  };

  const validateField = (data: any) => {
    return typeof data !== 'undefined' && data && data.length > 0;
  };

  const profileRoute = `/${isSupplier ? 'supplier' : 'champion'}/${supplierId}`;

  if (grid) {
    return (
      <div
        className="result-card col-md-16 my-2 pb-4 mx-4 mx-md-1 mx-lg-3"
        id={supplierId}
      >
        <div className="row d-flex justify-content-center mx-0 mb-2">
          <div className="col-12 text-center font-primary result-banner mb-4 py-2">
            <h3 className="font-primary text-black font-20 font-weight-bold user-cardlabel my-2">
              {isSupplier ? 'Supplier' : 'Champion'}
            </h3>
          </div>
          <div
            className="connection avatar-opener d-flex justify-content-center align-items-center pointer-cursor"
            onClick={() => {
              history.push(profileRoute);
            }}
          >
            <div className="medium-avatar grid-item">
              <LazyLoadImage
                referrerPolicy="no-referrer"
                src={image || emptyStateProfilePic}
                alt=""
                effect="blur"
              />
            </div>
          </div>
        </div>

        {validateField(name) && (
          <div className="row d-flex justify-content-center mx-1 py-2 px-2">
            <span
              onClick={() => {
                history.push(profileRoute);
              }}
              className="h4 text-black font-primary font-weight-bolder text-center my-0 clickable"
            >
              {name}
            </span>
          </div>
        )}

        {validateField(state) && (
          <div className="row d-flex justify-content-center mx-1">
            <label className="text-black font-primary d-flex align-items-center font-20">
              <i className="icon-locationprofile font-32"></i>{' '}
              {city && city.length > 0 ? city + ', ' : ''} {state}
            </label>
          </div>
        )}

        {validateField(industries) && (
          <div className="row d-flex justify-content-center my-1 mx-1">
            {industries.map((industry, index) => {
              return (
                <div
                  className="col-auto mx-1 px-0"
                  key={`industry-${industry}-${index}`}
                >
                  <label className="area-label text-black font-primary font-18 text-center">
                    {industry}
                  </label>
                </div>
              );
            })}
          </div>
        )}

        {validateField(fieldOfOpportunity) && (
          <div className="row d-flex justify-content-center mx-1">
            {renderFieldOfOpportunities()}
            {fieldOfOpportunity.length > 4 && (
              <div className="col-auto mx-0 px-0">
                <label className="text-black font-primary font-weight-bold mr-1 font-14">
                  and more!
                </label>
              </div>
            )}
          </div>
        )}

        <div className="row d-flex justify-content-center align-items-center mt-1 mx-2 px-4">
          {getCorrespondingButton()}
        </div>

        <div className="row d-flex justify-content-center mt-2">
          <span
            onClick={() => {
              history.push(profileRoute);
            }}
            className="font-primary text-black font-18 clickable"
            style={{ textDecoration: 'underline' }}
          >
            See more
          </span>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="result-card row my-2 my-md-3 pb-4 mx-md-3"
        id={supplierId}
      >
        <div className="col-12 text-center font-primary result-banner mb-4 py-2">
          <h3 className="font-primary text-black font-20 font-weight-bold user-cardlabel my-2">
            {isSupplier ? 'Supplier' : 'Champion'}
          </h3>
        </div>
        <div className="col-5 col-md-3">
          <div
            className="connection avatar-opener d-flex justify-content-center align-items-center pointer-cursor"
            onClick={() => {
              history.push(profileRoute);
            }}
          >
            <div className="medium-avatar grid-item result-avatar">
              <LazyLoadImage
                referrerPolicy="no-referrer"
                src={image || emptyStateProfilePic}
                alt=""
                effect="blur"
              />
            </div>
          </div>
        </div>
        <div className="col col-md-9">
          <div className="row mr-2">
            {validateField(name) && (
              <span
                onClick={() => {
                  history.push(profileRoute);
                }}
                className="h4 text-black font-primary font-weight-bolder d-flex align-items-center clickable"
              >
                {name}
              </span>
            )}
            {validateField(state) && (
              <label className="text-black font-primary d-flex align-items-center font-20">
                <i className="icon-locationprofile font-32"></i>{' '}
                {city.length > 0 ? city + ', ' : ''} {state}
              </label>
            )}
          </div>
          {validateField(industries) && (
            <div className="row my-1">
              {industries.map((industry, index) => {
                return (
                  <div
                    className="col-auto pl-0 ml-0"
                    key={`industry-${industry}-${index}`}
                  >
                    <label className="area-label text-black font-primary font-18">
                      {industry}
                    </label>
                  </div>
                );
              })}
            </div>
          )}
          {validateField(fieldOfOpportunity) && (
            <div className="row">
              {renderFieldOfOpportunities()}
              {fieldOfOpportunity.length > 4 && (
                <div className="col-auto mx-0 px-0">
                  <label className="text-black font-primary font-weight-bold mr-1 font-14">
                    and more!
                  </label>
                </div>
              )}
            </div>
          )}
          <div className="row">
            <div className="col-8 px-0">{getCorrespondingButton()}</div>
          </div>
          <div className="row mt-3">
            <div className="col-4 d-flex align-items-center px-0">
              <span
                onClick={() => {
                  history.push(profileRoute);
                }}
                className="font-primary text-black font-18 clickable"
                style={{ textDecoration: 'underline' }}
              >
                See more
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
