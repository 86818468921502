import React, { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';

interface Props {
  setActivePage: Dispatch<SetStateAction<number>>;
}

const SupplierConfirmationMessage = ({ setActivePage }: Props) => {
  const { requestFinished, hasErrors } = useSelector(
    (state: RootState) => state.supplier
  );

  if (requestFinished && hasErrors) setActivePage(3);

  return (
    requestFinished &&
    !hasErrors && (
      <>
        <div className="section mx-0 mt-5">
          <div className="row d-flex justify-content-center">
            <h1 className="font-weight-bold font-primary text-black text-center">
              Account Created!
            </h1>
          </div>
          <div className="row d-flex justify-content-center">
            <p className="text-black text-center">
              Your new BSN account has been successfully created.
            </p>
          </div>
          <div className="row d-flex justify-content-center">
            <p className="text-black text-center">
              Once you verify your account and the BGV Staff approves your request you'll have access to your profile.
            </p>
          </div>
        </div>
      </>
    )
  );
};

export default SupplierConfirmationMessage;
