import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { IAuth } from '../../types/CognitoAuth';
import { CustomerOpportunities } from '../../types/CustomerInfo';
import { IFieldOfOpportunity } from '../../types/SupplierInfo';
import FieldOfOpportunityCard from '../FieldOfOpportunityCard/FieldOfOpportunityCard';
interface Props {
  fieldsOfOpportunity: IFieldOfOpportunity;
  setFieldsOfOpportunity: Dispatch<SetStateAction<IFieldOfOpportunity>>;
  handleSignup: (event: any) => void;
  authData?: IAuth;
}

const AreasOfOpportunity = ({
  fieldsOfOpportunity,
  setFieldsOfOpportunity,
  handleSignup,
  authData,
}: Props) => {
  const history = useHistory();

  return (
    <>
      <div className="d-flex justify-content-left justify-content-md-center">
        <h2 className="text-black font-primary font-weight-bolder">
          What are you bringing to the table?
        </h2>
      </div>
      <div className="d-flex flex-row text-center justify-content-left justify-content-md-center align-items-center mt-3 mt-md-5">
        <div
          className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center mr-2"
          style={{ height: '35px', width: '35px' }}
        >
          <span className="my-0 font-primary text-black font-20 px-3 ">3</span>
        </div>
        <span className="text-black py-2 text-uppercase font-20 text-left ml-2">
          Select Areas of Opportunity
        </span>
      </div>
      <div className="onboarding section-description text-black text-center mx-n4 mx-md-0 py-3 mt-3 mt-md-5">
        <p className="mb-0 px-2">
          {' '}
          <strong className="font-italic">
            Select your areas of opportunity.{' '}
          </strong>
          This will help us narrow down results and provide you with the right
          connections. (You can select more than one)
        </p>
      </div>
      <div className="row px-0 py-4 d-flex justify-content-center align-items-center">
        {CustomerOpportunities.map((opportunity, index) => (
          <FieldOfOpportunityCard
            key={`${opportunity.name}-${index}`}
            fieldName={opportunity.name}
            fieldChecked={
              opportunity.name === 'Procurement'
                ? fieldsOfOpportunity.bigBoxRetailer
                : fieldsOfOpportunity[opportunity.attribute]
            }
            icon={opportunity.icon}
            description={opportunity.description}
            handleClick={() => {
              if (opportunity.name === 'Procurement') {
                const prevBigBox = fieldsOfOpportunity.bigBoxRetailer;
                const prevBoutique = fieldsOfOpportunity.smallBoutiques;
                setFieldsOfOpportunity({
                  ...fieldsOfOpportunity,
                  bigBoxRetailer: !prevBigBox,
                  smallBoutiques: !prevBoutique,
                });
              } else if (opportunity.name === 'Partnerships') {
                const prevPartnerships = fieldsOfOpportunity.partnerships;
                const prevInternship = fieldsOfOpportunity.internship;
                setFieldsOfOpportunity({
                  ...fieldsOfOpportunity,
                  partnerships: !prevPartnerships,
                  internship: !prevInternship,
                });
              } else {
                const prevVal = fieldsOfOpportunity[opportunity.attribute];
                setFieldsOfOpportunity({
                  ...fieldsOfOpportunity,
                  [opportunity.attribute]: !prevVal,
                });
              }
            }}
          />
        ))}
      </div>
      <div className="d-flex flex-column-reverse flex-md-row justify-content-center">
        <button
          className="btn modal-cancel-btn text-black btn-lg text-uppercase mt-4 rounded-5 px-5 mx-1 mx-md-3"
          onClick={() => {
            history.goBack();
          }}
        >
          Go back
        </button>
        <button
          className="btn btn-black btn-lg text-uppercase text-pinky rounded-5 mt-0 mt-md-4 px-5 mx-1 mx-md-3"
          disabled={!Object.values(fieldsOfOpportunity).some((field) => field)}
          onClick={(event) => {
            handleSignup(event);
          }}
        >
          {`Finish & Create Account`}
        </button>
      </div>
    </>
  );
};

export default AreasOfOpportunity;
