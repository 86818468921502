import { types } from '../actions/notification';
import { NotificationWithSenderProfile } from '../types/NotificationWithSenderProfile';

export interface NotificationState {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  requestFinished: boolean;
  notifications: NotificationWithSenderProfile[];
  total: number;
  toastMessage: string;
}

const initialState: NotificationState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  notifications: [],
  total: 0,
  requestFinished: false,
  toastMessage: '',
};

export default function NotificationReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PENDING_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        notifications: [],
        total: 0
      };
    case types.GET_PENDING_NOTIFICATIONS_SUCCESS:
        return {
            ...state,
            loading: false,
            hasErrors: false,
            errorMessage: '',
            requestFinished: true,
            notifications: action.payload.notifications,
            total: action.payload.total
        };
    case types.GET_PENDING_NOTIFICATIONS_FAILURE:
        return {
            ...state,
            loading: false,
            hasErrors: true,
            errorMessage: '',
            requestFinished: true,
        };
    case types.RESET_NOTIFICATIONS_ERRORS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        toastMessage: '',
      };
    default:
      return state;
  }
}
