import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { authContext } from '../../components/Authentication/CognitoContextProvider';
import validator from 'validator';
import { toast } from 'react-toastify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { Helmet } from 'react-helmet';
import Google from '../../styles/img/g-icon.svg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  // const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { setAuthData } = useContext(authContext);
  // const [cookies, setCookies, removeCookie] = useCookies(['rememberedEmail']);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!validator.isEmail(email)) {
      toast.error('Invalid email format.');
      return;
    }
    try {
      const user = await Auth.signIn(email, password);
      setAuthData(user);
      // if (isChecked) {
      //   setCookies('rememberedEmail', email, { path: '/' });
      // } else {
      //   removeCookie('rememberedEmail');
      // }

      const redirectUrl = localStorage.getItem('redirect-url');
      if (redirectUrl) {
        localStorage.removeItem('redirect-url');
        history.push(redirectUrl);
      } else {
        history.push('/');
      }
    } catch (err) {
      if (err.code === 'PasswordResetRequiredException') {
        try {
          await Auth.forgotPassword(email);
          toast.success(
            `An email with a password reset link has been sent to your email ${email}!`,
            { toastId: 'password-reset-retry' }
          );
        } catch (err) {
          toast.error(
            'An error occured while attempting to resend the password reset link, try to log in later or contact support',
            { toastId: 'password-reset-retry-failure' }
          );
        }
      } else if (err.code === 'UserNotConfirmedException') {
        try {
          await Auth.resendSignUp(email);
          toast.success(
            `A new verification link has been sent to your email ${email}!`,
            { toastId: 'verification-resend' }
          );
        } catch (err) {
          toast.error(
            'An error occured while attempting to resend the verification link, try to log in later or contact support',
            { toastId: 'verification-resend-error' }
          );
        }
      } else {
        toast.error(
          'Unable to login. Please check your credentials or contact support if the problem persists.'
        );
      }
    }
  };

  // const handleCheckboxChange = (event) => {
  //   setIsChecked(event.target.checked);
  // };

  // useEffect(() => {
  //   const { rememberedEmail } = cookies;
  //   if (rememberedEmail) {
  //     setEmail(rememberedEmail);
  //   }
  // }, [cookies]);

  return (
    <>
      <Helmet>
        <title>BSN - Login</title>
      </Helmet>
      <main className="body-min-h90 container auth-container py-5 px-4">
        <h1 className="h2 text-black text-center font-primary font-weight-bolder">
          Log In
        </h1>
        <form className="auth-form mt-4">
          <div className="input-group input-group-lg mb-4">
            <input
              className="form-control  border-left-0 border-top-0 border-right-0 rounded-0"
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
          <div className="input-group input-group-lg mb-4">
            <input
              className="form-control border-left-0 border-top-0 border-right-0 rounded-0"
              type="password"
              placeholder="Password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
          </div>

          <div className="input-group input-group-lg mb-4 form-check">
            <div className="row w-100 mx-0 justify-content-spaced">
              <div className="col text-right px-0">
                <div
                // className="text-black font-weight-bold clickable link"
                // onClick={() => history.push('/reset-password', { email })}
                >
                  <span
                    className="text-black font-weight-bold clickable link"
                    onClick={() => history.push('/reset-password', { email })}
                  >
                    Forgot password?
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/*
          <div className="input-group input-group-lg mb-3 form-check">
          {/* <div className="input-group input-group-lg mb-3 form-check">
            <div className="row w-100 mx-0 justify-content-spaced">
              <div className="col-auto px-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberme"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label text-dark"
                  htmlFor="rememberme"
                >
                  Remember me!
                </label>
              </div>
            </div>
          </div> */}
          <button
            className="btn btn-black btn-block btn-lg text-uppercase text-pinky rounded-5"
            onClick={handleLogin}
          >
            Log In
          </button>
        </form>
        <div className="divider d-flex my-4">
          <div className="black-line mr-3"></div>
          <span className="d-inline-block px-2 text-black">or</span>
          <div className="black-line ml-3"></div>
        </div>
        <section className="alternate-auth">
          <div className="google-auth my-4">
            <div className="btn-outline text-reset btn-block text-decoration-none">
              <span className="d-flex align-items-center justify-content-center">
                <div
                  className="google-signin-wrapper d-flex align-items-center pointer-cursor"
                  onClick={() =>
                    Auth.federatedSignIn({
                      provider: CognitoHostedUIIdentityProvider.Google,
                    })
                  }
                >
                  <img
                    className="google-icon mr-4 pl-2"
                    src={Google}
                    alt="google-icon"
                  />
                  <span className="google-sso-text text-black pr-2">
                    SIGN IN WITH GOOGLE{' '}
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div className="signup-links text-center text-black mt-3">
            <span>New around here? </span>
            <Link className="text-black" to="/role-selection">
              <strong>Sign Up</strong>
            </Link>
          </div>
        </section>
      </main>
    </>
  );
};

export default Login;
