import React from 'react';
import HomeBreadcrumb from '../HomeBreadcrumb/HomeBreadcrumb';
import { useHistory } from 'react-router-dom';

interface Props {
  routes?: Array<{ route: string; label: string; isHome?: boolean }>;
  customClasses?: string;
  goBack: Function;
}

const Breadcrumbs = ({ routes, goBack, customClasses }: Props) => {
  const history = useHistory();

  const buildBreadcrumbs = () => {
    return routes.map((route, index) => (
      <li className="breadcrumb-item" key={index}>
        <span
          className={index === routes.length - 1 ? 'crumb' : 'crumb-link'}
          onClick={() => {
            history.push(route.route);
          }}
        >
          {route.isHome ? <HomeBreadcrumb /> : route.label}
        </span>
      </li>
    ));
  };

  return (
    <div className={customClasses || 'navigator mt-4 mb-4 px-3 px-md-0'}>
      <div className="row mx-0 align-items-center">
        <div className="col-auto px-0 align-items-center">
          <button className="btn back-btn-chip text-dark" onClick={() => goBack()}>
            <i className="icon-chevron-left font-24" />
          </button>
        </div>
        {routes && (
          <div className="col align-items-center">
            <nav className="bgv" aria-label="breadcrumb">
              <ol className="breadcrumb my-0 pt-1 pb-0">
                {buildBreadcrumbs()}
              </ol>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
