import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import {
  getUsersPendingApproval,
  resetUserErrors,
  updateAdmissionStatus,
} from "../../actions/user";
import { DenyUserModal } from "../../components/Modals/DenyUserModal";
import UltimatePagination from "../../components/Pagination/Pagination";
import { RequestCard } from "../../components/RequestCard/RequestCard";
import { RootState } from "../../reducers";
import { UserRolesData } from "../../types/User";
import { useHistory } from "react-router-dom";

const UserApproval = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState(1);
  const [activateToast, setActivateToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userReqData, setUserReqData] = useState(undefined);
  const {
    usersPendingApproval,
    totalUsersPendingApproval: total,
    loading,
    requestFinished,
    hasErrors,
    errorMessage,
  } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    let page = 1;
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('page')) {
      page = parseInt(urlParams.get('page'));
      setActivePage(page);
    } 
    dispatch(getUsersPendingApproval({ page }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersPendingApproval]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  const changePage = (index: number) => {
    history.replace(`/admin/users-management/users-requests?page=${index}`)
    window.scrollTo(0, 0);
    setActivePage(index);
    dispatch(getUsersPendingApproval({ page: index }));
  };

  const renderPagination = () => {
    return (
      total > 10 && (
        <nav className="table-pagination my-3 px-4 px-lg-0">
          <UltimatePagination
            hideFirstAndLastPageLinks={true}
            siblingPagesRange={1}
            boundaryPagesRange={1}
            totalPages={Math.ceil(total / 10)}
            currentPage={activePage}
            onChange={changePage}
          />
        </nav>
      )
    );
  };

  const handleChangeAdmissionStatus = (
    newStatus: number,
    id: number,
    role: string,
    message: string
  ) => {
    const payload = {
      id,
      newStatus,
      role,
    };
    setToastMessage(message);
    setActivateToast(true);
    dispatch(updateAdmissionStatus(payload));
  };

  const handleOpenModal = (reqData: any) => {
    setUserReqData(reqData);
    setToastMessage(`${reqData.name}'s profile has been denied`);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDenyRequest = () => {
    const { id, newStatus, role } = userReqData;
    const payload = {
      id,
      newStatus,
      role,
    };
    history.replace(`/admin/users-management/users-requests?page=1`);
    setActivePage(1);
    setActivateToast(true);
    dispatch(updateAdmissionStatus(payload));
  };

  const showToast = () => {
    if (requestFinished && activateToast) {
      if (hasErrors) {
        toast.error(errorMessage);
      } else {
        toast.success(`${toastMessage}.`, {
          toastId: "approve-deny-user-request",
        });
        dispatch(resetUserErrors());
      }
      setToastMessage("");
      setActivateToast(false);
      dispatch(resetUserErrors());
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>BSN - Users Requests</title>
      </Helmet>
      <main className="body-min-h90 py-1 py-sm-5 px-1 px-sm-5">
        <div className="row pt-5 px-md-5 mx-0">
          <div className="col-12 px-0 px-md-3">
            <h1 className="font-primary text-black font-weight-bold px-2">
              Users Requests
            </h1>

            {loading ? (
              <div className="d-flex justify-content-center mx-0 connection-width-hack mt-5">
                <div className="pl-3 mt-5 d-flex justify-content-center">
                  <FadeLoader color={"#F8DCD4"} />
                </div>
              </div>
            ) : total > 0 ? (
              <div className="row px-0 px-lg-5 pt-3 mx-0">
                {usersPendingApproval.map((user) => (
                  <RequestCard
                    key={user.sub}
                    userData={user}
                    userRoleData={
                      user.isSupplier
                        ? UserRolesData.SUPPLIER
                        : UserRolesData.CHAMPION
                    }
                    handleChangeAdmissionStatus={handleChangeAdmissionStatus}
                    openModal={handleOpenModal}
                  />
                ))}
              </div>
            ) : (
              <div className="d-flex justify-content-center mx-0 connection-width-hack mt-5">
                <div className="mt-5 d-flex justify-content-center text-center">
                  <p className="font-primary text-black font-20">
                    There are no approval requests to show.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          {renderPagination()}
        </div>
        {showToast()}
        <DenyUserModal
          isModalOpen={isModalOpen}
          closeModal={handleCloseModal}
          onConfirm={handleDenyRequest}
          role={userReqData?.role}
          name={userReqData?.name}
        />
      </main>
    </>
  );
};

export default UserApproval;
