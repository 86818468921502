import React from 'react';

interface Props {
  fieldChecked: boolean;
  fieldName: string;
  icon: string;
  description?: string;
  handleClick: () => void;
}

const FieldOfOpportunityCard = ({
  fieldChecked,
  fieldName,
  icon,
  description,
  handleClick,
}: Props) => {
  return (
    <div
      className={`col-5 col-md-3 card opportunity-card no-border text-center text-black mx-2 my-2 px-0 clickable bg-pinky`}
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <div
        className="card-body"
        style={{ minHeight: '176px' /*minWidth: '150px'*/ }}
      >
        <i className={`${icon} font-48`}></i>
        <p className="text-uppercase font-weight-bold foo-name mb-0">
          {fieldName}
        </p>
        {description && (
          <div>
            <small>{description}</small>
          </div>
        )}
        {fieldChecked && <i className="icon-check font-28"></i>}
      </div>
    </div>
  );
};

export default FieldOfOpportunityCard;
