import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { v4 as uuidv4 } from 'uuid';
import { ITeamMember, shippingTimes } from '../../types/SupplierInfo';
import { fileIsImage } from '../../utils/validations';

interface Props {
  isProduct: boolean;
  setIsProduct: Dispatch<SetStateAction<boolean>>;
  isService: boolean;
  setIsService: Dispatch<SetStateAction<boolean>>;
  industries: Array<string>;
  handleIndustryChange: (industry: string) => void;
  handleSelectChange: (event: any, isCity?: boolean) => void;
  allCities: any[];
  city: string;
  allStates: any[];
  state: string;
  markets: string;
  setMarkets: Dispatch<SetStateAction<string>>;
  funding: string;
  setFunding: Dispatch<SetStateAction<string>>;
  orderQuantity: string;
  setOrderQuantity: Dispatch<SetStateAction<string>>;
  shippingTime: string;
  setShippingTime: Dispatch<SetStateAction<string>>;
  productUpdate: string;
  setProductUpdate: Dispatch<SetStateAction<string>>;
  sellingCycle: string;
  setSellingCycle: Dispatch<SetStateAction<string>>;
  teamMembers: Array<ITeamMember>;
  setTeamMembers: Dispatch<SetStateAction<Array<ITeamMember>>>;
  fieldOfOpportunity: {
    smallBoutiques: boolean;
    mentorships: boolean;
    bigBoxRetailer: boolean;
    corporations: boolean;
    partnerships: boolean;
    investment: boolean;
    internship: boolean;
    openToWork: boolean;
  };
  handleFieldOfOpportunity: any;
  communityVisibility: boolean;
  setCommunityVisibility: Dispatch<SetStateAction<boolean>>;
  hiringVisibility: boolean;
  setHiringVisibility: Dispatch<SetStateAction<boolean>>;
  investmentVisibility: boolean;
  setInvestmentVisibility: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  handleSubmit: any;
  isEdit?: boolean;
  setActivePage?: Dispatch<SetStateAction<number>>;
  isAdmin?: boolean;
}

const SupplierSearchSettings = ({
  isProduct,
  setIsProduct,
  isService,
  setIsService,
  industries,
  handleIndustryChange,
  handleSelectChange,
  allCities,
  city,
  allStates,
  state,
  markets,
  setMarkets,
  funding,
  setFunding,
  orderQuantity,
  setOrderQuantity,
  shippingTime,
  setShippingTime,
  productUpdate,
  setProductUpdate,
  sellingCycle,
  setSellingCycle,
  teamMembers,
  setTeamMembers,
  fieldOfOpportunity,
  handleFieldOfOpportunity,
  communityVisibility,
  setCommunityVisibility,
  hiringVisibility,
  setHiringVisibility,
  investmentVisibility,
  setInvestmentVisibility,
  disabled,
  handleSubmit,
  isEdit,
  setActivePage,
  isAdmin = false,
}: Props) => {
  const foo: Array<{ name: string; attribute: string }> = [
    { name: 'Small Boutiques', attribute: 'smallBoutiques' },
    { name: 'Mentorships', attribute: 'mentorships' },
    { name: 'Big Box Retailer', attribute: 'bigBoxRetailer' },
    { name: 'Corporations', attribute: 'corporations' },
    { name: 'Partnerships', attribute: 'partnerships' },
    { name: 'Investment', attribute: 'investment' },
    { name: 'Internship', attribute: 'internship' },
    { name: 'Open To Work', attribute: 'openToWork' },
  ];

  const buttonPillsText = [
    'EdTech',
    'App Development',
    'Professional Development',
    'Wellness',
    'Arts',
    'Makers',
    'Beauty',
    'Design',
    'FinTech',
    'Fashion / Apparel',
    'Esports / Egaming',
    'Hospitality',
    'Restaurant',
    'Share Economy',
    'InsurTech',
    'Construction Tech',
    'MarTech / AdTech',
    'Intersectional',
    'Skincare',
    'Hair Care',
    'Healthcare',
    'Business Development',
    'Web Development',
    'Product Development',
    'Other',
  ];

  const multiRef = useRef([]);

  useEffect(() => {
    multiRef.current = multiRef.current.slice(0, teamMembers.length);
  }, [teamMembers]);

  const fieldOfOpportunitySelected = () => {
    let response = false;
    for (const field in fieldOfOpportunity) {
      if (fieldOfOpportunity[field]) response = true;
    }
    return response;
  };

  const handleAddTeammate = () => {
    setTeamMembers((oldTeammembers) => [
      ...oldTeammembers,
      { id: uuidv4(), name: '', role: '', image: { file: null, src: '' } },
    ]);
  };

  const handleDeleteTeammate = (currentMember) => {
    setTeamMembers((prev) =>
      prev.filter((teammate) => teammate.id !== currentMember.id)
    );
  };

  const handleImageInputClick = (e, id) => {
    e.preventDefault();
    const imgInput = document.getElementById(`featured-image-${id}`);
    if (imgInput) imgInput.click();
  };

  const handleTeammateUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    currentMember
  ) => {
    const property = e.target.name;
    if (property === 'image') {
      if (e.target.files && e.target.files[0]) {
        const imageFile = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          setTeamMembers((prev) =>
            prev.map((member) => {
              if (member.id !== currentMember.id) {
                return member;
              }
              currentMember['image']['src'] = event.target.result
                ? event.target.result.toString()
                : '';
              return currentMember;
            })
          );
        };

        if (fileIsImage(imageFile.name)) {
          reader.readAsDataURL(imageFile);
          setTeamMembers((prev) =>
            prev.map((member) => {
              if (member.id !== currentMember.id) {
                return member;
              }
              currentMember['image']['file'] = imageFile;
              currentMember['image']['updated'] = true;
              return currentMember;
            })
          );
        } else {
          toast.error('File must be a valid image (JPG, GIF, PNG, JPEG)');
        }
      } else {
        setTeamMembers((prev) =>
          prev.map((member) => {
            if (member.id !== currentMember.id) {
              return member;
            }
            currentMember['image']['file'] = null;
            currentMember['image']['src'] = '';
            return currentMember;
          })
        );
      }
    } else {
      setTeamMembers((prev) =>
        prev.map((member) => {
          if (member.id !== currentMember.id) {
            return member;
          }
          currentMember[property] = e.target.value;
          return currentMember;
        })
      );
    }
  };

  const renderTeamMember = (member, index) => {
    return (
      <div className="col-12 col-md-6 mb-4" key={index}>
        <div className="row d-flex">
          <div className="form-group col-12">
            <label className="form-label text-black font-primary font-weight-bolder">
              Name
            </label>
            <input
              className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
              type="text"
              name="name"
              maxLength={256}
              placeholder="First/Last"
              value={member.name}
              onChange={(e) => handleTeammateUpdate(e, member)}
            />
          </div>
        </div>
        <div className="row d-flex">
          <div className="form-group col-12">
            <label className="form-label text-black font-primary font-weight-bolder">
              Role
            </label>
            <input
              className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
              type="text"
              name="role"
              maxLength={256}
              placeholder="Role"
              value={member.role}
              onChange={(e) => handleTeammateUpdate(e, member)}
            />
          </div>
        </div>
        <div
          className="row mx-0"
          style={{
            display: member.image.file && member.image.src ? 'none' : '',
          }}
        >
          <div className="col-auto pl-0">
            <button
              className="upload-img-btn text-black my-3"
              onClick={(e) => handleImageInputClick(e, member.id)}
            >
              <i className="icon-upload-cloud font-48 text-black"></i>
              <span className="sr-only">Profile Picture</span>
            </button>
            <input
              id={`featured-image-${member.id}`}
              type="file"
              name="image"
              accept="image/*"
              style={{ display: 'none' }}
              ref={(el) => (multiRef.current[index] = el)}
              onChange={(e) => {
                multiRef.current[index].focus();
                handleTeammateUpdate(e, member);
              }}
            />
          </div>
          <div className="col align-items-center d-flex">
            <span className="text-black">Upload Profile Picture</span>
          </div>
        </div>

        {member.image.file && member.image.src && (
          <div className="row my-2 d-flex justify-content-center">
            <div className="col-auto">
              <div className="upload-img-preview my-2">
                <button
                  className="remove btn btn-link text-white text-decoration-none"
                  onClick={() => {
                    multiRef.current[index].value = '';
                    setTeamMembers((prev) =>
                      prev.map((temp) => {
                        if (temp.id !== member.id) {
                          return temp;
                        }
                        member['image']['file'] = null;
                        member['image']['src'] = '';
                        return member;
                      })
                    );
                  }}
                >
                  <i className="icon-close-x font-16 text-white" />
                  <span className="sr-only">Remove Image</span>
                </button>
                <button
                  className="replace btn text-white"
                  onClick={(e) => handleImageInputClick(e, member.id)}
                >
                  <i className="icon-refresh font-28 text-white" />
                  <span className="sr-only">Replace Image</span>
                </button>
                <LazyLoadImage src={member.image.src} alt="" />
              </div>
            </div>
          </div>
        )}
        {teamMembers.length > 1 && (
          <div className="row my-3 d-flex justify-content-center">
            <div className="col-auto">
              <button
                className="btn btn-black text-white remove-teammate"
                type="button"
                onClick={() => handleDeleteTeammate(member)}
              >
                <i className="icon-close-x mr-2"></i>
                <small id="remove-teammate-text">Remove</small>
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const searchFieldsEmpty = () =>
    (!isProduct && !isService) ||
    !fieldOfOpportunitySelected() ||
    industries.length === 0 ||
    state.trim().length === 0 ||
    markets.trim().length === 0 ||
    funding.trim().length === 0 ||
    orderQuantity.trim().length === 0 ||
    productUpdate.trim().length === 0 ||
    sellingCycle.trim().length === 0;

  return (
    <>
      <div className="section mx-lg-5 mt-2 mt-md-4">
        <div className="d-flex flex-row text-left text-center-md justify-content-left justify-content-md-center align-items-center mt-md-4 mb-0 mb-md-2">
          <div
            className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
            style={{ height: '35px', width: '35px' }}
          >
            <span className="my-0 font-primary text-black font-20">{`${
              isEdit && !isAdmin ? '4' : '3'
            }`}</span>
          </div>
          <span className="text-black py-2 ml-3 text-uppercase font-20">
            Search Settings
          </span>
        </div>
        <div className="d-flex justify-content-center">
          <div className="supplier-onboarding-container">
            <div className="product-service-section mx-3 mx-lg-5">
              <div className="row d-flex justify-content-left mb-3">
                <label className="h4 form-label text-black font-primary font-weight-bolder mt-5">
                  Select what you're offering{' '}
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div className="row mt-2 d-flex justify-content-left">
                <div className="form-group col-12 d-flex justify-content-left">
                  <div className="row">
                    <span className="col-auto pl-0">
                      <input
                        checked={isProduct && !isService}
                        type="radio"
                        name="isProductRadio"
                        id="isProductRadio"
                        onChange={() => {
                          setIsProduct(true);
                          setIsService(false);
                        }}
                      />
                      <label className="form-label text-black font-primary ps-responsive-font">
                        <i className="icon-product text-black font-32 align-middle" />
                        Product
                      </label>
                    </span>
                    <span className="col-auto ps-padding">
                      <input
                        checked={!isProduct && isService}
                        type="radio"
                        name="isServiceRadio"
                        id="isServiceRadio"
                        onChange={() => {
                          setIsProduct(false);
                          setIsService(true);
                        }}
                      />
                      <label className="form-label text-black font-primary ps-responsive-font">
                        <i className="icon-service text-black font-24 align-middle" />
                        Service
                      </label>
                    </span>
                    <span className="col-auto ps-padding">
                      <input
                        className="mr-2"
                        checked={isProduct && isService}
                        type="radio"
                        name="bothRadio"
                        id="bothRadio"
                        onChange={() => {
                          setIsProduct(true);
                          setIsService(true);
                        }}
                      />
                      <label className="form-label text-black font-primary ps-responsive-font">
                        Both
                      </label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="industries-section mx-3 mx-lg-5 mt-4">
              <div className="row d-flex justify-content-left">
                <label className="h4 form-label text-black font-primary font-weight-bolder mb-0">
                  Select your Industry
                  <span className="text-danger">* </span>
                </label>
                <span className="col-12 col-md-auto px-0 px-md-2 pt-0 pt-md-1 text-kinda-muted font-primary font-16 font-weight-normal">
                  Select at least 1, maximum 2
                </span>
              </div>
              <div className="row px-md-2 py-sm-4 py-md-2 d-flex justify-content-center justify-content-md-start align-items-center my-3">
                {buttonPillsText.map((text) => {
                  return (
                    <div
                      className="col-auto col-md-3 py-2"
                      key={`industry-pills-${text}`}
                    >
                      <button
                        className={`btn rounded-pill btn-block btn-block btn-outline-black pink-text-hover font-weight-light ${
                          industries.includes(text)
                            ? 'text-pinky btn-black'
                            : 'btn-white text-black'
                        }`}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleIndustryChange(text);
                        }}
                      >
                        {text}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="location-section mx-3 mx-lg-5 mt-4">
              <div className="row d-flex justify-content-left">
                <label className="h4 form-label text-center text-black font-primary font-weight-bolder mb-4">
                  Select your location
                </label>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="form-group col-12 col-md-6 px-0 px-md-2">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    State <span className="text-danger">*</span>
                  </label>
                  <select
                    className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0"
                    name="States"
                    id="state"
                    value={state}
                    onChange={handleSelectChange}
                  >
                    <option value={''} hidden>
                      {state ? state : 'Please select state'}
                    </option>
                    {allStates &&
                      allStates.length > 0 &&
                      allStates.map((s, index) => (
                        <option key={`${s.isoCode}-${index}`} value={s.name}>
                          {s.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-12 col-md-6 px-0 px-md-2">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    City{' '}
                    <small className="text-kinda-muted font-weight-normal">
                      (optional)
                    </small>
                  </label>

                  <select
                    className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0"
                    name="Cities"
                    id="city"
                    disabled={allCities.length === 0 && !city}
                    onChange={(e) => handleSelectChange(e, true)}
                    value={city}
                  >
                    <option key={'no-city-selected'} value={''} hidden={true}>
                      {city ? city : 'Please select a city'}
                    </option>

                    {allCities.length > 0 &&
                      allCities.map((c, index) => (
                        <option key={`${c.isoCode}-${index}`} value={c.name}>
                          {c.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="company-product-info-section mx-3 mx-lg-5 mt-5">
              <div className="row d-flex mt-4 justify-content-left">
                <label className="h4 form-label text-black font-primary font-weight-bolder mb-4">
                  Company/Product Information
                </label>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="form-group col-12 col-md-6 px-0 px-md-2">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Markets <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                    type="text"
                    placeholder="eg: 10"
                    maxLength={12}
                    value={markets}
                    onChange={(e) => {
                      if (!isNaN(+e.target.value)) {
                        setMarkets(e.target.value.trim());
                      }
                    }}
                  />

                  <div className="input-helper mb-2">
                    <small className={`text-wine`}>
                      <strong># of markets you are in</strong>
                    </small>
                  </div>
                </div>
                <div className="form-group col-12 col-md-6 px-0 px-md-2">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Funding <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                    placeholder="eg: $ 10000"
                    type="text"
                    maxLength={12}
                    value={funding}
                    onChange={(e) => {
                      if (!isNaN(+e.target.value)) {
                        setFunding(e.target.value.trim());
                      }
                    }}
                  />

                  <div className="input-helper mb-2">
                    <small className={`text-wine`}>
                      <strong>Amount of funding raised</strong>
                    </small>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="form-group col-12 col-md-6 px-0 px-md-2">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Minimum Order Quantity{' '}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className={`form-control border-left-0 border-top-0 border-right-0 border-black rounded-0`}
                    placeholder="eg: 25"
                    type="text"
                    maxLength={12}
                    value={orderQuantity}
                    onChange={(e) => {
                      if (!isNaN(+e.target.value)) {
                        setOrderQuantity(e.target.value.trim());
                      }
                    }}
                  />

                  <div className="input-helper mb-2">
                    <small className={`text-wine`}>
                      <strong>Minimum order quantity</strong>
                    </small>
                  </div>
                </div>
                <div className="form-group col-12 col-md-6 px-0 px-md-2">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Shipping Time{' '}
                    <small className="text-kinda-muted font-weight-normal">
                      (optional)
                    </small>
                  </label>
                  <select
                    className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0"
                    name="ShippingTime"
                    value={shippingTime}
                    onChange={(e) => setShippingTime(e.target.value)}
                  >
                    {!shippingTime && (
                      <option value={''} hidden={true}>
                        {''}
                      </option>
                    )}
                    {shippingTimes.map(time => <option value={time}>{time}</option>)}
                  </select>

                  <div className="input-helper mb-2">
                    <small className={`text-wine`}>
                      <strong>
                        After payment, how soon can you deliver your product or
                        service?
                      </strong>
                    </small>
                  </div>
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="form-group col-12 col-md-6 px-0 px-md-2">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Product Update <span className="text-danger">*</span>
                  </label>
                  <select
                    value={productUpdate}
                    className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0"
                    onChange={(e) => setProductUpdate(e.target.value)}
                  >
                    <option value={''} hidden={true}>
                      Please select a product update
                    </option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Annual">Annual</option>
                    <option value="Biannually">Biannually</option>
                    <option value="Seasonal">Seasonal</option>
                    <option value="Holidays Only">Holidays Only</option>
                  </select>

                  <div className="input-helper mb-2">
                    <small className={`text-wine`}>
                      <strong>
                        How often do you release new products or services?
                      </strong>
                    </small>
                  </div>
                </div>
                <div className="form-group col-12 col-md-6 px-0 px-md-2">
                  <label className="form-label text-black font-primary font-weight-bolder">
                    Selling Cycle <span className="text-danger">*</span>
                  </label>
                  <select
                    className="custom-select form-control border-left-0 border-top-0 border-right-0 border-black rounded-0"
                    id="selling-cycle-select"
                    onChange={(e) => setSellingCycle(e.target.value)}
                    value={sellingCycle}
                  >
                    <option value={''} hidden={true}>
                      Please select a selling cycle
                    </option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Annual">Annual</option>
                    <option value="Seasonal">Seasonal</option>
                  </select>

                  <div className="input-helper mb-2">
                    <small className={`text-wine`}>
                      <strong>What is your current selling cycle?</strong>
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-section mx-3 mx-lg-5 mt-2">
              <div className="row d-flex justify-content-left">
                <label className="h4 form-label text-black font-primary font-weight-bolder mb-4 mt-4">
                  Team Information{' '}
                  <small className="text-kinda-muted font-weight-normal">
                    (optional)
                  </small>
                </label>
              </div>
              <div className="row d-flex justify-content-center">
                {teamMembers.map((member, index) => {
                  return renderTeamMember(member, index);
                })}
                {teamMembers.length < 5 && (
                  <div className="col-6 mb-4 d-flex  align-items-center justify-content-center">
                    <div className="col-auto">
                      <div className="row d-flex mt-3 align-items-center justify-content-center">
                        <div
                          className="circle-icon-background text-center mr-2 pt-1 d-flex justify-content-center align-items-center"
                          onClick={handleAddTeammate}
                        >
                          <i className="icon-plus text-white clickable font-36 pb-1" />
                        </div>
                      </div>
                      <div className="row  d-flex justify-content-center align-items-center justify-content-center">
                        <small className="text-black font-primary font-weight-bolder">
                          Add a teammate
                        </small>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="field-of-opportunity-section mx-3 mx-lg-5 mt-5">
              <div className="row d-flex justify-content-left">
                <label className="h4 form-label text-black font-primary font-weight-bolder mb-0">
                  Field of Opportunity
                  <span className="text-danger">* </span>
                </label>
                <span className="col-12 col-md-auto px-0 px-md-2 pt-0 pt-md-1 text-kinda-muted font-primary font-16 font-weight-normal">
                  Which opportunities are a best fit for you?
                </span>
              </div>
              <div className="row d-flex justify-content-center mt-4">
                {foo.map((f, index) => (
                  <div
                    className="col-6 mb-1 mb-md-2 px-0 px-md-2"
                    key={`field-of-opportunity-${f.attribute}-${index}`}
                  >
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input opportunity-checkbox mr-0"
                        type="checkbox"
                        value={f.attribute}
                        checked={fieldOfOpportunity[f.attribute]}
                        onClick={handleFieldOfOpportunity}
                        readOnly
                      />
                    </div>
                    <label className="form-label text-black font-primary font-weight-bolder">
                      {f.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className="search-visibility-section mx-3 mx-lg-5 mt-4">
              <div className="row d-flex justify-content-left">
                <label className="form-label h4 text-black font-primary font-weight-bolder">
                  Search Visibility
                </label>
              </div>
              <div className="row just-a-container mb-2 mt-2">
                <div className="col-auto d-flex align-items-center pl-0">
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      checked={communityVisibility}
                      id="customSwitch1"
                      type="checkbox"
                      onChange={(e) => {
                        setCommunityVisibility(e.target.checked);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch1"
                    ></label>
                  </div>
                </div>
                <div className="col">
                  <div className="row pt-0">
                    <p className="text-black mb-0">
                      Profile visible to <strong>Network/Community</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row just-a-container mb-2">
                <div className="col-auto d-flex align-items-center pl-0">
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      checked={hiringVisibility}
                      id="customSwitch2"
                      type="checkbox"
                      onChange={(e) => {
                        setHiringVisibility(e.target.checked);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch2"
                    ></label>
                  </div>
                </div>
                <div className="col">
                  <div className="row pt-0">
                    <p className="text-black mb-0">
                      Profile visible for <strong>Hiring</strong>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row just-a-container mb-2">
                <div className="col-auto d-flex align-items-center pl-0">
                  <div className="custom-control custom-switch">
                    <input
                      className="custom-control-input"
                      checked={investmentVisibility}
                      id="customSwitch3"
                      type="checkbox"
                      onChange={(e) => {
                        setInvestmentVisibility(e.target.checked);
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitch3"
                    ></label>
                  </div>
                </div>
                <div className="col">
                  <div className="row pt-0">
                    <p className="text-black mb-0">
                      Profile visible for <strong>Investment</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isEdit && (
        <div className=" d-flex justify-content-center mt-4 flex-column-reverse flex-md-row">
          <button
            className="btn-lg btn btn-white text-black font-primary text-uppercase rounded-5 mt-4 px-4 mr-1"
            onClick={() => {
              window.scrollTo(0, 0);
              setActivePage(2);
            }}
          >
            Go back
          </button>
          <button
            disabled={searchFieldsEmpty() || disabled}
            className="btn btn-black btn-lg text-uppercase text-pinky rounded-5 mt-4 px-5 ml-1"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {`FINISH & CREATE ACCOUNT`}
          </button>
        </div>
      )}
    </>
  );
};

export default SupplierSearchSettings;
