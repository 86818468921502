import { Dispatch, SetStateAction } from 'react';
import { ICompanyStage } from '../../types/SupplierInfo';

interface Props {
  stage: ICompanyStage;
  selectedStage: ICompanyStage;
  changeStage: Dispatch<SetStateAction<ICompanyStage>>;
}

const CompanyStageCard = ({ stage, selectedStage, changeStage }: Props) => {
  return (
    <div className="row company-stage-card mx-0 py-3 mb-2 pointer-cursor" onClick={() => {changeStage(stage)}}>
      <div className="row d-flex justify-content-end w-100">
        <input
          type="radio"
          name={`stage-card-radio-${stage.id}`}
          id={`stage-card-radio-${stage.id}`}
          checked={stage.id === selectedStage.id}
          value={stage.title}
          onChange={() => changeStage(stage)}
        />
      </div>
      <div className="row mx-3">
        <div
          className="stage-circle-icon-background text-center text-white"
          style={{ fontSize: '0.6rem' }}
        >
          <p>{stage.id}</p>
        </div>
        <p className="font-primary text-black font-weight-bolder ml-1 font-13">
          {stage.title}
        </p>
      </div>
      <div className="col-12">
        <p className="font-primary text-black font-12 font-weight-bold mb-2">
          {stage.shortDescription}
        </p>
      </div>
      <div className="col-12">
        <p className="font-primary text-black font-12 font-weight-bold mb-2">
          {stage.longDescription}
        </p>
      </div>
    </div>
  );
};

export default CompanyStageCard;
