import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import UltimatePagination from '../../components/Pagination/Pagination';
import {
  changeConnectionStatus, resetConnectionErrors,
} from '../../actions/connection';
import { authContext } from '../../components/Authentication/CognitoContextProvider';
import ListView from '../../components/ListView/ListView';
import { RootState } from '../../reducers';
import { getDisplayName, requestMeeting } from '../../utils/connectionUtils';
import { ConnectionWithProfile } from '../../types/Connection';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router';
import { ListItemTypes } from '../../utils/validations';
import { redirectToLogin } from '../../utils/redirectToLogin';
import { sendMeetEmail } from '../../utils/emailUtils';
import { getPendingNotifications, resetNotificationsErrors } from '../../actions/notification';

const Notifications = () => {
  const { auth } = useContext(authContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [userId, setUserId] = useState('');
  const [activePage, setActivePage] = useState(1);
  const {
    notifications,
    requestFinished: notificationRequestFinished,
    hasErrors: notificationHasErrors,
    loading,
    total,
  } = useSelector((state: RootState) => state.notification);

  const {
    requestFinished: connectionRequestFinished,
    hasErrors: connectionHasErrors,
    toastMessage
  } = useSelector((state: RootState) => state.connection);

  useEffect(() => {
    if (auth && auth.data) {
      dispatch(
        getPendingNotifications({
            userId: auth.data.attributes.sub,
            activePage: 1
        })
      );
      setUserId(auth.data.attributes.sub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.data]);

  useEffect(() => {
    if (auth && auth.data) {
        dispatch(
            getPendingNotifications({
                userId: auth.data.attributes.sub,
                activePage,
            })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  const showToast = () => {
    if (connectionRequestFinished && notificationRequestFinished) {
      if (connectionHasErrors) {
        toast.error('An error occured while attempting to update the connection request', {
          toastId: 'notifications-connection-error',
        });
      } else if (notificationHasErrors) {
        toast.error('An error occured while attempting to delete the notification', {
          toastId: 'notifications-error',
        });
      } else {
        toastMessage && toast.success(toastMessage, {
          toastId: 'notifications-actions-success',
        });
      }
      dispatch(resetNotificationsErrors());
      dispatch(resetConnectionErrors())
    }
    return null;
  };

  const handleRequestMeeting = async (connection: ConnectionWithProfile) => {
    try {
      let token = '';
      if (auth.data) {
        const getSession = await Auth.currentSession();
        token = getSession.getIdToken().getJwtToken();
      } else {
        toast.error('Your session has expired, please login again');
        redirectToLogin(history, '/my-connections');
        return;
      }
      const message = await requestMeeting(connection, auth, token);
      const userId = auth.data.attributes.sub;
      const { senderEmail, receiverEmail, receiverId, senderId } = connection;
      const userIsReceiver = userId === receiverId;
      const recepientName = getDisplayName(connection, userIsReceiver);
      const requesterName = getDisplayName(connection, !userIsReceiver);
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      };
      await sendMeetEmail(
        userIsReceiver ? receiverEmail : senderEmail,
        requesterName,
        userIsReceiver ? senderEmail : receiverEmail,
        recepientName,
        receiverId,
        senderId,
        headers
      );
      toast.success(message);
    } catch (err) {
      toast.error('An error occured while attempting to request the meeting', {
        toastId: 'request-meeting-error',
      });
    }
  };

  const changeStatus = (
    connectionId: number,
    newStatus: number,
    name: string
  ) => {
    dispatch(
      changeConnectionStatus({
        connectionId,
        newStatus,
        name,
        isNotifications: true,
        userId: auth.data.attributes.sub,
        activePage: handleCurrentPage()
      })
    );
  };

  const changePage = (index: number) => {
    setActivePage(index);
  };

  const handleCurrentPage = () => {
    if (total - 1 > activePage * 10 - 10) {
      return activePage;
    } else {
      setActivePage(activePage - 1);
      return activePage - 1;
    }
  };

  const renderPagination = () => {
    return (
      total > 10 && (
        <nav className="table-pagination my-3 px-4 px-lg-0">
          <UltimatePagination
            hideFirstAndLastPageLinks={true}
            siblingPagesRange={1}
            boundaryPagesRange={1}
            totalPages={Math.ceil(total / 10)}
            currentPage={activePage}
            onChange={changePage}
          />
        </nav>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>BSN - My Notifications</title>
      </Helmet>
      <main className="body-min-h90 admin-container py-5 px-0 px-lg-5">
        <div className="row mx-0 px-3 px-lg-0 pr-lg-1 pb-3 d-flex justify-content-end ml-lg-0 ml-md-5 pl-md-5">
          <div className="col text-left text-md-center pl-md-5">
            <h1 className="h2 py-2 font-primary text-black font-weight-bolder pl-md-3">
              My Notifications
            </h1>
          </div>
        </div>

        <div className="connections d-flex justify-content-center mt-3">
            <ListView
              data={notifications}
              userId={userId}
              loading={loading}
              changeStatus={changeStatus}
              requestMeeting={handleRequestMeeting}
              type={ListItemTypes.NOTIFICATION}
            />
        </div>
        <div className="d-flex justify-content-center mt-3">
          {renderPagination()}
        </div>
        {showToast()}
      </main>
    </>
  );
};

export default Notifications;
