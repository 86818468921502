import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { authContext } from '../Authentication/CognitoContextProvider';
import { isAdmin, isCustomer, isSupplier } from '../../auth/userUtils';

const ProtectedRoute = ({
  allowedRoles = [],
  component: Component,
  componentProps = {},
  ...rest
}) => {
  const location = useLocation();
  const [isAuth, setIsAuth] = useState(false);
  const [canAccess, setCanAccess] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);
  const { auth, setAuthData } = useContext(authContext);

  Auth.currentAuthenticatedUser()
    .then((user) => {
      setIsAuth(true);
      if (allowedRoles.length === 0) {
        setCanAccess(true)
      } else {
        let userRoles: string[] = [];
        isSupplier(user) && userRoles.push('supplier');
        isAdmin(user) && userRoles.push('admin');
        isCustomer(user) && userRoles.push('customer')
        userRoles.forEach((role) => {
          if (allowedRoles.includes(role)) {
            setCanAccess(true);
          }
        });
      }
    })
    .catch(() => {
      setIsAuth(false);
    })
    .finally(() => {
      setRequestFinished(true);
    });

  return (
    <Route
      {...rest}
      render={() => {
        if (requestFinished) {
          if (!isAuth) {
            setAuthData(null);
            Auth.signOut();
            localStorage.setItem('redirect-url', location.pathname);
            return <Redirect to="/login" />;
          } else if (isAuth && canAccess) {
            return auth && auth.data && <Component {...componentProps} />;
          } else if (isAuth) {
            return <Redirect to="/" />;
          }
        } else {
          return <div>Loading...</div>;
        }
      }}
    />
  );
};

export default ProtectedRoute;
