import { types } from '../actions/user';
import { UserTableRow } from '../types/User';
import { mapAttributesToObject } from '../utils/cognito-utils';
export interface UserState {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  requestFinished: boolean;
  users: UserTableRow[];
  suppliers: UserTableRow[];
  customers: UserTableRow[];
  admins: UserTableRow[];
  selectedUser: any;
  usersPendingApproval: any[];
  totalUsersPendingApproval: number;
}

const initialState: UserState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  requestFinished: false,
  users: [],
  suppliers: [],
  customers: [],
  admins: [],
  selectedUser: undefined,
  usersPendingApproval: [],
  totalUsersPendingApproval: 0,
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_USERS_FOR_TABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        users: [],
        suppliers: [],
        customers: [],
        admins: [],
      };
    }
    case types.GET_ALL_USERS_FOR_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        users: action.payload.users || [],
        suppliers: action.payload.suppliers || [],
        customers: action.payload.customers || [],
        admins: action.payload.admins || [],
      };
    case types.SORT_USERS_FOR_TABLE_REQUEST: {
      return {
        ...state,
        loading: true,
        users: [],
        suppliers: [],
        customers: [],
        admins: [],
      };
    }
    case types.SORT_USERS_FOR_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        users: action.payload.users,
        suppliers: action.payload.suppliers,
        customers: action.payload.customers,
        admins: action.payload.admins,
      };
    case types.INVITE_USER_REQUEST:
    case types.UPDATE_ADMISSION_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
      };
    case types.INVITE_USER_SUCCESS:
    case types.UPDATE_ADMISSION_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: true,
      };
    case types.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        selectedUser: null,
      };
    case types.GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedUser: mapAttributesToObject(action.payload.Attributes),
      };
    case types.GET_USERS_PENDING_APPROVAL_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        usersPendingApproval: [],
        totalUsersPendingApproval: 0,
      };
    case types.GET_USERS_PENDING_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        usersPendingApproval: action.payload.users,
        totalUsersPendingApproval: action.payload.total,
      };
    case types.RESET_USER_ERRORS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
      };
    case types.GET_USER_BY_ID_FAILURE:
    case types.GET_ALL_USERS_FOR_TABLE_FAILURE:
    case types.SORT_USERS_FOR_TABLE_FAILURE:
    case types.INVITE_USER_FAILURE:
    case types.GET_USERS_PENDING_APPROVAL_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
        requestFinished: true,
      };
    default:
      return state;
  }
}
