import axios from 'axios';
import { backendUrl } from './env';

export const sendMeetEmail = async (
    senderEmail: string,
    senderName: string,
    recipientEmail: string,
    recipientName: string,
    senderId: string,
    receiverId: string,
    headers
) => {
    await axios.post(`${backendUrl}/Users/request-meeting`, {
        senderEmail,
        senderName,
        recipientEmail,
        recipientName,
        senderId,
        receiverId
    }, {
        headers
    });
}