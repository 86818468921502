import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UsersTable from "../../components/UsersTable/UsersTable";
import { getAllUsersForTable, sortUsersForTable } from "../../actions/user";
import { Tab, Tabs } from "react-bootstrap";
import { UserTableRow } from "../../types/User";
import { RootState } from "../../reducers";
import UltimatePagination from "../../components/Pagination/Pagination";
import { CSVLink } from "react-csv";
import {
  usersAndSuppliersColumns,
  adminsAndCustomersColumns,
} from "../../utils/csv-columns";
import { deleteAccount, toggleUserAdminStatus } from "../../auth/userUtils";
import { toast } from "react-toastify";
import axios from "axios";
import { backendUrl } from "../../utils/env";
import { useHistory } from "react-router";
import Auth from "@aws-amplify/auth";
import { authContext } from "../../components/Authentication/CognitoContextProvider";
import { Helmet } from "react-helmet";
import { DeleteUserModal } from "../../components/Modals/ManageUsersModals/DeleteUserModal";
import { MakeAdminModal } from "../../components/Modals/ManageUsersModals/MakeAdminModal";
import { ChangeStatusModal } from "../../components/Modals/ManageUsersModals/ChangeStatusModal";

const ManageUsers = () => {
  const tabNames = ["All", "Suppliers", "Champions", "Admins", "Super Admins"];
  const dispatch = useDispatch();
  const history = useHistory();
  const { auth } = useContext(authContext);
  const [activeTab, setActiveTab] = useState("All");
  const [activePage, setActivePage] = useState(1);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [keyword, setKeyword] = useState("");
  const [tableData, setTableData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [adminPermissionModal, setAdminPermissionModal] = useState(false);
  const [userStatusModal, setUserStatusModal] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("ASC");
  const [sortProperty, setSortProperty] = useState("name");
  const [remainOnPage, setRemainOnPage] = useState(false);

  const users: UserTableRow[] = useSelector(
    (state: RootState) => state.user.users
  );
  const admins: UserTableRow[] = useSelector(
    (state: RootState) => state.user.admins
  );
  const suppliers: UserTableRow[] = useSelector(
    (state: RootState) => state.user.suppliers
  );
  const customers: UserTableRow[] = useSelector(
    (state: RootState) => state.user.customers
  );

  const loading: boolean = useSelector(
    (state: RootState) => state.user.loading
  );

  useEffect(() => {
    dispatch(getAllUsersForTable());
    setDataForTableAndCsv(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDataForTableAndCsv(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    formatDataForCsv(tableData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const closeAdminModal = () => setAdminPermissionModal(false);

  const closeStatusModal = () => setUserStatusModal(false);

  const openModal = (user: UserTableRow, modal: string) => {
    setSelectedUser(user);
    if (modal === "Admin") {
      setAdminPermissionModal(true);
    } else if (modal === "Delete") {
      setDeleteModalOpen(true);
    } else {
      setUserStatusModal(true);
    }
  };

  const handleAdminStatus = async (
    username: string,
    roles: Array<string>,
    name: string
  ) => {
    const response = await toggleUserAdminStatus(
      username,
      roles.includes("Admin")
    );
    if (response.success) {
      toast.success(`${response.message} ${name}`, {
        toastId: "handle-admin-toast",
      });
      closeAdminModal();
      refreshTable();
    } else {
      toast.error(response.message);
    }
  };

  const handleUserStatus = async (
    username: string,
    enabled: boolean,
    name: string
  ) => {
    try {
      await axios.put(`${backendUrl}/Users/changeStatus`, {
        username,
        enabled,
      });
      refreshTable();
      const message = enabled
        ? `User ${name} was disabled`
        : `User ${name} was enabled`;
      closeStatusModal();
      toast.success(message, { toastId: "handle-user-status-toast" });
    } catch (error) {
      toast.error(error);
    }
  };

  const changePage = (index: number) => {
    setActivePage(index);
  };

  const refreshTable = () => {
    dispatch(getAllUsersForTable());
  };

  const formatDataForCsv = (data: UserTableRow[]) => {
    const formattedData: any = data.map((item) => {
      return {
        ...item,
        name: item.name.replace(/,|"/g, "").trim(),
        enabled: item.enabled ? "Enabled" : "Disabled",
        isAlumni: item.isAlumni ? "Yes" : "No",
        mainRole: item.mainRole === "Customer" ? "Champion" : item.mainRole,
      };
    });
    setCsvData(formattedData);
  };

  const getDataForCurrentTab = (tab: string) => {
    switch (tab) {
      case "All":
        return users;
      case "Suppliers":
        return suppliers;
      case "Champions":
        return customers;
      case "Admins":
        return admins;
      case "Super Admins":
        return [];
    }
  };

  const handleSearch = (tab: string) => {
    setKeyword(searchQuery);
    const original = getDataForCurrentTab(tab);
    const lowerCasedQuery = searchQuery.toLowerCase();
    const temp = searchQuery
      ? original.filter(
          (user) =>
            user.name.toLowerCase().includes(lowerCasedQuery) ||
            user.email.toLowerCase().includes(lowerCasedQuery)
        )
      : original;
    setTableData(temp);
    if (!remainOnPage) {
      setActivePage(1);
    }
    if (users.length !== 0) {
      setRemainOnPage(false);
    }
  };

  const clearSearch = () => {
    const original = getDataForCurrentTab(activeTab);
    setSearchQuery("");
    setKeyword("");
    setSortOrder("ASC");
    setSortProperty("name");
    setTableData(
      original.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      )
    );
    setActivePage(1);
  };

  const handleSortRequest = (column: string) => {
    setRemainOnPage(true);
    if (column === sortProperty) {
      const newSortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
      setSortOrder(newSortOrder);
      dispatch(sortUsersForTable({ users, column, order: newSortOrder }));
    } else {
      setSortOrder("ASC");
      setSortProperty(column);
      dispatch(sortUsersForTable({ users, column, order: "ASC" }));
    }
  };

  const setDataForTableAndCsv = (tab: string) => {
    switch (tab) {
      case "All":
        formatDataForCsv(users);
        setCsvHeaders(usersAndSuppliersColumns);
        break;
      case "Suppliers":
        formatDataForCsv(suppliers);
        setCsvHeaders(usersAndSuppliersColumns);
        break;
      case "Champions":
        formatDataForCsv(customers);
        setCsvHeaders(adminsAndCustomersColumns);
        break;
      case "Admins":
        formatDataForCsv(admins);
        setCsvHeaders(adminsAndCustomersColumns);
        break;
    }
    handleSearch(tab);
  };

  const renderPagination = () => {
    const total = tableData.length;
    return (
      total > 10 && (
        <nav className="table-pagination my-3 px-4 px-lg-0">
          <UltimatePagination
            hideFirstAndLastPageLinks={true}
            siblingPagesRange={1}
            boundaryPagesRange={1}
            totalPages={Math.ceil(total / 10)}
            currentPage={activePage}
            onChange={changePage}
          />
        </nav>
      )
    );
  };

  const renderTableTabs = () => {
    return (
      <Tabs
        id="manage-users-tabs"
        className="users-tabs"
        activeKey={activeTab}
        onSelect={(tab) => {
          setActiveTab(tab);
          setActivePage(1);
          setDataForTableAndCsv(tab);
        }}
      >
        {tabNames.map((tab, index) => (
          <Tab
            tabClassName="text-black"
            eventKey={tab}
            title={tab}
            key={`manage-users-tab-${index}`}
          >
            <UsersTable
              keyword={keyword}
              refreshTable={refreshTable}
              data={tableData.slice((activePage - 1) * 10, activePage * 10)}
              openModal={openModal}
              sortRequest={handleSortRequest}
              activeColumn={sortProperty}
              activeOrder={sortOrder}
            />
          </Tab>
        ))}
      </Tabs>
    );
  };

  const redirectToLogin = () => {
    localStorage.setItem("redirect-url", "/admin/manage-users");
    history.push("/login");
  };

  const handleDeleteAccount = async () => {
    const response = await deleteAccount(selectedUser.username, false);
    if (response.success) {
      let token = "";
      if (auth.data) {
        const getSession = await Auth.currentSession();
        token = getSession.getIdToken().getJwtToken();
      } else redirectToLogin();
      let authToken = "";
      if (token) {
        authToken = `Bearer ${token}`;
      }
      const headers = {
        Authorization: authToken,
        "Content-Type": "application/json",
      };
      await axios.delete(
        `${backendUrl}/Suppliers?email=${selectedUser.email}`,
        {
          headers,
        }
      );

      await axios.delete(
        `${backendUrl}/Customers?id=${selectedUser.id ? selectedUser.id : ""}`,
        {
          headers,
        }
      );

      await axios.put(
        `${backendUrl}/Connections/${selectedUser.id}/profileDeleted`,
        {},
        { headers }
      );

      await axios.delete(`${backendUrl}/Notifications/${selectedUser.id}`, {
        headers,
      });
      const message = `${selectedUser.name}'s profile deleted.`;
      toast.success(message, { toastId: "delete-user-success" });
      closeDeleteModal();
      refreshTable();
    } else {
      toast.error(response.message, { toastId: "delete-user-error" });
    }
  };

  const closeDeleteModal = () => setDeleteModalOpen(false);

  return (
    <>
      <Helmet>
        <title>BSN - Manage Users</title>
      </Helmet>
      <main className="body-min-h90 admin-container py-5 px-0 px-lg-5">
        <div className="row mx-0 px-3 px-lg-0 pr-lg-1 pb-3 d-flex justify-content-end">
          <div className="col-12 col-md">
            <h1 className="h2 pb-2 font-primary text-black font-weight-bolder">
              Manage Users
            </h1>
          </div>
          <div className="col col-md-4 col-lg-4">
            <div className="input-group mb-4 position-relative addon">
              {searchQuery && (
                <button
                  className="input-operator btn rounded-circle double-push-r text-black"
                  onClick={clearSearch}
                >
                  <i className="icon-close-x font-16" />
                </button>
              )}
              <input
                className="form-control alt-outline-focus"
                type="text"
                id="donation-1"
                placeholder="Search..."
                disabled={loading}
                value={searchQuery}
                onKeyPress={(event) => {
                  if (event.key === "Enter") handleSearch(activeTab);
                }}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-black"
                  id="search-trigger"
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    handleSearch(activeTab);
                  }}
                >
                  <i className="icon-search" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-auto col-md-auto px-0 text-center manage-users-actions">
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={`${activeTab === "All" ? "Users" : activeTab}.csv`}
              id="users-csv-link"
            />
            <button
              className="btn btn-outline-black text-uppercase rounded"
              onClick={() => {
                const csvLink = document.getElementById("users-csv-link");
                csvLink.click();
              }}
              disabled={loading}
            >
              <i className="icon-export font-20" />
              <span className="pl-2 font-14 d-none d-md-inline">
                Export CSV
              </span>
            </button>
          </div>
        </div>
        {selectedUser && (
          <>
            <DeleteUserModal
              isOpen={deleteModalOpen}
              closeModal={closeDeleteModal}
              name={selectedUser.name}
              handleDeleteAccount={handleDeleteAccount}
            />
            <MakeAdminModal
              isOpen={adminPermissionModal}
              closeModal={closeAdminModal}
              username={selectedUser.username}
              roles={selectedUser.roles}
              name={selectedUser.name}
              handleAdminStatus={handleAdminStatus}
            />
            <ChangeStatusModal
              isOpen={userStatusModal}
              closeModal={closeStatusModal}
              username={selectedUser.username}
              enabled={selectedUser.enabled}
              name={selectedUser.name}
              handleUserStatus={handleUserStatus}
            />
          </>
        )}
        {renderTableTabs()}
        {renderPagination()}
      </main>
    </>
  );
};

export default ManageUsers;
