import React, { useContext } from "react";
import { UserTableRow } from "../../types/User";
import { Dropdown } from "react-bootstrap";
import { authContext } from "../Authentication/CognitoContextProvider";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

interface Props {
  keyword: string;
  data: UserTableRow[];
  refreshTable: Function;
  openModal: Function;
  sortRequest: (column: string) => void;
  activeColumn: string;
  activeOrder: string;
}

const UsersTable = ({
  keyword,
  data,
  openModal,
  sortRequest,
  activeColumn,
  activeOrder,
}: Props) => {
  const { auth } = useContext(authContext);
  const history = useHistory();
  const tableColumns = [
    { displayName: "ID", value: "id", minWidth: "" },
    { displayName: "Name", value: "name", minWidth: "12rem" },
    { displayName: "Email", value: "email", minWidth: "" },
    { displayName: "Status", value: "status", minWidth: "" },
    { displayName: "Role", value: "mainRole", minWidth: "" },
    { displayName: "BGV Alumni", value: "isAlumni", minWidth: "" },
    { displayName: "Date Added", value: "dateAdded", minWidth: "" },
  ];
  const loading: boolean = useSelector(
    (state: RootState) => state.user.loading
  );

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
      //eslint-disable-next-line
      <a
        className='btn btn-link'
        href='#'
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    )
  );

  const renderContextMenu = (user: UserTableRow) => {
    const hideAdminChange =
      user.roles.includes("Admin") && auth.data.attributes.email === user.email;

    const showStatusChange = auth.data.attributes.email !== user.email;

    const showEditButton = user.roles.includes('Supplier') || user.roles.includes('Customer');
    
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} bsPrefix="btn btn-link">
          <i className="icon-chevron-down font-16 text-black" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {showEditButton && (
            <Dropdown.Item
              className="dropdown-item font-14 text-black"
              onClick={() => {
                history.push(
                  `/${
                    user.mainRole === 'Customer'
                      ? 'champion'
                      : user.mainRole.toLocaleLowerCase()
                  }/edit/${user.id}`,
                  { comesFromAdmin: true }
                );
              }}
            >
              Edit
            </Dropdown.Item>
          )}
          {showStatusChange && (
            <>
              <Dropdown.Item
                className="dropdown-item font-14 text-black"
                onClick={() => {
                  openModal(user, 'Status');
                }}
              >
                {`${user.enabled ? 'Disable' : 'Enable'} User`}
              </Dropdown.Item>
              {!hideAdminChange && (
                <Dropdown.Item
                  className="dropdown-item font-14  text-black"
                  onClick={() => {
                    openModal(user, 'Admin');
                  }}
                >
                  {`${!user.roles.includes('Admin') ? 'Make' : 'Remove'} Admin`}
                </Dropdown.Item>
              )}
              <Dropdown.Item
                className="dropdown-item font-14  text-black"
                onClick={() => {
                  openModal(user, 'Delete');
                }}
              >
                Delete User
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderTableRows = () => {
    const rows = data.map((user, index) => {
      return (
        <tr key={index}>
          <td
            onClick={() => goToManageConnections(user.id)}
            className="align-middle pointer-cursor"
          >
            {user.id}
          </td>
          <td
            onClick={() => goToManageConnections(user.id)}
            className="align-middle pointer-cursor"
            style={{ minWidth: '12rem' }}
          >
            {user.name}
          </td>
          <td
            onClick={() => goToManageConnections(user.id)}
            className="align-middle pointer-cursor"
          >
            {user.email}
          </td>
          <td
            onClick={() => goToManageConnections(user.id)}
            className="align-middle pointer-cursor"
          >
            {user.status}
          </td>
          <td
            onClick={() => goToManageConnections(user.id)}
            className="align-middle pointer-cursor"
          >
            {user.mainRole === 'Customer' ? 'Champion' : user.mainRole}
          </td>
          <td
            onClick={() => goToManageConnections(user.id)}
            className="align-middle pointer-cursor"
          >
            {user.isAlumni ? 'Yes' : 'No'}
          </td>
          <td
            onClick={() => goToManageConnections(user.id)}
            className="align-middle pointer-cursor"
          >
            {user.dateAdded}
          </td>
          <td className="align-middle">{renderContextMenu(user)}</td>
        </tr>
      );
    });
    return rows;
  };

  const goToManageConnections = (id: string) => {
    history.push(`/admin/manage-connections/${id}`);
  };

  return (
    <div className='table-responsive table-height-hack '>
      <table className='table bgv table-hover table-borderless font-14 table-va-center'>
        <thead>
          <tr className='table-color-pinky'>
            {tableColumns.map((column, index) => {
              return (
                <th
                  key={`users-header-${index}`}
                  scope='col'
                  style={{ minWidth: column.minWidth }}
                >
                  <button
                    className={`table-sorter btn btn-link text-black font-weight-bold px-0 align-middle ${
                      activeColumn === "id" ? "active" : ""
                    }`}
                    onClick={() => sortRequest(column.value)}
                  >
                    {column.displayName}
                    <i
                      className={`${
                        activeColumn === column.value
                          ? activeOrder === "ASC"
                            ? "icon-chevron-up"
                            : "icon-chevron-down"
                          : "icon-chevron-down"
                      } pl-2`}
                    />
                  </button>
                </th>
              );
            })}
            <th scope='col' />
          </tr>
        </thead>
        <tbody className='text-black'>{renderTableRows()}</tbody>
      </table>
      {loading && (
        <div className='empty-results text-center py-4'>
          <span className='text-muted'>Loading...</span>
        </div>
      )}
      {data.length === 0 && !loading && (
        <div className='empty-results text-center py-4'>
          <span className='text-muted'>
            {keyword
              ? `No results for "${keyword}"`
              : `There are no users to show.`}
          </span>
        </div>
      )}
    </div>
  );
};

export default UsersTable;
