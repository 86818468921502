import { Auth } from 'aws-amplify';
import aws4Interceptor from 'aws4-axios';
import axios from 'axios';
import { toast } from 'react-toastify';
import { cognitoPoolId, cognitoRegion } from '../utils/env';

export const isSupplier = (user) => {
  if (!user) return false;
  return (
    !!user.attributes['custom:is_supplier'] &&
    user.attributes['custom:is_supplier'] === 'true'
  );
};

export const isCustomer = (user) => {
  if (!user) return false;
  return (
    user.attributes['custom:is_customer'] &&
    user.attributes['custom:is_customer'] === 'true'
  );
};

//TODO: Clean unnecessary role checks (Founder, SuperAdmin)

export const isAdmin = (user) => {
  if (!user) return false;
  return (
    user.attributes['custom:is_admin'] &&
    user.attributes['custom:is_admin'] === 'true'
  );
};

export const isSuperAdmin = (user) => {
  if (!user) return false;
  return (
    !!user.attributes['custom:isSuperAdmin'] &&
    parseInt(user.attributes['custom:isSuperAdmin']) === 1
  );
};

export const isGoogleUser = (user) => {
  if (!user || !user.attributes.identities) return false;

  const cleanIdentitiesAttribute = user.attributes.identities.substring(
    1,
    user.attributes.identities.length - 1
  );

  const providerIdentities = JSON.parse(cleanIdentitiesAttribute);
  return providerIdentities.providerName.toLowerCase() === 'google';
};

export const getCurrentUser = async () => {
  return await Auth.currentAuthenticatedUser();
};

export const makeUserRole = async (user, role, setAuthData) => {
  if (user) {
    const client = await requestClientInterceptor();
    try {
      await client.post(
        `https://cognito-idp.${cognitoRegion}.amazonaws.com`,
        {
          UserAttributes: [
            {
              Name: `custom:${role}`,
              Value: 'true',
            },
          ],
          Username: user.username || user.Username,
          UserPoolId: cognitoPoolId,
        },
        {
          headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'x-amz-target':
              'AWSCognitoIdentityProviderService.AdminUpdateUserAttributes',
          },
        }
      );
      const editedUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setAuthData(editedUser);
    } catch (err) {
      console.log(err);
    }
  }
};

export const toggleUserAdminStatus = async (
  username: string,
  isAdmin: boolean
) => {
  const client = await requestClientInterceptor();
  try {
    await client.post(
      `https://cognito-idp.${cognitoRegion}.amazonaws.com`,
      {
        UserAttributes: [
          {
            Name: 'custom:is_admin',
            Value: isAdmin ? 'false' : 'true',
          },
        ],
        Username: username,
        UserPoolId: cognitoPoolId,
      },
      {
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'x-amz-target':
            'AWSCognitoIdentityProviderService.AdminUpdateUserAttributes',
        },
      }
    );
    const message = isAdmin
      ? 'Admin permissions removed from'
      : 'Admin permissions granted to';
    return { success: true, message };
  } catch (err) {
    console.log(err);
    return { success: false, message: err };
  }
};

export const toggleUserSuperAdminStatus = async (
  username: string,
  isSuperAdmin: boolean
) => {
  const client = await requestClientInterceptor();
  try {
    await client.post(
      `https://cognito-idp.${cognitoRegion}.amazonaws.com`,
      {
        UserAttributes: [
          {
            Name: 'custom:isSuperAdmin',
            Value: isSuperAdmin ? '0' : '1',
          },
        ],
        Username: username,
        UserPoolId: cognitoPoolId,
      },
      {
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'x-amz-target':
            'AWSCognitoIdentityProviderService.AdminUpdateUserAttributes',
        },
      }
    );
    const message = isSuperAdmin
      ? 'Super admin permissions removed from'
      : 'Super admin permissions granted to';
    return { success: true, message };
  } catch (err) {
    console.log(err);
    return { success: false, message: err };
  }
};

export const updateProfile = async (
  payload: any,
  username: string,
  setAuthData: any
) => {
  try {
    const client = await requestClientInterceptor();
    const UserAttributes = Object.keys(payload).map((key) => {
      return { Name: key, Value: payload[key] };
    });
    try {
      await client.post(
        `https://cognito-idp.${cognitoRegion}.amazonaws.com`,
        {
          UserAttributes,
          Username: username,
          UserPoolId: cognitoPoolId,
        },
        {
          headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'x-amz-target':
              'AWSCognitoIdentityProviderService.AdminUpdateUserAttributes',
          },
        }
      );
      const editedUser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setAuthData(editedUser);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const requestClientInterceptor = async () => {
  const creds = await Auth.currentCredentials();
  const client = axios.create();
  const interceptor = aws4Interceptor(
    {
      service: 'cognito-idp',
      region: cognitoRegion,
    },
    {
      sessionToken: creds.sessionToken,
      accessKeyId: creds.accessKeyId,
      secretAccessKey: creds.secretAccessKey,
    }
  );
  client.interceptors.request.use(interceptor);
  return client;
};

export const checkSessionStillActive = async (auth, setAuthData, history) => {
  try {
    if (auth && auth.data) {
      await Auth.currentSession();
      return true;
    }
    return false;
  } catch (err) {
    toast.error('Your session has expired, please log in again.', {
      toastId: 'session-expired',
    });
    setAuthData(null);
    await Auth.signOut();
    history.push('/');
    return false;
  }
};

export const deleteAccount = async (username: string, logoutUser?: boolean) => {
  const client = await requestClientInterceptor();
  try {
    await client.post(
      `https://cognito-idp.${cognitoRegion}.amazonaws.com`,
      {
        Username: username,
        UserPoolId: cognitoPoolId,
      },
      {
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'x-amz-target': 'AWSCognitoIdentityProviderService.AdminDeleteUser',
        },
      }
    );
    if (logoutUser) {
      await Auth.signOut();
    }
    const message = 'Your account has been deleted.';
    return { success: true, message };
  } catch (err) {
    console.log(err);
    return { success: false, message: err };
  }
};
