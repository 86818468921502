import moment from "moment";

export interface UserTableRow {
  id: string;
  name: string;
  email: string;
  mainRole: string;
  enabled: boolean;
  username: string;
  isSupplier: boolean;
  roles: Array<string>;
  isAlumni: boolean;
  dateAdded: string;
  status?: string;
}

export const sortFunctions = {
  string: (column, order) => (a, b) => {
    const firstValue =
      column === "isAlumni" ? isUserAlumni(a[column]) : a[column];
    const secondValue =
      column === "isAlumni" ? isUserAlumni(b[column]) : b[column];
    if (order === "ASC") {
      return firstValue.toLowerCase() < secondValue.toLowerCase() ? -1 : 1;
    } else {
      return firstValue.toLowerCase() > secondValue.toLowerCase() ? -1 : 1;
    }
  },
  number: (column, order) => (a, b) => {
    return order === "ASC" ? a[column] - b[column] : b[column] - a[column];
  },
  date: (column, order) => (a, b) => {
    return order === "ASC"
      ? moment(a[column], "MMMM DD, YYYY").diff(
          moment(b[column], "MMMM DD, YYYY")
        )
      : moment(b[column], "MMMM DD, YYYY").diff(
          moment(a[column], "MMMM DD, YYYY")
        );
  },
};

export const isUserAlumni = (isAlumni: boolean) => {
  return isAlumni ? "Yes" : "No";
};

export const UserRolesData = {
  CHAMPION: {
    name: "Champion",
    description:
      "Champions are people looking to provide guidance, looking to invest, or corporations looking for the hot new thing!",
    icon: "icon-champions",
    url: "/onboarding/basic-information",
  },
  SUPPLIER: {
    name: "Supplier",
    description:
      "Suppliers are people providing a product or service, and are looking to network, open to investments and hiring.",
    icon: "icon-partnership",
    url: "/create-supplier-profile",
  },
};

export enum AdmissionStatus {
  PENDING = 1,
  APPROVED = 2,
  DENIED = 3
}
