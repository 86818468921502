import { types } from '../actions/customer';
import { CustomerCompleteProfile, CustomerInfo } from '../types/CustomerInfo';

export interface CustomerState {
  loading: boolean;
  hasErrors: boolean;
  errorMessage: string;
  requestFinished: boolean;
  customerInfo: CustomerInfo | CustomerCompleteProfile;
}

const initialState: CustomerState = {
  loading: false,
  hasErrors: false,
  errorMessage: '',
  requestFinished: false,
  customerInfo: undefined,
};

export default function CustomerReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_CUSTOMER_INFO_REQUEST:
    case types.UPDATE_CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
      };
    case types.ADD_CUSTOMER_INFO_SUCCESS:
    case types.UPDATE_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: true,
      };
    case types.ADD_CUSTOMER_INFO_FAILURE:
    case types.UPDATE_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
        requestFinished: true,
      };
    case types.GET_CUSTOMER_INFO_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
        customerInfo: null,
      };
    case types.GET_CUSTOMER_INFO_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        customerInfo: action.payload,
      };
    case types.GET_CUSTOMER_INFO_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        hasErrors: true,
        errorMessage: action.payload,
      };
    case types.RESET_CUSTOMER_ERRORS:
      return {
        ...state,
        loading: false,
        hasErrors: false,
        errorMessage: '',
        requestFinished: false,
      };
    default:
      return state;
  }
}
