export const mapAttributesToObject = (attributes: Array<any>) => {
  let obj = {} as any;
  attributes.forEach((attribute) => {
    obj[attribute.Name] = attribute.Value;
  });
  return obj;
};

export interface IAttributes {
  'custom:first_name'?: string;
  'custom:is_admin'?: string;
  'custom:isSuperAdmin'?: string;
  'custom:last_name'?: string;
  'custom:is_supplier'?: string;
  'custom:picture'?: string;
  email?: string;
  email_verified?: boolean;
  sub?: string;
  picture?: string;
  identities?: any;
  given_name?: string;
}
