import "react-toastify/dist/ReactToastify.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LandingPageImg from "../../styles/img/landingpage-img.png";
import DisruptImg from "../../styles/img/disrupt-img.png";
import FindAndConnectImg from "../../styles/img/find-and-connect-img.png";
import OneStopShopImg from "../../styles/img/1stopshop-img.png";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";
import { useContext, useEffect } from "react";
import { authContext } from "../../components/Authentication/CognitoContextProvider";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Home = () => {
  const history = useHistory();
  const { auth } = useContext(authContext);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10)
  })

  return (
    <>
      <Helmet>
        <title>BSN - BGV Supplier Network</title>
      </Helmet>
      <main className="container herobanner my-4 mx-auto">
        <div className="section herobanner row text-center d-flex justify-content-center justify-content-between px-3 py-3">
          <div className="col-12 col-md-6">
            <LazyLoadImage className="img-fluid" src={LandingPageImg} alt="herobanner" />
          </div>
          <div className="col-12 col-md-6 pt-3 d-flex flex-column justify-content-center text-left">
            <h1 className="font-primary text-black font-weight-light with-line">
              Unlocking access to
              <div className="font-weight-bold d-inline">
                {" "}
                intros, info, and innovations
              </div>
            </h1>
            <button
              className="btn btn-black btn-lg text-uppercase text-pinky rounded-0 align-self-start mt-3 py-3"
              onClick={() => {
                if (auth.data) {
                  history.push("/guided-search/area");
                } else {
                  history.push("/role-selection");
                }
              }}
            >
              Get Started Now!
            </button>
          </div>
        </div>
        <div className="section benefits py-5 ">
          <div className="col">
            <div className="divider d-flex my-4" />
            <h2 className="font-primary text-black font-weight-light with-line">
              Discover great{" "}
              <div className="font-weight-bold d-inline">opportunities</div>
            </h2>
          </div>
          <div className="row py-3 px-4 align-items-center justify-content-center">
            <div className="col-12 col-md-6 col-lg-4 my-4 text-center px-4">
              <h4 className="font-primary text-black px-5 font-weight-light">
                <div className="font-weight-bold d-inline">Disrupt </div>
                traditional capital flow
              </h4>
              <div className="img-resized">
                <LazyLoadImage
                  className="img-fluid"
                  src={DisruptImg}
                  alt="Disrupt Capital Flow"
                />
              </div>
              <p className="font-primary text-black my-3">
                Generate deal-flow, hyper-local insights, find great investments
                and diverse suppliers.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 my-4 text-center px-4">
              <h4 className="font-primary text-black px-2 font-weight-light">
                <div className="font-weight-bold d-inline">Find & Connect</div>{" "}
                with women founders and their initiatives
              </h4>
              <div className="img-resized">
                <LazyLoadImage
                  className="img-fluid"
                  src={FindAndConnectImg}
                  alt="Find and Connect Flow"
                />
              </div>
              <p className="font-primary text-black my-3">
                Hire, contract, mentor, and onboard new suppliers.
              </p>
            </div>
            <div className="col-12 col-md-6 col-lg-4 my-4 text-center px-4">
              <h4 className="font-primary text-black px-2 font-weight-light">
                <div className="font-weight-bold d-inline">
                  A one stop shop{" "}
                </div>
                for founders, local insights and more
              </h4>
              <div className="img-resized">
                <LazyLoadImage
                  className="img-fluid"
                  src={OneStopShopImg}
                  alt="One Stop Shop Flow"
                />
              </div>
              <p className="font-primary text-black my-3">
                Suppliers connect with other suppliers to build your network and
                find partnerships.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <button
              className="btn btn-outline-black text-uppercase rounded-0 align-self-start mt-3 py-3"
              onClick={() => {
                if (auth.data) {
                  history.push("/guided-search/area");
                } else {
                  history.push("/role-selection");
                }
              }}
            >
              Learn More
            </button>
          </div>
        </div>
      </main>
      <div className="section-testimonials mt-3 px-4 py-4 bg-pinky">
        <div>
          <div className="container d-lg-flex">
            <div className="img-resized">
              <LazyLoadImage
                referrerPolicy="no-referrer"
                src={LandingPageImg}
                alt="Testimonial"
                effect="blur"
                className="img-fluid px-md-3 py-md-3 testimonial-img align-self-center"
              />
            </div>
            <div className="testimonial__content px-md-3 px-3 py-md-3 align-self-center">
              <h2 className="font-primary text-black text-left with-line font-weight-light">
                We Provide
                <div className="font-weight-bold d-inline">
                  {`${" "}visibility and opportunity`}
                </div>
              </h2>
              <h4 className="font-primary caps-text text-left">Meet Amelia</h4>
              <div className="row px-4 py-4">
                <div className="col-12 col-md-2 text-left">
                  <i className="quote-mark icon-quote text-primary font-weight-bold" />
                </div>
                <div className="col-12 col-md-8 text-left">
                  <p className="text-black my-0 font-primary">
                    I was just starting out and venturing into new
                    opportunities. BSN helped me feel safe and supported all the
                    way.
                    <p className="font-primary text-black text-left my-3">
                      <div className="font-weight-bolder text-black">
                        Amelia James{" "}
                      </div>
                    </p>
                  </p>
                </div>
                <div className="col-12 col-md-2">
                  <i className="quote-mark icon-quoteup text-primary font-weight-bold align-self-end" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </Carousel> */}
      </div>
      <div className="section-partners bg-black">
        <div className="container px-4 py-5">
          <h1 className="font-primary text-pinky text-left mb-0 with-line font-weight-light">
            Our<div className="font-weight-bold d-inline"> Partners</div>
          </h1>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-2 text-center">
              <i className="icon-knightfoundation partner-icon text-pinky"></i>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 text-center">
              <i className="icon-visa partner-icon text-pinky"></i>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 text-center">
              <i className="icon-nike partner-icon text-pinky"></i>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 text-center">
              <i className="icon-omaze partner-icon text-pinky"></i>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 text-center">
              <i className="icon-kauffmanfoundation partner-icon text-pinky"></i>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-2 text-center">
              <i className="icon-kroger partner-icon text-pinky"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="section-cta">
        <div className="container px-4 py-5">
          <div className="row px-5 py-5 mx-1 bg-pinky d-flex flex-row position-relative">
            <div
              className={`row col-12 col-md-8 justify-content-start align-content-center px-3 pb-3 mt-2`}
            >
              <h1 className="font-primary text-black text-left mb-4 with-line font-weight-light">
                Get the insights you need and
                <b className="font-weight-bold"> maximize your reach</b>
              </h1>
              {!auth.data && (
                <button
                  className="btn btn-black text-uppercase text-pinky rounded-0 align-self-start py-3"
                  onClick={() => history.push("/role-selection")}
                >
                  Sign Up Now
                </button>
              )}
              <a
                className={`mb-0 
                ${auth.data ? "" : "px-md-3"}
                 align-self-center text-black contactus-atag`}
                href="mailto:contact@blackgirlventures.org"
              >
                {auth.data ? "Contact" : "Or contact"} us for more information
              </a>
            </div>
            <div className="col-md-3 col-sm-12">
              <i className="icon-growth text-primary insight-icon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
