export const backendUrl = process.env.REACT_APP_BACKEND_URL;
export const cognitoPoolId = process.env.REACT_APP_COGNITO_POOL_ID;
export const cognitoIdentityPoolId = process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID;
export const cognitoClientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
export const cognitoRegion = process.env.REACT_APP_COGNITO_REGION;
export const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN;
export const redirectUri = process.env.REACT_APP_REDIRECT_URI;
export const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
export const stripeSecretKey = process.env.REACT_APP_STRIPE_SECRET_KEY;
export const googleAnalyticsId = process.env.REACT_APP_ANALYTICS_ID;

const env = {
    backendUrl,
    cognitoPoolId,
    cognitoIdentityPoolId,
    cognitoClientId,
    cognitoRegion,
    cognitoDomain,
    redirectUri,
    stripePublishableKey,
    stripeSecretKey,
    googleAnalyticsId
};

export default env;