import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { getCurrentUser } from '../../auth/userUtils';
import { FadeLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { backendUrl } from '../../utils/env';

const Redirecting = () => {
  const history = useHistory();

  useEffect(() => {
    const fetchCurrentUser = async () => {
      const user = await getCurrentUser();
      const hasProfile = await axios.get(`${backendUrl}/Users/hasProfile/${user.attributes.sub}`);

      if (user) {
        if (!hasProfile.data) {
          localStorage.setItem('needsOpportunities', user.attributes.sub);
          history.push('/onboarding/professional-information')
        } else {
          localStorage.removeItem('needsOpportunities');
          const redirectUrl = localStorage.getItem('redirect-url');
          if (redirectUrl) {
            localStorage.removeItem('redirect-url');
            history.replace(redirectUrl);
          } else {
            history.replace('/');
          }
        }
        
      }
    };

    // Cognito returns an error when no user is authenticated (sign out)
    fetchCurrentUser().catch(() => {
      history.replace('/');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>BSN - Redirecting</title>
      </Helmet>
      <main className="container-lg py-5 px-4">
        <div className="redirection-title pt-5 d-flex flex-column align-items-center">
          <h5 className="text-black font-primary text-center">
            You're being redirected to
          </h5>
          <h5 className="text-black font-primary font-weight-bold text-center">
            BSN
          </h5>
        </div>
        <div className="loader row px-2 py-2 justify-content-center align-items-center">
          <div className="col d-flex justify-content-center">
            <FadeLoader color={'#f8dcd4'} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Redirecting;
