import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import {
  getSupplierById,
  resetSupplierErrors,
  updateSupplierInfo,
  resetSupplierInfo,
} from '../../actions/supplier';
import { isAdmin, updateProfile } from '../../auth/userUtils';
import { authContext } from '../../components/Authentication/CognitoContextProvider';
import { RootState } from '../../reducers';
import { backendUrl } from '../../utils/env';
import { v4 as uuidv4 } from 'uuid';
import { ICompanyStage, SupplierInfo } from '../../types/SupplierInfo';
import {
  uploadProfilePictureToS3,
  uploadSupplierPDFToS3,
} from '../../utils/uploadToS3';
import { City, State } from 'country-state-city';
import { getIsoCode } from '../../utils/getIsoCode';
import SupplierBasicInformation from '../../components/CreateSupplierViews/SupplierBasicInformation';
import SupplierOtherInformation from '../../components/CreateSupplierViews/SupplierOtherInformation';
import SupplierSearchSettings from '../../components/CreateSupplierViews/SupplierSearchSettings';
import moment from 'moment';
import validator from 'validator';
import bgvLogo from '../../styles/img/bgv-logo.png';

const EditProfile = () => {
  const { auth, setAuthData } = useContext(authContext);
  const history = useHistory();
  const { state: locationState } = useLocation<{comesFromAdmin: boolean, comesFromUnderAdmission: boolean}>();
  const params: { supplierId: string } = useParams();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [revenueGenerated, setRevenueGenerated] = useState('');
  const [establishmentDate, setEstablishmentDate] = useState('');
  const [currentStage, setCurrentStage] = useState<ICompanyStage>({
    id: 0,
    title: '',
    shortDescription: '',
    longDescription: '',
  });
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [image, setImage] = useState<File>();
  const [imageSrc, setImageSrc] = useState('');
  const [bio, setBio] = useState('');
  const [facebookUrl, setFacebookUrl] = useState('');
  const [twitterUrl, setTwitterUrl] = useState('');
  const [instagramUrl, setInstagramUrl] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [pitchVideoUrl, setPitchVideoUrl] = useState('');
  const [pitchDeckUrl, setPitchDeckUrl] = useState('');
  const [businessCanvasUrl, setBusinessCanvasUrl] = useState('');
  const [salesSheetUrl, setSalesSheetUrl] = useState('');
  const [pitchDeckDoc, setPitchDeckDoc] = useState<File>();
  const [businessCanvasDoc, setBusinessCanvasDoc] = useState<File>();
  const [salesSheetDoc, setSalesSheetDoc] = useState<File>();
  const [capabilitiesStatementUrl, setCapabilitiesStatementUrl] = useState('');
  const [capabilitiesStatementDoc, setCapabilitiesStatementDoc] =
    useState<File>();
  const [industries, setIndustries] = useState([]);
  const [communityVisibility, setCommunityVisibility] = useState(true);
  const [hiringVisibility, setHiringVisibility] = useState(true);
  const [investmentVisibility, setInvestmentVisibility] = useState(true);
  const [markets, setMarkets] = useState('');
  const [funding, setFunding] = useState('');
  const [orderQuantity, setOrderQuantity] = useState('');
  const [shippingTime, setShippingTime] = useState('');
  const [sellingCycle, setSellingCycle] = useState('');
  const [productUpdate, setProductUpdate] = useState('');
  const [teamMembers, setTeamMembers] = useState<any>([
    {
      id: uuidv4(),
      name: '',
      role: '',
      image: { file: null, src: '', updated: false },
    },
  ]);
  const [isProduct, setIsProduct] = useState(false);
  const [isService, setIsService] = useState(false);
  const [fieldOfOpportunity, setFieldOfOpportunity] = useState({
    smallBoutiques: false,
    mentorships: false,
    bigBoxRetailer: false,
    corporations: false,
    partnerships: false,
    investment: false,
    internship: false,
    openToWork: false,
  });

  const [allCities, setAllCities] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [imageWasModified, setImageWasModified] = useState(false);
  const [pitchDeckWasModified, setPitchDeckWasModified] = useState(false);
  const [businessCanvasWasModified, setBusinessCanvasWasModified] =
    useState(false);
  const [salesSheetWasModified, setSalesSheetWasModified] = useState(false);
  const [
    capabilitiesStatementWasModified,
    setCapabilitiesStatementWasModified,
  ] = useState(false);

  const [loading, setLoading] = useState(false);
  const [hasCalledUpdate, setHasCalledUpdate] = useState(false);
  const [infoHasLoaded, setInfoHasLoaded] = useState(false);
  const [profileSub, setProfileSub] = useState('');
  const supplierInfo: SupplierInfo = useSelector(
    (state: RootState) => state.supplier.supplierInfo
  );

  const { requestFinished, hasErrors, errorMessage } = useSelector(
    (state: RootState) => state.supplier
  );

  useEffect(() => {
    const getAllStates = State.getStatesOfCountry('US').filter(
      (s) => City.getCitiesOfState('US', s.isoCode).length > 0
    );
    setAllStates(getAllStates);
  }, []);

  useEffect(() => {
    if (auth && auth.data) {
      if (params.supplierId === 'new-supplier') {
        history.replace(`/supplier/edit/${auth.data.attributes.sub}`);
        dispatch(getSupplierById(auth.data.attributes.sub));
      } else if (
        params.supplierId !== auth.data.attributes.sub &&
        !isAdmin(auth.data)
      ) {
        history.replace('/');
      } else {
        dispatch(getSupplierById(params.supplierId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.data]);

  useEffect(() => {
    if (supplierInfo && supplierInfo.sub) {
      setFields();
      setInfoHasLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierInfo]);

  useEffect(() => {
    if (supplierInfo && allCities.length === 0 && state.length > 0) {
      setAllCities(
        City.getCitiesOfState('US', getIsoCode(supplierInfo.state, allStates))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, state, supplierInfo]);

  useLayoutEffect(() => {
    return () => {
      dispatch(resetSupplierInfo());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFields = () => {
    setFirstName(supplierInfo.firstName || '');
    setLastName(supplierInfo.lastName || '');
    setEmail(supplierInfo.email);
    setProfileSub(supplierInfo.sub);
    setImageSrc(supplierInfo.picture || '');
    if (supplierInfo.id) {
      if (supplierInfo.companyinformation) {
        const {
          funding,
          markets,
          orderQuantity,
          productUpdate,
          shippingTime,
          sellingCycle,
        } = supplierInfo.companyinformation;
        setIsProduct(supplierInfo.companyinformation.isProduct);
        setIsService(supplierInfo.companyinformation.isService);
        setFunding(funding);
        setMarkets(markets);
        setOrderQuantity(orderQuantity);
        setProductUpdate(productUpdate);
        setShippingTime(shippingTime);
        setSellingCycle(sellingCycle ? sellingCycle : '');
      } else {
        setIsProduct(false);
        setIsService(false);
        setFunding('');
        setMarkets('');
        setOrderQuantity('');
        setProductUpdate('');
        setShippingTime('');
        setSellingCycle('');
      }
      setCompanyName(supplierInfo.companyName);
      setRevenueGenerated(supplierInfo.revenueGenerated);
      setEstablishmentDate(
        supplierInfo.establishmentDate
          ? moment(supplierInfo.establishmentDate, 'YYYY-MM-DD').format(
              'MM/DD/YYYY'
            )
          : ''
      );
      supplierInfo.stage && setCurrentStage(supplierInfo.stage);
      setBio(supplierInfo.bio);
      setFacebookUrl(supplierInfo.facebook);
      setTwitterUrl(supplierInfo.twitter);
      setInstagramUrl(supplierInfo.instagram);
      setLinkedinUrl(supplierInfo.linkedin);
      setWebsiteUrl(supplierInfo.website);
      setPitchVideoUrl(supplierInfo.pitchVideo);
      setPitchDeckUrl(supplierInfo.pitchDeck);
      setBusinessCanvasUrl(supplierInfo.businessCanvas);
      setSalesSheetUrl(supplierInfo.salesSheet);
      setCapabilitiesStatementUrl(supplierInfo.capabilitiesStatement);
      setIndustries(supplierInfo.industries);
      setState(supplierInfo.state);
      setCity(supplierInfo.city);
      setAllCities(
        City.getCitiesOfState('US', getIsoCode(supplierInfo.state, allStates))
      );
      setCommunityVisibility(supplierInfo.isCommunityVisible);
      setHiringVisibility(supplierInfo.isHiringVisible);
      setInvestmentVisibility(supplierInfo.isInvestmentVisible);

      if (supplierInfo.teaminfo && supplierInfo.teaminfo.length > 0) {
        const parsedTeam = supplierInfo.teaminfo.map((m) => ({
          id: uuidv4(),
          name: m.name,
          role: m.role,
          image: { file: {}, src: m.image ? m.image : '' },
        }));
        setTeamMembers([...parsedTeam]);
      } else {
        setTeamMembers([
          {
            id: uuidv4(),
            name: '',
            role: '',
            image: { file: null, src: '', updated: false },
          },
        ]);
      }
      if (supplierInfo.fieldofopportunity) {
        setFieldOfOpportunity(supplierInfo.fieldofopportunity);
      }
    }
  };

  const fieldOfOpportunitySelected = () => {
    let response = false;
    for (const field in fieldOfOpportunity) {
      if (fieldOfOpportunity[field]) response = true;
    }
    return response;
  };

  const validateFieldsBeforeSubmit = () => {
    return (
      firstName.trim().length === 0 ||
      lastName.trim().length === 0 ||
      companyName.trim().length === 0 ||
      revenueGenerated.trim().length === 0 ||
      (establishmentDate.length > 0 &&
        (!validator.isDate(establishmentDate, {
          format: 'mm/dd/yyyy',
          strictMode: true,
          delimiters: ['/'],
        }) ||
          moment().diff(moment(establishmentDate, 'MM/DD/YYYY')) < 0)) ||
      establishmentDate.trim().length === 0 ||
      currentStage.id === 0 ||
      imageSrc.trim().length === 0 ||
      (pitchDeckUrl.length === 0 && !pitchDeckDoc) ||
      (!isProduct && !isService) ||
      industries.length === 0 ||
      state.trim().length === 0 ||
      markets.trim().length === 0 ||
      funding.trim().length === 0 ||
      orderQuantity.trim().length === 0 ||
      productUpdate.trim().length === 0 ||
      sellingCycle.trim().length === 0 ||
      !fieldOfOpportunitySelected()
    );
  };

  const hasValidTeamMembers = () => {
    let valid = false;

    teamMembers.forEach((member) => {
      if (member.name.trim().length > 0) {
        valid = true;
      }
    });

    return valid;
  };

  const redirectToLogin = () => {
    localStorage.setItem('redirect-url', `/supplier/edit/${profileSub}`);
    history.push('/login');
  };

  const showToast = () => {
    if (requestFinished && hasCalledUpdate) {
      if (hasErrors) {
        toast.error(errorMessage);
      } else {
        window.scrollTo(0, 0);
        locationState?.comesFromAdmin
          ? history.replace(`/profile/supplier/${params.supplierId}`)
          : locationState?.comesFromUnderAdmission
          ? history.replace('/account-under-admission')
          : history.goBack();
        setLoading(false);
        setDisabled(false);
        toast.success('Profile updated successfully', {
          toastId: 'profile-updated-success',
        });
        dispatch(getSupplierById(params.supplierId));
      }
      dispatch(resetSupplierErrors());
      setHasCalledUpdate(false);
    }
    return null;
  };

  const handleSelectChange = (event, isCity?) => {
    const val = event.target.value;
    if (isCity) {
      setCity(val);
    } else {
      setState(val);
      setCity('');
      setAllCities(City.getCitiesOfState('US', getIsoCode(val, allStates)));
    }
  };

  const handleFieldOfOpportunity = (e) => {
    const setting = e.target.value;
    setFieldOfOpportunity((prevState) => ({
      ...prevState,
      [setting]: !fieldOfOpportunity[setting],
    }));
  };

  const handleDocModification = (docType: string, deleted: boolean) => {
    switch (docType) {
      case 'pitchDeck':
        setPitchDeckWasModified(true);
        deleted && setPitchDeckUrl('');
        break;
      case 'businessCanvas':
        setBusinessCanvasWasModified(true);
        deleted && setBusinessCanvasUrl('');
        break;
      case 'salesSheet':
        setSalesSheetWasModified(true);
        deleted && setSalesSheetUrl('');
        break;
      case 'capabilitiesStatement':
        setCapabilitiesStatementWasModified(true);
        deleted && setCapabilitiesStatementUrl('');
        break;
      default:
        console.error('Could not identify the document you tried to upload.');
    }
  };

  const handleIndustryChange = (industry: string) => {
    if (industries.includes(industry)) {
      setIndustries(industries.filter((ind) => ind !== industry));
    } else if (industries.length < 2) {
      setIndustries([...industries, industry]);
    }
  };

  const getTeamMembers = async () => {
    try {
      let response = await Promise.all(
        teamMembers.map(async (member) => {
          if (member.image.updated && member.name.trim().length > 0) {
            const formattedMember = {
              name: member.name.trim(),
              role: member.role,
              image: '',
            };

            if (member.image.file) {
              const imgFormData = new FormData();
              imgFormData.append('imageKey', member.image.file.name);
              imgFormData.append('image', member.image.file);
              imgFormData.append('name', `${companyName}-${member.name}`);
              const imageRequest = await axios.post(
                `${backendUrl}/Suppliers/team-member/picture`,
                imgFormData,
                {
                  headers: {
                    'Content-Type': 'application/form-data',
                  },
                }
              );
              formattedMember.image = imageRequest.data;
            }
            return formattedMember;
          } else {
            return {
              name: member.name,
              role: member.role,
              image: member.image.src ? member.image.src : '',
            };
          }
        })
      );
      return response;
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleSubmit = async () => {
    const invalidFields = validateFieldsBeforeSubmit();

    if (invalidFields) {
      return;
    }

    let token = '';
    if (auth.data) {
      const getSession = await Auth.currentSession();
      token = getSession.getIdToken().getJwtToken();
    } else redirectToLogin();

    setLoading(true);
    setDisabled(true);
    let teamData = hasValidTeamMembers() ? await getTeamMembers() : [];
    teamData = teamData.filter((element) => element !== undefined);

    const supplierInfoPayload = {
      firstName,
      lastName,
      email,
      companyName,
      revenueGenerated,
      establishmentDate,
      stage: currentStage,
      bio,
      facebook: facebookUrl,
      twitter: twitterUrl,
      instagram: instagramUrl,
      linkedin: linkedinUrl,
      website: websiteUrl,
      pitchVideo: pitchVideoUrl,
      pitchDeck: pitchDeckUrl,
      businessCanvas: businessCanvasUrl,
      salesSheet: salesSheetUrl,
      capabilitiesStatement: capabilitiesStatementUrl,
      industries,
      state,
      city: city || '',
      companyInformation: {
        isProduct,
        isService,
        markets,
        funding,
        orderQuantity,
        shippingTime,
        productUpdate,
        sellingCycle,
      },
      teamInfo: teamData,
      fieldOfOpportunity,
      isCommunityVisible: communityVisibility,
      isHiringVisible: hiringVisibility,
      isInvestmentVisible: investmentVisibility,
    };

    try {
      const cognitoPayload = {
        'custom:first_name': firstName.replace(/,|"/g, '').trim(),
        'custom:last_name': lastName.replace(/,|"/g, '').trim(),
      };
      const docNamePrefix = `${firstName}-${lastName}`;
      let imageUrl = '';
      if (imageWasModified && image) {
        imageUrl = await uploadProfilePictureToS3(image, docNamePrefix);
        cognitoPayload['custom:picture'] = imageUrl;
      } else if (imageWasModified && !image) {
        cognitoPayload['custom:picture'] = '';
      }

      const updatedCognitoAttributes = await updateProfile(
        cognitoPayload,
        supplierInfo.username || auth.data.username,
        setAuthData
      );

      if (!updatedCognitoAttributes) {
        throw new Error();
      }

      // Update Pitch Deck Document
      let pitchDeckDocUrl = '';
      if (pitchDeckWasModified && pitchDeckDoc) {
        pitchDeckDocUrl = await uploadSupplierPDFToS3(
          pitchDeckDoc,
          docNamePrefix,
          'pitch-decks'
        );
        supplierInfoPayload.pitchDeck = pitchDeckDocUrl;
      } else if (pitchDeckWasModified && !pitchDeckDoc) {
        supplierInfoPayload.pitchDeck = '';
      }

      // Update Business Canvas Document
      let businessCanvasDocUrl = '';
      if (businessCanvasWasModified && businessCanvasDoc) {
        businessCanvasDocUrl = await uploadSupplierPDFToS3(
          businessCanvasDoc,
          docNamePrefix,
          'business-canvas'
        );
        supplierInfoPayload.businessCanvas = businessCanvasDocUrl;
      } else if (businessCanvasWasModified && !businessCanvasDoc) {
        supplierInfoPayload.businessCanvas = '';
      }

      // Update Sales Sheet Document
      let salesSheetDocUrl = '';
      if (salesSheetWasModified && salesSheetDoc) {
        salesSheetDocUrl = await uploadSupplierPDFToS3(
          salesSheetDoc,
          docNamePrefix,
          'sales-sheets'
        );
        supplierInfoPayload.salesSheet = salesSheetDocUrl;
      } else if (salesSheetWasModified && !salesSheetDoc) {
        supplierInfoPayload.salesSheet = '';
      }

      // Update Capabilities Statement Document
      let capabilitiesStatementDocUrl = '';
      if (capabilitiesStatementWasModified && capabilitiesStatementDoc) {
        capabilitiesStatementDocUrl = await uploadSupplierPDFToS3(
          capabilitiesStatementDoc,
          docNamePrefix,
          'capabilities-statements'
        );
        supplierInfoPayload.capabilitiesStatement = capabilitiesStatementDocUrl;
      } else if (
        capabilitiesStatementWasModified &&
        !capabilitiesStatementDoc
      ) {
        supplierInfoPayload.capabilitiesStatement = '';
      }

      if (supplierInfo.id) {
        dispatch(
          updateSupplierInfo({
            payload: supplierInfoPayload,
            token,
            supplierInfoId: supplierInfo.id,
          })
        );
      } else {
        toast.error('Profile does not exist', { toastId: 'profile-existnce-error' })
      }
    } catch (error) {
      toast.error('Something went wrong when updating the profile');
      console.error(error);
      setLoading(false);
      setDisabled(false);
    } finally {
      setHasCalledUpdate(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>BSN - Edit Supplier Profile</title>
      </Helmet>
      <main className="body-min-h90 section py-5 px-4">
        <div className="section pt-3 pb-2">
          <h1 className="h2 text-black text-center font-weight-bold font-primary">
            Edit Supplier Profile
          </h1>
        </div>
        {loading ? (
          <div className="align-middle ">
            <div className="d-flex justify-content-center mt-2 mb-0">
              <img
                src={bgvLogo}
                alt={'bgv-logo'}
                width={200}
                height={200}
                style={{ maxHeight: 200 }}
              />
            </div>
            <p className="loader-text text-center text-black mb-5 pt-3">
              Your profile is being updated, this might take a few seconds
            </p>
          </div>
        ) : (
          <>
            <SupplierBasicInformation
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              email={email}
              setEmail={setEmail}
              companyName={companyName}
              setCompanyName={setCompanyName}
              revenueGenerated={revenueGenerated}
              setRevenueGenerated={setRevenueGenerated}
              establishmentDate={establishmentDate}
              setEstablishmentDate={setEstablishmentDate}
              currentStage={currentStage}
              setCurrentStage={setCurrentStage}
              image={image}
              setImage={setImage}
              imageSrc={imageSrc}
              setImageSrc={setImageSrc}
              validEmail={true}
              isEdit
              setImageWasModified={setImageWasModified}
              infoHasLoaded={infoHasLoaded}
              isAdmin={isAdmin(auth.data)}
            />
            <SupplierOtherInformation
              bio={bio}
              setBio={setBio}
              facebookUrl={facebookUrl}
              setFacebookUrl={setFacebookUrl}
              twitterUrl={twitterUrl}
              setTwitterUrl={setTwitterUrl}
              instagramUrl={instagramUrl}
              setInstagramUrl={setInstagramUrl}
              linkedinUrl={linkedinUrl}
              setLinkedinUrl={setLinkedinUrl}
              websiteUrl={websiteUrl}
              setWebsiteUrl={setWebsiteUrl}
              pitchVideoUrl={pitchVideoUrl}
              setPitchVideoUrl={setPitchVideoUrl}
              pitchDeckDoc={pitchDeckDoc}
              setPitchDeckDoc={setPitchDeckDoc}
              businessCanvasDoc={businessCanvasDoc}
              setBusinessCanvasDoc={setBusinessCanvasDoc}
              salesSheetDoc={salesSheetDoc}
              setSalesSheetDoc={setSalesSheetDoc}
              isEdit
              capabilitiesStatementDoc={capabilitiesStatementDoc}
              setCapabilitiesStatementDoc={setCapabilitiesStatementDoc}
              pitchDeckUrl={pitchDeckUrl}
              businessCanvasUrl={businessCanvasUrl}
              salesSheetUrl={salesSheetUrl}
              capabilitiesStatementUrl={capabilitiesStatementUrl}
              handleDocModification={handleDocModification}
              isAdmin={isAdmin(auth.data)}
            />
            <SupplierSearchSettings
              isProduct={isProduct}
              setIsProduct={setIsProduct}
              isService={isService}
              setIsService={setIsService}
              industries={industries}
              handleIndustryChange={handleIndustryChange}
              handleSelectChange={handleSelectChange}
              allCities={allCities}
              city={city}
              allStates={allStates}
              state={state}
              markets={markets}
              setMarkets={setMarkets}
              funding={funding}
              setFunding={setFunding}
              orderQuantity={orderQuantity}
              setOrderQuantity={setOrderQuantity}
              shippingTime={shippingTime}
              setShippingTime={setShippingTime}
              productUpdate={productUpdate}
              setProductUpdate={setProductUpdate}
              sellingCycle={sellingCycle}
              setSellingCycle={setSellingCycle}
              teamMembers={teamMembers}
              setTeamMembers={setTeamMembers}
              fieldOfOpportunity={fieldOfOpportunity}
              handleFieldOfOpportunity={handleFieldOfOpportunity}
              communityVisibility={communityVisibility}
              setCommunityVisibility={setCommunityVisibility}
              hiringVisibility={hiringVisibility}
              setHiringVisibility={setHiringVisibility}
              investmentVisibility={investmentVisibility}
              setInvestmentVisibility={setInvestmentVisibility}
              disabled={disabled}
              handleSubmit={handleSubmit}
              isEdit
              isAdmin={isAdmin(auth.data)}
            />
            <div className="d-flex mx-0 mt-5 justify-content-center w-100 bor">
              <button
                className="btn btn-white  text-uppercase text-black rounded-5 mt-0 mt-md-4 px-4 mx-1 mx-md-3  border-black"
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.goBack();
                }}
              >
                Cancel
              </button>
              <button
                className="btn btn-black text-uppercase text-pinky rounded-5 mt-0 mt-md-4 px-5 mx-2 mx-md-3"
                disabled={disabled || validateFieldsBeforeSubmit()}
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo(0, 0);
                  handleSubmit();
                }}
              >
                Update Profile
              </button>
            </div>
          </>
        )}
      </main>
      {showToast()}
    </>
  );
};

export default EditProfile;
