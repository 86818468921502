import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import UltimatePagination from '../../components/Pagination/Pagination';
import {
  changeConnectionStatus,
  getAcceptedConnections,
  getPendingConnections,
  resetConnectionErrors,
} from '../../actions/connection';
import { authContext } from '../../components/Authentication/CognitoContextProvider';
import GridView from '../../components/GridView/GridView';
import ListView from '../../components/ListView/ListView';
import { ConfirmationModal } from '../../components/Modals/ConfirmationModal';
import { RootState } from '../../reducers';
import { requestMeeting } from '../../utils/connectionUtils';
import { ConnectionWithProfile } from '../../types/Connection';
import Auth from '@aws-amplify/auth';
import { useHistory } from 'react-router';
import { ListItemTypes } from '../../utils/validations';
import { redirectToLogin } from '../../utils/redirectToLogin';
import { search } from '../../actions/search';

const MyConnections = () => {
  const { auth } = useContext(authContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [listType, setListType] = useState('list');
  const [activeTab, setActiveTab] = useState('pending');
  const [activeFilter, setActiveFilter] = useState('all');
  const [userId, setUserId] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [name, setName] = useState('');
  const [payload, setPayload] = useState<any>({});
  const [activePage, setActivePage] = useState(1);
  const [userAction, setUserAction] = useState('');
  const {
    connections,
    requestFinished,
    hasErrors,
    errorMessage,
    toastMessage,
    loading,
    total,
  } = useSelector((state: RootState) => state.connection);

  const searchParameters = useSelector(
    (state: RootState) => state.search.searchParameters
  );

  useEffect(() => {
    if (auth && auth.data) {
      dispatch(
        getPendingConnections({
          userId: auth.data.attributes.sub,
          filter: activeFilter,
          activePage,
        })
      );
      setUserId(auth.data.attributes.sub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.data]);

  useEffect(() => {
    refreshConnections()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, activeFilter, activePage]);

  const refreshConnections = () => {
    if (auth && auth.data) {
      if (activeTab === 'pending') {
        dispatch(
          getPendingConnections({
            userId: auth.data.attributes.sub,
            filter: activeFilter,
            activePage,
          })
        );
      } else {
        dispatch(
          getAcceptedConnections({
            userId: auth.data.attributes.sub,
            activePage,
          })
        );
      }
    }
  }

  const showToast = () => {
    if (requestFinished) {
      if (hasErrors) {
        toast.error(errorMessage, {
          toastId: 'connection-actions-error',
        });
      } else {
        dispatch(search(searchParameters));
        toastMessage &&
          toast.success(toastMessage, {
            toastId: 'connection-actions-success',
          });
      }
      dispatch(resetConnectionErrors());
    }
    return null;
  };

  const handleRequestMeeting = async (connection: ConnectionWithProfile) => {
    try {
      let token = '';
      if (auth.data) {
        const getSession = await Auth.currentSession();
        token = getSession.getIdToken().getJwtToken();
      } else {
        toast.error('Your session has expired, please login again');
        redirectToLogin(history, '/my-connections');
        return;
      }
      const message = await requestMeeting(connection, auth, token);
      toast.success(message);
    } catch (err) {
      toast.error('An error occured while attempting to request the meeting', {
        toastId: 'request-meeting-error',
      });
      refreshConnections();
    }
  };

  const openModal = (type: string, n: string, p: any) => {
    setUserAction(type);
    setName(n);
    setModalIsOpen(true);
    setPayload({ filter: activeFilter, activePage, ...p });
  };

  const changeStatus = (
    connectionId: number,
    newStatus: number,
    name: string
  ) => {
    dispatch(
      changeConnectionStatus({
        connectionId,
        newStatus,
        userId,
        name,
        filter: activeFilter,
        activePage: handleCurrentPage(),
      })
    );
  };

  const changePage = (index: number) => {
    setActivePage(index);
  };

  const changeTab = (tab: string) => {
    setActivePage(1);
    setActiveTab(tab);
  };

  const changeFilter = (filter: string) => {
    setActivePage(1);
    setActiveFilter(filter);
  };

  const handleCurrentPage = () => {
    if (total - 1 > activePage * 10 - 10) {
      return activePage;
    } else {
      setActivePage(activePage - 1);
      return activePage - 1;
    }
  };

  const renderPagination = () => {
    return (
      total > 10 && (
        <nav className="table-pagination my-3 px-4 px-lg-0">
          <UltimatePagination
            hideFirstAndLastPageLinks={true}
            siblingPagesRange={1}
            boundaryPagesRange={1}
            totalPages={Math.ceil(total / 10)}
            currentPage={activePage}
            onChange={changePage}
          />
        </nav>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>BSN - My Connections</title>
      </Helmet>
      <main className="body-min-h90 admin-container py-5 px-0 px-lg-5">
        <div className="row mx-0 px-3 px-lg-0 pr-lg-1 pb-3 d-flex justify-content-end ml-md-5 pl-md-5">
          <div className="col text-left text-md-center pl-md-5">
            <h1 className="h2 py-2 font-primary text-black font-weight-bolder pl-md-3">
              My Connections
            </h1>
          </div>
          <div className="row px-0 text-center manage-users-actions mx-0">
            <i
              className={`${
                listType === 'grid'
                  ? 'icon-activestategridview'
                  : 'icon-gridview'
              } font-48 pointer-cursor text-black ${
                listType === 'grid' ? 'text-black' : 'text-muted'
              }`}
              onClick={() => {
                setListType('grid');
              }}
            />
            <i
              className={`${
                listType === 'list' ? 'icon-listview' : 'icon-listview-empty'
              } font-48 pointer-cursor text-black ${
                listType === 'list' ? 'text-black' : 'text-muted'
              }`}
              onClick={() => {
                setListType('list');
              }}
            />
          </div>
        </div>

        <div className="row d-flex justify-content-center mx-0 mb-3">
          <div className="navigator align-items-center mobile-w-100">
            <div className="col justify-content-center">
              <div className="btn-group btn-group-toggle mobile-w-100">
                <button
                  className={`tab-btn-width btn ${
                    activeTab === 'pending' ? 'btn-black' : 'inactive-tab'
                  }`}
                  type="button"
                  onClick={() => changeTab('pending')}
                >
                  Pending
                </button>
                <button
                  className={`tab-btn-width btn ${
                    activeTab === 'connected' ? 'btn-black' : 'inactive-tab'
                  }`}
                  type="button"
                  onClick={() => changeTab('connected')}
                >
                  Connected
                </button>
              </div>
            </div>
          </div>
        </div>
        {activeTab === 'pending' && (
          <div className="d-flex justify-content-center mt-2 mx-0">
            <div className="row d-flex justify-content-center mobile-w-100">
              <div className="col-auto px-0 all filter-width-hack">
                <button
                  className={`gray-btn-pill no-border ${
                    activeFilter === 'all' ? 'active-connection-filter' : ''
                  } `}
                  onClick={() => changeFilter('all')}
                >
                  All
                </button>
              </div>
              <div className="col-auto px-0 filter-width-hack mx-2">
                <button
                  className={`gray-btn-pill no-border ${
                    activeFilter === 'received'
                      ? 'active-connection-filter'
                      : ''
                  }`}
                  onClick={() => changeFilter('received')}
                >
                  Received
                </button>
              </div>
              <div className="col-auto px-0 filter-width-hack">
                <button
                  className={`gray-btn-pill no-border ${
                    activeFilter === 'sent' ? 'active-connection-filter' : ''
                  }`}
                  onClick={() => changeFilter('sent')}
                >
                  Sent
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="connections d-flex justify-content-center mt-3">
          {listType === 'grid' ? (
            <GridView
              openModal={openModal}
              data={connections}
              userId={userId}
              loading={loading}
              changeStatus={changeStatus}
              requestMeeting={handleRequestMeeting}
              tab={activeTab}
              type={ListItemTypes.CONNECTION}
            />
          ) : (
            <ListView
              openModal={openModal}
              data={connections}
              userId={userId}
              loading={loading}
              changeStatus={changeStatus}
              requestMeeting={handleRequestMeeting}
              tab={activeTab}
              type={ListItemTypes.CONNECTION}
            />
          )}
        </div>
        <div className="d-flex justify-content-center mt-3">
          {renderPagination()}
        </div>
        {showToast()}
        <ConfirmationModal
          isModalOpen={modalIsOpen}
          closeModal={() => {
            setModalIsOpen(false);
            setName('');
          }}
          modalMessage={
            userAction === 'disconnect'
              ? `Are you sure you want to disconnect from ${name}?`
              : `Are you sure you want to cancel this request to connect with ${name}?`
          }
          onConfirm={() => {
            if (userAction === 'disconnect')
              dispatch(changeConnectionStatus(payload));
            else if (userAction === 'cancelConnection') {
              changeStatus(
                payload.connectionId,
                payload.newStatus,
                payload.name
              );
            }
          }}
          confirmText={userAction === 'disconnect' ? 'Disconnect' : 'Accept'}
        />
      </main>
    </>
  );
};

export default MyConnections;
