import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { RootState } from '../../reducers';
import { useSelector } from 'react-redux';
import { ConnectionWithProfile } from '../../types/Connection';
import moment from 'moment';

interface Props {
  data: ConnectionWithProfile[];
  refreshTable: Function;
  openModal: Function;
  sortRequest: (column: string) => void;
  activeColumn: string;
  activeOrder: string;
  userId: string;
}

const ConnectionsTable = ({
  data,
  openModal,
  sortRequest,
  activeColumn,
  activeOrder,
  userId,
}: Props) => {
  const tableColumns = [
    { displayName: 'Name', value: 'connectionName', minWidth: '12rem' },
    { displayName: 'Email', value: 'connectionEmail', minWidth: '' },
    { displayName: 'Date Connected', value: 'modificationDate', minWidth: '' },
    { displayName: 'Meeting Request', value: 'meetRequested', minWidth: '' },
    { displayName: 'Opportunity', value: 'opportunities', minWidth: '' },
  ];

  const opportunities = {
    smallBoutiques: 'Small Boutiques',
    mentorships: 'Mentorships',
    bigBoxRetailer: 'Big Box Retailer',
    corporations: 'Corporations',
    partnerships: 'Partnerships',
    investment: 'Investment',
    internship: 'Internship',
    openToWork: 'Open to Work',
    ongoingContracts: 'Ongoing Contracts',
  };

  const loading: boolean = useSelector(
    (state: RootState) => state.user.loading
  );

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => (
      //eslint-disable-next-line
      <a
        className="btn btn-link"
        href="#"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          props.onClick(e);
        }}
      >
        {props.children}
      </a>
    )
  );

  const renderContextMenu = (connection: ConnectionWithProfile) => {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} bsPrefix="btn btn-link">
          <i className="icon-chevron-down font-16 text-black" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            className="dropdown-item font-14  text-black"
            onClick={() => {
              openModal(connection, 'Delete');
            }}
          >
            Disconnect
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderTableRows = () => {
    const rows = data.map((connection, index) => {
      const userIsReceiver = connection.receiverId === userId;
      const userToShow = userIsReceiver
        ? connection.sender
        : connection.receiver;
      const name = userToShow['custom:first_name']
        ? `${userToShow['custom:first_name']} ${userToShow['custom:last_name']}`.trim()
        : userToShow.given_name;
      let matchingOpportunities = Object.keys(
        connection.matchingFieldsOfOpportunity
      )
        .map((field) =>
          connection.matchingFieldsOfOpportunity[field] === true ? field : null
        )
        .filter((val) => !!val);
      matchingOpportunities = matchingOpportunities.map(
        (field) => opportunities[field]
      );
      return (
        <tr key={index}>
          <td className="align-middle" style={{ minWidth: '12rem' }}>
            {name}
          </td>
          <td className="align-middle">
            {userIsReceiver ? connection.senderEmail : connection.receiverEmail}
          </td>
          <td className="align-middle">
            {moment(connection.modificationDate).format('M/D/YYYY')}
          </td>
          <td className="align-middle">
            {connection.meetRequested ? '' : 'No '}Request Sent
          </td>
          <td className="align-middle" style={{ maxWidth: '12rem' }}>
            <p>{matchingOpportunities.join(', ') || 'None'}</p>
          </td>
          <td className="align-middle">{renderContextMenu(connection)}</td>
        </tr>
      );
    });
    return rows;
  };

  return (
    <div className="table-responsive table-height-hack ">
      <table className="table bgv table-hover table-borderless font-14 table-va-center">
        <thead>
          <tr className="table-color-pinky">
            {tableColumns.map((column, index) => {
              return (
                <th
                  key={`users-header-${index}`}
                  scope="col"
                  style={{ minWidth: column.minWidth }}
                >
                  <button
                    className={`table-sorter btn btn-link text-black font-weight-bold px-0 align-middle text-left ${
                      activeColumn === column.value ? 'active' : ''
                    }`}
                    onClick={() =>
                      column.displayName !== 'Opportunity' &&
                      sortRequest(column.value)
                    }
                  >
                    {column.displayName}
                    {column.displayName !== 'Opportunity' && (
                      <i
                        className={`${
                          activeColumn === column.value
                            ? activeOrder === 'ASC'
                              ? 'icon-chevron-up'
                              : 'icon-chevron-down'
                            : 'icon-chevron-down'
                        } pl-2`}
                      />
                    )}
                  </button>
                </th>
              );
            })}
            <th scope="col" />
          </tr>
        </thead>
        <tbody className="text-black">{renderTableRows()}</tbody>
      </table>
      {loading && (
        <div className="empty-results text-center py-4">
          <span className="text-muted">Loading...</span>
        </div>
      )}
      {data.length === 0 && !loading && (
        <div className="empty-results text-center py-4">
          <span className="text-muted">There are no connections to show.</span>
        </div>
      )}
    </div>
  );
};

export default ConnectionsTable;
