import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { search, setSearchParams } from "../../actions/search";
import { RootState } from "../../reducers";
import { City, State } from "country-state-city";
import {
  industriesInitialState,
  IFieldOfOpportunity,
  CustomerOpportunitiesInterface,
  customerOpportunitiesInitialState,
  IndustriesInterface,
  supplierOpportunitiesInitialState,
} from "../../types/SupplierInfo";
import {
  defaultSettings,
  objectHasNonSelectedValues,
} from "../../utils/searchUtils";
import GridView from "../../components/GridView/GridView";
import ListView from "../../components/ListView/ListView";
import { ListItemTypes } from "../../utils/validations";
import UltimatePagination from "../../components/Pagination/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import {
  requestConnection,
  resetConnectionErrors,
} from "../../actions/connection";
import { connectionStatus } from "../../types/Connection";
import { authContext } from "../../components/Authentication/CognitoContextProvider";
import { redirectToLogin } from "../../utils/redirectToLogin";
import { getUserSub } from "../../utils/getUserData";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import { isAdmin, isCustomer, isSupplier } from "../../auth/userUtils";
import Auth from "@aws-amplify/auth";
import { sendMeetEmail } from "../../utils/emailUtils";
import { SearchSettingsModal } from "../../components/Modals/SearchSettingsModal";
import { ISearchParameters } from "../../types/Search";

const Search = () => {
  const location = useLocation();
  const history = useHistory();
  const { searchResults, loading, total, searchParameters } = useSelector(
    (state: RootState) => state.search
  );
  const { auth } = useContext(authContext);
  const [state, setState] = useState(undefined);
  const [city, setCity] = useState(undefined);
  const [customerIndustries, setCustomerIndustries] =
    useState<IndustriesInterface>(industriesInitialState);
  const [opportunities, setOpportunities] =
    useState<CustomerOpportunitiesInterface>(customerOpportunitiesInitialState);
  const [supplierOpportunities, setSupplierOpportunities] =
    useState<IFieldOfOpportunity>(supplierOpportunitiesInitialState);
  const [shippingTime, setShippingTime] = useState("Any");
  const [sellingCycle, setSellingCycle] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const dispatch = useDispatch();
  const [listType, setListType] = useState("grid");
  const [activePage, setActivePage] = useState(
    localStorage.getItem("lastPage") ? +localStorage.getItem("lastPage") : 1
  );
  const [nameOnToastMessage, setNameOnToastMessage] = useState("");
  const [activateToast, setActivateToast] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setListType("grid");
    }
    const getAllStates = State.getStatesOfCountry("US").filter(
      (s) => City.getCitiesOfState("US", s.isoCode).length > 0
    );
    setAllStates(getAllStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [modalIsOpen]);

  useEffect(() => {
    const storedSettings = localStorage.getItem("searchSettings");
    const settings = storedSettings
      ? JSON.parse(storedSettings)
      : defaultSettings;
    let opportunityPayload = undefined;
    if (isCustomer(auth.data) || isAdmin(auth.data)) {
      opportunityPayload = {
        Mentorship: {
          value:
            settings.fieldsOfOpportunity.Mentorship !== undefined
              ? settings.fieldsOfOpportunity.Mentorship.value
              : customerOpportunitiesInitialState.Mentorship.value,
          icon: customerOpportunitiesInitialState.Mentorship.icon,
        },
        Investment: {
          value:
            settings.fieldsOfOpportunity.Investment !== undefined
              ? settings.fieldsOfOpportunity.Investment.value
              : customerOpportunitiesInitialState.Investment.value,
          icon: customerOpportunitiesInitialState.Investment.icon,
        },
        Procurement: {
          value:
            settings.fieldsOfOpportunity.Procurement !== undefined
              ? settings.fieldsOfOpportunity.Procurement.value
              : customerOpportunitiesInitialState.Procurement.value,
          icon: customerOpportunitiesInitialState.Procurement.icon,
        },
        Hiring: {
          value:
            settings.fieldsOfOpportunity.Hiring !== undefined
              ? settings.fieldsOfOpportunity.Hiring.value
              : customerOpportunitiesInitialState.Hiring.value,
          icon: customerOpportunitiesInitialState.Hiring.icon,
        },
        Partnerships: {
          value:
            settings.fieldsOfOpportunity.Partnerships !== undefined
              ? settings.fieldsOfOpportunity.Partnerships.value
              : customerOpportunitiesInitialState.Partnerships.value,
          icon: customerOpportunitiesInitialState.Partnerships.icon,
        },
        "Supplier Diversity": {
          value:
            settings.fieldsOfOpportunity["Supplier Diversity"] !== undefined
              ? settings.fieldsOfOpportunity["Supplier Diversity"].value
              : customerOpportunitiesInitialState["Supplier Diversity"].value,
          icon: customerOpportunitiesInitialState["Supplier Diversity"].icon,
        },
      };
      setOpportunities(opportunityPayload);
    } else if (isSupplier(auth.data)) {
      opportunityPayload = settings.fieldsOfOpportunity;
      setSupplierOpportunities(settings.fieldsOfOpportunity);
    }
    setShippingTime(settings.shippingTime || "Any");
    setSellingCycle(settings.sellingCycle);
    setCustomerIndustries(settings.industries);
    setState(settings.location.state);
    setCity(settings.location.city);
    const urlPage = getUrlPage();
    const searchParams = {
      page: urlPage,
      userId: getUserSub(auth),
      isSupplier: isSupplier(auth.data),
      industries: settings.industries,
      opportunities: opportunityPayload,
      sellingCycle: settings.sellingCycle,
      shippingTime: settings.shippingTime,
      location: {
        state: settings.location.state,
        city: settings.location.city,
      },
    };
    handleSearch(urlPage, searchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCurrentPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const { requestFinished, hasErrors, errorMessage } = useSelector(
    (state: RootState) => state.connection
  );

  useEffect(() => {
    if (requestFinished && !activateToast) {
      dispatch(resetConnectionErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestFinished, activateToast]);

  const handleRequestConnection = (
    supplier: {
      email: string;
      sub: string;
      fieldsOfOpportunity: IFieldOfOpportunity;
    },
    name: string
  ) => {
    if (auth && auth.data) {
      setActivateToast(true);
      setNameOnToastMessage(name);

      const payload = {
        senderEmail: auth.data.attributes.email,
        receiverEmail: supplier.email,
        status: connectionStatus.PENDING,
        senderId: auth.data.attributes.sub,
        receiverId: supplier.sub,
        fieldsOfOpportunity: supplier.fieldsOfOpportunity,
        callback: () => handleSearch(activePage),
      };
      dispatch(requestConnection(payload));
      const supplierDiv = document.getElementById(supplier.sub);
      supplierDiv.scrollTo({ behavior: "smooth" });
    } else {
      setNameOnToastMessage("");
      redirectToLogin(history, `/search?page=${activePage}`);
    }
  };

  const setCurrentPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsPage = urlParams.has("page")
      ? parseInt(urlParams.get("page"))
      : 1;
    setActivePage(paramsPage);
  };

  const hasSearched = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.has("hS");
  };

  const getUrlPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramsPage = urlParams.has("page")
      ? parseInt(urlParams.get("page"))
      : 1;
    return paramsPage;
  };

  const handleSearch = (page: number, data?) => {
    if (auth && auth.data && hasSearched()) {
      const urlPage = getUrlPage();
      const industryParam = data
        ? data.industries
        : searchParameters.industryPayload.industries;
      const opportunityParam = data
        ? data.opportunities
        : searchParameters.opportunityPayload.opportunities;
      const supplier = isSupplier(auth.data);
      const searchParams: ISearchParameters = {
        page: page === urlPage ? page : urlPage,
        userId: getUserSub(auth),
        isSupplier: supplier,
        industryPayload: {
          industries: industryParam,
          industrySelected: objectHasNonSelectedValues(industryParam),
        },

        opportunityPayload: {
          opportunities: opportunityParam,
          opportunitySelected: objectHasNonSelectedValues(opportunityParam),
        },
        sellingCycle: data ? data.sellingCycle : searchParameters.sellingCycle,
        shippingTime: data ? data.shippingTime : searchParameters.shippingTime,
        location: {
          state: data ? data.location.state : searchParameters.location.state,
          city: data ? data.location.city : searchParameters.location.city,
        },
      };
      dispatch(setSearchParams(searchParams));
      dispatch(search(searchParams));
    }
  };

  const changePage = (index: number) => {
    history.replace(`/search?page=${index}&hS=${true}`);
    localStorage.setItem("lastPage", String(index));
    setActivePage(index);
    window.scrollTo(0, 0);
    handleSearch(index);
  };

  const requestMeetingSearch = async (
    receiverName: string,
    receiverEmail: string,
    recipientId: string
  ) => {
    try {
      let token = "";
      if (auth.data) {
        const getSession = await Auth.currentSession();
        token = getSession.getIdToken().getJwtToken();
      } else {
        toast.error("Your session has expired, please login again");
        redirectToLogin(history, "/search");
        return;
      }
      const attributes = auth.data.attributes;
      const senderEmail = attributes.email;
      const senderName = attributes["custom:first_name"]
        ? `${attributes["custom:first_name"]} ${attributes["custom:last_name"]}`.trim()
        : attributes["given_name"];
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      await sendMeetEmail(
        senderEmail,
        senderName,
        receiverEmail,
        receiverName,
        attributes.sub,
        recipientId,
        headers
      );
      toast.success(
        `An email requesting a meeting with ${receiverName} has been sent!`
      );
    } catch (err) {
      toast.error("An error occured while attempting to request the meeting", {
        toastId: "request-meeting-error",
      });
    }
  };

  const storeSettings = (data) => {
    const storedSettings = localStorage.getItem("searchSettings");
    const settings = storedSettings
      ? JSON.parse(storedSettings)
      : defaultSettings;
    settings.userId = auth.data?.attributes.sub || "";
    const supplier = isSupplier(auth.data);
    settings.isSupplier = supplier;
    settings.fieldsOfOpportunity = data.opportunities;
    supplier
      ? setSupplierOpportunities(data.opportunities)
      : setOpportunities(data.opportunities);
    settings.shippingTime = data.shippingTime;
    setShippingTime(data.shippingTime);
    settings.sellingCycle = data.sellingCycle;
    setSellingCycle(data.sellingCycle);
    settings.industries = data.industryPayload
      ? data.industryPayload.industries
      : data.industries;
    setCustomerIndustries(settings.industries);
    settings.location.city = data.location ? data.location.city : data.city;
    setCity(settings.location.city);
    settings.location.state = data.location ? data.location.state : data.state;
    setState(settings.location.state);
    localStorage.setItem("searchSettings", JSON.stringify(settings));
  };

  const supplierOpportunityLabels = {
    smallBoutiques: "Small Boutiques",
    mentorships: "Mentorships",
    bigBoxRetailer: "Big Box Retailer",
    corporations: "Corporations",
    partnerships: "Partnerships",
    investment: "Investment",
    internship: "Internship",
    openToWork: "Open to Work",
    ongoingContracts: "Ongoing Contracts",
  };

  const renderFields = (
    fields:
      | IndustriesInterface
      | CustomerOpportunitiesInterface
      | IFieldOfOpportunity,
    isSupplier?: boolean
  ) => {
    const fieldsArray = Object.keys(fields).filter((field) => {
      return isSupplier ? fields[field] : fields[field].value;
    });
    const renderMoreTag = fieldsArray.length > 6 ? true : false;

    return fieldsArray.slice(0, 6).map((field, i) => {
      let label = isSupplier ? supplierOpportunityLabels[field] : field;
      return (
        <>
          <span className="text-black font-14" key={`field-${field}-${i}`}>
            {i === 0 ? label : `, ${label}`}
            {renderMoreTag && i === 5 ? " and more..." : ""}
          </span>
        </>
      );
    });
  };

  const renderPagination = () => {
    return (
      total > 10 && (
        <nav className="table-pagination my-3 px-4 px-lg-0">
          <UltimatePagination
            hideFirstAndLastPageLinks={true}
            siblingPagesRange={1}
            boundaryPagesRange={1}
            totalPages={Math.ceil(total / 10)}
            currentPage={activePage}
            onChange={changePage}
          />
        </nav>
      )
    );
  };

  const showToast = () => {
    if (requestFinished && activateToast) {
      if (hasErrors) {
        toast.error(errorMessage);
      } else {
        toast.success(
          `You have requested to connect with ${nameOnToastMessage}.`,
          {
            toastId: "sent-request-connection-toast",
          }
        );
        dispatch(resetConnectionErrors());
      }
      setActivateToast(false);
      dispatch(resetConnectionErrors());
    }
    return null;
  };

  return (
    <>
      <Helmet>
        <title>BSN - Search</title>
      </Helmet>
      {/* Header */}
      <div className="search-settings is-sticky">
        <div className="row mx-2 px-2 pr-lg-1 py-4">
          <div className="pl-2 d-flex align-items-center px-lg-5 col-6">
            {searchResults && searchResults.length > 0 && (
              <label className="font-primary text-black font-weight-bolder mb-0 font-18">
                {searchResults ? searchResults.length : 0} of {total} RESULTS
              </label>
            )}
          </div>
          <div className="pl-2 pr-2 pr-sm-0 px-lg-5 d-flex justify-content-end col-6">
            <button
              className="ml-2 btn btn-outline-black text-black d-flex justify-content-center align-items-center fit-content edit-search-btn pr-2 mr-lg-4"
              onClick={(e) => {
                e.stopPropagation();
                setModalIsOpen(true);
              }}
            >
              <i className="icon-filter font-20"></i>
              <span className="pl-2 font-14 texfont-weight-normal">
                Edit Search
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="search-settings">
        <div
          className={`row mx-2 px-0 px-lg-2 pt-1 pr-lg-1 d-flex justify-content-end`}
        >
          <div
            className="col-12 row pl-0 pt-lg-2 px-0 px-lg-4"
            style={{ marginRight: "-0.5rem" }}
          >
            <div className="col-6 d-flex align-items-center">
              <h3 className="font-primary text-black font-weight-bolder">
                Search
              </h3>
            </div>
            <div className="col-6 pl-2 px-lg-5 d-flex justify-content-end">
              {!isMobile && (
                <>
                  <button
                    className="btn-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListType("grid");
                    }}
                  >
                    <i
                      className={`${
                        listType === "grid"
                          ? "icon-activestategridview"
                          : "icon-gridview"
                      } font-48 pointer-cursor text-black ${
                        listType === "grid" ? "text-black" : "text-muted"
                      }`}
                    />
                  </button>
                  <button
                    className="btn-icon"
                    onClick={(e) => {
                      e.stopPropagation();
                      setListType("list");
                    }}
                  >
                    <i
                      className={`${
                        listType === "list"
                          ? "icon-listview"
                          : "icon-listview-empty"
                      } font-48 pointer-cursor text-black ${
                        listType === "list" ? "text-black" : "text-muted"
                      }`}
                    />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <>
          <div className="row mx-2 px-3 px-lg-2 pt-3 pr-lg-1 d-flex">
            <div className="col-6 col-md-4 pl-2 px-lg-5">
              <div className="row">
                <div className="col">
                  <label className="text-black font-weight-bolder text-uppercase mb-0">
                    Opportunity:
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  {renderFields(
                    isSupplier(auth.data)
                      ? supplierOpportunities
                      : opportunities,
                    isSupplier(auth.data)
                  )}
                </div>
              </div>
            </div>
            <div className="col-6 col-md-4 pl-2 px-lg-5">
              <div className="row">
                <div className="col">
                  {" "}
                  <label className="text-black font-weight-bolder text-uppercase mb-0">
                    Industries:
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col"> {renderFields(customerIndustries)}</div>
              </div>
            </div>
          </div>
          <div className="row mx-2 px-3 px-lg-2 pt-3 pr-lg-1 d-flex">
            <div className="col-12 pl-2 px-lg-5">
              <label className="text-black font-weight-bolder text-uppercase mb-0">
                Company Information:
              </label>
            </div>
          </div>
          <div className="row mx-2 px-3 px-lg-2 pr-lg-1 d-flex">
            <div className="col-5 col-md-4 pl-2 px-lg-5">
              <div className="row">
                <div className="col">
                  <label className="text-black font-weight-bolder font-14 mb-0">
                    Selling Cycle:
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="text-black  font-14">{sellingCycle}</label>
                </div>
              </div>
            </div>
            <div className="col-5 col-md-3 pl-2 px-lg-5">
              <div className="row">
                <div className="col">
                  <label className="text-black font-weight-bolder font-14 mb-0">
                    Shipping time:
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="text-black  font-14">{shippingTime}</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row mx-2 px-3 px-lg-2 pr-lg-1 pb-4 d-flex">
            <div className="col-12 pl-2 px-lg-5">
              <div className="row">
                <div className="col">
                  <label className="text-black font-weight-bolder text-uppercase font-14 mb-0">
                    Location:
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label className="text-black  font-14">
                    {state && (
                      <>
                        {state}
                        {city ? ", " : ""} {city}
                      </>
                    )}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <main className="body-min-h90 container ext-l-container py-5 px-4">
        <div className="search-results d-flex justify-content-center mt-3 connection-width-hack">
          {listType === "grid" ? (
            <GridView
              data={searchResults}
              loading={loading}
              type={ListItemTypes.SEARCH_RESULT}
              allStates={allStates}
              handleRequestConnection={handleRequestConnection}
              noDataMessage={
                hasSearched() ? "No results for this criteria" : ""
              }
              userId={auth && auth.data ? auth.data.attributes.sub : ""}
              requestMeetingSearch={requestMeetingSearch}
            />
          ) : (
            <ListView
              data={searchResults}
              loading={loading}
              type={ListItemTypes.SEARCH_RESULT}
              allStates={allStates}
              handleRequestConnection={handleRequestConnection}
              noDataMessage={
                hasSearched() ? "No results for this criteria" : ""
              }
              userId={auth && auth.data ? auth.data.attributes.sub : ""}
              requestMeetingSearch={requestMeetingSearch}
            />
          )}
        </div>
        <div className="d-flex justify-content-center">
          {renderPagination()}
        </div>
        {showToast()}
        {
          <SearchSettingsModal
            allStates={allStates}
            closeModal={() => {
              setModalIsOpen(false);
            }}
            isModalOpen={modalIsOpen}
            isSupplier={isSupplier(auth.data)}
            onConfirm={(data) => {
              storeSettings(data);
              setModalIsOpen(false);
              history.replace(`/search?page=1&hS=${true}`);
              localStorage.setItem("lastPage", String(1));
              setActivePage(1);
              handleSearch(1, data);
            }}
          />
        }
      </main>
    </>
  );
};

export default Search;
