import Modal from 'react-modal';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  username: string;
  enabled: boolean;
  name: string;
  handleUserStatus: (username: string, enabled: boolean, name: string) => void;
}

export const ChangeStatusModal = ({
  isOpen,
  closeModal,
  username,
  enabled,
  name,
  handleUserStatus,
}: Props) => {
  const customStyles = {
    content: {
      background: 'transparent',
      border: 'none',
      padding: 'none',
      position: 'absolute',
      display: 'block',
      margin: '0',
      transform: 'translate(-50%, -30%)',
      width: '90%',
      maxWidth: '360px',
      inset: 'unset',
      overflow: 'initial',
    },
    overlay: {
      zIndex: '3',
      backgroundColor: 'rgba(23, 41, 64, 0.75)',
    },
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
      onRequestClose={closeModal}
      closeTimeoutMS={500}
      ariaHideApp={false}
    >
      <div className="modal-content" style={{ borderRadius: '14.1562px' }}>
        <div className="modal-header py-4 border-0">
          <div className="modal-title px-3">
            <h5 className="modal-title font-primary text-black">
              Are you sure you want to{' '}
              {
                <strong>
                  {enabled ? 'disable' : 'enable'} {name}
                  's{' '}
                </strong>
              }
              account?
            </h5>
          </div>
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            onClick={closeModal}
            aria-label="Close"
          >
            <span aria-hidden="true"></span>
            <i className="icon-close-x" />
          </button>
        </div>
        <div className="modal-footer justify-content-center border-0 pb-4">
          <div className="row">
            <div className="col-6 text-right">
              <button
                className="btn btn-link btn-block btn-lg text-uppercase text-black rounded"
                onClick={closeModal}
              >
                <span>Cancel</span>
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-black btn-block btn-lg text-uppercase text-pinky rounded"
                onClick={() => handleUserStatus(username, enabled, name)}
              >
                {enabled ? 'Disable' : 'Enable'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
