import React from 'react';
import { useHistory } from 'react-router';

const GuidedSearchRedirect = () => {
  const history = useHistory();

  return (
    <>
      <div className="d-flex justify-content-left justify-content-md-center">
        <h2 className="text-black text-center font-primary font-weight-bolder">
          Crafting the Perfect Fit
        </h2>
      </div>
      <div className="d-flex flex-row text-center justify-content-left justify-content-md-center align-items-center mt-3 mt-md-5">
        <div
          className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
          style={{ height: '35px', width: '35px' }}
        >
          <span className="my-0 font-primary text-black font-20">4</span>
        </div>
        <span className="text-black py-2 ml-3 text-uppercase font-20">
          Guided Search
        </span>
      </div>
      <div className="d-flex justify-content-center mt-4 mt-md-5">
        <div className="d-flex justify-content-center align-items-center pink-circle text-center text-black">
          <i className="icon-plant"></i>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="btn btn-black btn-lg text-uppercase text-pinky rounded-5 mt-4"
          onClick={() => {
            history.push('/guided-search/area');
          }}
        >
          Go to Guided Search
        </button>
      </div>
    </>
  );
};

export default GuidedSearchRedirect;
