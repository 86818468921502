export const getIsoCode = (stateName: string, states) => {
  let isoCode;
  states.forEach((s) => {
    if (s.name === stateName) {
      isoCode = s.isoCode;
      return;
    }
  });
  return isoCode;
};
