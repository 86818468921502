
export const usersAndSuppliersColumns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'mainRole',
    label: 'Role',
  },
  {
    key: 'isAlumni',
    label: 'BGV Alumni'
  },
  {
    key: 'dateAdded',
    label: 'Date Added'
  }
];
  
export const adminsAndCustomersColumns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'mainRole',
    label: 'Role',
  },
  {
    key: 'isAlumni',
    label: 'BGV Alumni'
  },
  {
    key: 'dateAdded',
    label: 'Date Added'
  }
];
  
export const connectionsColumns = [
  {
    key: 'connectionName',
    label: 'Name'
  },
  {
    key: 'connectionEmail',
    label: 'Email',
  },
  {
    key: 'modificationDate',
    label: 'Date Connected'
  },
  {
    key: 'meetRequested',
    label: 'Meeting Requested'
  },
  {
    key: 'opportunities',
    label: 'Opportunity'
  }
]