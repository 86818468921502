import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backendUrl } from '../utils/env';

export const types = {
  RESET_CUSTOMER_ERRORS: 'errors/customer/reset',
  ADD_CUSTOMER_INFO_REQUEST: 'customer/post/pending',
  ADD_CUSTOMER_INFO_SUCCESS: 'customer/post/fulfilled',
  ADD_CUSTOMER_INFO_FAILURE: 'customer/post/rejected',
  GET_CUSTOMER_INFO_BY_ID_REQUEST: 'customer/getById/pending',
  GET_CUSTOMER_INFO_BY_ID_SUCCESS: 'customer/getById/fulfilled',
  GET_CUSTOMER_INFO_BY_ID_FAILURE: 'customer/getById/rejected',
  UPDATE_CUSTOMER_INFO_REQUEST: 'customer/put/pending',
  UPDATE_CUSTOMER_INFO_SUCCESS: 'customer/put/fulfilled',
  UPDATE_CUSTOMER_INFO_FAILURE: 'customer/put/rejected',
};

export const resetCustomerErrors = createAction('errors/customer/reset');

export const addCustomerInfo = createAsyncThunk(
  'customer/post',
  async (
    payload: {
      email: string;
      companyName: string;
      firstName: string;
      lastName: string;
      fieldOfOpportunity: any;
      isGoogle?: boolean;
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(`${backendUrl}/Customers`, payload);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getCustomerById = createAsyncThunk(
  'customer/getById',
  async (id: string) => {
    const response = await axios.get(`${backendUrl}/Customers/byId/${id}`);
    return response.data;
  }
);

export const updateCustomerInfo = createAsyncThunk(
  'customer/put',
  async ({ payload, token, customerInfoId }: any, thunkAPI) => {
    let authToken = '';
    if (token) {
      authToken = `Bearer ${token}`;
    }

    const headers = {
      Authorization: authToken,
    };

    try {
      const response = await axios.put(
        `${backendUrl}/Customers/${customerInfoId}`,
        payload,
        { headers }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
