import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  connectionStatus,
  ConnectionWithProfile,
  getConnectionMessage,
  sortFunctions,
} from "../types/Connection";
import { IFieldOfOpportunity } from "../types/SupplierInfo";
import { backendUrl } from "../utils/env";
import { getPendingNotifications } from "./notification";

export const types = {
  ADD_CONNECTION_REQUEST: "connection/post/pending",
  ADD_CONNECTION_SUCCESS: "connection/post/fulfilled",
  ADD_CONNECTION_FAILURE: "connection/post/rejected",
  ADMIN_ADD_CONNECTION_REQUEST: "connection/admin/post/pending",
  ADMIN_ADD_CONNECTION_SUCCESS: "connection/admin/post/fulfilled",
  ADMIN_ADD_CONNECTION_FAILURE: "connection/admin/post/rejected",
  GET_CONNECTION_REQUEST: "connection/get/pending",
  GET_CONNECTION_SUCCESS: "connection/get/fulfilled",
  GET_CONNECTION_FAILURE: "connection/get/rejected",
  GET_CONNECTION_BY_IDS_REQUEST: "connection/byIds/get/pending",
  GET_CONNECTION_BY_IDS_SUCCESS: "connection/byIds/get/fulfilled",
  GET_CONNECTION_BY_IDS_FAILURE: "connection/byIds/get/rejected",
  RESET_CONNECTION_ERRORS: "errors/connection/reset",
  CHANGE_CONNECTION_STATUS_REQUEST: "connection/changeStatus/pending",
  CHANGE_CONNECTION_STATUS_SUCCESS: "connection/changeStatus/fulfilled",
  CHANGE_CONNECTION_STATUS_FAILURE: "connection/changeStatus/rejected",
  GET_ALL_ACTIVE_CONNECTIONS_FOR_USER_REQUEST:
    "connections/get/active/forUser/pending",
  GET_ALL_ACTIVE_CONNECTIONS_FOR_USER_SUCCESS:
    "connections/get/active/forUser/fulfilled",
  GET_ALL_ACTIVE_CONNECTIONS_FOR_USER_FAILURE:
    "connections/get/active/forUser/rejected",
  SORT_CONNECTIONS_FOR_TABLE_REQUEST: "connections/sortForTable/pending",
  SORT_CONNECTIONS_FOR_TABLE_SUCCESS: "connections/sortForTable/fulfilled",
  SORT_CONNECTIONS_FOR_TABLE_FAILURE: "connections/sortForTable/rejected",
};

export const resetConnectionErrors = createAction("errors/connection/reset");

export const getAllActiveConnectionsForUser = createAsyncThunk(
  "connections/get/active/forUser",
  async (userId: string) => {
    const response = await axios.get(
      `${backendUrl}/Connections/active/${userId}`
    );
    return response.data;
  }
);

export const sortConnectionsForTable = createAsyncThunk(
  "connections/sortForTable",
  async ({
    connections,
    column,
    order,
    userEmail,
  }: {
    connections: ConnectionWithProfile[];
    column: string;
    order: string;
    userEmail: string;
  }) => {
    let type: string =
      column === "modificationDate"
        ? "date"
        : column === "connectionName" ||
          column === "connectionEmail" ||
          column === "meetRequested"
        ? column
        : typeof connections[0][column];

    let sortedConnections = [];

    switch (type) {
      case "connectionName":
      case "connectionEmail":
        sortedConnections = connections.sort(
          sortFunctions[type](column, order, userEmail)
        );
        break;
      case "meetRequested":
        let temp = connections.sort(sortFunctions["boolean"](column, order));
        let truePart = temp
          .filter((con) => con.meetRequested)
          .sort(sortFunctions["connectionName"](column, "ASC", userEmail));
        let falsePart = temp
          .filter((con) => !con.meetRequested)
          .sort(sortFunctions["connectionName"](column, "ASC", userEmail));
        sortedConnections =
          order === "ASC"
            ? falsePart.concat(truePart)
            : truePart.concat(falsePart);
        break;

      default:
        sortedConnections = connections.sort(
          sortFunctions[type](column, order)
        );
    }

    return {
      connections: sortedConnections,
    };
  }
);

export const requestConnection = createAsyncThunk(
  "connection/post",
  async (
    payload: {
      senderEmail: string;
      receiverEmail: string;
      status: number;
      senderId: string;
      receiverId: string;
      fieldsOfOpportunity: IFieldOfOpportunity;
      callback?: Function
    },
    thunkAPI
  ) => {
    try {
      const response = await axios.post(`${backendUrl}/Connections`, payload);
      thunkAPI.dispatch(
        getConnectionByIds({
          user1Id: payload.senderId,
          user2Id: payload.receiverId,
        })
      );
      if (payload.callback) payload.callback();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getPendingConnections = createAsyncThunk(
  "connection/get",
  async (payload: { userId: string; filter: string; activePage: number }) => {
    const { userId, filter, activePage } = payload;
    const response = await axios.get(
      `${backendUrl}/Connections/${userId}?status=pending&filter=${filter}&pageNumber=${activePage}`
    );
    return {
      connections: response.data.connections,
      total: response.data.total,
    };
  }
);

export const getAcceptedConnections = createAsyncThunk(
  "connection/get",
  async (payload: { userId: string; activePage: number }) => {
    const { userId, activePage } = payload;
    const response = await axios.get(
      `${backendUrl}/Connections/${userId}?status=accepted&pageNumber=${activePage}`
    );
    return {
      connections: response.data.connections,
      total: response.data.total,
    };
  }
);

export const changeConnectionStatus = createAsyncThunk(
  "connection/changeStatus",
  async (
    payload: {
      connectionId: number;
      newStatus: number;
      name: string;
      userId?: string;
      filter?: string;
      activePage?: number;
      isNotifications?: boolean;
    },
    thunkAPI
  ) => {
    const {
      connectionId,
      newStatus,
      userId,
      name,
      filter,
      activePage,
      isNotifications,
    } = payload;
    const response = await axios.put(
      `${backendUrl}/Connections/${connectionId}/status`,
      { newStatus }
    );
    if (!isNotifications) {
      if (newStatus === connectionStatus.DISCONNECTED) {
        thunkAPI.dispatch(getAcceptedConnections({ userId, activePage }));
      } else if (newStatus === connectionStatus.ADMIN_DELETED) {
        thunkAPI.dispatch(getAllActiveConnectionsForUser(userId));
      } else {
        thunkAPI.dispatch(
          getPendingConnections({ userId, filter, activePage })
        );
      }
    } else {
      thunkAPI.dispatch(
        getPendingNotifications({
          userId,
          activePage,
        })
      );
    }
    return {
      toastMessage: getConnectionMessage(newStatus, name),
      ...response.data,
    };
  }
);

export const adminAddConnection = createAsyncThunk(
  "connection/admin/post",
  async (
    payload: {
      headers: any;
      senderId: string;
      senderEmail: string;
      receiverId: string;
      receiverEmail: string;
      status: number;
    },
    thunkAPI
  ) => {
    const {
      headers,
      senderId,
      senderEmail,
      receiverId,
      receiverEmail,
      status,
    } = payload;
    const response = await axios.post(
      `${backendUrl}/Connections/adminAdd`,
      {
        senderId,
        senderEmail,
        receiverId,
        receiverEmail,
        status,
      },
      {
        headers,
      }
    );
    thunkAPI.dispatch(getAllActiveConnectionsForUser(senderId));

    return {
      toastMessage: "The connection has been added successfully!",
      ...response.data,
    };
  }
);

export const getConnectionByIds = createAsyncThunk(
  "connection/byIds/get",
  async (
    payload: {
      user1Id: string;
      user2Id: string;
    },
    thunkAPI
  ) => {
    const { user1Id, user2Id } = payload;
    const response = await axios.get(
      `${backendUrl}/Connections/byIds?user1Id=${user1Id}&user2Id=${user2Id}`
    );
    return response.data;
  }
);
