export interface SupplierInfo {
  id: number;
  creationDate: Date;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  revenueGenerated: string;
  establishmentDate: string;
  stage: ICompanyStage;
  bio: string;
  facebook: string;
  twitter: string;
  instagram: string;
  linkedin: string;
  website: string;
  pitchVideo: string;
  pitchDeck: string;
  businessCanvas: string;
  salesSheet: string;
  capabilitiesStatement: string;
  industries: string[];
  state: string;
  city: string;
  companyinformation: ICompanyInformation;
  teaminfo: ITeamMember[];
  fieldofopportunity: IFieldOfOpportunity;
  isCommunityVisible: boolean;
  isHiringVisible: boolean;
  isInvestmentVisible: boolean;
  isAlumni: boolean;
  sub: string;
  picture?: string;
  username?: string;
  admissionStatus: number;
}

export interface ITeamMember {
  id: string;
  name: string;
  role: string;
  image: {
    file: File;
    src: string;
  };
}

export interface ICompanyInformation {
  isProduct: boolean;
  isService: boolean;
  markets: string;
  funding: string;
  orderQuantity: string;
  shippingTime: string;
  productUpdate: string;
  sellingCycle: string;
}

export interface IFieldOfOpportunity {
  smallBoutiques: boolean;
  mentorships: boolean;
  bigBoxRetailer: boolean;
  corporations: boolean;
  partnerships: boolean;
  investment: boolean;
  internship: boolean;
  openToWork: boolean;
}

export const supplierOpportunitiesInitialState = {
  smallBoutiques: false,
  mentorships: false,
  bigBoxRetailer: false,
  corporations: false,
  partnerships: false,
  investment: false,
  internship: false,
  openToWork: false,
};

export interface SupplierCompleteProfile extends SupplierInfo {
  sub: string;
  lastName: string;
  firstName: string;
  picture: string;
  isSupplier: boolean;
}

export interface ICompanyStage {
  id: number;
  title: string;
  shortDescription: string;
  longDescription: string;
}

export const industriesSelectData = {
  edtech: "EdTech",
  appDevelopment: "App Development",
  professionalDevelopment: "Professional Development",
  wellness: "Wellness",
  arts: "Arts",
  makers: "Makers",
  beauty: "Beauty",
  design: "Design",
  finTech: "FinTech",
  fashionApparel: "Fashion / Apparel",
  esportsEgaming: "Esports / Egaming",
  hospitality: "Hospitality",
  restaurant: "Restaurant",
  shareEconomy: "Share Economy",
  insurTech: "InsurTech",
  constructionTech: "Construction Tech",
  marAdTech: "MarTech / AdTech",
  intersectional: "Intersectional",
  skinCare: "Skincare",
  hairCare: "Hair Care",
  healthCare: "Healthcare",
  businessDevelopment: "Business Development",
  webDevelopment: "Web Development",
  productDevelopment: "Product Development",
  other: "Other",
};

export const areasOfInterest = [
  { value: "Investment", label: "Investment" },
  { value: "Supplier", label: "Supplier" },
  { value: "Hiring", label: "Hiring" },
];

export const revenueGeneratedOptions = [
  '$0-$50K',
  '$50K-$250K',
  '$250K-$500K',
  '$500K+',
];

export const companyStages: ICompanyStage[] = [
  {
    id: 1,
    title: "Founding Team",
    shortDescription: "2+ co-founders",
    longDescription:
      "Ability to develop low-fi prototype, knows market target share and size. Has an outline of revenue model. Funding comes from Friends & Fam, Personal Credit",
  },
  {
    id: 2,
    title: "Setting the Vision",
    shortDescription: "Team has experienced the Problem",
    longDescription:
      "Low-fi prototype has been built, understands regulations & has strategy for compliance. Has existing pricing/business model, supports revenue model. Funding comes from Friends & Fam, Personal Credit",
  },
  {
    id: 3,
    title: "Value Proposition",
    shortDescription: "Team builds product, understands value chain",
    longDescription:
      "Working prototype has a product roadmap. Sales evidence the capture of target market. Articulates cost structure & unit economics in Industry. Funding comes from Angel Investors, Friends & Fam, Personal Credit",
  },
  {
    id: 4,
    title: "Validating an Investable Market",
    shortDescription:
      'Team understands the Market, has strong industry contacts',
    longDescription: `Understands Product management and costs, their total addressable market is over $1B. Projects revenues & costs, has strategy and clear KPIs. Funding comes from Angel Investors, Friends & Fam`,
  },
  {
    id: 5,
    title: "Profitable Business Model",
    shortDescription: "Team has clear strategy and understands sales",
    longDescription:
      "Product is almost ready for broad commercial distribution. Talks with large partners about distribution and marketing. Revenues and costs support future positive unit economics. Funding comes from VC: Seed Angel",
  },
  {
    id: 6,
    title: "Moving Beyond Early Adopters",
    shortDescription: "Product & Sales teams are ready for growth",
    longDescription: `Product is complete in Market & gathers feedback, suppliers & distributors see benefits from company's success. Customer acquisition costs go down while pricing goes up. Funding comes from VC: Series A Debt`,
  },
  {
    id: 7,
    title: "Product-Market Fit",
    shortDescription: "Executive team leads company through growth",
    longDescription:
      "Product is built for scaling and new offerings are in progress. Sales Cycle meets & beats Industry Standard. Business Model is validated, and has strong unit economics. Funding comes from VC: Series B, C",
  },
  {
    id: 8,
    title: "Scaling Up",
    shortDescription: "Team is recognized as market leaders in the industry",
    longDescription:
      "Product successfully handles dramatic growth, has hard-to-beat partnerships for distribution marketing and growth. Has rapid growth each month and has a path to profitability. Funding comes from VC: Series C, D+",
  },
  {
    id: 9,
    title: "Exit in Sight",
    shortDescription: "Team is prepared for mergers, acquisitions or IPO",
    longDescription: `Product is recognized at top of the industry, with a clear line of sight to industry dominance. Revenue has met or exceeded investors' targets for multiple years. Funding comes from Acquirers IPO`,
  },
];

export const shippingTimes = [
  "Immediately",
  "5 or 7 business days",
  "2-4 Weeks",
  "30 days",
  "60 days",
  "90 days",
  "Any",
];

export const sellingCycleData = ["Quarterly", "Annual", "Seasonal", "Any"];

export interface IndustriesInterface {
  EdTech: { value: boolean };
  "App Development": { value: boolean };
  "Professional Development": { value: boolean };
  Wellness: { value: boolean };
  Arts: { value: boolean };
  Makers: { value: boolean };
  Beauty: { value: boolean };
  Design: { value: boolean };
  FinTech: { value: boolean };
  "Fashion / Apparel": { value: boolean };
  "Esports / Egaming": { value: boolean };
  Hospitality: { value: boolean };
  Restaurant: { value: boolean };
  "Share Economy": { value: boolean };
  InsurTech: { value: boolean };
  "Construction Tech": { value: boolean };
  "MarTech / AdTech": { value: boolean };
  Intersectional: { value: boolean };
  Skincare: { value: boolean };
  "Hair Care": { value: boolean };
  Healthcare: { value: boolean };
  "Business Development": { value: boolean };
  "Web Development": { value: boolean };
  "Product Development": { value: boolean };
  Other: { value: boolean };
}

export const industriesInitialState: IndustriesInterface = {
  EdTech: { value: false },
  "App Development": { value: false },
  "Professional Development": { value: false },
  Wellness: { value: false },
  Arts: { value: false },
  Makers: { value: false },
  Beauty: { value: false },
  Design: { value: false },
  FinTech: { value: false },
  "Fashion / Apparel": { value: false },
  "Esports / Egaming": { value: false },
  Hospitality: { value: false },
  Restaurant: { value: false },
  "Share Economy": { value: false },
  InsurTech: { value: false },
  "Construction Tech": { value: false },
  "MarTech / AdTech": { value: false },
  Intersectional: { value: false },
  Skincare: { value: false },
  "Hair Care": { value: false },
  Healthcare: { value: false },
  "Business Development": { value: false },
  "Web Development": { value: false },
  "Product Development": { value: false },
  Other: { value: false },
};
export interface CustomerOpportunitiesInterface {
  Mentorship: { value: boolean; icon: string };
  Investment: { value: boolean; icon: string };
  Procurement: { value: boolean; icon: string };
  Hiring: { value: boolean; icon: string };
  Partnerships: { value: boolean; icon: string };
  "Supplier Diversity": { value: boolean; icon: string };
}

export const customerOpportunitiesInitialState = {
  Mentorship: { value: false, icon: "icon-mentor" },
  Investment: { value: false, icon: "icon-investment" },
  Procurement: { value: false, icon: "icon-procurement" },
  Hiring: { value: false, icon: "icon-hiring" },
  Partnerships: { value: false, icon: "icon-partnership" },
  "Supplier Diversity": { value: false, icon: "icon-corporations" },
};

export enum SellingCycles {
  Seasonal = "Seasonal",
  Quarterly = "Quarterly",
  Annual = "Annual",
  Any = "Any",
}
