import axios from 'axios';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import validator from 'validator';
import { isAdmin, isGoogleUser } from '../../auth/userUtils';
import { backendUrl } from '../../utils/env';
import { LinkedinRegularExpression } from '../../utils/validations';
import { authContext } from '../Authentication/CognitoContextProvider';

interface Props {
  linkedinUrl: string;
  setLinkedinUrl: Dispatch<SetStateAction<string>>;
  companyName: string;
  setCompanyName: Dispatch<SetStateAction<string>>;
  companyWebsite: string;
  setCompanyWebsite: Dispatch<SetStateAction<string>>;
  isEdit?: boolean;
}

const ProfessionalInformation = ({
  linkedinUrl,
  setLinkedinUrl,
  companyName,
  setCompanyName,
  companyWebsite,
  setCompanyWebsite,
  isEdit = false,
}: Props) => {
  const history = useHistory();
  const { auth } = useContext(authContext);
  const [highlightLinkedinUrl, setHighlightLinkedinUrl] = useState(false);
  const [highlightCompanyName, setHighlightCompanyName] = useState(false);
  const [invalidLinkedinUrl, setInvalidLinkedinUrl] = useState(false);
  const [invalidCharactersCompanyName, setInvalidCharactersCompanyName] =
    useState(false);
  const [invalidCharactersCompanyWebsite, setInvalidCharactersCompanyWebsite] =
    useState(false);
  const [hideBackButton, setHideBackButton] = useState(false);

  const anyFieldEmpty =
    linkedinUrl.trim().length === 0 || companyName.trim().length === 0;

  useEffect(() => {
    if (linkedinUrl) {
      setInvalidLinkedinUrl(
        linkedinUrl && !LinkedinRegularExpression.test(linkedinUrl)
      );
    }

    if (companyName) {
      setInvalidCharactersCompanyName(
        companyName && (companyName.includes('"') || companyName.includes(','))
      );
    }

    if (companyWebsite) {
      setInvalidCharactersCompanyWebsite(
        companyWebsite && !validator.isURL(companyWebsite)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      if (auth && auth.data && !isAdmin(auth.data)) {
        const hasProfile = await axios.get(
          `${backendUrl}/Users/hasProfile/${auth.data.attributes.sub}`
        );
        if (!hasProfile.data) {
          localStorage.setItem('needsOpportunities', auth.data.attributes.sub);
          history.push('/onboarding/professional-information');
        } else {
          localStorage.removeItem('needsOpportunities');
        }
      }
    };
    fetchCurrentUser();
    setHideBackButton(auth && auth.data && isGoogleUser(auth.data));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.data]);

  return (
    <>
      {!isEdit && (
        <div className="section pt-3 pb-2">
          <h1 className="font-weight-bold font-primary text-black text-left text-md-center">
            Setting up your Champion Account
          </h1>
        </div>
      )}
      <div className="d-flex flex-row justify-content-left justify-content-md-center align-items-center mt-3 mt-md-5">
        <div
          className="filled-roundstep mx-0 d-flex flex-column justify-content-center align-items-center"
          style={{ height: '35px', width: '35px' }}
        >
          <span className="my-0 font-primary text-black font-20">2</span>
        </div>
        <span className="text-black py-2 ml-3 text-uppercase font-20">
          Professional Information
        </span>
      </div>
      <form className="auth-form mt-4">
        <div
          className={`form-group ${
            invalidLinkedinUrl || highlightLinkedinUrl ? 'mb-2' : 'mb-4'
          }`}
        >
          <label className="form-label text-black font-primary font-weight-bolder">
            Linkedin <span className="text-danger">*</span>
          </label>
          <input
            className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 ${
              invalidLinkedinUrl || highlightLinkedinUrl ? 'is-invalid' : ''
            }`}
            type="text"
            id="linkedin-url"
            placeholder="www.linkedin.com/in/username, www.linkedin.com/pub/username"
            value={linkedinUrl}
            onChange={(event) => {
              const val = event.target.value;
              setHighlightLinkedinUrl(val.trim().length === 0);
              setInvalidLinkedinUrl(
                val && !LinkedinRegularExpression.test(val)
              );
              setLinkedinUrl(val);
            }}
          />
        </div>
        {highlightLinkedinUrl && (
          <div className="input-helper mb-2">
            <small className={`text-danger`}>
              <strong>Linkedin Url is required</strong>
            </small>
          </div>
        )}
        {invalidLinkedinUrl && (
          <div className="input-helper mb-2">
            <small className={`text-danger`}>
              <strong>Invalid Linkedin Url</strong>
            </small>
          </div>
        )}
        <div
          className={`form-group ${
            invalidCharactersCompanyName || highlightCompanyName
              ? 'mb-2'
              : 'mb-4'
          }`}
        >
          <label className="form-label text-black font-primary font-weight-bolder">
            Company Name <span className="text-danger">*</span>
          </label>
          <input
            className={`form-control border-left-0 border-top-0 border-right-0 rounded-0 ${
              invalidCharactersCompanyName || highlightCompanyName
                ? 'is-invalid'
                : ''
            }`}
            type="text"
            id="company-name"
            placeholder="Enter Company Name"
            value={companyName}
            onChange={(event) => {
              const val = event.target.value;
              setHighlightCompanyName(val.trim().length === 0);
              setInvalidCharactersCompanyName(
                val && (val.includes('"') || val.includes(','))
              );
              setCompanyName(val);
            }}
          />
        </div>
        {highlightCompanyName && (
          <div className="input-helper mb-2">
            <small className={`text-danger`}>
              <strong>Company Name is required</strong>
            </small>
          </div>
        )}
        {invalidCharactersCompanyName && (
          <div className="input-helper mb-2">
            <small className={`text-danger`}>
              <strong>Company Name can not have commas or double quotes</strong>
            </small>
          </div>
        )}
        <div className={`form-group`}>
          <label className="form-label text-black font-primary font-weight-bolder">
            Company Website{' '}
            <small className="text-kinda-muted font-weight-normal">
              (optional)
            </small>
          </label>
          <input
            className={`form-control border-left-0 border-top-0 border-right-0 rounded-0`}
            type="text"
            id="company-website"
            placeholder="www.mycompany.com"
            value={companyWebsite}
            onChange={(event) => {
              const val = event.target.value;
              setInvalidCharactersCompanyWebsite(val && !validator.isURL(val));
              setCompanyWebsite(val);
            }}
          />
        </div>
        {invalidCharactersCompanyWebsite && (
          <div className="input-helper mb-2">
            <small className={`text-danger`}>
              <strong>Invalid URL format</strong>
            </small>
          </div>
        )}
        {!isEdit && (
          <div className="d-flex flex-column-reverse flex-md-row justify-content-center">
            {!hideBackButton && (
              <button
                className="btn modal-cancel-btn text-black btn-lg text-uppercase mt-4 rounded-5 px-5 mx-1 mx-md-3"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Go back
              </button>
            )}
            <button
              className="btn btn-black btn-lg text-uppercase text-pinky rounded-5 mt-4 px-5"
              disabled={
                anyFieldEmpty ||
                invalidCharactersCompanyName ||
                invalidLinkedinUrl ||
                (companyWebsite.length > 0 && invalidCharactersCompanyWebsite)
              }
              onClick={(e) => {
                e.preventDefault();
                history.push('/onboarding/areas-of-opportunity');
              }}
            >
              Next
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default ProfessionalInformation;
