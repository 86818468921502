import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";
import moment from "moment";
import { getSupplierById, resetSupplierInfo } from "../../actions/supplier";
import { RootState } from "../../reducers";
import { SupplierCompleteProfile } from "../../types/SupplierInfo";
import { trimName } from "../../utils/validations";
import { authContext } from "../../components/Authentication/CognitoContextProvider";
import {
  connectionStatus,
  ConnectionWithProfile,
} from "../../types/Connection";
import {
  getConnectionByIds,
  requestConnection,
  resetConnectionErrors,
} from "../../actions/connection";
import { toast } from "react-toastify";
import { RingLoader } from "react-spinners";
import emptyStateProfilePic from "../../styles/img/empty-state-profile-pic.svg";
import { search } from "../../actions/search";
import { redirectToLogin } from "../../utils/redirectToLogin";
import { requestMeeting } from "../../utils/connectionUtils";
import { Auth } from "aws-amplify";
import validator from "validator";
import { isAdmin } from "../../auth/userUtils";
import { AdmissionStatus } from "../../types/User";

const ViewSupplierProfile = () => {
  const { auth } = useContext(authContext);
  const socialMedia: string[] = [
    "Instagram",
    "Twitter",
    "Facebook",
    "Linkedin",
    "Youtube",
    "Website",
  ];
  const documents: { name: string; attribute: string }[] = [
    { name: "Pitch Deck", attribute: "pitchDeck" },
    { name: "Business Canvas", attribute: "businessCanvas" },
    { name: "Line Sheet", attribute: "salesSheet" },
    { name: "Capabilities Statement", attribute: "capabilitiesStatement" },
  ];

  const businessOpportunities: { name: string; attribute: string }[] = [
    { name: "Big Box Retailer", attribute: "bigBoxRetailer" },
    { name: "Corporations", attribute: "corporations" },
    { name: "Internship", attribute: "internship" },
    { name: "Investment", attribute: "investment" },
    { name: "Mentorships", attribute: "mentorships" },
    { name: "Ongoing Contracts", attribute: "ongoingContracts" },
    { name: "Partnerships", attribute: "partnerships" },
    { name: "Small Boutiques", attribute: "smallBoutiques" },
    { name: "Open to Work", attribute: "openToWork" },
  ];

  const companyInformation: {
    name: string;
    attribute: string;
    icon: string;
  }[] = [
    {
      name: "Markets",
      attribute: "markets",
      icon: "icon-markets",
    },
    {
      name: "Funding",
      attribute: "funding",
      icon: "icon-fund",
    },
    {
      name: "Shipping Time",
      attribute: "shippingTime",
      icon: "icon-calendar",
    },
    {
      name: "Selling Cycle",
      attribute: "sellingCycle",
      icon: "icon-service",
    },
    {
      name: "Product Update",
      attribute: "productUpdate",
      icon: "icon-productupdate",
    },
    {
      name: "Minimum Order Quantity",
      attribute: "orderQuantity",
      icon: "icon-minoq",
    },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const params: { supplierId: string } = useParams();
  const [trimmedName, setTrimmedName] = useState("");
  const [activateToast, setActivateToast] = useState(false);
  const [invalidDate, setInvalidDate] = useState(true);

  const supplierProfile: SupplierCompleteProfile = useSelector(
    (state: RootState) => state.supplier.supplierInfo
  );

  const {
    requestFinished,
    hasErrors,
    errorMessage,
    loading: connectionLoading,
  } = useSelector((state: RootState) => state.connection);

  const loading: boolean = useSelector(
    (state: RootState) => state.supplier.loading
  );

  const searchParameters = useSelector(
    (state: RootState) => state.search.searchParameters
  );

  const connection: ConnectionWithProfile = useSelector(
    (state: RootState) => state.connection.connection
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.supplierId) {
      dispatch(getSupplierById(params.supplierId));
    } else {
      history.replace("/");
    }

    return () => {
      dispatch(resetSupplierInfo());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (params.supplierId) {
      dispatch(getSupplierById(params.supplierId));
    } else {
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.supplierId]);

  useEffect(() => {
    if (params.supplierId) {
      if (auth && auth.data) {
        dispatch(
          getConnectionByIds({
            user1Id: auth.data.attributes.sub,
            user2Id: params.supplierId,
          })
        );
      } else redirectToLogin(history, history.location.pathname);
    } else {
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (supplierProfile) {
      setTrimmedName(
        trimName(supplierProfile.firstName, supplierProfile.lastName)
      );
      supplierProfile.establishmentDate &&
        setInvalidDate(
          !validator.isDate(supplierProfile.establishmentDate, {
            format: "yyyy-mm-dd",
            strictMode: true,
            delimiters: ["-"],
          })
        );
    }
  }, [supplierProfile]);

  useEffect(() => {
    if (requestFinished && !activateToast) {
      dispatch(resetConnectionErrors());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestFinished, activateToast]);

  const showConnectButton = () => {
    if (
      supplierProfile &&
      supplierProfile.admissionStatus !== AdmissionStatus.APPROVED
    )
      return false;

    if (params.supplierId) {
      if (auth && auth.data && auth.data.attributes.sub === params.supplierId) {
        return false;
      } else return true;
    }
    return false;
  };

  const handleRequestConnection = () => {
    const payload = {
      senderEmail: auth.data.attributes.email,
      receiverEmail: supplierProfile.email,
      status: connectionStatus.PENDING,
      senderId: auth.data.attributes.sub,
      receiverId: supplierProfile.sub,
      fieldsOfOpportunity: supplierProfile.fieldofopportunity,
    };
    setActivateToast(true);
    dispatch(requestConnection(payload));
  };

  const handleRequestMeeting = async (connection: ConnectionWithProfile) => {
    try {
      let token = "";
      if (auth.data) {
        const getSession = await Auth.currentSession();
        token = getSession.getIdToken().getJwtToken();
      } else {
        toast.error("Your session has expired, please login again");
        redirectToLogin(history, window.location.pathname);
        return;
      }
      setActivateToast(true);
      const message = await requestMeeting(connection, auth, token);
      toast.success(message);
    } catch (err) {
      toast.error("An error occured while attempting to request the meeting", {
        toastId: "request-meeting-error",
      });
    }
  };

  const showToast = () => {
    if (requestFinished && activateToast) {
      if (hasErrors) {
        toast.error(errorMessage);
      } else {
        dispatch(search(searchParameters));
        toast.success(
          `You have requested to connect with ${
            supplierProfile ? trimmedName : ""
          }.`,
          { toastId: "request-connection-toast" }
        );
        dispatch(resetConnectionErrors());
      }
      setActivateToast(false);
      dispatch(resetConnectionErrors());
    }
    return null;
  };

  const renderSocialMedia = () => {
    const mappedIcons = socialMedia.map((media, index) => {
      let mediaAttribute = media.toLowerCase();
      return supplierProfile && supplierProfile[mediaAttribute] ? (
        <li className="list-inline-item" key={`social-media-${index}`}>
          <a
            className="btn-link text-black text-decoration-none"
            href={
              supplierProfile
                ? `${
                    supplierProfile[mediaAttribute].includes("http://") ||
                    supplierProfile[mediaAttribute].includes("https://")
                      ? supplierProfile[mediaAttribute]
                      : `//${supplierProfile[mediaAttribute]}`
                  }`
                : ""
            }
            target="_blank"
            rel="noreferrer"
          >
            <i
              className={`icon-${
                media !== "Website" ? mediaAttribute : "globe"
              }`}
            />
            <span className="sr-only">{media}</span>
          </a>
        </li>
      ) : null;
    });
    return mappedIcons;
  };

  const getProductServiceText = () => {
    if (supplierProfile && supplierProfile.companyinformation) {
      const isProduct = supplierProfile.companyinformation.isProduct;
      const isService = supplierProfile.companyinformation.isService;
      return `${isProduct ? "Product" : ""}${
        isProduct && isService ? "/" : ""
      }${isService ? "Service" : ""}`;
    } else {
      return "N/A";
    }
  };

  const getFormattedCompanyProductInfo = (attribute: string) => {
    if (supplierProfile && supplierProfile.companyinformation) {
      if (attribute === "funding") {
        return `$ ${supplierProfile.companyinformation.funding}`;
      } else if (attribute === "orderQuantity") {
        return `${supplierProfile.companyinformation.orderQuantity} ${
          parseInt(supplierProfile.companyinformation.orderQuantity) > 1
            ? "items"
            : "item"
        }`;
      } else {
        return supplierProfile.companyinformation[attribute] || "N/A";
      }
    } else {
      return "N/A";
    }
  };

  const getCorrespondingButton = (connectionStatus: number) => {
    if (connectionStatus === 1) {
      return (
        <>
          <button
            className="btn btn-black btn-md text-white rounded-5 mt-2 px-5 font-weight-normal"
            type="button"
            disabled={true}
          >
            <label className="font-primary font-weight-normal d-flex justify-content-center align-items-center my-0">
              <i className="icon-link text-white mr-2 font-16 align-middle " />
              Pending
            </label>
          </button>
        </>
      );
    }

    if (connectionStatus === 2) {
      return (
        <>
          {" "}
          <button
            className="btn btn-black btn-md text-white rounded-5 mt-2 px-5 font-weight-normal"
            onClick={() => handleRequestMeeting(connection)}
          >
            Request a Meeting
          </button>
        </>
      );
    }
    return (
      <>
        <button
          className="btn btn-black btn-lg text-white rounded-5 mt-2 px-5"
          type="button"
          onClick={() => {
            handleRequestConnection();
          }}
          disabled={connectionLoading}
        >
          <i className="icon-link text-white mr-2" />
          Connect with me
        </button>
      </>
    );
  };

  const renderTableHeader = () => {
    const productServiceTh = (
      <>
        <th className="text-black font-weight-black font-14 align-items-center align-middle">
          <i className="icon-productservice font-24 text-black mr-2" />
          {getProductServiceText()}
        </th>
        <th className="text-black font-weight-black font-14 align-items-center align-middle pb-0">
          <p>{`Revenue: ${
            supplierProfile.revenueGenerated
              ? supplierProfile.revenueGenerated
              : "N/A"
          }`}</p>
        </th>
      </>
    );

    const companyStageTh = supplierProfile.stage &&
      supplierProfile.stage.id !== 0 && (
        <th
          colSpan={2}
          className="text-black  align-items-center align-middle w-100"
        >
          <div className="row">
            <div className="col-auto d-flex align-items-center ml-1 mr-3">
              <span className="company-stage-i d-flex justify-content-center align-items-center font-12">
                i
              </span>
            </div>
            <div className="col-auto pl-0">
              <div className="row">
                <p className="font-weight-bold font-14 mb-1">Company Stage:</p>
              </div>
              <div className="row">
                <p className="font-weight-normal font-16 mb-1">{`Stage ${supplierProfile.stage.id}: ${supplierProfile.stage.title}`}</p>
              </div>
            </div>
          </div>
        </th>
      );

    return (
      <thead>
        <tr>{companyStageTh}</tr>
        <tr>{productServiceTh}</tr>
      </thead>
    );
  };

  return (
    <>
      <Helmet>
        <title>BSN - Supplier{supplierProfile ? `: ${trimmedName}` : ""}</title>
      </Helmet>
      <main
        className="body-min-h90 container-lg px-4 pt-3 supplier-profile"
        style={{ marginBottom: "10rem" }}
      >
        {loading ? (
          <div className="align-middle mt-5">
            <div className="d-flex justify-content-center">
              <RingLoader size={120} loading={loading} />
            </div>
            <p className="loader-text text-center text-black">
              Loading Profile
            </p>
          </div>
        ) : (
          <>
            <div className="row d-flex justify-content-between mr-0 mr-md-3">
              <button
                className="d-flex justify-content-center align-items-center go-back-btn text-pinky ml-3"
                onClick={() => {
                  history.goBack();
                }}
              >
                <i className="icon-chevron-left" />
              </button>
              {supplierProfile &&
                auth &&
                auth.data &&
                (isAdmin(auth.data) ||
                  auth.data.attributes.sub === params.supplierId) && (
                  <Link
                    className="btn-btn-link text-decoration-none"
                    to={`/supplier/edit/${params.supplierId}`}
                  >
                    <i className="icon-settings text-black font-40" />
                  </Link>
                )}
            </div>
            <div className="avatar-opener d-flex justify-content-center align-items-center pt-4">
              <div className="supplier-big-avatar">
                <LazyLoadImage
                  referrerPolicy="no-referrer"
                  src={
                    supplierProfile && supplierProfile.picture
                      ? supplierProfile.picture
                      : emptyStateProfilePic
                  }
                  alt=""
                  effect="blur"
                />
              </div>
            </div>
            <div className="supplier-name text-center mt-3 mb-3">
              <h1 className="h2 text-black font-primary font-weight-bold mb-1">
                {supplierProfile ? `${trimmedName}` : "Profile Not Found"}
              </h1>
            </div>
            <section className="body pb-3 px-4 pt-0 text-center">
              <div className="mb-4">
                <i className="icon-location font-24 text-black mr-2 align-middle" />
                <span className="text-black font-20 font-weight-bold align-middle">
                  {supplierProfile &&
                    `${
                      supplierProfile.city ? `${supplierProfile.city}, ` : ""
                    }${supplierProfile.state || ""}`}
                </span>
              </div>
              <p className="text-black font-primary font-20">
                {supplierProfile ? supplierProfile.bio : ""}
              </p>
              {showConnectButton() &&
                getCorrespondingButton(connection ? connection.status : 0)}
            </section>
            <div className="social-links mb-4">
              <ul className="list-unstyled list-inline text-center">
                {renderSocialMedia()}
              </ul>
            </div>
            {supplierProfile && (
              <>
                <section className="media-section">
                  {supplierProfile && supplierProfile.pitchVideo && (
                    <div className="d-flex justify-content-center mb-4">
                      <div
                        className="pitch-video-billboard embed-responsive embed-responsive-16by9 py-5"
                        style={{ width: "482px", height: "265px" }}
                      >
                        <ReactPlayer
                          url={supplierProfile.pitchVideo}
                          controls={true}
                          width="560"
                          height="315"
                        />
                      </div>
                    </div>
                  )}

                  <div className="row mx-0 d-flex justify-content-center mb-4">
                    {supplierProfile.industries &&
                      supplierProfile.industries.map((industry, i) => {
                        return (
                          <div
                            className="col-auto mx-4 px-0"
                            key={`industry-${i}-${industry}`}
                          >
                            <label className="area-label text-black font-primary font-18 text-center py-1 px-4">
                              {industry}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                  {supplierProfile && supplierProfile.fieldofopportunity && (
                    <section className="business-opportunity pt-4 mb-5">
                      <div className="bg-pinky text-center mt-3 py-4 mb-3">
                        <h1
                          className="h3 text-black font-primary font-weight-bold"
                          style={{ fontSize: "18pt" }}
                        >
                          Business Opportunity
                        </h1>
                      </div>
                      <div className="row mx-0">
                        {businessOpportunities.map((business, index) => {
                          return supplierProfile.fieldofopportunity[
                            business.attribute
                          ] ? (
                            <div
                              className="col-6 text-black font-primary font-weight-bold py-2"
                              key={`business-opportunities-${business.name}-${index}`}
                            >
                              <p>{business.name}</p>
                            </div>
                          ) : (
                            <></>
                          );
                        })}
                      </div>
                    </section>
                  )}
                </section>
                {supplierProfile && supplierProfile.companyinformation && (
                  <section className="company-product-information mt-4">
                    <div className="bg-pinky text-center mt-3 pt-1 pb-1">
                      <h1 className="text-black font-primary font-weight-bold font-32">
                        {supplierProfile.companyName}
                      </h1>
                      {!invalidDate && (
                        <p className="text-black font-primary font-weight-bold font-20">
                          {`Established in   ${moment(
                            supplierProfile.establishmentDate,
                            "YYYY-MM-DD"
                          ).format("MM/DD/YYYY")}`}
                        </p>
                      )}
                    </div>
                    <table className="table bgv table-hover table-borderless font-14 table-va-center">
                      {renderTableHeader()}
                      <tbody className="text-black">
                        {supplierProfile &&
                          companyInformation.map((info, index) => {
                            return (
                              <tr
                                key={`company-info-${info.attribute}-${index}`}
                              >
                                <td className="d-flex align-items-center align-left font-weight-bold text-uppercase font-16 align-middle">
                                  <i
                                    className={`${info.icon} text-black font-32 mr-2`}
                                  />
                                  {info.name}
                                </td>
                                <td className="align-items-center align-left font-16 text-muted align-middle">
                                  {getFormattedCompanyProductInfo(
                                    info.attribute
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    {documents && documents.length > 0 && (
                      <div className="pdf-documents-container d-flex justify-content-center mt-4 mx-0">
                        <div
                          className="row d-flex justify-content-center"
                          style={{ width: "75vh" }}
                        >
                          {documents.map((doc, index) => {
                            return (
                              supplierProfile[doc.attribute] && (
                                <div
                                  className="col-3 d-flex flex-column align-items-center"
                                  key={`doc-button-${doc.name}-${index}`}
                                >
                                  <div className="profile-media-button">
                                    <a
                                      href={supplierProfile[doc.attribute]}
                                      className="text-black px-0 text-decoration-none"
                                    >
                                      <i className="icon-pdf font-40 text-black" />
                                      <span className="sr-only">
                                        {doc.name}
                                      </span>
                                    </a>
                                  </div>
                                  <div className="d-flex text-center">
                                    <p className="font-weight-bold text-black font-16">
                                      {doc.name}
                                    </p>
                                  </div>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </section>
                )}
                {supplierProfile &&
                  supplierProfile.teaminfo &&
                  supplierProfile.teaminfo.length > 0 && (
                    <section className="meet-the-team mt-5">
                      <div className="bg-pinky text-center mt-3 py-4">
                        <h1
                          className="h3 text-black font-primary font-weight-bold"
                          style={{ fontSize: "18pt" }}
                        >
                          Meet the Team
                        </h1>
                      </div>
                      <div className="justify-content-center d-flex">
                        <div className="row mx-0 justify-content-center meet-team-row">
                          {supplierProfile.teaminfo.map((member, index) => {
                            return (
                              <div
                                className="col-6 col-md-3"
                                key={`member-${index}-${member.name}`}
                              >
                                <div className="avatar-opener d-flex justify-content-center align-items-center pt-4">
                                  <div className="medium-avatar">
                                    <LazyLoadImage
                                      referrerPolicy="no-referrer"
                                      src={
                                        member && member.image
                                          ? member.image
                                          : emptyStateProfilePic
                                      }
                                      alt=""
                                      effect="blur"
                                    />
                                  </div>
                                </div>
                                <div className="supplier-name text-center mt-3 mb-3">
                                  <p className="text-black font-primary  font-16 mb-1">
                                    {member.name}
                                  </p>
                                  <h3 className="text-black font-primary font-weight-bold font-16 mb-1">
                                    {member.role}
                                  </h3>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </section>
                  )}
              </>
            )}
          </>
        )}
      </main>
      {showToast()}
    </>
  );
};

export default ViewSupplierProfile;
