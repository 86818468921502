import { useState } from "react";
import Modal from "react-modal";
import { UserConnectionSearch } from "../UserConnectionSearch/UserConnectionSearch";

interface Props {
  modalMessage: string;
  isModalOpen: boolean;
  closeModal: () => void;
  onConfirm: (user) => void;
  confirmText?: string;
  cancelText?: string;
}

export const AdminConnectModal = ({
  modalMessage,
  isModalOpen,
  closeModal,
  onConfirm,
  confirmText,
  cancelText,
}: Props) => {
  const [user, setUser] = useState(undefined);

  const customStyles = {
    content: {
      background: "transparent",
      border: "none",
      padding: "none",
      position: "absolute",
      display: "block",
      margin: "0",
      transform: "translate(-50%, -30%)",
      width: "90%",
      maxWidth: "660px",
      inset: "unset",
      overflow: "initial",
    },
    overlay: {
      zIndex: "3",
      backgroundColor: "rgba(23, 41, 64, 0.75)",
    },
  };

  const handleCloseModal = () => {
    setUser(undefined);
    closeModal();
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      closeTimeoutMS={500}
      ariaHideApp={false}
    >
      <div className="modal-content" style={{ borderRadius: "14.1562px" }}>
        <div className="modal-header border-0">
          <button
            className="close"
            type="button"
            data-dismiss="modal"
            onClick={handleCloseModal}
            aria-label="Close"
          >
            <span className="text-black" aria-hidden="true">
              <i className="icon-close-x" />
            </span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <span className="d-flex flex-column text-center  px-3 py-3 font-20">
            <h4 className="font-weight-bold text-black font-primary">
              {modalMessage}
            </h4>
          </span>
          <span className="d-flex flex-column text-center mx-3 px-lg-5 py-3 font-20">
            {<UserConnectionSearch setUser={setUser} />}
          </span>

          <span className="d-flex flex-column text-center mx-3 px-lg-5 py-3 font-14 text-black">
            <p>
              *A notification will be sent to both users, letting them know they
              are now connected.
            </p>
          </span>
        </div>
        <div className="modal-footer justify-content-center border-0 pb-4">
          <div className="row">
            <div className="col-auto text-center order-1 my-2">
              <button
                className="btn-lg btn modal-cancel-btn text-black font-weight-bold text-uppercase font-primary rounded"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                <span style={{ fontSize: "1rem" }}>
                  {cancelText || "Cancel"}
                </span>
              </button>
            </div>
            <div className="col-auto text-center order-1 my-2">
              <button
                className="btn btn-lg btn-primary btn-black text-pinky font-weight-bold text-uppercase rounded"
                disabled={!user}
                onClick={() => {
                  onConfirm(user);
                  handleCloseModal();
                }}
              >
                <span style={{ fontSize: "1rem" }}>
                  {confirmText || "Confirm"}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
