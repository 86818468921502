import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import { authContext } from '../../components/Authentication/CognitoContextProvider';
import { AdmissionStatus } from '../../types/User';
import { backendUrl } from '../../utils/env';

const AccountUnderAdmission = () => {
  const { auth } = useContext(authContext);
  const history = useHistory();
  const [userRole, setUserRole] = useState('');
  const [userAdmissionStatus, setUserAdmissionStatus] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth?.data) {
      (async () => {
        const roleResponse = await axios.get(
          `${backendUrl}/Users/${auth.data.attributes.sub}/role`
        );
        const role = roleResponse.data;
        setUserRole(role);

        const admissionStatusResponse = await axios.get(
          `${backendUrl}/Users/${auth.data.attributes.sub}/admissionStatus?role=${role}`
        );
        const admissionStatus = admissionStatusResponse.data;
        setUserAdmissionStatus(admissionStatus);
      })();
    }
  });

  useEffect(() => {
    handleAdmissionStatusChange(userAdmissionStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAdmissionStatus]);

  const handleAdmissionStatusChange = (admissionStatus: number) => {
    switch (admissionStatus) {
      case AdmissionStatus.APPROVED:
        history.replace('/');
        break;
      case AdmissionStatus.DENIED:
        history.replace('/account-denied');
        break;
      case AdmissionStatus.PENDING:
        history.replace('/account-under-admission');
        break;
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{`BSN - ${
          userAdmissionStatus === AdmissionStatus.PENDING
            ? 'Account Under Admission'
            : 'Account Denied'
        }`}</title>
      </Helmet>
      <main className="body-min-h90 py-5 px-4">
        {loading ? (
          <div className="pl-3 pt-5 mt-5 d-flex justify-content-center">
            <FadeLoader color={'#F8DCD4'} />
          </div>
        ) : userAdmissionStatus === AdmissionStatus.PENDING ||
          userAdmissionStatus === 0 ? (
          <div className="section mx-0 mt-5">
            <div className="row d-flex justify-content-center mb-5">
              <h1 className="font-weight-bold font-primary text-black text-center">
                Your account is under admission
              </h1>
            </div>
            <div className="row d-flex justify-content-center">
              <p className="text-black text-center">
                BGV Staff will take a look at your admission.
              </p>
            </div>
            <div className="row d-flex justify-content-center">
              <p className="text-black text-center">
                Once admitted you'll be able to access your account.
              </p>
            </div>
          </div>
        ) : (
          <div className="section mx-0 mt-5">
            <div className="row d-flex justify-content-center mb-5">
              <h1 className="font-weight-bold font-primary text-black text-center">
                Your account is currently denied
              </h1>
            </div>
            <div className="row d-flex justify-content-center">
              <p className="text-black text-center">
                Please contact{' '}
                <a
                  href="https://www.blackgirlventures.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  blackgirlventures.org
                </a>{' '}
                with your inquiry.
              </p>
            </div>
            <div className="row d-flex justify-content-center">
              <p className="text-black text-center">
                You can submit your admission again by editing your{' '}
                <span
                  className="link clickable text-primary"
                  onClick={() => {
                    history.push(
                      `/${userRole}/edit/${auth.data.attributes.sub}`,
                      { comesFromUnderAdmission: true }
                    );
                  }}
                >
                  profile information
                </span>
                .
              </p>
            </div>
          </div>
        )}
      </main>
    </>
  );
};

export default AccountUnderAdmission;
