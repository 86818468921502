import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import Home from './containers/Home/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { backendUrl, googleAnalyticsId } from './utils/env';
import { Helmet } from 'react-helmet';
import Onboarding from './containers/Onboarding/Onboarding';
import Login from './containers/Login/Login';
import Navbar from './components/Navbar/Navbar';
import { Footer } from './components/Footer/Footer';
import Redirecting from './containers/Redirecting/Redirecting';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import ConfirmReset from './containers/ConfirmReset/ConfirmReset';
import GuidedSearch from './containers/GuidedSearch/GuidedSearch';
import Search from './containers/Search/Search';
import CreateSupplierProfile from './containers/CreateSupplierProfile/CreateSupplierProfile';
import EditProfile from './containers/EditProfile/EditProfile';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import ViewSupplierProfile from './containers/ViewSupplierProfile/ViewSupplierProfile';
import VerifyAccount from './containers/VerifyAccount/VerifyAccount';
import MyConnections from './containers/MyConnections/MyConnections';
import { ViewCustomerProfile } from './containers/ViewCustomerProfile/ViewCustomerProfile';
import BottomNavbar from './components/BottomNavbar/BottomNavbar';
import { useContext, useEffect, useState } from 'react';
import { authContext } from './components/Authentication/CognitoContextProvider';
import { isMobile } from 'react-device-detect';
import EditCustomerProfile from './containers/EditCustomerProfile/EditCustomerProfile';
import {
  isCustomer,
  isGoogleUser,
  isSupplier,
  makeUserRole,
} from './auth/userUtils';
import Notifications from './containers/Notifications/Notifications';
import RoleSelection from './containers/RoleSelection/RoleSelection';
import AccountUnderAdmission from './containers/AccountUnderAdmission/AccountUnderAdmission';
import axios from 'axios';
import { UsersManagement } from './components/UsersManagement/UsersManagement';
import { HowItWorks } from './containers/Home/HowItWorks';
import { AdmissionStatus } from './types/User';
import ManageConnections from './containers/ManageConnections/ManageConnections';

function App() {
  const location = useLocation();
  const history = useHistory();
  const { auth, setAuthData } = useContext(authContext);
  const [showBottomNavbar, setShowBottomNavbar] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (window) window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    setShowBottomNavbar(false);
    const userLoggedIn = auth && auth.data;
    const path = location.pathname;
    const needsOpportunities =
      userLoggedIn &&
      localStorage.getItem('needsOpportunities') &&
      localStorage.getItem('needsOpportunities') === auth.data.attributes.sub;
    setShowBottomNavbar(!needsOpportunities && userLoggedIn && isMobile);
    if (
      needsOpportunities &&
      path !== '/redirecting' &&
      path !== '/onboarding/professional-information' &&
      path !== '/onboarding/areas-of-opportunity'
    )
      history.push('/onboarding/professional-information');
    let newActiveTab = 0;
    if (auth.data) {
      if (path === '/') newActiveTab = 1;
      else if (path === `/supplier/${auth.data.attributes.sub}`)
        newActiveTab = 2;
      else if (path === '/my-connections') newActiveTab = 3;
      else if (path === '/notifications') newActiveTab = 4;
    }
    setActiveTab(newActiveTab);

    if (
      auth &&
      auth.data &&
      isGoogleUser(auth.data) &&
      !isCustomer(auth.data)
    ) {
      (async () => {
        await makeUserRole(auth.data, 'is_customer', setAuthData);
      })();
    }

    if (auth && auth.data && (isCustomer(auth.data) || isSupplier(auth.data))) {
      let admissionStatus = -1;
      const role = isCustomer(auth.data) ? 'champion' : 'supplier';
      (async () => {
        const response = await axios.get(
          `${backendUrl}/Users/${auth.data.attributes.sub}/admissionStatus?role=${role}`
        );
        admissionStatus = response.data;
        if (admissionStatus === AdmissionStatus.PENDING)
          history.replace('/account-under-admission');
        else if (admissionStatus === AdmissionStatus.DENIED)
          !location.pathname.includes('/edit/') &&
            history.replace('/account-denied');
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, location.pathname]);

  return (
    <div>
      <Helmet>
        <title>BSN</title>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${googleAnalyticsId}');`}
        </script>
      </Helmet>
      <ToastContainer limit={1} />
      <Navbar />
      <Switch>
        <Route exact path="/" component={auth.data ? HowItWorks : Home} />
        <Route exact path="/onboarding/:step" component={Onboarding} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/redirecting" component={Redirecting} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/confirm-reset" component={ConfirmReset} />
        <Route
          exact
          path="/create-supplier-profile"
          component={CreateSupplierProfile}
        />
        <Route exact path="/verify-account" component={VerifyAccount} />
        <Route exact path="/role-selection" component={RoleSelection} />
        <ProtectedRoute
          key="edit-supplier"
          path="/supplier/edit/:supplierId"
          component={EditProfile}
          allowedRoles={['supplier', 'admin']}
        />
        <ProtectedRoute
          key="edit-customer"
          path="/champion/edit/:customerId" //Customers are now shown as 'champions' to the user
          component={EditCustomerProfile}
          allowedRoles={['customer', 'admin']}
        />
        <ProtectedRoute
          key="view-profile-supplier"
          exact
          path="/profile/supplier/:supplierId"
          component={ViewSupplierProfile}
        />
        <ProtectedRoute
          key="view-profile-customer"
          exact
          path="/profile/champion/:customerId" //Customers are now shown as 'champions' to the user
          component={ViewCustomerProfile}
        />
        <ProtectedRoute
          key="view-supplier"
          exact
          path="/supplier/:supplierId"
          component={ViewSupplierProfile}
        />
        <ProtectedRoute
          key="view-customer"
          exact
          path="/champion/:customerId" //Customers are now shown as 'champions' to the user
          component={ViewCustomerProfile}
        />
        <ProtectedRoute
          exact
          path="/guided-search/:id"
          component={GuidedSearch}
        />
        <ProtectedRoute exact path="/search" component={Search} />
        <ProtectedRoute
          path="/admin/manage-connections/:id"
          component={ManageConnections}
          allowedRoles={["admin"]}
        />
        <ProtectedRoute
          path="/admin/users-management"
          component={UsersManagement}
          allowedRoles={['admin']}
        />
        <ProtectedRoute
          exact
          path="/my-connections"
          component={MyConnections}
        />
        <ProtectedRoute exact path="/notifications" component={Notifications} />
        <ProtectedRoute
          key="account-under-admission"
          path="/account-under-admission"
          component={AccountUnderAdmission}
          allowedRoles={['customer', 'supplier']}
        />
        <ProtectedRoute
          key="account-denied"
          path="/account-denied"
          component={AccountUnderAdmission}
          allowedRoles={['customer', 'supplier']}
        />
        <Route path="*">
          <Redirect to="/" />
        </Route>{' '}
      </Switch>
      <Footer date={new Date()} />
      {showBottomNavbar && <BottomNavbar activeTab={activeTab} />}
    </div>
  );
}

export default App;
